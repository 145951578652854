import React, { useEffect, useRef, useState } from 'react';
import { Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader';
import CustomCkeditor from '../../../common/customeditor';
import * as pageServices from "../../../services/pages.services";
import { SpecialCharacter, globalLoader, handleServerValidations } from '../../../../../utils/commonfunction';
import { CMS_PAGES_POSITIONS, FOOTER_MENUS, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { Tooltip, Whisper } from 'rsuite';
import CustomCarousel from '../../../../user/shared/customCarousel';
import CustomChip from '../../../../user/shared/customChip';
import { ContentBlocksDetails, ContentBlocksEdit } from '../../../services/contentBlocks.services';




const ContentBlockEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showdefault, setShowDefault] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saveType, setSaveType] = useState('');
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Content Blocks", url: "/admin/cms/content-blocks/list/1" }, { title: "Edit", url: "" }];

    useEffect(() => {
        ContentBlocksDetails(params.id).then(data => {
            setShowDefault(data && data?.data ? data.data : [])
            setTimeout(() => {
                setDataLoaded(true);
            }, 100)
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    title: showdefault && showdefault.title ? showdefault.title : "",
                    richtext: showdefault && showdefault.description ? showdefault.description : "",
                }}
                validate={values => {
                    const error = {};
                    if (!values.title || !values.title.trim()) error.title = "Title is required";
                    if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append("o_id", params.id)
                    formData.append('title', values.title);
                    formData.append('description', values.richtext);
                    ContentBlocksEdit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if (saveType !== 'Save') {
                                setTimeout(() => {
                                    resetForm({ values: '' });
                                    globalLoader(false);
                                    navigate(`/admin/cms/content-blocks/list/1`)
                                }, 1000);
                            }
                            if (saveType === 'Save') {
                                setTimeout(() => {
                                    globalLoader(false);
                                }, 1000);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            });
                            globalLoader(false);
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Edit Content Block</h6>
                                    </div>
                                    {showdefault && Object.keys(showdefault).length > 0 ?
                                        <div className="row row-sm">
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                    </Whisper>
                                                </label>
                                                <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>

                                                {
                                                    dataLoaded ?
                                                        <CustomCkeditor
                                                            fieldname={"richtext"}
                                                            setFieldValue={setFieldValue}
                                                            value={values.richtext}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                        :
                                                        ""
                                                }
                                                <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                            </div>
                                            <div className="">
                                                <button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save
                                                </button>
                                                <button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Save & Exit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div> : <Loader />}
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>

    )
}

export default ContentBlockEdit