import axiosInstance from "../../../utils/axios";

const path = "web/leads";

  export const InvitationDetails = async (_id, status) => {
    return await axiosInstance().get(
      `${path}/invitation-detail?_id=${_id}`
    );
  };

  export const InvitationAccept = async (_id, status) => {
    return await axiosInstance().get(
      `${path}/invitation?_id=${_id}&status=${status}`
    );
  };

  export const CompleteProfileLMUser = async (values) => {
    return await axiosInstance().post(`${path}/complete-profile`, values)
}

export const QuestionList = async (values) => {
  return await axiosInstance().post(`${path}/question-list`, values)
}

export const ScanImage = async (values) => {
  return await axiosInstance().post(`${path}/scan-image`, values)
}
