import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import toHtml from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { CMS_PAGES_POSITIONS } from '../../../utils/Constants';
import * as commonService from "../../user/services/common.services";


const BackOfficeFooter = () => {
    const leadData = useSelector((state) => state.designportal);
    const globalDetails = useSelector((state) => state.globaldetail);
    const { t } = useTranslation();
    const loc = useLocation();
    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
    const [companyMenuData, setCompanyMenuData] = useState([]);
    const [impMenuData, setImpMenuData] = useState([]);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    useEffect(() => {
        commonService.menuOptions({position:CMS_PAGES_POSITIONS.FOOTER}).then((response) => {
            let companyMenu = [];
            let linksMenu = [];
            response?.data?.forEach(element => {
                if(element.footer_menu_name === 'company'){
                    companyMenu.push(element);
                }else{
                    linksMenu.push(element);
                }
            })
            setCompanyMenuData(companyMenu);
            setImpMenuData(linksMenu);
        }).catch((error) => {
            console.log("error",error);
        })
    },[loc])

    return (
        <>
            <footer className="bg-black">
                <div className="footer-box">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-4 footer-content me-auto">
                        <div className="footer-top-left">
                            <figure className="mb-4">
                            {/* <a href="index.php">
                                <img src={leadData?.data?.reverse_logo} alt="reverse logo" />
                            </a> */}
                                <img src={getGlobalData?.portal_design?.reverse_logo} alt="reverse logo" />
                            </figure>
                            <p className="pe-5">{getGlobalData?.settings?.footer_text}</p>
                        </div>
                        </div>
                        <div className="col-md-2 footer-content">
                        <h3>{t('web_footer_company')}</h3>
                        <ul className="footer-link">
                            {/* {getuserkey?.role!==STAFF_ROLE.LM_BACK_OFFICE ? 
                            <>
                            {surveysData && surveysData === true && (
                                <li>    
                                <Link to={"/survey/1"}>{t('web_footer_survey')}</Link>
                                </li>
                            )}
                            </>
                            :null
                            } */}
                            <li>    
                                <Link to={"/back-office/home"}>{t('web_lbl_home')}</Link>
                                </li>
                             <li>    
                                <Link to={"/back-office/leads/add"}>{t('web_lbl_add_lead_manually')}</Link>
                                </li>
                                <li>    
                                <Link to={"/back-office/leads/list/1"}>{t('web_lbl_leads')}</Link>
                                </li>
                            {/* {companyMenuData && companyMenuData.map((item,index) => {
                                return(
                                    <li key={index}>
                                        <Link to={`/back-office/dynamic-pages/${item?.slug}`}>{item?.title}</Link>
                                    </li>
                                )
                            })} */}
                        </ul>
                        </div>
                        {/* <div className="col-md-2 footer-content">
                        <h3>{t('web_footer_links')}</h3>
                        <ul className="footer-link">
                            <li>
                            </li>
                            {impMenuData && impMenuData.map((item,index) => {
                                return(
                                    <li key={index}>
                                        <Link to={`/back-office/dynamic-pages/${item?.slug}`}>{item?.title}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                        </div> */}
                        <div className="col-md-3 footer-content ps-5">

                        {console.log("item",getGlobalData?.settings?.social_media)}
                        {getGlobalData?.settings?.social_media ?    
                            <>
                                <h3>{t('web_footer_connect')}</h3>
                                <ul className="social-link">
                                {getGlobalData?.settings?.social_media.map((item, index) => (
                                    <li key={index}>
                                    <a href={item?.url} target="_blank" rel="noreferrer">
                                    {toHtml(item?.icon)}
                                    </a>
                                    </li>
                                ))
                                }
                                </ul>
                            </> : <></>
                        }
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                    <div className="text-center">
                        <p>{getGlobalData?.settings?.copy_right_text}</p>
                    </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default BackOfficeFooter