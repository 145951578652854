import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import {
  getFormatedTime,
  formateDateWithMonth,
  prepareQuestionsFormData,
} from "../../../../utils/commonfunction";
import Breadcrums from "../../common/breadcrumbs";
import DatePicker from "react-datepicker";
import * as Yup from 'yup';
import { Field, FieldArray, Form, Formik } from "formik";
import toHtml from 'html-react-parser'
import moment from "moment";
import $ from 'jquery';
import Loader from "../../common/loader";
import { Rating } from "react-simple-star-rating";
import "react-datepicker/dist/react-datepicker.css";
import DynamicQuestions from "../../../user/shared/DynamicQuestions";

const EditRegistrationDetail = () => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const params = useParams();
  const formikRef = useRef(null);
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const [invitationDetailsData, setInvitationDetailsData] = useState([]);
  const [detailsData, setDetailsData]= useState({}); 
  const [pricing, setPricing]= useState([]); 
  const [agenda, setAgenda]= useState([]); 
  const [formikHandle, setFormikHandle]= useState(0); 
  const [disableBtn, setDisableBtn]= useState(false); 
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [apiDone, setApiDone] = useState(false);
  const [secondApiDone, setSecondApiDone] = useState(false);
  const [thirdApi, setThirdApi] = useState(false);
  const [prevStateData, setPrevStateData] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);
  const { state } = useLocation();
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "View Event", url: "/admin/event-management/events/list/1" },
    {
      title: "View Registrations",
      url: `/admin/event-management/events/view/registrations/${params?.registrationId}/1`,
    },
    { title: "Edit Registered User info", url: "" },
  ];
  const dynamicQuestionsRef = useRef();
  const [questionFilesUploadPath] = useState("registrations/questions");
  const [eventsData, setEventsData]= useState([]); 

  useEffect(() => {
      // globalLoader(true);
      eventsService
        .RegistrationsDetails(query.get('reg_id'))
        .then((response) => {
          if(response.success){
            setEventsData(response?.data && response?.data?.event_data?response?.data?.event_data:null );
            setRegistrationData(response?.data);
            setFormikHandle(response?.data?.primary_user?.questions_data.length);
            setTimeout(() => {
              setApiDone(true);
              // globalLoader(false);
            },1000)
            let selectedAgenda = response?.data && response?.data?.primary_user && response?.data?.primary_user?.agenda ? response?.data?.primary_user?.agenda : [];
            if(detailsData){
              agenda && agenda.forEach((item, index) => {
                selectedAgenda && selectedAgenda.forEach((agenda, agendaIndex) => {
                  if(item?._id === agenda?.agendaId){
                    item.elements && item.elements.forEach((element, elementIndex) => {
                      agenda.elementIds && agenda.elementIds.forEach((selectedElement, i) => {
                        if(selectedElement === element._id){
                          element.checked = true;
                        }
                      })
                    })
                  }
                })
              })
            }
          }
        })
        .catch((err) => {
          console.log("err", err)
          setApiDone(true);
          // globalLoader(false);
        });
  }, [detailsData]);

  useEffect(() => {
    if(params?.registrationId && query.get('user_id')){
    eventsService.EventDetailsForEdit(params?.registrationId, query.get('user_id')).then((response) => {
      if(response && response.success){
        setDetailsData(response?.data);
        setPricing({event_price:response?.data?.event_quota?.price});
        let obj = {};
        if(response?.data?.choice_arrival_departure_date === true){
          obj.startDate = Yup.string().required("Start date is required");
          obj.endDate = Yup.string().required("End date is required");
        }
        if(state?.stateData?.agenda){
          /** For previous data */
          setAgenda(state?.stateData?.agenda)
        }else{
          setAgenda(response?.data?.agenda_data)
        }
        setFormikHandle(response?.data?.primary_question_set_data?.questions.length);
        setSecondApiDone(true);
      }
    }).catch((err) => {
      console.log("err",err);
      setSecondApiDone(true);
    })
  }
  },[params?.registrationId]);

  useEffect(() => {
      setPrevStateData(state?.stateData);
      /** For previous data */
      setAgenda(state?.stateData?.agenda);
      if (query.get('invitation_id') && query.get('invitation_id') != "null") {
        eventsService.InvitationsDetailsByEmail(query.get('invitation_id')).then((response) => {
          if (response.success) {
            setInvitationDetailsData(response?.data);
            setThirdApi(true);
          }
        }).catch((err) => {
          console.log("error", err);
          setThirdApi(true);
        })
      }
      else {
        setThirdApi(true);
      }
  }, []);

  useEffect(() => {
    if (detailsData && invitationDetailsData && registrationData){
      let preSelectedAns = [];
      let questionArr = registrationData?.primary_user?.questions_data.length ? registrationData?.primary_user?.questions_data : [];
      questionArr.forEach(item => {
        let singleAns = {}
          singleAns = {
            question_id: item.question_id,
            question_title: item.question_title,
            answer: item.answer
          }
        preSelectedAns.push(singleAns)
      })
      setPreSelectedValues([...preSelectedAns]);
      setSecondApiDone(true);
    }
  }, [invitationDetailsData, detailsData, registrationData])

  let schema = {
    checkIn: Yup.string().test('check_acommondation','Check in is required', (value) => {
      if(formikRef?.current?.values.accommondation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    checkOut: Yup.string().test('check_acommondation','Check out is required', (value) => {
      if(formikRef?.current?.values.accommondation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    hotel: Yup.string().test('check_acommondation','Hotel is required', (value) => {
      if(formikRef?.current?.values.accommondation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    roomType: Yup.string().test('check_acommondation','Room type is required', (value) => {
      if(formikRef?.current?.values.accommondation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    specifyText: Yup.string().test('check_acommondation','This field is required', (value) => {
      if(formikRef?.current?.values.accommondation){
        if(formikRef?.current?.values.allergic){
          return Boolean(value);
        }else{
          return true
        }
      }else{
        return true;
      }
    }),
    passportNumber: Yup.string().test('check_visa','Passport number is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    surname: Yup.string().test('check_visa','Surname is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    firstName: Yup.string().test('check_visa','First name is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    dateOfBirth: Yup.string().test('check_visa','date of birth is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    dateOfExpiry: Yup.string().test('check_visa','Date of expiry is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    dateOfIssue: Yup.string().test('check_visa','Date of issue is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    nationality: Yup.string().test('check_visa','Nationality is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    authority: Yup.string().test('check_visa','Authority is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
    placeOfBirth: Yup.string().test('check_visa','Place of birth is required', (value) => {
      if(formikRef?.current?.values.visaInvitation){
        return Boolean(value);
      }else{
        return true;
      }
    }),
  };

  const validationSchema = Yup.object().shape(schema);

  function validateElements (index=null){
    let errors = [];
    if(isFormSubmitted || index === null){
      for(let i = 0; i < agenda.length ; i++){
        const max = agenda[i].maximum_bookable_element;
        const min = agenda[i].minimum_bookable_element;
        let selectedElements = (agenda[i].elements.filter(item => item.checked)).length;
        if(min){
          if(min <= selectedElements){
            $(`#error-min-${agenda[i]._id}-${i}`).text("");
          }else{
            $(`#error-min-${agenda[i]._id}-${i}`).text("Please select at least minimum " + min + (min === 1  ? " element" : " elements"));
            errors.push({
              message:"Please select at least minimum " + min + (min === 1  ? " element" : " elements"),
              id:`error-min-${agenda[i]._id}-${i}`
            });
          }
        }
        if(max){
          if(max >= selectedElements){
            $(`#error-max-${agenda[i]._id}-${i}`).text("");
          }else{
            $(`#error-max-${agenda[i]._id}-${i}`).text("Cannot select more than " + max + " elements");
            errors.push({
              message:"Cannot select more than " + max + " elements",
              id:`error-max-${agenda[i]._id}-${i}`
            });
          }
        }
      }
    }else{
      const max = agenda[index].maximum_bookable_element;
        const min = agenda[index].minimum_bookable_element;
        let selectedElements = (agenda[index].elements.filter(item => item.checked)).length;
        if(min){
          if(min <= selectedElements){
            $(`#error-min-${agenda[index]._id}-${index}`).text("");
          }else{
            $(`#error-min-${agenda[index]._id}-${index}`).text("Please select at least minimum " + min + (min === 1  ? " element" : " elements"));
            errors.push({
              message:"Please select at least minimum " + min + (min === 1  ? " element" : " elements"),
              id:`error-min-${agenda[index]._id}-${index}`
            });
          }
        }
        if(max){
          if(max >= selectedElements){
            $(`#error-max-${agenda[index]._id}-${index}`).text("");
          }else{
            $(`#error-max-${agenda[index]._id}-${index}`).text("Cannot select more than " + max + " elements");
            errors.push({
              message:"Cannot select more than " + max + " elements",
              id:`error-max-${agenda[index]._id}-${index}`
            });
          }
        }
    }
    return errors;
  };

  const getMinDate = (startDate) => {
    let today = new Date();
    let start_date = new Date(startDate);
    
    const maxDate = today > start_date ? today : start_date;
    return maxDate;
  }

  
 const selectAgendaElement = (parentArray, items, value, agendaIndex) => {
   const newArray = parentArray.elements.map(i => {
     if(i._id === items._id){
       i.checked = value
       return i;
     }else{
       return i;
     }
   });

   const selectedAgenda = {...parentArray, elements : newArray};
   let anotherAgendas = [...agenda];
   anotherAgendas[agendaIndex] = selectedAgenda;
   setAgenda(anotherAgendas);
 }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* {console.log("apiDone",apiDone)}
      {console.log("thirdApi",thirdApi)}
      {console.log("(formikHandle === 0 || preSelectedValues.length)",(formikHandle === 0 || preSelectedValues.length))}
      {console.log("secondApiDone",secondApiDone)} */}
      <div className="card custom-card main-content-body-profile">
        {apiDone &&
        thirdApi &&
        (formikHandle === 0 || preSelectedValues.length) &&
        secondApiDone ? (
          <>
            <Formik
              validationSchema={validationSchema}
              innerRef={formikRef}
              validateOnBlur={false}
              initialValues={{
                vegan:
                  prevStateData && prevStateData?.vegan
                    ? prevStateData?.vegan
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.vegan
                    ? registrationData?.primary_user?.vegan
                    : false,
                allergic:
                  prevStateData && prevStateData?.allergic
                    ? prevStateData?.allergic
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.allergic
                    ? registrationData?.primary_user?.allergic
                    : false,
                vegeterian:
                  prevStateData && prevStateData?.vegeterian
                    ? prevStateData?.vegeterian
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.vegetarian
                    ? registrationData?.primary_user?.vegetarian
                    : false,
                visaInvitation:
                  prevStateData && prevStateData?.visaInvitation
                    ? prevStateData?.visaInvitation
                    : registrationData && registrationData?.visa_invitation
                    ? registrationData?.visa_invitation
                    : false,
                startDate:
                  prevStateData && prevStateData?.startDate
                    ? prevStateData?.startDate
                    : registrationData && registrationData?.start_date
                    ? registrationData?.start_date
                    : new Date(),
                endDate:
                  prevStateData && prevStateData?.endDate
                    ? prevStateData?.endDate
                    : registrationData && registrationData?.end_date
                    ? registrationData?.end_date
                    : new Date(),
                specifyText:
                  prevStateData && prevStateData?.specifyText
                    ? prevStateData?.specifyText
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.allergy_reason
                    ? registrationData?.primary_user?.allergy_reason
                    : "",
                passportNumber:
                  prevStateData && prevStateData?.passportNumber
                    ? prevStateData?.passportNumber
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.passport_number
                    ? registrationData?.primary_user?.visa_info?.passport_number
                    : "",
                surname:
                  prevStateData && prevStateData?.surname
                    ? prevStateData?.surname
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.surname
                    ? registrationData?.primary_user?.visa_info?.surname
                    : "",
                firstName:
                  prevStateData && prevStateData?.firstName
                    ? prevStateData?.firstName
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.first_name
                    ? registrationData?.primary_user?.visa_info?.first_name
                    : "",
                dateOfBirth:
                  prevStateData && prevStateData?.dateOfBirth
                    ? prevStateData?.dateOfBirth
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.date_of_birth
                    ? registrationData?.primary_user?.visa_info?.date_of_birth
                    : new Date(),
                dateOfExpiry:
                  prevStateData && prevStateData?.dateOfExpiry
                    ? prevStateData?.dateOfExpiry
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.date_of_expiry
                    ? registrationData?.primary_user?.visa_info?.date_of_expiry
                    : new Date(),
                dateOfIssue:
                  prevStateData && prevStateData?.dateOfIssue
                    ? prevStateData?.dateOfIssue
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.date_of_issue
                    ? registrationData?.primary_user?.visa_info?.date_of_issue
                    : new Date(),
                nationality:
                  prevStateData && prevStateData?.nationality
                    ? prevStateData?.nationality
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.nationality
                    ? registrationData?.primary_user?.visa_info?.nationality
                    : "",
                placeOfBirth:
                  prevStateData && prevStateData?.placeOfBirth
                    ? prevStateData?.placeOfBirth
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.place_of_birth
                    ? registrationData?.primary_user?.visa_info?.place_of_birth
                    : "",
                authority:
                  prevStateData && prevStateData?.authority
                    ? prevStateData?.authority
                    : registrationData &&
                      registrationData?.primary_user &&
                      registrationData?.primary_user?.visa_info &&
                      registrationData?.primary_user?.visa_info?.authority
                    ? registrationData?.primary_user?.visa_info?.authority
                    : "",
                questionData:
                  prevStateData && prevStateData?.questionData
                    ? prevStateData?.questionData
                    : [...preSelectedValues],
                selectedCheckboxes: [],
              }}
              onSubmit={(values) => {
                const questionFormResult = dynamicQuestionsRef?.current?.submit();
                if (validateElements().length || !questionFormResult.isValid) {
                  return;
                }
                let allElementPrice = [];
                agenda &&
                  agenda.forEach((item, index) => {
                    item?.elements?.forEach((element, eIndex) => {
                      if (element?.checked) {
                        allElementPrice.push({ elementPrice: element });
                      }
                    });
                  });
                let obj = { ...pricing };
                obj.primaryAgenda = allElementPrice;
                setPricing(obj);
                let result = dynamicQuestionsRef?.current?.getValues();
                let finalForm = prepareQuestionsFormData(result, new FormData(), questionFilesUploadPath);
                window.registrationFormStatus = true;
                window.registrationFinalForm = finalForm;
                navigate(
                  `/admin/event-management/events/registration-edit-step-two?event_id=${
                    params?.registrationId
                  }&invitation_id=${query.get(
                    "invitation_id"
                  )}&reg_id=${query.get("reg_id")}&user_id=${query.get(
                    "user_id"
                  )}&email=${query.get("email")}&edit=true`,
                  {
                    state: {
                      values: { ...values, agenda: agenda, pricing: obj },
                    },
                  }
                );
              }}
              validate={(values) => {
                return {};
              }}
            >
              {({
                values,
                handleSubmit,
                setFieldValue,
                handleChange,
                handleBlur,
                errors,
                touched,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="p-5">
                    <h3 className="inner-title">Registration</h3>
                    <div className="dash-box-shadow-2 position-relative">
                      {eventsData ? <>
                          <h4 className="reg-title">{eventsData?.title}</h4>
                          {detailsData?.event_quota?.price > 0 ? (
                          <span className="mb-2 price-text">
                            Price:
                            <span>
                              {detailsData?.currency?.sign}
                              {detailsData?.event_quota?.price}
                            </span>
                          </span>)
                          : <span className="mb-2 price-text text-success">Free</span>}
                          <ul className="card-info-list pb-0">
                            <li>
                              <div className="date-info pb-0">
                                <i className="ri-calendar-event-line black-icon" />
                                {formateDateWithMonth(detailsData?.start_date ? detailsData?.start_date: eventsData?.start_date)}
                                {" - "}
                                {formateDateWithMonth(detailsData?.end_date ? detailsData?.end_date : eventsData?.end_date)}
                              </div>
                            </li>
                          </ul>
                        </>
                        :<p style={{color:'red',fontWeight:'bold'}}>Event not found</p>
                      }
                    </div>
                    { detailsData && Object.keys(detailsData).length>0 ? 
                      <>
                        {detailsData && detailsData.choice_event_attendance_date === true && ( 
                            <div className="workshop-box">
                              <h5 className="reg-sub-title mb-2">
                                When will you attend the event?
                              </h5>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    Start date{" "}
                                    <span className="requirestar">*</span>
                                  </label>
                                  <div className="w-100 date-box date datepicker">
                                    {/* <DatePicker
                                      className=""
                                      oneTap={true}
                                      value={new Date(values.startDate)}
                                      onChange={(date) => {
                                        setFieldValue("startDate", date);
                                        setFieldValue("endDate", "");
                                      }}
                                      minDate={
                                        new Date(detailsData?.start_date) &&
                                        new Date()
                                      }
                                      maxDate={new Date(detailsData?.end_date)}
                                    /> */}
                                    <DatePicker 
                                      showMonthDropdown
                                      scrollableYearDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={30}
                                      onChange={date => {
                                        setFieldValue('startDate',date);
                                        setFieldValue('endDate',"");
                                      }} 
                                      className="form-control"
                                      value={values.startDate === "" ? "" : moment(values.startDate).format('DD/MM/YYYY')}
                                      minDate={getMinDate(detailsData?.start_date)}
                                      maxDate={new Date(detailsData?.end_date)}
                                    />
                                    <span className="input-group-append">
                                      <span className="input-cal-icon ">
                                        <i className="ri-calendar-2-line"></i>
                                      </span>
                                    </span>
                                  </div>
                                  <span className="text-danger text-start d-blockerr-spn">
                                    {errors.startDate &&
                                      touched.startDate &&
                                      errors.startDate}
                                  </span>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <label className="form-label">
                                    End date<span className="requirestar">*</span>
                                  </label>
                                  <div className="date-box date datepicker">
                                    {/* <DatePicker
                                      className=""
                                      value={new Date(values.endDate)}
                                      onChange={(date) =>
                                        setFieldValue("endDate", date)
                                      }
                                      oneTap={true}
                                      minDate={
                                        new Date(detailsData?.start_date) &&
                                        new Date(values?.startDate)
                                      }
                                      maxDate={new Date(detailsData?.end_date)}
                                    /> */}
                                    <DatePicker 
                                      onChange={date => setFieldValue('endDate',date)} 
                                      showMonthDropdown
                                      scrollableYearDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={30}
                                      className="form-control"
                                      value={values.endDate === "" ? "" : moment(values.endDate).format('DD/MM/YYYY')}
                                      minDate={new Date(detailsData?.start_date) && new Date(values?.startDate)}
                                      maxDate={new Date(detailsData?.end_date)}
                                    />
                                    <span className="input-group-append">
                                      <span className="input-cal-icon ">
                                        <i className="ri-calendar-2-line"></i>
                                      </span>
                                    </span>
                                  </div>
                                  <span className="text-danger text-start d-blockerr-spn">
                                    {errors.endDate &&
                                      touched.endDate &&
                                      errors.endDate}
                                  </span>
                                </div>
                              </div>
                            </div>
                        )}

                        <div className="about-dietary">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <h4 className="reg-title">
                                About Dietary requirements
                              </h4>
                              <h5 className="reg-sub-title mb-2">
                                Do you have a special requirement regarding your
                                diet
                              </h5>

                              <div className="chek-box-card mt-3">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="vegan"
                                    name="vegan"
                                    value={values.vegan}
                                    checked={values.vegan}
                                    onChange={(e) =>
                                      setFieldValue("vegan", e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="vegan"
                                  >
                                    I am vegan
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="vegeterian"
                                    id="vegeterian"
                                    checked={values.vegeterian}
                                    value={values.vegeterian}
                                    onChange={(e) =>
                                      setFieldValue("vegeterian", e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="vegeterian"
                                  >
                                    I am vegetarian
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="allergic"
                                    id="allergic"
                                    value={values.allergic}
                                    checked={values.allergic}
                                    onChange={(e) =>
                                      setFieldValue("allergic", e.target.checked)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="allergic"
                                  >
                                    I am allergic/others
                                  </label>
                                </div>
                              </div>
                              {values?.allergic && values?.allergic === true && (
                                <div className="form-group mt-3 please-specify">
                                  <label className="form-label">
                                    Please specify
                                  </label>
                                  <input
                                    type="text"
                                    name="specifyText"
                                    id="specifyText"
                                    placeholder="Please specify"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.specifyText}
                                  />
                                  <span className="text-danger text-start d-blockerr-spn">
                                    {errors.specifyText &&
                                      touched.specifyText &&
                                      errors.specifyText}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        
                        {agenda && agenda?.length > 0 ? (
                          <div className="agenda-box">
                            <h4 className="reg-title mb-4">About Agenda Options</h4>
                            {agenda &&
                              agenda.map((item, index) => (
                                <div key={index}>
                                  <h4 className="reg-title">{item.name}</h4>
                                  <ul className="card-info-list">
                                    <li>
                                      <div className="date-info pb-0">
                                        <i className="ri-calendar-event-line black-icon" />
                                        {item.start_date &&
                                          formateDateWithMonth(
                                            item.start_date
                                          )}{" "}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="card-info pb-0">
                                        <i className="ri-alarm-line black-icon" />
                                        {item.start_time &&
                                          getFormatedTime(item.start_time)}{" "}
                                        -{" "}
                                        {item.end_time &&
                                          getFormatedTime(item.end_time)}
                                      </div>
                                    </li>
                                  </ul>
                                  {toHtml(item.description)}
                                  <div className="radio-group-coustom d-flex row mt-3">
                                    {item.elements.map((items, i) => (
                                      <div
                                        className="form-check radio-coustom mb-2"
                                        key={i}
                                      >
                                        <input
                                          className="form-check-input cp"
                                          type="checkbox"
                                          name="flexRadioDefault"
                                          id={`flexRadioDefault1${i}${index}`}
                                          checked={items?.checked}
                                          disabled={!items?.available_quantity}
                                          onChange={(e) => {
                                            selectAgendaElement(
                                              item,
                                              items,
                                              e.target.checked,
                                              index
                                            );
                                          }}
                                        />
                                        <label
                                          className="form-check-label cp"
                                          htmlFor={`flexRadioDefault1${i}${index}`}
                                        >
                                          <h5>{items.name}</h5>
                                          {items.price > 0 ? (
                                            <span>
                                              {detailsData?.currency?.sign}
                                              {items.price +
                                                (items.price * items.tax_rate) /
                                                  100}{" "}
                                              <small>
                                                inclusive {items.tax_rate}% tax
                                              </small>
                                            </span>
                                          ) : (
                                            <span className="text-success">Free</span>
                                          )}
                                          {!items?.available_quantity ? (
                                            <span>
                                              <small className="ms-5 ps-5 text-danger">
                                                Out of stock
                                              </small>
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                  <span
                                    className="text-danger text-start d-blockerr-spn"
                                    id={`error-min-${item._id}-${index}`}
                                  ></span>
                                  <span
                                    className="text-danger text-start d-blockerr-spn"
                                    id={`error-max-${item._id}-${index}`}
                                  ></span>
                                  <hr className="divider less-space" />
                                </div>
                              ))}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div className="more-information">
                          <div className="row">
                            <div className="col-md-12 mb-3">
                              <h4 className="reg-title">
                                Few more information needed{" "}
                              </h4>
                                {detailsData?.primary_question_set_data?.questions && detailsData?.primary_question_set_data?.questions.length ?
                                  <DynamicQuestions ref={dynamicQuestionsRef} questions={detailsData?.primary_question_set_data?.questions} showCount={true}
                                    defaultValues={registrationData?.primary_user?.questions_data}
                                    parentClass="admin-edit-reg-questions" footer="" videoUploadPath={questionFilesUploadPath} />
                                  : null
                                }
                            </div>
                          </div>
                        </div>

                        <div className="about-invoice">
                          {detailsData &&
                            detailsData.askto_visa_verification === true && (
                              <div>
                                <div className="col-md-6 mb-3">
                                  <h4 className="reg-title">
                                    About Visa Invitation letter
                                  </h4>
                                  <div className="toggle-box d-flex align-items-center justify-content-between mb-3">
                                    <h4>Do you need a Visa Invitation letter?</h4>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={values.visaInvitation}
                                        onChange={(e) =>
                                          setFieldValue(
                                            "visaInvitation",
                                            e.target.checked
                                          )
                                        }
                                      />
                                      <span className="slider round" />
                                    </label>
                                  </div>
                                </div>
                                {values.visaInvitation && values.visaInvitation && (
                                  <div className="row">
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Passport number
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="passportNumber"
                                        id="passportNumber"
                                        placeholder=""
                                        className="form-control"
                                        value={values.passportNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.passportNumber &&
                                          touched.passportNumber &&
                                          errors.passportNumber}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Surname (as stated in your passport)
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="surname"
                                        id="surname"
                                        placeholder=""
                                        className="form-control"
                                        value={values.surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.surname &&
                                          touched.surname &&
                                          errors.surname}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        First name (as stated in your passport)
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        placeholder=""
                                        className="form-control"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.firstName &&
                                          touched.firstName &&
                                          errors.firstName}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Date of birth
                                        <span className="requirestar">*</span>
                                      </label>
                                      <div className="date-box date datepicker">
                                        {/* <DatePicker
                                          className=""
                                          oneTap={true}
                                          value={new Date(values.dateOfBirth)}
                                          onChange={(date) => {
                                            setFieldValue("dateOfBirth", date);
                                          }}
                                          // maxDate={new Date(detailsData?.end_date)}
                                        /> */}
                                        <DatePicker 
                                          showMonthDropdown
                                          scrollableYearDropdown
                                          showYearDropdown
                                          yearDropdownItemNumber={30}
                                          onChange={date => setFieldValue('dateOfBirth',date)}
                                          // placeholderText="Please select birth date"
                                          className="form-control"
                                          value={values.dateOfBirth === "" ? "" : moment(values.dateOfBirth).format('DD/MM/YYYY')}
                                        />
                                        <span className="input-group-append">
                                          <span className="input-cal-icon ">
                                            <i className="ri-calendar-2-line"></i>
                                          </span>
                                        </span>
                                      </div>
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.dateOfBirth &&
                                          touched.dateOfBirth &&
                                          errors.dateOfBirth}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Place of birth{" "}
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="placeOfBirth"
                                        id="placeOfBirth"
                                        placeholder=""
                                        className="form-control"
                                        value={values.placeOfBirth}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.placeOfBirth &&
                                          touched.placeOfBirth &&
                                          errors.placeOfBirth}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Nationality
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="nationality"
                                        id="nationality"
                                        placeholder=""
                                        className="form-control"
                                        value={values.nationality}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.nationality &&
                                          touched.nationality &&
                                          errors.nationality}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3 ">
                                      <label className="form-label">
                                        Date of issue
                                        <span className="requirestar">*</span>
                                      </label>
                                      <div className="date-box date datepicker">
                                        <div className="date-box date datepicker">
                                          {/* <DatePicker
                                            className=""
                                            oneTap={true}
                                            value={new Date(values.dateOfIssue)}
                                            onChange={(date) => {
                                              setFieldValue("dateOfIssue", date);
                                            }}
                                            maxDate={new Date()}
                                            // maxDate={new Date(detailsData?.end_date)}
                                          /> */}
                                          <DatePicker 
                                            showMonthDropdown
                                            scrollableYearDropdown
                                            showYearDropdown
                                            yearDropdownItemNumber={30}
                                            onChange={date => setFieldValue('dateOfIssue',date)} 
                                            // placeholderText="Please select issue date"
                                            className="form-control"
                                            value={values.dateOfIssue === "" ? "" : moment(values.dateOfIssue).format('DD/MM/YYYY')}
                                            maxDate={new Date()}
                                          />
                                          <span className="input-group-append">
                                            <span className="input-cal-icon ">
                                              <i className="ri-calendar-2-line"></i>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.dateOfIssue &&
                                          touched.dateOfIssue &&
                                          errors.dateOfIssue}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Date of expiry
                                        <span className="requirestar">*</span>
                                      </label>
                                      <div className="date-box date datepicker">
                                        {/* <DatePicker
                                          className=""
                                          oneTap={true}
                                          value={new Date(values.dateOfExpiry)}
                                          onChange={(date) => {
                                            setFieldValue("dateOfExpiry", date);
                                          }}
                                          minDate={new Date()}
                                          // maxDate={new Date(detailsData?.end_date)}
                                        /> */}
                                        <DatePicker 
                                          showMonthDropdown
                                          scrollableYearDropdown
                                          showYearDropdown
                                          yearDropdownItemNumber={30}
                                          onChange={date => setFieldValue('dateOfExpiry',date)} 
                                          // placeholderText="Please select expiry date"
                                          className="form-control"
                                          value={values.dateOfExpiry === "" ? "" : moment(values.dateOfExpiry).format('DD/MM/YYYY')}
                                          minDate={new Date()}
                                        />
                                        <span className="input-group-append">
                                          <span className="input-cal-icon ">
                                            <i className="ri-calendar-2-line"></i>
                                          </span>
                                        </span>
                                      </div>
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.dateOfExpiry &&
                                          touched.dateOfExpiry &&
                                          errors.dateOfExpiry}
                                      </span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                      <label className="form-label">
                                        Authority, Country
                                        <span className="requirestar">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        name="authority"
                                        id="authority"
                                        placeholder=""
                                        className="form-control"
                                        value={values.authority}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                      <span className="text-danger text-start d-blockerr-spn">
                                        {errors.authority &&
                                          touched.authority &&
                                          errors.authority}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          <button
                            className="btn btn-main-primary signbtn mr-2"
                            type="submit"
                            onClick={() => {
                              dynamicQuestionsRef?.current?.submit();
                              setIsFormSubmitted(true);
                              setDisableBtn(true);
                            }}
                          >
                            Next
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </button>
                        </div>
                      </> : 
                      <p style={{color:'red',fontWeight:'bold'}}>This user has been deleted.</p>
                    }
                  </div>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default EditRegistrationDetail;
