import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {attachDynamicStrings, setTitle} from '../../../utils/commonfunction'
import { cmsBanner, cmsDetails } from '../../user/services/common.services';

const InfoCounterHome = () => {

    const [bannerData, setBannerData] = useState([]);
    const loc = useLocation();
    const [homeData, setHomeData] = useState();

    useEffect(() => {
        cmsBanner().then((response) => {
            setBannerData(response?.data?.list);
            setTimeout(() => {
                const event = new CustomEvent("startSlider");
                document.dispatchEvent(event);
            }, 100);
        }).catch((err) => {
            console.log("err",err);
        })
    },[])
    

    useEffect(() => {
        cmsDetails("home_page_content").then((response) => {
          setHomeData(attachDynamicStrings(response?.data?.description));
          setTitle(response?.data?.title);
        })
        .catch((error) => {
        })
      },[loc]);

    useEffect(() => {
        cmsDetails("kljkjk").then((response) => {
          console.log(response.data)
        })
        .catch((error) => {
        })
      },[]);

    return (
    <>
        <div className="wraper-inner cpt">
            {/* <h3>{t('welcome')}</h3> */}
            <div className="owl-carousel owl-theme">
            {bannerData && bannerData.map((item,index) => (
            <div className="item" key={index}>
                <section 
                    className="banner-sec" 
                    style={{
                        background:`url(${item?.image}) no-repeat center center`
                    }}>
                        <div className="container">
                        <div className="row ">
                            <div className="col-md-8 m-auto pt-5 banner-sec-content">
                            <h1>{item?.title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: `${item?.description ? item?.description : "N/A"}` }}>
                                </div>
                            </div>
                        </div>
                        </div>
                    </section>
                </div>
            ))}
            </div>
      <div dangerouslySetInnerHTML={{ __html: `${homeData ? homeData : ""}` }}></div>
        </div>
    </>
    )
}

export default InfoCounterHome