import React, { useState, useEffect, useRef } from "react";
import { Form, Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as usersService from "../../services/usermgmt.services";
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import {
  LEADTYPES,
  SWAL_SETTINGS,
  showFilterlist,
} from "../../../../utils/Constants";
import {
  capitalizeFirstLetter,
  formateDate,
  globalLoader,
  handleServerValidations,
  hasPermission,
  removeCommas,
  removeSpecialCharacter,
  writeMyExcel,
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import {
  ExcelRead,
  InviteLeads,
  LeadManagementDelete,
  List,
  Options,
} from "../../services/leadManagement.services";
import { Button, Modal, Steps } from "rsuite";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import * as eventServices from "../../services/events.services";

const LeadManagementTable = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Lead Management", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  // const [fileType] = useState(Object.keys(GENDER).map((item => {
  //   return {
  //     label: item, value: GENDER[item]
  //   }
  // })));
  const [open, setOpen] = useState(false);
  const [step, setStep] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [file, setFile] = useState("");
  const [eventOptions, setEventOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [allUsersExportFlag] = useState(false);
  const [type] = useState(
    Object.keys(LEADTYPES).map((item) => {
      return {
        label: item,
        value: LEADTYPES[item],
      };
    })
  );

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setSelectedAllData(
            data && data.data && data.data.data_ids ? data.data.data_ids : []
          );
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    itemperpage,
    params.pgno,
  ]);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    Options()
      .then((response) => {
        if (response.success) {
          setOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const viewfunction = (row) => {
    navigate(`/admin/lead-management/view/${row._id}`);
  };

  // const ChangeStatus = (data, Num) => {
  //   let ids = Array.isArray(data) ? data : [data];
  //   const formData = new FormData();
  //   formData.append("o_id", JSON.stringify(ids));
  //   formData.append("status", Num);
  //   Swal.fire({
  //     customClass: "swal-wide",
  //     title: "Are you sure?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#403fad",
  //     cancelButtonColor: "#f1388b",
  //     confirmButtonText: "Yes",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       usersService
  //         .Status(formData)
  //         .then((response) => {
  //           if (response.success) {
  //             Swal.fire({
  //               icon: "success",
  //               text: response.message,
  //               ...SWAL_SETTINGS,
  //             });
  //             setStatusUpdate(!statsupdate);
  //           } else {
  //             Swal.fire({
  //               icon: "error",
  //               text: handleServerValidations(response),
  //               ...SWAL_SETTINGS,
  //             });
  //           }
  //         })
  //         .catch((error) => {
  //           console.log("error===>");
  //         });
  //     }
  //   });
  // };

  const exportAllLeads = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    // formData.append("event_id", id);
    List(formData)
      .then((response) => {
        let data =
          response && response?.data && response?.data?.list
            ? response.data.list
            : [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.questions_data?.length) {
            lengthArr = Array(item?.questions_data?.length).fill([]);
          }
        });
        let rows = data.map((item) => {
          const tempRow = [
            removeCommas(item?.name),
            removeCommas(item?.email),
            removeCommas(item?.event_data?.title),
            removeCommas(
              item?.is_draft === true || item?.is_draft === true
                ? "Draft"
                : "Completed"
            ),
            removeCommas(item?.lm_back_office_data?.name)
              ? removeCommas(item?.lm_back_office_data?.name)
              : "N/A",
            removeCommas(formateDate(item?.createdAt)),
          ];
          for (let i = 0; i < lengthArr?.length; i++) {
            let questionTitle;
            if (item && item?.questions_data && item?.questions_data[i]) {
              questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            } else {
              questionTitle = "N/A";
            }
            let answer;
            if (item && item.questions_data && item.questions_data[i]) {
              answer = item.questions_data[i].answer ?? "N/A";
            } else {
              answer = "N/A";
            }

            if (questionTitle === "N/A" && answer === "N/A") {
              tempRow.push("N/A");
            } else {
              tempRow.push(
                `${capitalizeFirstLetter(
                  removeSpecialCharacter(questionTitle)
                )}: ${answer}`
              );
            }
          }
          return tempRow;
        });

        const headers = [
          "Name",
          "Email",
          "Event",
          "Type",
          "Created By",
          "Created Date",
          ...lengthArr.map((_, index) => `Extra Info`),
        ];

        writeMyExcel([headers, ...rows], `lead-report.xlsx`)
          .then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };

  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectedRecords.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedRecords.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedRecords]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };

  const handleSelectAll = (check) => {
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelected = (data) => {
    return selectedRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT <<<<<<<<<********* */

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        let ids = Array.isArray(data) ? data : [data];
        formdata.append("o_id", JSON.stringify(ids));
        LeadManagementDelete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedRecords((data) =>
                data.filter((item) => {
                  return ids.indexOf(item) === -1;
                })
              );
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    console.log("keykey", key);
    console.log("valuevalue", value);
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    console.log("srrsrrsr", sr);
    setSearch(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilter")[0].selectedIndex = 0;
    $("#event")[0].selectedIndex = 0;
    $("#created_by")[0].selectedIndex = 0;
  };

  const goToEdit = (row) => {
    navigate(`/admin/lead-management/${params.pgno}/edit/${row._id}`);
  };


  // const handleFilterChange = (e) => {
  //   if (e.target.value) {
  //     prepareSearch("gender", e.target.value)
  //   } else {
  //     prepareSearch("gender", "")
  //   }
  // };

  const handleEventChange = (e) => {
    if (e.target.value) {
      prepareSearch("event_id", e.target.value);
    } else {
      prepareSearch("event_id", "");
    }
  };

  const handleCreatedByChange = (e) => {
    if (e.target.value) {
      prepareSearch("created_by", e.target.value);
    } else {
      prepareSearch("created_by", "");
    }
  };

  const inviteValidationUsers = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
  });

  const readExcelFile = (e) => {
    globalLoader(true);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      ExcelRead(formData)
        .then((response) => {
          setTimeout(() => {
            if (response?.success) {
              setFile(e?.target?.files[0]);
              setSelectedUser(response?.data);
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
              document.getElementById("file_reader").value = "";
            }
            globalLoader(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          globalLoader(false);
        });
    }
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearch("type", e.target.value);
    } else {
      prepareSearch("type", "");
    }
  };

  const onOkayClick = () => {
    globalLoader(true);
    let formData = new FormData();
    formData.append("title", title);
    formData.append("users", JSON.stringify(selectedUser?.list));
    formData.append("event_id", id);
    formData.append("file", file);
    InviteLeads(formData)
      .then((response) => {
        globalLoader(false);
        if (response?.success) {
          Swal.fire({
            icon: "success",
            text: response?.message,
            ...SWAL_SETTINGS,
          });
          setStep(step + 1);
          setTitle("");
        } else {
          Swal.fire({
            icon: "error",
            text: response?.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        Swal.fire({
          icon: "error",
          text: error,
          ...SWAL_SETTINGS,
        });
      });
  };

  const modalData = (steps) => {
    if (steps === 0) {
      return (
        <div className="my-3 upload-button ">
          <form
            id="reader_id"
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center ">
              <label htmlFor="file_reader">
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  id="file_reader"
                  onChange={readExcelFile}
                  accept=".xls,.xlsx,.csv"
                />
                <i
                  className="fa fa-upload cp"
                  aria-hidden="true"
                  style={{ fontSize: "7em", color: "#3598ff" }}
                ></i>
              </label>
              <p className="text-muted mb-0">Please upload excel file.</p>
              <a
                className="download-file my-0"
                href={`${process.env.REACT_APP_API_URL.replace(
                  "/webservice/api/v1",
                  ""
                )}/configs/default/LM-users-invitation-sample.xlsx`}
                download
              >
                Download sample file.
              </a>
            </div>
          </form>
        </div>
      );
    } else if (steps === 1) {
      return (
        <Formik
          initialValues={{
            title: title,
            event_id: id,
          }}
          onSubmit={(values) => {
            setStep(step + 1);
          }}
          validationSchema={inviteValidationUsers}
        >
          {({ handleSubmit, values, handleBlur, setFieldValue }) => (
            <div className="mt-4">
              <div className="row">
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">
                        Title:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className="form-control"
                        value={values.title}
                        onChange={(e) => {
                          setFieldValue("title", e?.target?.value);
                          setTitle(e?.target?.value);
                        }}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Select Event:</label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.event_id}
                            onChange={(e) => {
                              setFieldValue("event_id", e.target.value);
                              setId(e?.target?.value);
                            }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {eventOptions &&
                              eventOptions.length > 0 &&
                              eventOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setTitle("");
                        setStep(0);
                      }}
                      appearance="subtle"
                    >
                      Cancel
                    </Button>
                    <Button appearance="primary" type="submit">
                      Next
                    </Button>
                  </Modal.Footer>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      );
    } else if (steps === 2) {
      return (
        <div className="mt-4">
          <div className="table-responsive">
            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={selectedUser?.headings?.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {selectedUser.list.length ? (
                      selectedUser.list.map((row, i) => {
                        return (
                          <tr key={i}>
                            {selectedUser?.headings?.map((item, index) => {
                              return (
                                <td key={index}>
                                  {row[item.toString().toLowerCase()]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedUser?.headings?.length}
                          className="text-center"
                        >
                          No records
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <Modal.Footer className="mt-4">
            <div className="chek-box-card mt-3 d-flex justify-content-end"></div>
            <Button
              appearance="primary"
              onClick={() => {
                setStep(step - 1);
              }}
              type="button"
              className="mt-2"
            >
              Previous
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                onOkayClick();
              }}
              type="submit"
              className="mt-2"
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      );
    } else if (steps === 3) {
      return (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "68vh" }}
        >
          <h1>Success</h1>
        </div>
      );
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Lead Management</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearch}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select
                        className="form-control cp"
                        onChange={handleFilterChange}
                        id="resetFilter"
                        style={{ width: "130px" }}
                      >
                        <option value="">Type</option>
                        {type &&
                          type.length > 0 &&
                          type.map((option, i) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select
                        className="form-control cp"
                        onChange={handleEventChange}
                        id="event"
                      >
                        <option value="" label="Select event">
                          Select{" "}
                        </option>
                        {eventOptions &&
                          eventOptions.map((option, i) => {
                            return (
                              <option key={i} value={option._id}>
                                {option.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select
                        className="form-control cp"
                        onChange={handleCreatedByChange}
                        id="created_by"
                      >
                        <option value="" label="Select created by">
                          Select{" "}
                        </option>
                        {options &&
                          options.map((option, i) => {
                            return (
                              <option key={i} value={option._id}>
                                {option.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {/* <button className="btn ripple btn-main-primary signbtn mr-2" onClick={() => navigate(`/admin/lead-management/add`)}>Add New</button> */}
                {/* <button
            className="btn ripple btn-main-primary signbtn"
            onClick={() => setOpen(true)}
          >
            Invite Back Office
          </button> */}
                {allUsersExportFlag ? (
                  <>
                    <button
                      className="btn ripple btn-main-primary signbtn mr-2"
                      disabled={datalength === 0}
                      onClick={exportAllLeads}
                    >
                      Export Leads
                    </button>
                  </>
                ) : (
                  <button
                    className="btn ripple btn-main-primary signbtn mr-2"
                    disabled={datalength === 0}
                    onClick={exportAllLeads}
                  >
                    Export Leads
                  </button>
                )}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className="position-relative select_head">
                      <div className="select-check-container">
                        <label
                          htmlFor="selectAllCheck"
                          style={{ lineHeight: "unset" }}
                          className="ckbox  cp"
                        >
                          <input
                            ref={selectAllCheckbox}
                            id="selectAllCheck"
                            checked={
                              datalength &&
                              datalength === selectedRecords.length
                            }
                            onChange={(e) => handleSelectAll(e.target.checked)}
                            type="checkbox"
                          />
                          <span></span>
                        </label>
                      </div>
                    </th>
                    {/* <th>
                      <div className="sorting_column">
                        <span>Salutation</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="gender"
                        />
                      </div>
                    </th> */}
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="email"
                        />
                      </div>
                    </th>
                    <th>Event</th>
                    <th>Type</th>
                    <th>Created By</th>
                    <th className="created_head">
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    {/* <th className="status_head">Status</th> */}
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={11}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr
                              key={i}
                              className={isSelected(row) ? "selected" : ""}
                            >
                              <td className="position-relative">
                                <div className="select-check-container">
                                  <label
                                    htmlFor={row?._id + "input"}
                                    style={{ lineHeight: "unset" }}
                                    className="ckbox cp"
                                  >
                                    <input
                                      id={row?._id + "input"}
                                      checked={isSelected(row)}
                                      onChange={(e) =>
                                        selectRecord(row, e?.target?.checked)
                                      }
                                      type="checkbox"
                                      className="form-check select-check cp"
                                    />
                                    <span></span>
                                  </label>
                                </div>
                              </td>
                              {/* <td className="text-capitalize">{row?.gender ? row.gender : "N/A"}</td> */}
                              <td className="text-capitalize">
                                {row?.name ? row.name : "N/A"}
                              </td>
                              <td>{row?.email ? row.email : "N/A"}</td>
                              <td>
                                {row?.event_data?.title
                                  ? row?.event_data?.title
                                  : "N/A"}
                              </td>
                              <td>
                                {row?.is_draft === true ||
                                row?.is_draft === "true" ? (
                                  <span className="badge badge-danger">
                                    Draft
                                  </span>
                                ) : (
                                  <span className="badge badge-success">
                                    Completed
                                  </span>
                                )}
                              </td>

                              <td className="text-capitalize">
                                {row?.lm_back_office_data?.name ? (
                                  <Link
                                    to={`/admin/staff/view/${row.lm_back_office_data._id}`}
                                  >
                                    {row.lm_back_office_data.name}
                                  </Link>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                {row.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              {/* {hasPermission(
                                "/admin/user-management/users/status"
                              ) ? (
                                <td>
                                  {row.status === 1 ? (
                                    <button
                                      className="btn ripple btn-main-primary signbtn"
                                      onClick={() => {
                                        ChangeStatus(row?._id, 0);
                                      }}
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      className="btn ripple btn-secondary"
                                      onClick={() => {
                                        ChangeStatus(row?._id, 1);
                                      }}
                                    >
                                      Inactive
                                    </button>
                                  )}
                                </td>
                              ) : (
                                <td>
                                  {row?.status === 1 ? (
                                    <span className="badge badge-success">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="badge badge-danger">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              )} */}
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                  <button
                                    className="btn ripple btn-success mlAction"
                                    onClick={() => {
                                      goToEdit(row);
                                    }}
                                  >
                                    Edit
                                  </button>
                                  {hasPermission(
                                    "/admin/user-management/users/delete"
                                  ) ? (
                                    <button
                                      className="btn ripple btn-secondary mlAction"
                                      onClick={() => {
                                        Deletefunction(row?._id);
                                      }}
                                    >
                                      Delete
                                    </button>
                                  ) : null}
                                  {/* {hasPermission('/admin/user-management/users/more') ?
                                          <div className="btn-group mlAction">
                                            <button
                                              type="button"
                                              className="btn btn-dark dropdown-toggle"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              More
                                            </button>
                                            <ul className="dropdown-menu">
                                              {hasPermission('/admin/user-management/users/duplicate') ?
                                                <li>
                                                  <a style={{ color: "#647194" }}
                                                  href="/"
                                                    className="dropdown-item"
                                                    onClick={(e) =>{ 
                                                      e.preventDefault();
                                                      goToDuplicate(row)}}
                                                  >
                                                    Duplicate
                                                  </a>
                                                </li>
                                                : null}
                                            </ul>
                                          </div>
                                          : null} */}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  { name: "User", path: "/admin/lead-management/list" },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                {/* {hasPermission("/admin/user-management/users/status") ? (
                  <li>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 1);
                      }}
                    >
                      Active
                    </button>
                  </li>
                ) : null} */}
                {/* {hasPermission("/admin/user-management/users/status") ? (
                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 0);
                      }}
                    >
                      Inactive
                    </button>
                  </li>
                ) : null} */}
                {hasPermission("/admin/user-management/users/delete") ? (
                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        Deletefunction(selectedRecords);
                      }}
                    >
                      Delete
                    </button>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}

      {/* Invite Back Office Modal Start */}
      <Modal
        size={"full"}
        className="invitation-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setStep(0);
        }}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite Back Office</Modal.Title>
        </Modal.Header>
        <div>
          <Steps current={step}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Process" />
            <Steps.Item title="Preview" />
            <Steps.Item title="Success" />
          </Steps>
          <div>{modalData(step)}</div>
        </div>
      </Modal>
      {/* Invite Back Office Modal End */}
    </>
  );
};

export default LeadManagementTable;
