import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {  Modal } from 'rsuite';
import { copyText } from '../../../utils/commonfunction';
import { DEFAULT_TAB_HTML,SWAL_SETTINGS } from '../../../utils/Constants';
import Swal from 'sweetalert2';

const CustomTabs = forwardRef(({save, open }, ref) => {
    const [openModal, setOpenModal] = useState(false);
    const [slug, setSlug] = useState(DEFAULT_TAB_HTML);
    const validationSchema = Yup.object().shape({
        description: Yup.string(),
    });

    useEffect(() => {
        if (open) {
            setOpenModal(true);
        }
    }, [open]);


    const initialValues = {
        description: DEFAULT_TAB_HTML,
    };

    const handleCopy = (text) => {
        copyText(text);
        Swal.fire({
            icon: 'success',
            text: 'Copied',
            ...SWAL_SETTINGS,
        })
    }

    useImperativeHandle(ref, () => ({
        submitForm: () => {
            document.getElementById('tabForm').submit();
        }
    }));

    return (
        <>
            <Modal
                size={"lg"}
                className="invitation-modal modal-height-auto"
                open={openModal}
                onClose={() => { setOpenModal(false); save('') }}
                backdrop={'static'}
            >
                <Modal.Header className="mb-3">
                    <Modal.Title>Tab Demo</Modal.Title>
                    <label htmlFor={slug} className='text-left d-flex mt-2' onClick={() => { handleCopy(slug) }}><span className="badge badge-dark me-2 cp">Copy Tab Code</span><span className="text-warning ms-2">(Click to copy)</span></label>
                    <label style={{color:'red'}}>Please change related ids to avoid duplicacy</label>
                </Modal.Header>
                 <Modal.Body style={{ overflow: "hidden",overflowY:'auto' }}>
                    <div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                save(values);
                                setOpenModal(false);
                                resetForm();
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                handleBlur,
                                touched,
                                handleSubmit,
                                setFieldValue,
                            }) => (
                                <form id="tabForm" onSubmit={handleSubmit}>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="form-group">
                                            <textarea
                                                    type="text"
                                                    name="description"
                                                    placeholder='description'
                                                    value={values.description}
                                                    onChange={(e)=>{handleChange(e);setSlug(e.target.value)}}
                                                    onBlur={handleBlur}
                                                    className="form-control"
                                                    rows={20}
                                                ></textarea>
                                                <span className='text-danger d-flex text-left'><ErrorMessage name={"description"} /></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default CustomTabs;
