import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey, setLocalKey } from '../../../utils/commonfunction';

const UserPrivateRoute = ({ component: Component, restrictedRoles=[] }) => {
    const getuserkey = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {}

    function isRoleAllowed(role){
        return restrictedRoles.indexOf(role) === -1;
    }

    if(getuserkey && getuserkey.token){

    }else{
        if(window.location.pathname!=="/" && window.location.pathname!=="/login")
        {
            setLocalKey("return_url", window.location.href);
        }
    }
    return (
        getuserkey && getuserkey.token && isRoleAllowed(getuserkey.role) ? <Component /> : <Navigate to="/login" />
    )
}

export default UserPrivateRoute;