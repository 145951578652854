import React, { useEffect, useState, useRef } from "react";
import {
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {  useSelector } from "react-redux";
import { ErrorMessage, Field, Formik } from "formik";
import * as authService from "../services/auth.services";
import {
  handleServerValidations,
  setLocalKey,
  removeSpacesAndCharacters,
  getSessionKey,
  getLocalKey,
  removeLocalKey,
  selectSalutation,
  getTranslatedGender
} from "../../../utils/commonfunction";
import Swal from "sweetalert2";
import { 
  GENDER, 
  SWAL_SETTINGS, 
  ATLEAST_ONE_CAPITAL_REGEX, 
  ATLEAST_ONE_NUMBER_REGEX, 
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ATLEAST_ONE_SMALL_REGEX,
  COOKIES_EXPIRATION,
  STAFF_ROLE
} from "../../../utils/Constants";
import * as commonServices from "../services/common.services";
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const CompleteProfile = () => {
  const navigate = useNavigate();
  const formikRef = useRef();
  const [params] = useSearchParams();
  const [show, setShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [apiDone, setApiDone] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [invitationDetailsData, setInvitationDetailsData] = useState("");
  const [dynamicValidations, setDynamicValidations] = useState({});
  const register_event_id = getSessionKey('register_event_id');
	const { t } = useTranslation();
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);


  const handleshow = (event) => {
    if (event === "pwd") {
      setShow(!show);
    }
    if (event === "confirmpwd") {
      setConfirmShow(!confirmShow);
    }
  };

  useEffect(() => {
    commonServices.InvitationsDetailsByEmail(params.get('invitation_id')).then((response) => {
      if(response.success){
        setInvitationDetailsData(response?.data);
        if(response?.data && response?.data?.event_data){
          setApiDone(true);
        }
      }
    }).catch((err) => {
      console.log("error",err);
    })
  },[]);

  useEffect(() => {
    let obj = {};
    getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.map((item, index) => {
      if(item == 'gender'){
        obj.gender = Yup.string().oneOf(Object.values(getTranslatedGender()),t('web_lbl_invalid_err_salutation')).required(t('web_registration_err_salutation'))
      }
      else if(item == 'title'){
        obj.title = Yup.string().trim().required(t('web_lbl_err_title'));
      }
      else if(item == 'first_name'){
        obj.first_name = Yup.string().trim().required(t('web_registration_err_firstname'));
      }
      else if(item == 'middle_name'){
        obj.middle_name = Yup.string().trim().required(t('web_lbl_err_middle_name'));
      }
      else if(item == 'last_name'){
        obj.last_name = Yup.string().trim().required(t('web_registration_err_lastname'))
      }
      else if(item == 'event_function'){
        obj.function = Yup.string().trim().required(t('web_lbl_err_function'));
      }
      else if(item == 'phone_number'){
        obj.phone_number = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number'))
        .test("min-length", (t('web_registration_err_min_mobile_number')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number')), (value) => {
          if(value?.trim()?.length > 13){
            return false
          }else{
            return true
          }
        })
      }else if(item == 'phone_number_work'){
        obj.phone_number_office = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number_work'))
        .test("min-length",  (t('web_registration_err_min_mobile_number_work')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number_work')), (value) => {
          if(value?.trim()?.length > 14){
            return false
          }else{
            return true
          }
        })
      }
      else if(item == 'files'){
        obj.files = Yup.mixed().required(t('web_lbl_err_image')).test(
          'fileType',
         (t('web_lbl_err_file_format')),
          (value) => {
            if (!value) {
              const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
              return supportedFormats.includes(value.type);
            } else {
              return true
            }
          }
        )
      }
      else if(item == 'password'){
     obj.new_password = Yup.string()
    .required(t('web_lbl_err_password'))
    .matches(ATLEAST_ONE_SMALL_REGEX, (t('web_lbl_err_password_lower')))
    .matches(ATLEAST_ONE_CAPITAL_REGEX, (t('web_lbl_err_password_capital')))
    .matches(ATLEAST_ONE_NUMBER_REGEX, (t('web_lbl_err_password_number')))
    .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, (t('web_lbl_err_password_special_character')))
    .min(PASSWORD_MIN_LENGTH, (t('web_lbl_err_password_8_character_long')))
    .max(PASSWORD_MAX_LENGTH, (t('web_lbl_err_password_more_than')))
      }
    else if(item == 'confirm_password'){
      obj.confirm_new_password =  Yup.string().required(t('web_lbl_err_confirm_password')).oneOf([Yup.ref("new_password"), null], (t('web_lbl_err_password_and_confirm')))
      }
    })
    setDynamicValidations(obj);
  },[getGlobalData]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').trim().required(t('web_registration_err_email')),
    ...dynamicValidations
  });
  

  return (
    <div className="wraper-inner cpt">
      <section className="accounts-page section-padding">
        <div className="container ">
          <div className="d-flex justify-content-center align-item-center">
            <div className="accounts-page-box-right col-md-5">
            {apiDone ||  params.get('invitation_id') === null ? (
              <Formik
              validateOnBlur={false}
              innerRef={formikRef}
              initialValues={{
                files: "", 
                title: invitationDetailsData?.fields?.title ? invitationDetailsData?.fields?.title : "",
                gender: invitationDetailsData?.fields?.gender ? selectSalutation(invitationDetailsData?.fields?.gender) : "",
                first_name: invitationDetailsData?.fields?.firstname ? invitationDetailsData?.fields?.firstname : "",
                middle_name: invitationDetailsData?.fields?.middlename  ? invitationDetailsData?.fields?.middlename : "",
                last_name: invitationDetailsData?.fields?.lastname ? invitationDetailsData?.fields?.lastname : "", 
                function: invitationDetailsData?.fields?.function ? invitationDetailsData?.fields?.function : "", 
                email:params.get('email')? params.get('email'):null,
                dialCodePhone: "",
                phone_number: "",
                dialCodePhoneOffice:"",
                phone_number_office: "",
                new_password: "",
                confirm_new_password: "",
                t_and_c : false,
                agree_user : false,
              }}
              validationSchema={validationSchema}
              validate={(values,errors) => {
console.log("hbjhij",formikRef?.current?.errors)
              }}
              onSubmit={(values, { setSubmitting }) => {
                
                if(values.t_and_c && values.agree_user){
                let formData = new FormData();
                formData.append("first_name", values.first_name);
                formData.append("middle_name", values.middle_name);
                formData.append("last_name", values.last_name);
                formData.append("image", values.files);
                formData.append("gender", values.gender);
                formData.append("title", values.title);
                formData.append("event_function", values.function);
                formData.append("phone_number", JSON.stringify({ code: values.dialCodePhone, number:  removeSpacesAndCharacters(values.phone_number)}));
                formData.append("phone_number_work", JSON.stringify({ code: values.dialCodePhoneOffice, number: removeSpacesAndCharacters(values.phone_number_office)}));
                formData.append("password", values.new_password);
                formData.append("confirm_password", values.confirm_new_password);
                formData.append("email", params.get('email') ? params.get('email') : values.email);
                formData.append("event_id",params.get('event_id')? params.get('event_id'):null);
                formData.append("invitation_id",params.get('invitation_id') ? params.get('invitation_id') : null);
                formData.append("role", invitationDetailsData?.fields?.role ? invitationDetailsData?.fields?.role :STAFF_ROLE.USER);
                for (var pair of formData.entries()) {
                  console.log(pair[0]+ ', ' + pair[1]); 
                }
                // return
                authService
                  .completeProfile(formData)
                  .then((response) => {
                    if (response.success) {
                      /** Remember last logged-in user >>>> */
                      if(getSessionKey('remember-me') === "true"){
                        Cookies.set('username', response?.data?.email, { expires: COOKIES_EXPIRATION });
                      }
                      /** Remember last logged-in user <<<< */
                      setLocalKey("user", JSON.stringify(response.data));
                      Swal.fire({
                      icon: "success",
                      text: response.message,
                      ...SWAL_SETTINGS,
                      });
                      let returnUrl = getLocalKey("return_url");
                      if (returnUrl && register_event_id) {
                        removeLocalKey("return_url");
                        window.location.href = returnUrl;
                        // removeSessionKey('register_event_id')
                      }
                      else{
                        if(params.get('invitation_id')&& params.get('invitation_id').length){

                          navigate(`/event-detail/${params.get('event_id')}?email=${params.get('email')}&invitation_id=${params.get('invitation_id')}`);
                        }
                        else{
                          navigate(`/open-registration/events/list/1`);
                        }
                      }
                    } else {
                      Swal.fire({
                      icon: "error",
                      text: handleServerValidations(response),
                      ...SWAL_SETTINGS,
                      });
                      setTimeout(() => {
                      }, 2000);
                    }
                  })
                  .catch((error) => {
                  console.log("error: ", error);
                  });
                }else {
                  Swal.fire({
                    icon: "error",
                    text: "Please accept both term and conditions.",
                    ...SWAL_SETTINGS,
                  });
                }
              }}
              >
                {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                }) => (
                  <div className="accounts-page-box-inner-L">
                    {/* {checkValidationError(errors)} */}
                    <div className="common-bg-form">
                      <form
                        className="form-box login-form"
                        onSubmit={handleSubmit}
                        >
                        <div className='form-group mb-4'>
                          <input 
                            style={{paddingLeft: 10,}}
                            className='form-control increaseBtnFile' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                            setFieldValue("files", event.currentTarget.files[0] || "");
                            if(event.currentTarget.files.length === 1){
                              setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
                            }
                            else{
                              setPreviewImage("")
                            }
                          }} />
                       <span className='text-danger d-flex text-left'>{errors.files && touched.files && errors.files}</span>
                        </div>
                        {
                          previewimage ?
                            <div className='d-flex mb-4'>
                              <img src={previewimage} style={{ height: "100px" }} alt="PreviewImage" />
                            </div> : ""
                        }
                        <div className="form-group mb-4 ">
                          <div className="select-down-arrow ">
                            <Field as='select' id="gender" name={"gender"} className="form-control select2 text-capitalize">
                              <option value="" label={t('web_lbl_select_salutation')}>{t('web_lbl_select')}{" "}</option>
                              {getTranslatedGender() && Object.values(getTranslatedGender()).map((option, i) => {
                                return (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                          <span className='text-danger d-flex text-left'>{errors.gender && touched.gender && errors.gender}</span>
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control"
                            placeholder={t('web_lbl_enter_your_title')}
                            type="text"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            autoComplete="off"
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.title && touched.title && errors.title}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control"
                            placeholder={t('web_lbl_enter_your_first_name')}
                            type="text"
                            name="first_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            autoComplete="off"
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.first_name && touched.first_name && errors.first_name}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control"
                            placeholder={t('web_lbl_enter_your_middle_name')}
                            type="text"
                            name="middle_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.middle_name}
                            autoComplete="off"
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                              {errors.middle_name && touched.middle_name && errors.middle_name}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control"
                            placeholder={t('web_lbl_enter_your_last_name')}
                            type="text"
                            name="last_name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            autoComplete="off"
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                              {errors.last_name && touched.last_name && errors.last_name}
                          </span>
                        </div>
                          <div className="form-group mb-4">
                            <input
                              className="form-control"
                              placeholder={t('web_lbl_enter_your_email')}
                              type="text"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              autoComplete="off"
                              disabled = {params.get('email')? true:false}
                            />
                            <span className="text-danger text-start d-blockerr-spn">
                                {errors.email && touched.email && errors.email}
                            </span>
                          </div>
                        <div className="form-group mb-4">
                          <input
                            className="form-control"
                            placeholder={t('web_lbl_enter_function_name')}
                            type="text"
                            name="function"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.function}
                            autoComplete="off"
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.function && touched.function && errors.function}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <PhoneInput 
                            // country={"de"}
                            value={values.dialCodePhone + values.phone_number} 
                            placeholder={t('web_lbl_enter_your_phone_number')}
                            className="form-control"
                            buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                            containerStyle={{padding: 0,}} 
                            inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                            onChange={(value, data, event, formattedValue) => {
                              // console.log("formattedValue", formattedValue);
                              setFieldValue("dialCodePhone", formattedValue.substring(0, formattedValue.indexOf(" ")))
                              setFieldValue(`phone_number`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                            }} 
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.phone_number && touched.phone_number && errors.phone_number}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <PhoneInput 
                            // country={"de"}
                            value={values.dialCodePhoneOffice + values.phone_number_office} 
                            placeholder={t('web_lbl_enter_your_phone_number_official')}
                            className="form-control"
                            buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                            containerStyle={{padding: 0,}} 
                            inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                            onChange={(value, data, event, formattedValue) => {
                              // console.log("formattedValue", formattedValue);
                              setFieldValue("dialCodePhoneOffice", formattedValue.substring(0, formattedValue.indexOf(" ")))
                              setFieldValue(`phone_number_office`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                            }} 
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.phone_number_office && touched.phone_number_office && errors.phone_number_office}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <div className="with-icon">
                            <input
                              className="form-control"
                              placeholder={t('web_lbl_enter_your_new_password')}
                              type={show === true ? "text" : "password"}
                              name="new_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.new_password}
                              autoComplete="off"
                            />
                            <div className="icon-box cp" onClick={() => handleshow("pwd")}>
                              {!show === true ? (
                                  <i className="far fa-eye-slash"></i>
                              ) : (
                                  <i className="far fa-eye"></i>
                              )}
                            </div>
                          </div>
                          <span className="text-danger text-start d-block err-spn">
                            {errors.new_password &&
                            touched.new_password &&
                            errors.new_password}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <div className="with-icon">
                            <input
                              className="form-control"
                              placeholder={t('web_lbl_enter_your_new_confirm_password')}
                              type={confirmShow === true ? "text" : "password"}
                              name="confirm_new_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirm_new_password}
                              autoComplete="off"
                            />
                            <div className="icon-box cp" onClick={() => handleshow("confirmpwd")}>
                            {!confirmShow === true ? (
                                <i className="far fa-eye-slash"></i>
                            ) : (
                                <i className="far fa-eye"></i>
                            )}
                            </div>
                          </div>
                          <span className="text-danger text-start d-block err-spn">
                            {errors.confirm_new_password &&
                            touched.confirm_new_password &&
                            errors.confirm_new_password}
                          </span>
                        </div>
                        <div className="form-group mb-4">
                          <div className="d-flex ">
                            <input
                              type="checkbox"
                              name="checkbox"
                              autoComplete="off"
                              id="terms_condition_one"
                              className="cp"
                              checked={values.t_and_c}
                              onChange={(e) => setFieldValue('t_and_c', e.target.checked)}
                            />
                            <label htmlFor="terms_condition_one" className="ms-2 cp" >
                              <div dangerouslySetInnerHTML={{ __html: `${getGlobalData?.settings?.terms_and_condition_one ? getGlobalData?.settings?.terms_and_condition_one : "N/A"}` }}></div>
                            </label>
                          </div>
                        </div>
                        <div className="form-group mb-4">
                            <div className="d-flex" style={{alignItems:'self-start'}}>
                              <input
                                style={{marginTop:'5px'}}
                                className="cp"
                                type="checkbox"
                                name="checkbox"
                                id="terms_conditions_two"
                                autoComplete="off"
                                checked={values.agree_user}
                                onChange={(e) => setFieldValue('agree_user', e.target.checked)}
                              />
                              <label htmlFor="terms_conditions_two" className="ms-2 cp">
                                <div dangerouslySetInnerHTML={{ __html: `${getGlobalData?.settings?.terms_and_condition_two ? getGlobalData?.settings?.terms_and_condition_two : "N/A"}` }}></div>
                              </label>
                            </div>
                        </div>
                        <div className="btn-box text-center mt-4 ">
                          <button
                              className="btn btn-primary w-100 h-50"
                              type="submit"
                          >
                         {t('web_lbl_submit')}
                          </button>
                        </div>
                          <button
                              className="btn btn-primary mt-2  w-100 h-50"
                              type="button"
                              onClick={(e)=>{ navigate('/')}}
                          >
                         {t('web_lbl_back_to_login')}
                          </button>
                      </form>
                    </div>
                  </div>
                )}
              </Formik>
            ) : (
              <div style={{height: "25vh"}}>
                <h1>{t('web_lbl_url')}</h1>
              </div>
            )}
            </div>
          </div>
        </div>  
      </section>
    </div>
  );
};

export default CompleteProfile;
