import { createSlice } from '@reduxjs/toolkit';
export const EventSlice = createSlice({
    name: 'eventData',
    initialState: "",
    reducers: {
        eventData: (state, action) => {
            return state = action.payload;
        },
    }
})

export const { eventData } = EventSlice.actions;
export default EventSlice.reducer;