import React, { useEffect, useState } from 'react';
import * as otherServices from '../services/common.services';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { attachDynamicStrings, globalLoader, setTitle } from '../../../utils/commonfunction'
import likeImage from "../../../assets/user/images/thump-icon.png";
import { useTranslation } from 'react-i18next';
import { EVENT_TYPES } from '../../../utils/Constants';

const RegistrationThanksPage = () => {

    const loc = useLocation();
    const navigate = useNavigate();
    const [query] = useSearchParams();
    const [invitationSuccess, setInvitationSuccess] = useState();
    const { t} = useTranslation();


    useEffect(() => {
        globalLoader(false);
        otherServices.cmsDetails("registration_successful").then((response) => {
            setInvitationSuccess(attachDynamicStrings(response?.data?.description));
            setTitle(response?.data?.title);
        }).catch((error) => {
        })
    }, [loc]);

    useEffect(() => {
        if(query.get('paypalsuccess') === "true"){
            globalLoader(true);
            otherServices.executePayment({
                payment_id: query.get('paymentId'),
                payer_id : query.get('PayerID')
            }).then((response) => {
                if(response.success){
                    navigate(`/registration-thanks-page?paypalsuccess=false&paymentId=${query.get('paymentId')}&PayerID=${query.get('PayerID')}`);
                    globalLoader(false);
                }else{
                    globalLoader(false);
                }
            }).catch((err) => {
                console.log("err",err);
                globalLoader(false);
            })
        }
    },[])

    return (
        <>
       <div className="wraper-inner cpt bg-grey">
                <section className="inner-space space-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 m-auto thanku-card-main">
                                <div className="dash-box-shadow pb-5">
                                    <div className="thanku-card text-center mb-4 ">
                                        <figure className="mb-3">
                                            <img src={likeImage} alt="LikeImage" />
                                        </figure>
                                        {loc?.state?.event_type===EVENT_TYPES.OPEN_PENDING_APPROVAL ?  <p>Your Regisration has been placed successfully and currently it is in review</p>:
                                        <div dangerouslySetInnerHTML={{ __html: `${invitationSuccess ? invitationSuccess : "N/A"}` }}></div> }
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Link className='btn btn-primary mb-4' to={"/settings/profile"}>{t('web_lbl_go_to_profile')}</Link>
                                    </div>
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RegistrationThanksPage