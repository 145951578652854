import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import { formateDate, handleServerValidations } from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import { BookingList, BookingsCancel } from "../../services/roommanagement.services";
import Swal from "sweetalert2";
import { BOOKING_STATUS, SWAL_SETTINGS } from "../../../../utils/Constants";
import StatusFilter from "../../common/statusFilter";
import $ from "jquery";


const BookingTable = () => {
  const [status] = useState([{ name: "Status", status__id: "" }, { name: "Active", status__id: BOOKING_STATUS.ACTIVE }, { name: "Pending", status__id: BOOKING_STATUS.PENDING }, { name: "Cancelled", status__id: BOOKING_STATUS.CANCELLED }]);
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [bookingList, setBookingList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  /********** MULTI SELECT <<<<<<********* */

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("room_id", params.id);
    BookingList(formData)
      .then((response) => {
        setDataLength(response.data.total_records);
        setBookingList(
          response && response?.data && response?.data?.list
            ? response.data.list
            : []
        );
        setSelectedAllData(
          response && response.data && response.data.data_ids
            ? response.data.data_ids
            : []
        );
        setPage(
          response && response.data && response.data.page
            ? response.data.page
            : 1
        );
        setLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    itemperpage,
    params.pgno,
    params.id,
  ]);

  const handleViewDetails = (bookingId, room_id) => {
    const to = `/admin/room-management/view/booking/${bookingId}`;
    const state = { room_id };
    navigate(to, { state });
  };

  // sorting function start
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function start
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
  };
  // reset filter function end

  function CancelFunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You wan't to cancel this booking!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("o_id",data?._id);
        BookingsCancel(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("cancelError");
          });
      }
    });
  }

  const getStatus = (status) => {
    if (status === BOOKING_STATUS.PENDING) {
      return <span className="badge badge-warning">Pending</span>;
    } else if (status === BOOKING_STATUS.ACTIVE) {
      return <span className="badge badge-success">Active</span>;
    } else if (status === BOOKING_STATUS.CANCELLED) {
      return <span className="badge badge-danger">Cancelled</span>;
    }
  };

  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="main-content-label">
            <span></span>
          </h6>
          <div className="d-flex align-items-center">
            <div className="form-group mb-0 me-3">
              <div className="form-group mb-0 rangepicker_container filter_design">
                <i className="fa fa-search calender_icon"></i>
                <input
                  type="search"
                  className="form-control"
                  value={globalsearch}
                  placeholder="Search"
                  onChange={(e) => {
                    setGlobalSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="me-3">
              <CustomRangepicker
                GetDateRange={(e) => {
                  prepareSearch("createdAt", e);
                }}
                resetdate={resetdate}
              />
            </div>
            <div className="me-3">
             <StatusFilter
             data={status}
              prepareSearch={prepareSearch}
             />
            </div>
            <button
              type="reset"
              value="Reset"
              onClick={resetFilter}
              className="btn btn-warning float-right mr-2"
            >
              Reset Filter
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th>
                  <div className="sorting_column">
                    <span>Title</span>
                    <Sorting
                      sort={sorting}
                      handleSort={handleSort}
                      column="title"
                    />
                  </div>
                </th>
                <th>Event Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>
                  <div className="sorting_column">
                    <span>Created Date</span>
                    <Sorting
                      sort={sorting}
                      handleSort={handleSort}
                      column="createdAt"
                    />
                  </div>
                </th>

                <th className="action_head">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={7}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {bookingList.length ? (
                    bookingList.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>{row?.title ? row.title : "N/A"}</td>
                          <td>
                            {row?.event_data?.title ? (
                              <Link
                                to={`/admin/event-management/events/view/event/${row?.event_id}`}
                              >
                                {row?.event_data?.title}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {row?.start_date
                              ? formateDate(row?.start_date)
                              : "N/A"}
                          </td>
                          <td>
                            {row?.end_date ? formateDate(row?.end_date) : "N/A"}
                          </td>
                          <td>{getStatus(row?.status)}</td>
                          <td>
                            {row?.createdAt
                              ? formateDate(row?.createdAt)
                              : "N/A"}
                          </td>
                          <td>
                            <div className="d-flex">
                              <button
                                className="btn ripple btn-main-primary signbtn"
                                onClick={() =>
                                  handleViewDetails(row._id, params.id)
                                }
                              >
                                View
                              </button>
                              <button
                                className="btn ripple btn-success mlAction "
                                onClick={() => {
                                  navigate(
                                    `/admin/room-management/view/booking/edit/${row._id}`
                                  );
                                }}
                              >
                                Edit
                              </button>
                              <button
                                className="btn ripple btn-secondary mlAction "
                                onClick={() => {
                                  CancelFunction(row);
                                }}
                              >
                               Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="" id="example1_info" role="status" aria-live="polite">
          <b>Total Records : {datalength ? datalength : "0"}</b>
        </div>
        {datalength && datalength > 0 ? (
          <CustomPagination
            datalength={datalength}
            itemperpage={itemperpage}
            currentPage={page}
            setPage={setPage}
            pageRoute={[
              {
                name: "Event Announcements",
                path: `/admin/room-management/view/bookings/list/${params.id}`,
              },
            ]}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default BookingTable;
