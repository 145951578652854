import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as authService from '../services/auth.services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { decodeValue, handleServerValidations, setLocalKey } from '../../../utils/commonfunction';
import { STAFF_ROLE, SWAL_SETTINGS } from '../../../utils/Constants';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


const UserOtpVerification = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState()
    const [type, setType] = useState('')
    const [loader, setLoader] = useState('false')
    const [visibleEmail, setVisibleEmail] = useState("");
    const [searchParams] = useSearchParams();
    const [timer, setTimer] = useState(0);
    const { t } = useTranslation();
    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    useEffect(() => {
        let decodeemail = decodeValue(decodeURIComponent(searchParams.get('email')));
        if (searchParams.get('visible_email')) {
            let decodeVisibleEmail = decodeValue(decodeURIComponent(searchParams.get('visible_email')));
            setVisibleEmail(decodeVisibleEmail)

        } else {
            setVisibleEmail(decodeemail)
        }
        setEmail(decodeemail);
        setType(searchParams.get('type'))
    }, [])

    const resentotpfunct = () => {
        runTimer();
        let formData = new FormData();
        formData.append("email", email)
        formData.append("type", type);
        authService.resendOtp(formData).then(response => {
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.message,
                    ...SWAL_SETTINGS
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS
                })
            }
        }).catch(error => {
            console.log("error: ", error);
        })
    }
    function runTimer() {
        setTimer(59);
        if (timer <= 0) {
            const intervalId = setInterval(() => {
                setTimer((prevSeconds) => {
                    if (prevSeconds <= 0) {
                        clearInterval(intervalId); // Stop the timer when it reaches 0
                        return 0;
                    } else {
                        return prevSeconds - 1;
                    }
                });
            }, 1001);
        }
    }


    return (

        <>
            {/* <Formik
                initialValues={{ otp: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.otp) {
                        errors.otp = 'OTP is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", email)
                    formData.append("otp", values.otp);
                    formData.append("type", type);
                    authService.otpVerification(formData).then(response => {
                        if (response.success) {
                            setLocalKey("user", JSON.stringify(response.data))
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                                if(type == "password_reset"){
                                    navigate('/reset-password', { state: response.data.validate_string })
                                }else{
                                    navigate('/home')
                                }
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                            }, 2000);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    // and other goodies
                }) => (
                    <div className='page user-main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="row">
                                            <div className='col-12'>
                                                <div className="card-body mt-2 mb-2">
                                                    <div className="clearfix"></div>
                                                    <form onSubmit={handleSubmit}>
                                                        <h5 className="text-center mb-2">OTP-VERIFICATION</h5>
                                                        <div className="form-group text-start mb-3">
                                                            <label>OTP</label>
                                                            <input className={"form-control inputwidth" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                                                placeholder="Enter your otp"
                                                                type="otp"
                                                                name="otp"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.otp}
                                                                autoComplete="off" />
                                                            <span className='text-danger text-start d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                                                        </div>
                                                        <div className='text-start'>
                                                            <button className={"btn btn-primary mt-2" + (loader === true ? " disabled" : "")} type="submit">Verified OTP</button>
                                                        </div>
                                                        {loader === true ?
                                                            <div className="spinner-border text-primary mt-2" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : ""}
                                                    </form>
                                                    <div className="text-left mt-3 ml-0">
                                                        <div className="mb-1"><Link className='a' onClick={resentotpfunct}>Resend otp?</Link></div>
                                                    </div>
                                                    <div className="text-left mt-0 ml-0">
                                                        <div className="mb-1"><Link to="/login">Back to Login</Link></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </Formik> */}
            <div className="wraper-inner cpt">
                <section className="accounts-page section-padding">
                    <div className="container">
                        <div className="accounts-page-box row">
                            <div className="accounts-page-box-left col-md-7">
                                <figure>
                                    {getGlobalData?.settings?.login_bg_image ?
                                        <img src={getGlobalData?.settings?.login_bg_image} alt="LoginImage" /> :
                                        <Skeleton count={1} style={{ height: '500px' }} />
                                    }
                                </figure>
                                <div className="account-content">
                                    <div dangerouslySetInnerHTML={{ __html: `${getGlobalData?.settings?.login_content ? getGlobalData.settings.login_content : "N/A"}` }}></div>
                                </div>
                            </div>
                            <div className="accounts-page-box-right col-md-5">
                                <Formik
                                    initialValues={{ otp: '' }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.otp) {
                                            errors.otp = t('web_login_err_otp_required');
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        console.log("values", values);
                                        setLoader(true)
                                        let formData = new FormData();
                                        formData.append("email", email)
                                        formData.append("otp", values.otp);
                                        formData.append("type", type);
                                        authService.otpVerification(formData).then(response => {
                                            if (response.success) {
                                                const userData = response.data ? response.data : null
                                                setLocalKey("user", JSON.stringify(response.data))
                                                Swal.fire({
                                                    icon: 'success',
                                                    text: response.message,
                                                    ...SWAL_SETTINGS
                                                })
                                                setTimeout(() => {
                                                    setLoader(false)
                                                    if (type === "password_reset") {
                                                        navigate('/reset-password', { state: response.data.validate_string })
                                                    } else if (type === "update_email") {
                                                        if(userData.role === STAFF_ROLE.LM_BACK_OFFICE){
                                                            navigate(`/back-office/settings/profile`);
                                                          }
                                                          else if(userData.role === STAFF_ROLE.LEAD_COORDINATOR){
                                                            navigate(`/lead-coordinator/settings/profile`);
                                                          }
                                                          else if(userData.role === STAFF_ROLE.BOOTH_ATTENDANTS){
                                                            navigate(`/sis-user/settings/profile`);
                                                          }
                                                          else if(userData.role === STAFF_ROLE.INFO_COUNTER){
                                                            navigate(`/info-counter/settings/profile`);
                                                          }
                                                          else{
                                                            navigate('/settings/profile')
                                                          }
                                                    }
                                                }, 2000);
                                            } else {
                                                Swal.fire({
                                                    icon: 'error',
                                                    text: handleServerValidations(response),
                                                    ...SWAL_SETTINGS
                                                })
                                                setTimeout(() => {
                                                    setLoader(false)
                                                }, 2000);
                                            }
                                        }).catch(error => {
                                            console.log("error: ", error);
                                        })
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        setFieldValue
                                        // and other goodies
                                    }) => (
                                        <div className="accounts-page-box-inner-L">
                                            <div className="common-bg-form">
                                                <div className="top-from-headning text-left mb-4">
                                                    <figure>
                                                        <img src={getGlobalData?.portal_design?.logo_transparent} alt="Logo" />
                                                    </figure>
                                                    <h3>{t('web_otp_verification')}</h3>
                                                    <p>{t('web_otp_verification_code')} <Link className='text-link' to={"mailto:" + visibleEmail}>{visibleEmail}</Link> </p>
                                                </div>
                                                <form className="form-box login-form" onSubmit={handleSubmit}>
                                                    <div className="form-group text-start mb-3">
                                                        <input className={"form-control inputwidth" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                                            placeholder={t('web_lbl_enter_your_otp')}
                                                            type="otp"
                                                            name="otp"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.otp}
                                                            autoComplete="off" />
                                                        {/* <OTPInput
                                // className={"form-control inputwidth" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                type="otp"
                                name="otp"
                                value={values.otp}
                                onChange={(e) => setFieldValue('otp',e)}
                                numInputs={4}
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input className='form-control' {...props} />}
                                // inputStyle={{height:"44px",width:"98px",marginRight:21}}
                                inputStyle="form-control otp_input"
                                /> */}
                                                        <span className='text-danger text-start d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                                                    </div>
                                                    <div className="btn-box text-center mt-4 ">
                                                        {/* <button className="btn btn-primary w-100 h-50" type="submit"> */}
                                                        <button className={"btn btn-primary w-100 h-50" + (loader === true ? " disabled" : "")} type="submit">
                                                            {t('web_otp_verification_verify')}
                                                        </button>
                                                    </div>
                                                    {timer <= 0 ? (
                                                        <div className="bottom-text mt-4 text-center">
                                                            {t('web_otp_verification_receive')} <Link onClick={resentotpfunct} >{t('web_otp_verification_resend')}</Link>
                                                        </div>
                                                    ) : (
                                                        <div className="bottom-text mt-4 text-center">
                                                            {t('web_otp_verification_resend_otp')}  {timer}  {t('web_otp_verification_second')}
                                                        </div>
                                                    )}


                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default UserOtpVerification;