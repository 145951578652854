import React, { useState,useEffect } from "react";
import { Formik,ErrorMessage } from "formik";
import { EventRegistrationDetails } from "../../user/services/eventdetailservice";
import { useSelector } from "react-redux";
import { BoothStatusDetails, StatusChange, StatusDetails } from "../services/common.services";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../utils/Constants";
import { handleServerValidations } from "../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';


const ChangeStatus = () => {

const [eventDetailsData, setEventDetailsData] = useState({});
const [detailsData, setDetailsData] = useState({});
const [status, setStatus] = useState("");
let reducerData = useSelector(state => state.manageLmBackOffice);
const [statsupdate,setStatusUpdate]= useState('false');
const {t} = useTranslation();

useEffect(() => {
    StatusDetails(reducerData).then((response) => {
      if (response.success) {
        setEventDetailsData(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    BoothStatusDetails(reducerData).then((response) => {
      if (response.success) {
        setDetailsData(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [statsupdate,reducerData])

  const validationSchema = Yup.object().shape({
    status: Yup.string().required('Status is required'),
  });

  return (
    <>
        <Formik
          enableReinitialize
          initialValues={{
            status: detailsData && detailsData?.status ?  detailsData?.status : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let formData = new FormData();
            formData.append("status", values.status);
            formData.append("event_id", reducerData);
            StatusChange(formData)
              .then((response) => {
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  resetForm({ values: "" });
                  setStatusUpdate(!statsupdate);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-9 m-auto ">
                  <h3 className="dash-title">{t('web_change_status')}</h3>
                  <div className="dash-box-shadow space-b">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                      <label className="form-label">{t('web_change_status')}<small>*</small></label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.status}
                            onChange={(e) => {
                              setFieldValue("status", e.target.value);
                              setStatus(e?.target?.value);
                            }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {eventDetailsData && Array.isArray(eventDetailsData.status_booth_attendants) && eventDetailsData.status_booth_attendants.map((option, i) => (
                                 <option key={i} >
                                       {option}
                                         </option>
                                        ))}
                          </select>
                        </div>
                        <span className="text-danger d-flex text-left">
                        {errors.status && touched.status && <ErrorMessage name="status" />}
                        </span>
                      </div>
                      </div>
                      <div className="col-md-12 mt-4">
                      <button className="btn btn-primary" type="submit">{t('web_text_dynamic_form_submit_button')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          )}
        </Formik>
    </>
  );
};

export default ChangeStatus;