import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as otherService from "../../user/services/webother.service";
import { formateDate, handleServerValidations } from "../../../utils/commonfunction";
import CustomPagination from "../../admin/common/custompagination";
import * as sisUserService from "../services/common.services";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-fullscreen.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { SWAL_SETTINGS, } from "../../../utils/Constants";




const MyBookings = () => {

  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const eventId = useSelector(state => state.eventDetail);

  const getBookingList = (eventId, pageNo, itemperpage) => {
    if (eventId) {
      const formData = new FormData()
      formData.append("page", pageNo)
      formData.append("per_page", itemperpage)
      formData.append("event_id", eventId)
      sisUserService.bookingList(formData).then(res => {
        if (res && res.success) {
          setList(res?.data?.list);
          setDataLength(res?.data?.total_records);
          setPage(res && res?.data && res?.data?.page ? res?.data?.page : 1);
        } else {
        }
      }).catch(err => console.log("err", err))
    }
  }
  useEffect(() => {
    getBookingList(eventId, params.pgno, itemperpage)
  }, [page, itemperpage, params.pgno, eventId])



  const handleCancelClick = async (event) => {
    const values = {
      o_id: event._id,
    };
    Swal.fire({
      customClass: 'swal-wide',
      title: t('web_swal_are_you_sure'),
      text: t('web_swal_cancel_booking'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#66cc33",
      cancelButtonColor: "#6e7881",
      confirmButtonText: t('web_swal_yes'),
      cancelButtonText: t('web_swal_no')
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          sisUserService.cancelBooking(values).then((response) => {
            if (response.success) {
              getBookingList(eventId, params.pgno, itemperpage)
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
        } catch (error) {
          console.error('An error occurred while cancelling the registration', error);
          // window.alert('An error occurred while cancelling the registration');
        }
      }
    })
  };
  return (
    <div className="dash-main-R">
      <div className="row">
        <div className="col-md-9 m-auto">
          <h3 className="dash-title mt-4">{t('web_lbl_booking_list')}</h3>
          <div className="dash-box-shadow">
            <div className="row">
              {list?.length ? (list.map((item, index) => (
                <div className="col-md-4 my-2" key={index}>
                  <div className="grid-box">
                    <h3 className="text-capitalize">{item?.title}</h3>
                    <strong>{item?.event_data?.title}</strong>
                    <div className="date-info">
                      <i className="ri-calendar-event-line"></i>
                      {formateDate(item?.start_date)}
                    </div>
                    <div className="date-info">
                      <i className="ri-calendar-event-line"></i>
                      {formateDate(item?.end_date)}
                    </div>

                    <div className="action-tag mt-4 flex-wrap">
                      <Link to={`/sis-user/settings/booking/details/${item?._id}`} className="btn-s green-btn cp">{t('web_lbl_button_view')}</Link>
                      {item && item?.status !== 2 && (
                        <>
                          <a className="btn-s danger-btn cp mb-2" href="/" onClick={(e) => { e.preventDefault(); handleCancelClick(item) }}>
                            {t('web_lbl_button_cancel')}
                          </a>
                          <Link to={`/sis-user/settings/booking/edit/${item?._id}`} className="btn-s org-btn cp mb-2">{t('web_lbl_button_update')}</Link>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              ))
              ) : (
                <div className="col-md-12 my-2">
                  <div className="grid-box text-center">
                    {t('web_lbl_no_records')}
                  </div>
                </div>
              )}
            </div>
            {datalength && datalength > 0 ? (
              <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Sharemedia", path: "/sis-user/settings/booking-list" }]} />
              </div>) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyBookings;