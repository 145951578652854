import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as newsletterservices from "../../services/newsletterservice";
import * as emailTemplateServices from "../../services/email.template";
import * as pushNotificationTemplateServices from "../../services/pushnotification.services";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import { SWAL_SETTINGS, showFilterlist, EMAIL_TEMPLATE_TYPES, INVITATION_TYPES, GENDER } from "../../../../utils/Constants";
import {
  formateDate,
  globalLoader,
  handleServerValidations,
  hasPermission,
  TrimText,
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import {
  Modal,
  Button,
  DatePicker,
} from "rsuite";
import { List } from "../../services/usermgmt.services";
import { Options } from "../../services/email.template";
// import { Field, formik } from 'formik'
import * as Yup from 'yup';
import { ErrorMessage,  Formik } from 'formik';
import Sorting from "../../common/sorting";
import { TemplateOptions } from "../../services/pushnotification.services";
import { DistributionOptions } from "../../services/distributionlist.services";
import * as senderEmailsServices from "../../services/senderEmail.services";
import {ContentBlocksOptions  } from '../../services/contentBlocks.services';

const NewsLettersTable = () => {

  const formikRef = useRef(null);

  const params = useParams(); 
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [status] = useState([{ name: "Status", status__id: "" }, { name: "Scheduled", status__id: INVITATION_TYPES.SCHEDULE }, { name: "Sent", status__id: INVITATION_TYPES.SEND_NOW }, { name: "Send later", status__id: INVITATION_TYPES.SEND_LATER }]);
  const [datalength, setDataLength] = useState(0);
  const [userdatalength, setUserDataLength] = useState(0);
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [searchNew, setSearchNew] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [globalsearchNews, setGlobalSearchNews] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Newsletters", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedModal, setSelectedModal] = useState(false);
  const [emailOptions, setEmailOptions] = useState([]);
  const [pushNotificationOptions, setPushNotificationOptions] = useState([]);
  const [distributionListOptions, setDistributionListOptions] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [id, setId] = useState("");
  const [scheduledTime] = useState();
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  const submitFormButton = useRef();
  const selectAllCheckboxData = useRef();
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [selectedAllUserData, setSelectedAllUserData] = useState(false);
  const [fileType] = useState(Object.keys(GENDER).map((item=>{
    return{
      label:item, value: GENDER[item]
    }
  })));
  const [itemPerPageModal] = useState(10);
  const [dataPageNo, setDataPageNo] = useState(1);
  const [distributionId,setDistributionId] = useState("");
  const [senderEmailOptions, setSenderEmailOptions] = useState([]);
  const [senderEmailId,setSenderEmailId] = useState("");
  const [contentClockOptions, setContentClockOptions] = useState([]);
  const [contentBlockId,setContentBlockId] = useState("");



  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      newsletterservices
        .List(formData)
        .then((data) => {
          setDataLength(data.data && data.data.total_records ? data.data.total_records : []);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setSelectedAllUserData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, selectedModal,itemperpage,params.pgno]);

  useEffect(() => {
    Options({ type: EMAIL_TEMPLATE_TYPES.PROMOTIONAL }).then((response) => {
      setEmailOptions(response?.data);
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    TemplateOptions().then((response) => {
      setPushNotificationOptions(response?.data);
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    DistributionOptions().then((response) => {
      setDistributionListOptions(response?.data);
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    senderEmailsServices.Options().then((response) => {
      if (response.success) {
        setSenderEmailOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    ContentBlocksOptions().then((response) => {
      if (response.success) {
        setContentClockOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])
  const viewfunction = (row) => {
    navigate(`/admin/news-letter/view/${row._id}/1`, { state: row })
  }


  useEffect(() => {
    if(formikRef?.current?.values?.sending_type === "email"){
      emailTemplateServices.Details(id).then((response) => {
        if (response.success) {
          setPreviewData(response?.data)
        }
      }).catch((error) => {
        console.log("error", error);
      })
    }else if(formikRef?.current?.values?.sending_type === "push_notification"){
      pushNotificationTemplateServices.Details(id).then((response) => {
        if (response.success) {
          setPreviewData(response?.data)
        }
      }).catch((error) => {
        console.log("error", error);
      })
    }
  }, [id]);

  useEffect(() => {
    let formData = new FormData();
    formData.append('global_search', globalsearchNews);
    formData.append("per_page", itemPerPageModal);
    formData.append("page", dataPageNo);
    formData.append("search", JSON.stringify({subscribed_for_newsletter:true , ...searchNew}));
    formData.append("distribution_id",distributionId);
    List(formData)
      .then((response) => {
        setUserDataLength(response?.data?.total_records);
        setUserData(response?.data?.list);
        setSelectedAllData(response && response.data && response.data.data_ids ? response.data.data_ids : []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [globalsearchNews, searchNew, statsupdate,dataPageNo,distributionId]);

  /**********Handle user selection when send newsletters >>>>>>********* */

  useEffect(() => {
    if (open) {
      if (selectedUser.length === userdatalength) {
        selectAllCheckbox.current.indeterminate = false;
        selectAllCheckbox.current.checked = true;
      } else if (selectedUser.length) {
        selectAllCheckbox.current.indeterminate = true;
      } else {
        selectAllCheckbox.current.indeterminate = false;
      }

    }
  }, [selectedUser, open]);
  const selectUser = (userData, check) => {
    if (check) {
      setSelectedUser((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedUser((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectAll = (check) => {
    if (check) {
      setSelectedUser(selectedAllData);
    } else {
      setSelectedUser([]);
    }
  };
  const isSelected = (item) => {
    return selectedUser.filter((data) => item._id === data).length > 0;
  };

  /**********Handle user selection when send newsletters end >>>>>>********* */



  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectedUserData.length === datalength) {
      selectAllCheckboxData.current.indeterminate = false;
      selectAllCheckboxData.current.checked = true;
    } else if (selectedUserData.length) {
      selectAllCheckboxData.current.indeterminate = true;
    } else {
      selectAllCheckboxData.current.indeterminate = false;
    }
  }, [selectedUserData]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedUserData((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedUserData((previousState) => previousState.filter((item) => item !== userData._id));
    }
  };
  const handleSelectAllData = (check) => {
    if (check) {
      setSelectedUserData(selectedAllUserData);
    } else {
      setSelectedUserData([]);
    }
  };
  const isSelectedData = (data) => {
    return (selectedUserData.filter((item) => data?._id === item).length > 0);
  };
  /********** MULTI SELECT END<<<<<<<<<********* */



  //Invitation Work
  const handleInvitation = () => {
    setSelectedUser([]);
    setOpen(true);
  };


  const isDateDisabled = (date,  minDate) => {
    return date <= minDate;
  };

  const onOkayClick = () => {
    setOpen(false);
    setSelectedModal(true)
  }


  const selectRow = (item) => {
    let id = item?._id;
    $("#" + id + "label").trigger("click");
  };

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const prepareSearchNew = (key, value) => {
    let sr = { ...searchNew };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearchNew(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    // setGlobalSearchNews("");
    // prepareSearch()
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;


  };

  const resetFilterNew = (e) => {
    e.preventDefault();
    setGlobalSearchNews("");
    setDistributionId("");
    setSenderEmailId("");
    // prepareSearch()
    setSearchNew({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#resetFilter")[0].selectedIndex = 0;
    $("#options")[0].selectedIndex = 0;
    $("#senderOptions")[0].selectedIndex = 0;

  };

  // const validationSchema = Yup.object().shape({
  //   email_slug: Yup.string().test('sending_type', 'Email template is required', (value) => {
  //     if(formikRef?.current?.values.sending_type == "email"){
  //       if(formikRef?.current?.values.sending_type == "email"){
  //         return Boolean(value);
  //       }else{
  //         return true
  //       }
  //     }else{
  //       return true;
  //     }
  //   }),
  //   push_notification_slug: Yup.string().test('sending_type', "Push Notification Template is required",(value) => {
  //     if(formikRef?.current?.values.sending_type == "push_notification"){
  //       if(formikRef?.current?.values.sending_type == "push_notification"){
  //         return Boolean(value);
  //       }else{
  //         return true
  //       }
  //     }else{
  //       return true;
  //     }
  //   }),
  //   title: Yup.string().required('Promotion Title is required').test('no-spaces', 'Promotion Title is required', (value) => value.trim()),
  //   type: Yup.string().required('Email Type is required'),
  //   scheduledTime: Yup.mixed().required('Schedule time is required'),
  //   });

  const validationSchemaNew = Yup.object().shape({
    email_slug: Yup.string().test('sending_type', 'Email template is required', (value) => {
      if(formikRef?.current?.values.sending_type === "email"){
        if(formikRef?.current?.values.sending_type === "email"){
          return Boolean(value);
        }else{
          return true
        }
      }else{
        return true;
      }
    }),
    push_notification_slug: Yup.string().test('sending_type', "Push notification template is required",(value) => {
      if(formikRef?.current?.values.sending_type === "push_notification"){
        if(formikRef?.current?.values.sending_type === "push_notification"){
          return Boolean(value);
        }else{
          return true
        }
      }else{
        return true;
      }
    }),
    title: Yup.string().required('Promotion title is required').test('no-spaces', 'Promotion title is required', (value) => value.trim()),
    type: Yup.string().required('Email type is required'),
    sending_type: Yup.string().required('Sending type is required'),
    scheduledTime: Yup.mixed().test("type","Schedule time is required",(value) => {
      if(formikRef?.current?.values.type === "schedule"){
        if(formikRef?.current?.values.type === "schedule"){
          return Boolean(value);
        }else{
          return true
        }
      }else{
        return true;
      }
    })
  });
    
  const showInviteType = (type) => {
    if (type === INVITATION_TYPES.SEND_NOW) {
      return <span className="badge badge-success">Sent</span>;
    } else if (type === INVITATION_TYPES.SCHEDULE) {
      return <span className="badge badge-info">Scheduled</span>;
    } else if (type === INVITATION_TYPES.SEND_LATER) {
      return <span className="badge badge-warning">Send Later</span>;
    }
  }
  const sendLaterEmails = (_id) => {
    newsletterservices.ResendEmails({ 'o_id': _id }).then((response) => {
      if (response.success) {
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(response),
          ...SWAL_SETTINGS,
        });
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let ids = Array.isArray(data) ? data : [data];
        const formdata = new FormData();
        formdata.append("o_id", JSON.stringify(ids));
        // formdata.append("o_id", row._id);
        newsletterservices
          .Delete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedUserData((data) => data.filter((item) => {return ids.indexOf(item) === -1}));
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError"); 
          });
      }
    });
  }

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearchNew("gender", e.target.value)
    } else {
      prepareSearchNew("gender", "")
    }
  };

 


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Newsletters</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={(e) => { prepareSearch("type", e.target.value) }} id="defaultstatus">
                        {status && status.length > 0 && status.map((option, i) => {
                          return (
                            <option key={i} value={option.status__id}>
                              {option.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {hasPermission('/admin/news-letter/newsletter') ?
                <button
                  className="btn ripple btn-main-primary signbtn mr-2"
                  onClick={() => handleInvitation()}
                >
                  Send Newsletter
                </button>
                :null}
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className={"position-relative select_head " + (hasPermission('/admin/news-letter/multi-select') ? '': "d-none")}>
                      <div className="select-check-container">
                        <label htmlFor="selectAllCheckData" style={{ lineHeight: 'unset' }}
                          className="ckbox  cp">
                          <input
                            ref={selectAllCheckboxData}
                            id="selectAllCheckData"
                            checked={
                              datalength && datalength === selectedUserData.length
                            }
                            onChange={(e) => handleSelectAllData(e.target.checked)} type="checkbox" />
                          <span></span>
                        </label>
                      </div>
                    </th>

             
                    <th>
                      <div className="sorting_column">
                        <span>Title</span>
                        <Sorting sort={sorting} handleSort={handleSort} column="title" />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email Template</span>
                        <Sorting sort={sorting} handleSort={handleSort} column="email_slug" />
                      </div>
                    </th>
                    <th>
                      subject
                    </th>
                    <th>Status</th>
                    <th>
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>

                    <th className='action_head'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={9}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i} className={isSelectedData(row) ? "selected" : ""}>
                              <td className={"position-relative " + (hasPermission('/admin/news-letter/multi-select') ? '': "d-none")}>
                                <div className="select-check-container">
                                  <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                    className="ckbox cp">
                                    <input
                                      id={row?._id + "input"}
                                      checked={isSelectedData(row)}
                                      onChange={(e) =>
                                        selectRecord(row, e?.target?.checked)
                                      }
                                      type="checkbox"
                                      className="form-check select-check cp" />
                                    <span></span>
                                  </label>
                                </div>
                              </td>
                              <td>{row?.title ? TrimText(row.title,20) : "N/A"}</td>
                              <td>{row?.email_slug ? row.email_slug : "N/A"}</td>

                              <td>{row?.template_data?.subject ? row.template_data.subject : "N/A"}</td>
                              <td>{showInviteType(row?.type)}</td>
                              <td>
                                {row.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              <td>
                                <div className="d-flex">

                                  <button className="btn ripple btn-main-primary signbtn " onClick={() => { viewfunction(row) }}>View</button>
                                  {hasPermission('/admin/news-letter/delete') ?
                                  <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(row?._id); }}
                                  >
                                    Delete
                                  </button>
                                  :null}
                                  {hasPermission('/admin/news-letter/more') ?

                                  <button
                                    type="button"
                                    className="btn btn-dark dropdown-toggle mlAction"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    More
                                  </button>
                                  :null}
                                  <ul className="dropdown-menu">
                                  {hasPermission('/admin/news-letter/resend') ?

                                    <li>
                                      <a style={{ color: "#647194" }}
                                      href="/"
                                        className="dropdown-item"
                                        onClick={(e) => { sendLaterEmails(row?._id); e.preventDefault()}}
                                      >
                                        Resend
                                      </a>
                                    </li>
                                    :null}
                                  </ul>
                                </div>

                              </td>
                            </tr>

                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  { name: "Newsletters", path: "/admin/news-letter/list" },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <Modal size={"550"} open={open} backdrop={'static'} onClose={() => { setOpen(false); setGlobalSearchNews(""); setSearchNew("") }}>
          <Modal.Header className="mb-3">
            <Modal.Title>Select users</Modal.Title>
            <div className="mt-4">
              <div className="d-flex align-items-center">
                {/* <div className="col-3"> */}

                <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                  <i className="fa fa-search calender_icon"></i>
                  <input type="search"
                    className="form-control"
                    placeholder="Search"
                    value={globalsearchNews}
                    onChange={(e) => setGlobalSearchNews(e.target.value)}
                  />
                </div>
                {/* </div> */}

                <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearchNew("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>

                {/* <div className="col-3"> */}
                <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                  <i className="fa fa-filter mr-2 filter_icon"></i>
                  <div className="select-down-arrow">
                    <select className="form-control cp" onChange={handleFilterChange} id="resetFilter" style={{ width: "130px" }}>
                      <option value="">
                      Salutation
                      </option>
                      {fileType && fileType.length > 0 && fileType.map((option, i) => {
                        return (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                  <i className="fa fa-filter mr-2 filter_icon"></i>
                  <div className="select-down-arrow">
                    <select className="form-control cp" onChange={(e) => {setDistributionId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id")); }} id="options" style={{ width: "160px" }}>
                      <option value="">
                      Distribution List
                      </option>
                      {distributionListOptions && distributionListOptions?.length > 0 && distributionListOptions.map((option, i) => {
                        return (
                          <option key={i} data-id={option?._id} value={option?._id}>
                            {option?.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group mb-0 me-3 filter_icon_container filter_design" style={{ width: "110px" }}>
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearchNew}
                  />
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilterNew}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
              </div>
            </div>
          </Modal.Header>
          <div className="clearfix"></div>
          <div className="w-100">
            <table className="table table-hover table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th className="position-relative select_head">
                    <div className="select-check-container">
                      <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                        className="ckbox  cp">
                        <input
                          ref={selectAllCheckbox}
                          id="selectAllCheck"
                          checked={
                            userdatalength && userdatalength === selectedUser.length
                          }
                          onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                        <span></span>
                      </label>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Salutation</th>
                  <th>Status</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                {loader ?
                  <tr>
                    <td colSpan={12}><Loader /></td>
                  </tr> : <>
                    {userData.length ? userData.map((item, i) => {
                      // {console.log("row",row);}
                      return <tr
                        key={i}
                        onClick={() => selectRow(item)}
                        className={isSelected(item) ? "selected" : ""}
                      >
                        <td>
                          <label htmlFor={item?._id + "input"} style={{ lineHeight: 'unset' }}
                            className="ckbox cp">
                            <input
                              id={item?._id + "input"}
                              checked={isSelected(item)}
                              onChange={(e) =>
                                selectUser(item, e?.target?.checked)
                              }
                              type="checkbox"
                              className="form-check select-check cp" />
                            <span></span>
                          </label>
                        </td>
                        <td className="text-capitalize">{item?.name ? TrimText(item.name,20) : "N/A" }</td>
                        <td>{item?.email ? TrimText(item.email,20) : "N/A"}</td>
                        <td className="text-capitalize">{item?.gender}</td>
                        <td>
                          {item.status === 1 ? (
                            <button
                              className="badge badge-success"
                            >
                              Active
                            </button>
                          ) : (
                            <button
                              className="badge badge-danger"

                            >
                              Inactive
                            </button>
                          )}
                        </td>

                        <td>
                          {item.createdAt
                            ? formateDate(item.createdAt)
                            : "N/A"}
                        </td>
                      </tr>
                    }) : <tr><td colSpan={12} className="text-center">No records</td></tr>}</>}
              </tbody>
            </table>
          </div>
          <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {userdatalength ? userdatalength : "0"}</b>
            </div>
            {userdatalength && userdatalength > 0 ? (
                <CustomPagination
                  datalength={userdatalength}
                  itemperpage={itemPerPageModal}
                  currentPage={dataPageNo}
                  setPage={setDataPageNo}
                  pageRoute={[
                    { name: "Newsletters", path: "/admin/news-letter/list" },
                  ]}
                  modalPagination={'modalPagination'}
                />
              ) : (
                ""
              )}
          {/* <Modal.Footer> */}
            <div className="d-flex justify-content-between align-items-center mt-5">
              <h6 className="ms-2 mt-1 mb-0">{selectedUser.length ? "Selected " + selectedUser.length + " of records " + userdatalength : ""}</h6>
              <div>
                <Button onClick={() => { setOpen(false); setGlobalSearchNews(""); setSearchNew("") }} appearance="subtle">
                  Cancel
                </Button>
                <Button
                  disabled={selectedUser.length === 0}
                  onClick={() => onOkayClick()}
                  appearance="primary"
                >
                  Next
                </Button>
             
              </div>
            </div>
          {/* </Modal.Footer> */}
        </Modal>

        <Modal size={"lg"} className="modal-newsletters" open={selectedModal} backdrop={'static'} onClose={() => { setSelectedModal(false); setPreviewData([]);  setId("");  }}>
          <Modal.Header className="mb-3">
            <Modal.Title>Selected users: </Modal.Title>


            <Formik
              enableReinitialize
              innerRef={formikRef}
              initialValues={{
                email_slug: "",
                push_notification_slug: "",
                title: "",
                scheduledTime: "",
                type: "",
                sending_type:"email"
              }}
              validateOnBlur={false}
              validate={(values) => {
                return {};
              }}
              validationSchema={validationSchemaNew}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                let formData = new FormData();
                formData.append('o_id', JSON.stringify(selectedUser));
                newsletterservices.ListById(formData).then((response) => {
                  if (response?.success) {
                    let formData = new FormData();
                    if (values?.type === "schedule") {
                      if (values?.scheduledTime) {
                        formData.append("schedule_date", values?.scheduledTime);
                      }
                      else {
                        Swal.fire({
                          icon: "error",
                          text: "Please select date",
                          ...SWAL_SETTINGS,
                        });
                        return;
                      }

                    }
                    if(values?.sending_type === "email"){
                      formData.append('email_slug', values?.email_slug);
                    }else{
                      formData.append('push_notification_slug', values?.push_notification_slug);
                    }
                    formData.append('title', values?.title);
                    formData.append("type", values?.type);
                    formData.append('users', JSON.stringify(response.data?.list));
                    formData.append('users_ids', JSON.stringify(selectedUser));
                    if(senderEmailId){
                      formData.append("sender_email_name",senderEmailId );
                    }
                    if(contentBlockId){
                      formData.append("content_block_id",contentBlockId );
                    }
                    globalLoader(true);
                    if(values?.sending_type === "email"){
                      newsletterservices.SendEmails(formData).then((response) => {
                        if (response?.success) {
                          globalLoader(false);
                          setSelectedModal(false);
                          Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                          });
                          setId("")
                          setSenderEmailId("")
                          setContentBlockId("")
                        }
                      }).catch((error) => {
                        globalLoader(false);
                        console.log("error", error)
                      })
                    }else{
                      newsletterservices.SendNotifications(formData).then((response) => {
                        if (response?.success) {
                          globalLoader(false);
                          setSelectedModal(false);
                          Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                          });
                          setId("")
                        }
                      }).catch((error) => {
                        globalLoader(false);
                        console.log("error", error)
                      })
                    }
                  }
                }).catch((error) => {
                  globalLoader(false);
                  console.log("error", error)
                })
              }}
            >{({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row ms-1">
                  <div className="d-flex mt-2">
                    <div className="form-check black-check ms-2 p-0">
                      <input
                        name="sending_type"
                        className="form-check-input"
                        type="radio"
                        value={"email"}
                        id="email"
                        checked={values?.sending_type === "email"}
                        onChange={(e) => {handleChange(e); setFieldValue("type","")}}
                        onBlur={handleBlur}
                      />
                      <label className="form-check-label cp no-before no-after" htmlFor="email">
                        Email
                      </label>
                    </div>
                    <div className="form-check black-check ms-3">
                    <input
                      name="sending_type"
                      className="form-check-input"
                      type="radio"
                      value={"push_notification"}
                      checked={values?.sending_type === "push_notification"}
                      id="push_notification"
                      onChange={(e) => {handleChange(e); setFieldValue("type","send_now")}}
                      onBlur={handleBlur}
                    />
                    <label className="form-check-label cp no-before no-after" htmlFor="push_notification">
                      Push Notification
                    </label>
                  </div>
                  </div>
                  <span className='text-danger d-flex text-left'><ErrorMessage name={"sending_type"} /></span>
                  {values?.sending_type === "email" ? (
                    <>
                    <div className="col-lg-6 text-center form-group ps-0 mt-3">
                      <label className='text-left d-flex'>Select Email Template:<span className="requirestar">*</span></label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select className="form-control select2" name="email_slug" onBlur={handleBlur} onChange={(e) => { handleChange(e); setId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id")); }}>
                            <option value="" label="Select">Select{" "}</option>
                            {emailOptions && emailOptions.length > 0 && emailOptions.map((option, i) => {
                              return (
                                <option key={i} data-id={option?._id} value={option.slug}>
                                  {option.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"email_slug"} /></span>
                      </div>
                    </div>
                    <div className="col-lg-6 text-center form-group ps-0 mt-3">
                      <label className='text-left d-flex'>Select Sender Email Name:</label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select className="form-control select2" name="sender_email_name" onBlur={handleBlur} onChange={(e) => { handleChange(e); setSenderEmailId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id")); }}>
                            <option value="" label="Select">Select{" "}</option>
                            {senderEmailOptions && senderEmailOptions.length > 0 && senderEmailOptions.map((option, i) => {
                              return (
                                <option key={i} data-id={option?._id} value={option?._id}>
                                  {option.email_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"sender_email_name"} /></span>
                      </div>
                    </div>

                    <div className="col-lg-6 text-center form-group ps-0 mt-3">
                      <label className='text-left d-flex'>Select Content Block:</label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select className="form-control select2" name="content_block" onBlur={handleBlur} onChange={(e) => { handleChange(e); setContentBlockId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id")); }}>
                            <option value="" label="Select">Select{" "}</option>
                            {contentClockOptions && contentClockOptions.length > 0 && contentClockOptions.map((option, i) => {
                              return (
                                <option key={i} data-id={option?._id} value={option?._id}>
                                  {option.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"content_block"} /></span>
                      </div>
                    </div>
              </>
                  ) : (
                    <div className="col-lg-6 text-center form-group ps-0 mt-3">
                      <label className='text-left d-flex'>Select Push Notification Template:<span className="requirestar">*</span></label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select className="form-control select2" name="push_notification_slug" onBlur={handleBlur} onChange={(e) => { handleChange(e); setId(e.target.options[e.target.options.selectedIndex].getAttribute("data-id")); }}>
                            <option value="" label="Select">Select{" "}</option>
                            {pushNotificationOptions && pushNotificationOptions.length > 0 && pushNotificationOptions.map((option, i) => {
                              return (
                                <option key={i} data-id={option?._id} value={option.slug}>
                                  {option.title}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"push_notification_slug"} /></span>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 text-center form-group ps-0 mt-3">
                    <label className='text-left d-flex'>Newsletter Title:<span className="requirestar">*</span></label>
                    <input className="form-control" name="title" onBlur={handleBlur} onChange={handleChange} value={values.title} />
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                  </div>
                  {id && previewData ? (
                    <>
                      <h5 className="mb-3">Preview</h5>
                      <div className="border p-4 rounded">
                        <h5 className="mb-3">{previewData && previewData.subject}</h5>
                        <div dangerouslySetInnerHTML={{ __html: previewData && previewData.description }}></div>
                      </div>
                    </>
                  ) : (
                    <div style={{ height: "40vh" }} className="border p-4 rounded justify-content-center align-items-center d-flex">
                      <h1>Preview</h1>
                    </div>
                  )}
                </div>
                {values?.sending_type === "email" ? (
                  <div className="chek-box-card mt-3 d-flex justify-content-end">
                    <div>
                      {values?.type === "schedule" ? (
                        <div className="d-flex justify-content-end">
                          <div>
                            <DatePicker
                              editable={false}
                              format="yyyy-MM-dd HH:mm:ss"
                              id="date_picker"
                              oneTap={true}
                              placeholder={"Please select date"}
                              value={ scheduledTime && values.scheduledTime}
                              onChange={(e) => setFieldValue("scheduledTime",e)}
                              onBlur={handleBlur}
                              shouldDisableDate={(date) => isDateDisabled(date,(new Date()).setDate((new Date()).getDate()-1))}
                            />
                            <span className='text-danger d-flex text-left'><ErrorMessage name={"scheduledTime"} /></span>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex">
                        <div className="form-check black-check ms-3">
                          <input
                            name="type"
                            className="form-check-input"
                            type="radio"
                            value={"send_later"}
                            id="send_later"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                          <label className="form-check-label cp no-before no-after" htmlFor="send_later">
                            Send Later
                          </label>
                        </div>
                        <div className="form-check black-check ms-3">
                          <input
                            name="type"
                            className="form-check-input"
                            type="radio"
                            value={"schedule"}
                            id="schedule"
                            onChange={handleChange}
                          />
                          <label className="form-check-label cp no-before no-after" htmlFor="schedule">
                            Schedule
                          </label>
                        </div>
                        <div className="form-check black-check  ms-3">
                          <input
                            name="type"
                            className="form-check-input"
                            type="radio"
                            value={"send_now"}
                            id="send_now"
                            onChange={handleChange}
                          />
                          <label className="form-check-label cp no-before no-after" htmlFor="send_now">
                            Send Now
                          </label>
                        </div>
                      </div>
                      <span className='text-danger d-flex text-left ms-3'><ErrorMessage name={"type"} /></span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <button ref={submitFormButton} type="submit" hidden>
                  Submit
                </button>
              </form>
            )}
            </Formik>
          </Modal.Header>
          <div className="w-100">

          </div>

          <Modal.Footer>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="ms-2 mt-3 mb-0 text-center">{selectedUser.length ? "Selected " + selectedUser.length : ""}</h6>
              <div>
                <Button onClick={() => { setSelectedModal(false); setPreviewData([]);  setId(""); }} appearance="subtle">
                  Cancel
                </Button>
                <Button onClick={() => { setSelectedModal(false); setOpen(true); setPreviewData([]); setId(""); }} appearance="primary">
                  Previous
                </Button>
                <Button
                  // disabled={!emailType}
                  onClick={() => { submitFormButton.current.click() }}
                  appearance="primary"
                >
                 Submit
                </Button>
              </div>
            </div>
          </Modal.Footer>


        </Modal>
      </div>

      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedUserData.length ?
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className='d-flex align-items-center'>
              <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
              <h6 className='ms-2 mt-1 mb-0'>Selected {selectedUserData.length} {selectedUserData.length > 1 ? "records" : "record"} of {datalength}</h6>
            </div>
            <div>
              <ul>
              {hasPermission('/admin/news-letter/delete') ?
                <li>
                  <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedUserData) }}>Delete</button>
                </li>
                :null}
              </ul>
            </div>
          </div>
        </div>
        : ""
      }
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}


    </>
  );
};

export default NewsLettersTable;
