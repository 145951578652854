import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import { formateDate, formateDateWithMonth, getFileNameFromURL } from "../../../../utils/commonfunction";
import { Details } from "../../services/leadManagement.services";
import VideoPlayer from "../../../user/shared/VideoPlayer";
import ShowAnswer from "../../shared/ShowAnswer";

const LeadManagementView = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  let [videoSourceType, setVideoSourceType] = useState("video");
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  const videoPlayerToggler = useRef();


  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Lead Management", url: "/admin/lead-management/list/1" },
    { title: "View", url: "" },
  ];

  useEffect(() => {
    Details(params.id)
      .then((data) => {
        setShowDefault(data && data.data ? data.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  const playVideo = (video,type) => {
    setVideoPlaySource(video);
    setVideoSourceType(type);
    videoPlayerToggler?.current?.click();
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm viewregisration">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card main-content-body-profile">
            <div className="tab-content">
              <div
                className="main-content-body tab-pane p-4 border-top-0 active"
                id="about"
              >
                <div className="card-body p-0 p-0 rounded-10">
                  <h4 className="tx-25 text-uppercase mb-3">Lead Details</h4>
                  <div className="p-0">
                    {Object.keys(showdefault).length > 0 ? (
                      <>
                        <div className="mb-4">
                          <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                            <div className="me-md-5">
                              <h4 className="tx-25 text-uppercase mb-3">
                                General Info
                              </h4>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Title:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {showdefault?.title
                                    ? showdefault.title
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Salutation:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {showdefault?.gender
                                    ? showdefault.gender
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  First Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {showdefault?.first_name
                                    ? showdefault.first_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Middle Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {showdefault?.middle_name
                                    ? showdefault.middle_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Last Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {showdefault?.last_name
                                    ? showdefault.last_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Email:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {showdefault?.email
                                    ? showdefault.email
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Phone Number:
                                </label>
                                {showdefault?.phone_number?.number
                                  ? showdefault?.phone_number?.number
                                  : "N/A"}
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Created By:
                                </label>
                                {showdefault?.creator_data?.name
                                  ? <Link
                                    to={`/admin/staff/view/${showdefault.creator_data._id}`}
                                  >
                                    {showdefault.creator_data.name}
                                  </Link>
                                  : "N/A"}
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Created Date:
                                </label>
                                {showdefault && showdefault.createdAt
                                  ? formateDate(showdefault.createdAt)
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4">
                            <h4 className="tx-15 text-uppercase mb-3">
                              About Event
                            </h4>
                            <div className="pt-10 pb-0">
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Event Title:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {showdefault?.event_data?.title ? showdefault?.event_data?.title : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Event Dates:
                                </label>
                                {showdefault?.event_data?.start_date && showdefault?.event_data?.end_date ? 
                                <p className="mb-0 line-height-normal">
                                  {showdefault?.event_data?.start_date
                                    ? formateDateWithMonth(
                                      showdefault?.event_data?.start_date
                                    )
                                    : "N/A"}{" "}
                                  -{" "}
                                  {showdefault?.event_data?.end_date
                                    ? formateDateWithMonth(
                                      showdefault?.event_data?.end_date
                                    )
                                    : "N/A"}
                                </p>
                                :
                                <p className="mb-0 line-height-normal">
                                  N/A
                              </p>
                                 }
                              </div>
                            </div>
                          </div>
                        </div>
                        {showdefault?.questions_data && showdefault.questions_data.length > 0 && (
                          <div className="mb-4">
                            <div className="border rounded p-4">
                              <h4 className="tx-15 text-uppercase mb-3">Extra Info</h4>
                              <div className="pt-10 pb-0">
                                {showdefault.questions_data.map((questionItem, index) => (
                                  <div key={index} className="mb-1">
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        {questionItem?.question_title || "N/A"}:
                                      </label>
                                      <div className="mb-0 text-capitalize">
                                      <ShowAnswer question={questionItem} setVideoPlaySource={playVideo} parentClass="user-view-registration"/>
                                     </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="mt-5">
                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#videoPlayerModal" ref={videoPlayerToggler}></button>
           <div className="modal fade" id="videoPlayerModal" data-bs-backdrop="false" data-bs-keyboard="false" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="videoPlayerModalLabel">Playing : {getFileNameFromURL(videoPlaySource)}</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onMouseDown={(event)=>{
                              event.preventDefault();
                              return false;
                          }} onClick={() => {
                              setTimeout(() => {
                              setVideoPlaySource(null);
                              }, 300);
                          }}></button>
                      </div>
                      <div className="modal-body">
                          {videoPlaySource ?
                              <VideoPlayer src={videoPlaySource} type={videoSourceType}/>
                              : null
                          }
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
};

export default LeadManagementView;
