import axiosInstance from "../../../utils/axios";

const path = "admin/events"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const ListHotels = async (values) => {
    return await axiosInstance().post(`${path}/hotels/list`, values);
}

export const ListAgenda = async (values) => {
    return await axiosInstance().post(`${path}/agenda/list`, values);
}
export const ListQuotas = async (values) => {
    return await axiosInstance().post(`${path}/Quotas/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const AddHotel = async (values) => {
    return await axiosInstance().post(`${path}/hotels/add`, values);
}

export const AddAgenda = async (values) => {
    return await axiosInstance().post(`${path}/agenda/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}
export const EditEvent = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}
export const EditHotel = async (values) => {
    return await axiosInstance().post(`${path}/hotels/edit`, values);
}
export const EditAgenda = async (values) => {
    return await axiosInstance().post(`${path}/agenda/edit`, values);
}

export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}
export const DeleteHotel = async (values) => {
    return await axiosInstance().post(`${path}/hotels/delete`, values)
}
export const DeleteAgenda = async (values) => {
    return await axiosInstance().post(`${path}/agenda/delete`, values)
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const ReportHotel = async (values) => {
    return await axiosInstance().post(`${path}/hotels/reports-list`,values);
}
export const ReportAgenda = async (values) => {
    return await axiosInstance().post(`${path}/agenda/reports-list`,values);
}

export const Status = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const Options = async (values) => {
    return await axiosInstance().post(`${path}/options`, values)
}

export const ReadExcel = async (values) => {
    return await axiosInstance().post(`${path}/read-excel`, values);
}

export const InviteUsers = async (values) => {
    return await axiosInstance().post(`${path}/invite-users`, values);
}

export const InvitationsList = async (values) => {
    return await axiosInstance().post(`${path}/invitations/list`, values);
}

export const TransactionsList = async (values) => {
    return await axiosInstance().post(`${path}/transactions/list`, values);
}

export const checkInCheckOutList = async (values) => {
    return await axiosInstance().post(`${path}/checkin-checkout/list`, values);
}

export const AnnouncementsList = async (values) => {
    return await axiosInstance().post(`${path}/announcements/list`, values);
}

export const AttendeesList = async (values) => {
    return await axiosInstance().post(`${path}/attendees/list`, values);
}

export const AttendeesDetails = async (id) => {
    return await axiosInstance().get(`${path}/attendees/details?o_id=${id}`);
}
export const LMUserInvitationDetails = async (id) => {
    return await axiosInstance().get(`${path}/lm-invitation-details?o_id=${id}`);
}

export const RegistrationsList = async (values) => {
    return await axiosInstance().post(`${path}/registrations/list`, values);
}
export const SettlePrice = async (values) => {
    return await axiosInstance().post(`${path}/registrations/settle-price`, values);
}
export const RegistrationsDetails = async (id) => {
    return await axiosInstance().get(`${path}/registrations/details?o_id=${id}`);
}

export const EditEventRegistration = async (values) => {
    return await axiosInstance().post(`${path}/event/registrations/edit`,values);
}

export const CancelEventRegistration = async (values) => {
    return await axiosInstance().post(`${path}/event/registrations/cancel`,values);
}

export const EventDetailsForEdit = async (id, user_id) => {
    return await axiosInstance().get(`${path}/registrations/details-for-registration?o_id=${id}&user_id=${user_id}`);
}

export const InviteesList = async (values) => {
    return await axiosInstance().post(`${path}/invitees/list`, values);
}

export const InvitationsDetails = async (id) => {
    return await axiosInstance().get(`${path}/invitations/details?o_id=${id}`);
}

export const DeleteInvitees = async (values) => {
    return await axiosInstance().post(`${path}/invitees/delete`, values);
}

export const ResendInviteesEmail = async (values) => {
    return await axiosInstance().post(`${path}/invitees/resend`, values);
}

export const SendLaterEmails = async (values) => {
    return await axiosInstance().post(`${path}/invitations/send-now`, values);
}

export const DeleteInvitationGroup = async (values) => {
    return await axiosInstance().post(`${path}/invitations/delete`, values);
}
export const TicketDetails = async (values) => {
    return await axiosInstance().post(`${path}/tickets/details`, values);
}
export const CheckIn = async (values) => {
    return await axiosInstance().post(`${path}/tickets/check-in`, values);
}
export const CheckOut = async (values) => {
    return await axiosInstance().post(`${path}/tickets/check-out`, values);
}

export const InvitationsDetailsByEmail = async (o_id) => {
    return await axiosInstance().get(`${path}/invitations/details-for-registration?o_id=${o_id}`);
}

export const oldPricing = async (values) => {
    return await axiosInstance().post(`${path}/event/registrations/old-pricing`, values);
}

export const CheckHotelAvailability = async (values) => {
    return await axiosInstance().post(`${path}/event/check-hotel-availability`, values);
}

export const InviteLeads = async (values) => {
    return await axiosInstance().post(`${path}/invite-lm-users`, values);
}
export const DetailsTickets = async (values) => {
    return await axiosInstance().post(`${path}/tickets`, values);
}

export const LMUserList = async (values) => {
    return await axiosInstance().post(`${path}/lm-user-list`, values);
}

export const DripContentOption = async (values) => {
    return await axiosInstance().post(`${path}/drip-content/get-options`, values);
}

export const AddDrip = async (values) => {
    return await axiosInstance().post(`${path}/drip-content/add`, values);
}
export const DripContentList = async (values) => {
    return await axiosInstance().post(`${path}/drip-content/list`, values);
}

export const DripContentDetails = async (id) => {
    return await axiosInstance().get(`${path}/drip-content/details?event_id=${id}`);
}

export const DeleteDripContent = async (values) => {
    return await axiosInstance().post(`${path}/drip-content/delete-email`, values);
}
export const DeleteDefaultOption = async (values) => {
    return await axiosInstance().post(`${path}/drip-content/delete-default-emails`, values);
}

export const LMUserRegistrationList = async (values) => {
    return await axiosInstance().post(`${path}/lmuser-registration-list`, values);
}

export const LMUserRegistrationDetails = async (id) => {
    return await axiosInstance().get(`${path}/lmuser-registration-detail?o_id=${id}`);
}

export const Counts = async (values) => {
    return await axiosInstance().post(`${path}/url-clicks/list`, values);
}

export const LinkStaticsDetails = async (values) => {
    return await axiosInstance().post(`${path}/url-clicks/details`,values);
}
export const EventRegistrationInvitationStatus = async (o_id,status) => {
    return await axiosInstance().get(`${path}/registrations/invitation-status?o_id=${o_id}&status=${status}`);
}
export const ListBoothAttendants = async (values) => {
    return await axiosInstance().post(`${path}/booth-attendant-list`, values);
}
export const StatusChange = async (values) => {
    return await axiosInstance().post(`${path}/change-booth-attendant-status`, values)
}
