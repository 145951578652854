import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as authService from "../services/auth.services";
import {
  getLocalKey,
  getSessionKey,
  getUser,
  globalLoader,
  handleServerValidations,
  isUser,
  removeLocalKey,
  removeSessionKey,
  setLocalKey,
  setSessionKey,
} from "../../../utils/commonfunction";
import Swal from "sweetalert2";
import { COOKIES_EXPIRATION, SWAL_SETTINGS, STAFF_ROLE } from "../../../utils/Constants";
import * as commonServices from '../services/common.services';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Cookies from 'js-cookie';


const UserLogin = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [show, setShow] = useState("false");
  const [urlData] = useState(window.location.search);
  const [userTrue, setUserTrue] = useState(false);
  const [loginMessage, setLoginMessagae] = useState(null);
  const leadData = useSelector((state) => state.designportal);
  const globalDetails = useSelector((state) => state.globaldetail);
  const { t } = useTranslation();
  const formikRef = useRef(null);
  const chooseRoleTrigger = useRef();
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  /** Remember last logged-in user >>>> */
  useEffect(() => {
    if(params.get('invitation') === "true"){
      formikRef?.current.setValues({
        email:params.get('email')
      });
    }else{
      const storedUsername = Cookies.get('username');
      if (storedUsername) {
        formikRef?.current.setValues({
          email:storedUsername
        });
      }
    }
  }, []);
  /** Remember last logged-in user <<<< */

  useEffect(() => {
    const userData = getUser();
    if(userData && Object.keys(userData).length){
      if(userData.role === STAFF_ROLE.LM_BACK_OFFICE){
        navigate(`/back-office/home`);
      }
      else if(userData.role === STAFF_ROLE.LEAD_COORDINATOR){
        navigate(`/lead-coordinator/home`);
      }
      else if(userData.role === STAFF_ROLE.BOOTH_ATTENDANTS){
        checkLeadCoordinatorId(userData?._id)
        // navigate(`/sis-user/home`);
      }
      else if(userData.role === STAFF_ROLE.INFO_COUNTER){
        navigate(`/info-counter/home`);
      }
      else if(userData.role === STAFF_ROLE.USER || userData.role === STAFF_ROLE.STAFF || userData.role === STAFF_ROLE.ADMIN || userData.role === STAFF_ROLE.SUB_ADMIN){
        navigate(`/event-detail/${params.get('event_id')}?invitation_id=${params.get('invitation_id')}&email=${getUser().email}`);
      }
      else{
        if (params.get('invitation') && params.get('invitation') === 'true') {
          commonServices.checkUser(params.get('email')).then((response) => {
            if (response?.data === false) {
              // navigate(`/complete-profile${urlData}`)
            } else {
              setUserTrue(true);
              // navigate("/",{});
            }
          }).catch((error) => {
    
          })
        }
      }
    }
    
  }, []);

  const handleshow = () => {
    setShow(!show);
  };

  // useEffect(() => {
  //   if (isUser(3)) {
  //     navigate(`/event-detail/${params.get('event_id')}?invitation_id=${params.get('invitation_id')}&email=${getUser().email}`)
  //   }
  // }, [])
  function handleRememberMe(e){
    console.log(e.target.checked);
    if(e.target.checked){
      setSessionKey('remember-me','true');
    }else{
      removeSessionKey('remember-me');
      Cookies.remove('username');
      console.log(('remvdkjl'))
    }
  }
  const checkLeadCoordinatorId=(id)=>{
    authService
    .checkLeadCoordinator(id)
    .then((response) => {
      if(response?.data?.isLeadCoordinator){
        chooseRoleTrigger.current.click();
      }
      else{
        navigate(`/sis-user/home`)
      }
    }).catch((err)=>{
      console.log("error: ", err);
    })
  }

  const handelSuccessMsg=()=>{
    Swal.fire({
      icon: "success",
      text: loginMessage,
      ...SWAL_SETTINGS,
    });
  }

  function DualLeadCoordinator(){
    setLocalKey('boothAttendantsAsLeadCoordinator','true');
  }
  
  return (
    <>
      <div className="wraper-inner cpt">
        <section className="accounts-page section-padding">
          <div className="container">
            <div className="accounts-page-box row">
              <div className="accounts-page-box-left col-md-7">
                <figure>
                {getGlobalData.settings?.login_bg_image ?
                    <img src={getGlobalData?.settings?.login_bg_image} alt="Logo" /> :
                    <Skeleton count={1} style={{ height: '500px' }} />
                  }
                </figure>
                <div className="account-content">
                  <div dangerouslySetInnerHTML={{ __html: `${getGlobalData.settings?.login_content ? getGlobalData?.settings?.login_content : "N/A"}` }}></div>
                </div>
              </div>
              <div className="accounts-page-box-right col-md-5">
                <Formik
                  innerRef={formikRef}
                  initialValues={{ email: "", password: "", otp: "" }}
                  validate={(values) => {
                    const errors = {};
                    if (!values.email) {
                      errors.email = t('web_registration_err_email');
                    } 
                    // else if (!(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(values.email))) {
                    //   errors.email = "Invalid email address";
                    // }
                    else if (!(/^[A-Za-z0-9._%+'-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(values.email)))
                    {
                      errors.email = t('web_registration_err_valid_email');
                    }
                    if (params.get('invitation')) {
                      if (!values.otp) {
                        errors.otp = t('web_login_err_otp_required');
                      }
                      if (userTrue && !values.password) {
                        errors.password = t('web_login_err_password_required');
                      }
                    } else {
                      if (!values.password) {
                        errors.password = t('web_login_err_password_required');
                      }
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    globalLoader(true);
                    let formData = new FormData();
                    formData.append("email", values.email.toLowerCase());
                    if (params.get('invitation')) {
                      formData.append("type", 'with_invitation');
                      formData.append("is_existing_user", userTrue ? true : false);
                      if (userTrue) {
                        formData.append("password", values.password);
                      }
                    } else {
                      formData.append("password", values.password);
                    }
                    formData.append("event_otp", values.otp);
                    authService
                      .login(formData)
                      .then((response) => {
                        if (response.success) {
                          /** Remember last logged-in user >>>> */
                          if(getSessionKey('remember-me') === "true"){
                            Cookies.set('username', response?.data?.email, { expires: COOKIES_EXPIRATION });
                          }else{
                            Cookies.remove('username');
                          }
                          /** Remember last logged-in user <<<< */
                          const appData = {action:'access-token', data:{token:response.data.token}};
                          window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
                          setLocalKey("user", JSON.stringify(response.data));
                          setLoginMessagae(response.message)
                          if(response.data.role!==STAFF_ROLE.BOOTH_ATTENDANTS){
                          Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                          });
                          }
                          let returnUrl = getLocalKey("return_url");
                          if (returnUrl) {
                            removeLocalKey("return_url");
                            window.location.href = returnUrl;
                          } else {
                            console.log("urlData",urlData);
                            if(response.data.role===STAFF_ROLE.LM_BACK_OFFICE){
                              navigate(`/back-office/home`);
                            }
                            else if(response.data.role===STAFF_ROLE.LEAD_COORDINATOR){
                              navigate(`/lead-coordinator/home`);
                            }
                            else if(response.data.role===STAFF_ROLE.BOOTH_ATTENDANTS){
                              checkLeadCoordinatorId(response?.data?._id)
                              // navigate(`/sis-user/home`);
                            }
                            else if(response.data.role === STAFF_ROLE.INFO_COUNTER){
                              navigate(`/info-counter/home`);
                            }
                            else{
                              params.get('invitation') ? userTrue ? navigate(`/event-detail/${params.get('event_id')}?invitation_id=${params.get('invitation_id')}&email=${values.email}`) : navigate(`/complete-profile${urlData}`) : navigate(`/`);
                            }
                          }
                          globalLoader(false);
                        } else {
                          Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                          });
                          setTimeout(() => {
                          }, 2000);
                          globalLoader(false);
                        }
                      })
                      .catch((error) => {
                        console.log("error: ", error);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue
                    // and other goodies
                  }) => (
                    <div className="accounts-page-box-inner-L">
                      <div className="common-bg-form">
                        <div className="top-from-headning text-left mb-4">
                          <figure>
                            <img src={getGlobalData?.portal_design?.logo_transparent} alt="Logo" />
                          </figure>
                          <h3>{t('web_login')}</h3>
                          {/* <p>Please enter below details to continue </p> */}
                        </div>
                        <form className="form-box login-form" onSubmit={handleSubmit}>
                          <div className="form-group mb-4">
                            <input
                              disabled={params.get('invitation') ? true : false}
                              className="form-control"
                              placeholder={t('web_lbl_enter_your_email')}
                              type="email"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              autoComplete="off"
                            />
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.email && touched.email && errors.email}
                            </span>
                          </div>
                          {params.get('invitation') && params.get('invitation') === 'true' ? (
                            <>
                              {userTrue ? (
                                <div className="form-group mb-4">
                                  <div className="with-icon">
                                    <input
                                      className="form-control"
                                      placeholder={t('web_lbl_enter_your_password')}
                                      type={!show === true ? "text" : "password"}
                                      name="password"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.password}
                                      autoComplete="off"
                                    />
                                    <div className="icon-box cp" onClick={handleshow}>
                                      {(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}
                                    </div>
                                  </div>
                                  <span className="text-danger text-start d-block err-spn">
                                    {errors.password &&
                                      touched.password &&
                                      errors.password}
                                  </span>
                                </div>
                              ) : (
                                <></>
                              )}
                              <div className="form-group text-start mb-4">
                                <input className={"form-control inputwidth" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                  placeholder={t('web_lbl_enter_your_otp')}
                                  type="otp"
                                  name="otp"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.otp}
                                  autoComplete="off" />
                                {/* <OTPInput
                                  // className={"form-control inputwidth" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                  type="otp"
                                  name="otp"
                                  inputType="text"
                                  value={values.otp}
                                  onChange={(e) => setFieldValue('otp',e)}
                                  numInputs={4}
                                  renderSeparator={<span></span>}
                                  renderInput={(props) => <input className='form-control' {...props} />}
                                  // inputStyle={{height:"44px",width:"98px",marginRight:21}}
                                  inputStyle="form-control otp_input mb-4"
                                  /> */}
                                <span className='text-danger text-start d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                              </div>
                            </>
                          ) : (
                            <div className="form-group">
                              <div className="with-icon">
                                <input
                                  className="form-control"
                                  placeholder={t('web_lbl_enter_your_password')}
                                  type={!show === true ? "text" : "password"}
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  autoComplete="off"
                                />
                                <div className="icon-box cp" onClick={handleshow}>
                                  {(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}
                                </div>
                              </div>
                              <span className="text-danger text-start d-block err-spn">
                                {errors.password &&
                                  touched.password &&
                                  errors.password}
                              </span>
                            </div>
                          )}
                          {/* <Link href="forgot-password.php" className="forget-password mt-2 d-block text-left">  Forgot Password? </Link> */}
                          <div className="form-check mt-3">
                            <input
                              type="checkbox"
                              id="remember-me"
                              className="form-check-input question-required cp"
                              value={"true"}
                              defaultChecked={Cookies.get('username')}
                              onChange={handleRememberMe}
                            />
                            <label className="form-check-label cp" htmlFor="remember-me">
                            {t('web_lbl_remember_me')}
                            </label>
                          </div>
                            {(params.get('invitation') === false || params.get('invitation') == null) && (
                                <Link className="forget-password mt-2 d-block text-left" to="/forget-password">
                                    {t('web_login_forgot_password')}
                                </Link>
                            )}
                          <div className="btn-box text-center mt-4 ">
                            <button className="btn btn-primary w-100 h-50" type="submit">
                              {t('web_login')}
                            </button>
                          </div>
                          <div className="bottom-text mt-4 text-center">
                            {t('web_lbl_dont_have_account')} <Link to={`/complete-profile`}>{t('web_lbl_sign_up')}</Link>
                          </div> 
                        </form>
                      </div>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      <a className="d-none" ref={chooseRoleTrigger} data-bs-toggle="modal" href="#ChooseRole" role="button" data-bs-target="#ChooseRole"><span></span></a>
      <div className="modal fade" id="ChooseRole" aria-hidden="true" data-bs-backdrop="static" aria-labelledby="ChooseRoleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="announcements-title" id="ChooseRoleLabel">{t('web_login_as')}</span>
              <button type="button" className="btn-close" id="close_button" data-bs-dismiss="modal" style={{display:'none'}} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <button onClick={()=>{handelSuccessMsg();navigate("/sis-user/home")}}  className="btn btn-primary" data-bs-dismiss="modal">{t('web_as_booth_attendant')}</button>
              <button onClick={()=>{handelSuccessMsg();navigate("/lead-coordinator/home");DualLeadCoordinator()}}  className="btn btn-primary ms-2" data-bs-dismiss="modal">{t('web_as_lead_coordinator')}</button>
            </div>
          </div>
        </div>
      </div>
      </div>

      
    </>
  );
};
export default UserLogin;
