import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'
import { getLocalKey, getUser, logOutUser } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import { STAFF_ROLE } from '../../../utils/Constants';


const Header = ({ updateHeader }) => {
	const [profile, setProfile] = useState("")
	const showprofile = useSelector((state) => state.profile);
	const [username, setUserName] = useState("")
	const navigate = useNavigate();
	
	useEffect(() => {
		const getuser = getUser()
		setUserName(getuser.name)
		setProfile(showprofile && showprofile.file ? URL.createObjectURL(showprofile.file) : getuser.profile_image)
	}, [showprofile])


	useEffect(() => {
		$(document).ready(function () {
			$('.showpop').click(function () {
				$('.main-profile-menu').addClass("show");
			})
			$('.fixed_bg').click(function () {
				$('.main-profile-menu').removeClass("show");
			})
		})
	}, [])

	const toggleSidebar = (e) => {
		e.preventDefault();
		if ($("body").hasClass("main-sidebar-hide")) {
			$("body").removeClass("main-sidebar-hide")
		} else {
			$("body").addClass("main-sidebar-hide")
		}
	}


	const logout = () => {
		Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You want to logout!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#403fad',
			cancelButtonColor: '#f1388b',
			confirmButtonText: 'Yes'
		}).then((result) => {
			if (result.isConfirmed) {
				logOutUser(navigate);
			}
		})
	}
	function getRoleName(){
		let roleName = null;
		const userData = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {};
		let role = userData.role;
		switch(role){
			case STAFF_ROLE.ADMIN: roleName = "Super Admin";
			break;
			case STAFF_ROLE.SUB_ADMIN: roleName = "Sub Admin";
			break;
			case STAFF_ROLE.STAFF: roleName = "Staff";
			break;
			default: roleName = "Unknown";
		};
		return roleName;
	}

	return (
		<>
			{/* <!-- Main Header--> */}
			<div className="main-header side-header">
				<div className="container-fluid">
					<div className="main-header-left">
						<a className="main-header-menu-icon e_none" href="/" id="mainSidebarToggle" onClick={toggleSidebar}><span></span></a>
					</div>
					<div className="main-header-center">
						<div className="responsive-logo">
							{/* <a href="index.html"><img src={logo} className="mobile-logo" alt="logo" /></a> */}
							{/* <a href="index.html"><img src="../assets/img/brand/Mask Group 1.png" className="mobile-logo" alt="logo" /></a>
							<a href="index.html"><img src="../assets/img/brand/Mask Group 1.png" className="mobile-logo-dark" alt="logo" /></a> */}
						</div>
					</div>
					<div className="main-header-right">
						<div className={`dropdown main-profile-menu d-flex align-items-center `}>
							{/* <a className="d-flex" href="javascript:void(0)">
								<span className="main-img-user" onClick={() => setShowProfile(!showprofile)}><img alt="avatar" src="../assets/img/brand/Mask Group 1@2x.png"/></span>
							</a> */}
							<button className="d-flex border-0 bg-white showpop">
								{/* <span className="main-img-user"><img alt="avatar" src={Logo} /></span> */}
								<span className="main-img-user"><img alt="avatar" src={profile} /></span>
							</button>
							<div className="dropdown-menu">
								<span className='fixed_bg'></span>
								<div className='position-relative headerdropdown' style={{ zIndex: "99999" }}>
									<div className="header-navheading">
										<h4 className="main-notification-title text-capitalize">{username}</h4>
										<p className="main-notification-text">{getRoleName()}</p>
									</div>
									<Link className="dropdown-item " to={`/admin/profile/edit/${"1"}`}>
										<i className="fe fe-edit"></i> Edit Profile
									</Link>
									<button className="dropdown-item " onClick={() => logout()}>
										<i className="fe fe-power"></i> Log Out
									</button>
								</div>
							</div>
						</div>
						<button className="navbar-toggler navresponsive-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4"
							aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
							<i className="fe fe-more-vertical header-icons navbar-toggler-icon"></i>
						</button>
						{/* <!-- Navresponsive closed --> */}
					</div>
				</div>
			</div>
			{/* <!-- End Main Header--> */}
		</>
	)
}

export default Header;