import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import toHtml from "html-react-parser";
import * as questionSetServices from "../../services/questionset";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import {
  addSpecialCharacter,
  capitalizeFirstLetter,
  capitalizeFirstLetterOfEachWord,
  formateDate,
  getFileNameFromURL,
  globalLoader,
  handleServerValidations,
  hasPermission,
  removeCommas,
  removeSpecialCharacter,
  showStatus,
  SpecialCharacter,
  TrimText,
  writeMyExcel,
} from "../../../../utils/commonfunction";
import { Modal, Button } from "rsuite";
import CustomPagination from "../../common/custompagination";
import {
  QUESTION_TYPES,
  showFilterlist,
  STAFF_ROLE,
  SURVEY_TYPES,
  SWAL_SETTINGS,
} from "../../../../utils/Constants";
import * as questionService from "../../services/questions";
import Swal from "sweetalert2";
import $ from "jquery";
import CustomRangepicker from "../../common/rangepicker";
import StatusFilter from "../../common/statusFilter";
import ShowAnswer from "../../shared/ShowAnswer";
import VideoPlayer from "../../../user/shared/VideoPlayer";

const QuestionSetView = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [query] = useSearchParams();
  const [showdefault, setShowDefault] = useState({});
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Question sets", url: "/admin/question-settings/sets/list/1" },
    { title: "View", url: "" },
  ];
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [csvHeaders] = useState([
    "Submitted By",
    "Email",
    "Create Date",
    "Change Date",
    "Number Of Questions",
  ]);
  const [allUsersExportFlag] = useState(false);
  const [surveyData, setSurveyData] = useState([]);
  const [page, setPage] = useState(1);
  const location = useLocation();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting] = useState({});
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const [surveyDatalength, setSurveyDataLength] = useState("");
  const [surveyItemperpage] = useState(10);
  const [surveyPage, setSurveyPage] = useState(1);
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  let [videoSourceType, setVideoSourceType] = useState("video");
  const [formData, setFormData] = useState([]);
  const [formDatalength, setFromDataLength] = useState("");
  const [formPage, setFormPage] = useState(1);
  const [formItemperpage] = useState(10);
  const [allUsersExportFlagNew] = useState(false);

  const handleViewClick = (question) => {
    setSelectedQuestion(question);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  const playVideo = (video, type) => {
    setVideoSourceType(() => type);
    setVideoPlaySource(video);
  };
  const stopVideoPlayer = () => {
    setVideoSourceType(null);
    setVideoPlaySource(null);
  };

  useEffect(() => {
    questionSetServices
      .Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, [params.id]);

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", query.get("survey_page"));
    formData.append("per_page", surveyItemperpage);
    formData.append("survey_id", params.id);
    questionSetServices
      .SubmittedQuestionsList(formData)
      .then((data) => {
        setSurveyData(
          data && data.data && data.data.list ? data.data.list : []
        );
        setSurveyDataLength(data.data.total_records);
        setSurveyPage(data && data.data && data.data.page ? data.data.page : 1);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, [surveyPage, surveyItemperpage, params.id]);

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", query.get("form_page"));
    formData.append("per_page", formItemperpage);
    formData.append("question_set_id", params.id);
    questionSetServices
      .SubmittedFormList(formData)
      .then((data) => {
        setFormData(data && data.data && data.data.list ? data.data.list : []);
        setFromDataLength(data.data.total_records);
        setFormPage(data && data.data && data.data.page ? data.data.page : 1);
        setLoader(false);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }, [formPage, formItemperpage, params.id]);

  const exportAllUsers = () => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", surveyDatalength);
    formData.append("survey_id", params.id);
    questionSetServices
      .SubmittedQuestionsList(formData)
      .then((response) => {
        let data =
          response && response?.data && response?.data?.list
            ? response.data.list
            : [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.questions_data?.length) {
            lengthArr = Array(item?.questions_data?.length).fill([]);
          }
        });
        let rows = data.map((item) => {
          const tempRow = [
            removeCommas(item?.user_data?.name),
            removeCommas(item?.user_data?.email),
            removeCommas(formateDate(item?.createdAt)),
            removeCommas(formateDate(item?.updatedAt)),
            lengthArr?.length,
          ];
          for (let i = 0; i < lengthArr?.length; i++) {
            let questionTitle;
            if (item && item?.questions_data && item?.questions_data[i]) {
              questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            } else {
              questionTitle = "N/A";
            }
            let answer;
            if (item && item.questions_data && item.questions_data[i]) {
              answer = item.questions_data[i].answer ?? "N/A";
            } else {
              answer = "N/A";
            }
            if (questionTitle === "N/A" && answer === "N/A") {
              tempRow.push("N/A");
            } else {
              tempRow.push(
                `${capitalizeFirstLetter(
                  removeSpecialCharacter(questionTitle)
                )}: ${answer}`
              );
            }
          }
          return tempRow;
        });
        const headers = [
          "Submitted By",
          "Email",
          "Create Date",
          "Change Date",
          "Number Of Questions",
          ...lengthArr.map((_, index) => `Extra Info`),
        ];
        writeMyExcel([headers, ...rows], `survey-report.xlsx`)
          .then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };
  /** This function is used to export all submitted survey data end */

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", query.get("page"));
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("question_set_id", params?.id);
      questionService
        .List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          // setSerialNo(data.data.offset)
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch]);

  const viewfunction = (row) => {
    navigate(`/admin/question-settings/questions/view/${row._id}`);
  };

  const goToEdit = (row) => {
    navigate(
      `/admin/question-settings/questions/${query.get("page")}/edit/${row._id}`
    );
    // console.log("******",query.get('page'));
  };

  const ChangeStatus = (data, Num) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        questionService
          .Status(formData)
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error===>");
          });
      }
    });
  };

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        let ids = Array.isArray(data) ? data : [data];
        formdata.append("o_id", JSON.stringify(ids));
        questionService
          .Delete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetQuesType")[0].selectedIndex = 0;
  };

  const goToDuplicate = (row) => {
    navigate(`/admin/question-settings/questions/add`, {
      state: { values: row, question_set_id: row?.questionsets_data?._id },
    });
  };

  const exportAllUsersNew = () => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", formDatalength);
    formData.append("question_set_id", params.id);
    questionSetServices
      .SubmittedFormList(formData)
      .then((response) => {
        let data =
          response && response?.data && response?.data?.list
            ? response.data.list
            : [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.questions_data?.length) {
            lengthArr = Array(item?.questions_data?.length).fill([]);
          }
        });
        let rows = data.map((item) => {
          const tempRow = [
            removeCommas(item?.questions_data?.length),
            removeCommas(formateDate(item?.createdAt)),
            removeCommas(formateDate(item?.updatedAt)),
          ];
          for (let i = 0; i < lengthArr?.length; i++) {
            let questionTitle;
            if (item && item?.questions_data && item?.questions_data[i]) {
              questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            } else {
              questionTitle = "N/A";
            }
            let answer;
            if (item && item.questions_data && item.questions_data[i]) {
              answer = item.questions_data[i].answer ?? "N/A";
            } else {
              answer = "N/A";
            }
            if (questionTitle === "N/A" && answer === "N/A") {
              tempRow.push("N/A");
            } else {
              tempRow.push(
                `${capitalizeFirstLetter(
                  removeSpecialCharacter(questionTitle)
                )}: ${answer}`
              );
            }
          }
          return tempRow;
        });
        const headers = [
          "Total Questions",
          "Create Date",
          "Change Date",
          ...lengthArr.map((_, index) => `Extra Info`),
        ];
        writeMyExcel([headers, ...rows], `form-report.xlsx`)
          .then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };

  //question list section end

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View Question Type</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <table
                      id="simple-table"
                      className="table table-bordered table-hover"
                    >
                      <tbody>
                        <tr>
                          <th>Title</th>
                          <td>
                            {showdefault && showdefault.title
                              ? showdefault.title
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Title In German</th>
                          <td>
                            {showdefault && showdefault.title_de
                              ? showdefault.title_de
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault.type
                              ? removeSpecialCharacter(showdefault.type)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Category</th>
                          <td>
                            {showdefault && showdefault?.category
                              ? SpecialCharacter(
                                  capitalizeFirstLetterOfEachWord(
                                    showdefault.category
                                  )
                                )
                              : "Default"}
                          </td>
                        </tr>
                        <tr>
                          <th>Created Date</th>
                          <td>
                            {showdefault && showdefault.createdAt
                              ? formateDate(showdefault.createdAt)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {showdefault && showdefault.status === 0
                              ? toHtml(showStatus(showdefault.status))
                              : toHtml(showStatus(showdefault.status))}
                          </td>
                        </tr>
                        <tr>
                          <th>Modified Date</th>
                          <td>
                            {showdefault && showdefault.updatedAt
                              ? formateDate(showdefault.updatedAt)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  showdefault && showdefault.description
                                    ? showdefault.description
                                    : "N/A",
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  showdefault && showdefault.description_de
                                    ? showdefault.description_de
                                    : "N/A",
                              }}
                            ></div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  showdefault &&
                                  showdefault?.additional_information
                                    ? showdefault?.additional_information
                                    : "N/A",
                              }}
                            ></div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
            <div className="">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h6 className="main-content-label">Questions</h6>
                    <div className="d-flex align-items-center">
                      <div className="form-group mb-0 me-3">
                        <div className="form-group mb-0 rangepicker_container filter_design">
                          <i className="fa fa-search calender_icon"></i>
                          <input
                            type="search"
                            className="form-control"
                            value={globalsearch}
                            placeholder="Search"
                            onChange={(e) => {
                              setGlobalSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="me-3">
                        <CustomRangepicker
                          GetDateRange={(e) => {
                            prepareSearch("createdAt", e);
                          }}
                          resetdate={resetdate}
                        />
                      </div>
                      <div className="me-3">
                        <div className="form-group mb-0 filter_icon_container filter_design">
                          <i className="fa fa-filter mr-2 filter_icon"></i>
                          <div className="select-down-arrow">
                            <select
                              className="form-control text-capitalize cp"
                              onChange={(e) => {
                                prepareSearch("question_type", e.target.value);
                              }}
                              id="resetQuesType"
                            >
                              <option value="">Type</option>
                              {QUESTION_TYPES &&
                                Object.values(QUESTION_TYPES).map(
                                  (option, i) => {
                                    return (
                                      <option key={i} value={option}>
                                        {SpecialCharacter(option)}
                                      </option>
                                    );
                                  }
                                )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="me-3">
                        <StatusFilter
                          data={showFilterlist}
                          prepareSearch={prepareSearch}
                        />
                      </div>
                      <button
                        type="reset"
                        value="Reset"
                        onClick={resetFilter}
                        className="btn btn-warning float-right mr-2"
                      >
                        Reset Filter
                      </button>
                      <button
                        className="btn ripple btn-main-primary signbtn"
                        onClick={() =>
                          navigate(`/admin/question-settings/questions/add`, {
                            state: { question_set_id: showdefault?._id },
                          })
                        }
                      >
                        Add New
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive position-relative">
                    <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                      <thead>
                        <tr>
                          <th> Title</th>
                          <th>
                            <div className="sorting_column">
                              <span>Question Set</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting_column">
                              <span>Question Type</span>
                            </div>
                          </th>
                          <th>Is Dedicated</th>
                          <th>Order</th>
                          <th>Created Date</th>
                          <th>Created By</th>
                          <th>Updated By</th>
                          <th className="status_head">Status</th>
                          <th className="action_head">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr>
                            <td colSpan={11}>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {list.length ? (
                              list.map((row, i) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      {row?.title
                                        ? TrimText(row.title, 20)
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {row?.questionsets_data &&
                                      row?.questionsets_data ? (
                                        <Link
                                          to={`/admin/question-settings/sets/view/${row?.questionsets_data?._id}?page=1&survey_page=1`}
                                        >
                                          {row?.questionsets_data?.title}
                                        </Link>
                                      ) : (
                                        "N/A"
                                      )}
                                    </td>
                                    <td className="text-capitalize">
                                      {row?.question_type
                                        ? SpecialCharacter(row.question_type)
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {row?.is_dedicated_questions === "true" ||
                                      row?.is_dedicated_questions === true
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    <td className="text-capitalize">
                                      {row?.order ? row.order : "N/A"}
                                    </td>
                                    <td>
                                      {row?.createdAt
                                        ? formateDate(row.createdAt)
                                        : "N/A"}
                                    </td>
                                    <td>
                                      {row?.createdBy?.name ? (
                                        <Link
                                          to={`/admin/staff/view/${row.createdBy._id}`}
                                        >
                                          {row.createdBy.name}
                                        </Link>
                                      ) : (
                                        "N/A"
                                      )}
                                    </td>
                                    <td>
                                      {row?.updatedBy?.name ? (
                                        <Link
                                          to={`/admin/staff/view/${row.updatedBy._id}`}
                                        >
                                          {row.updatedBy.name}
                                        </Link>
                                      ) : (
                                        "N/A"
                                      )}
                                    </td>
                                    {hasPermission(
                                      "/admin/question-settings/questions/status"
                                    ) ? (
                                      <td>
                                        {row?.status === 1 ? (
                                          <button
                                            className="btn ripple btn-main-primary signbtn"
                                            onClick={() => {
                                              ChangeStatus(row?._id, 0);
                                            }}
                                          >
                                            Active
                                          </button>
                                        ) : (
                                          <button
                                            className="btn ripple btn-secondary"
                                            onClick={() => {
                                              ChangeStatus(row?._id, 1);
                                            }}
                                          >
                                            Inactive
                                          </button>
                                        )}
                                      </td>
                                    ) : (
                                      <td>
                                        {row?.status === 1 ? (
                                          <span className="badge badge-success">
                                            Active
                                          </span>
                                        ) : (
                                          <span className="badge badge-danger">
                                            Inactive
                                          </span>
                                        )}
                                      </td>
                                    )}
                                    <td>
                                      <div className="d-flex">
                                        <button
                                          className="btn ripple btn-main-primary signbtn"
                                          onClick={() => {
                                            viewfunction(row);
                                          }}
                                        >
                                          View
                                        </button>
                                        <button
                                          className="btn ripple btn-success mlAction"
                                          onClick={() => {
                                            goToEdit(row);
                                          }}
                                        >
                                          Edit
                                        </button>
                                        {hasPermission(
                                          "/admin/question-settings/questions/delete"
                                        ) ? (
                                          <button
                                            className="btn ripple btn-secondary mlAction"
                                            onClick={() => {
                                              Deletefunction(row?._id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        ) : null}
                                        {hasPermission(
                                          "/admin/question-settings/questions/more"
                                        ) ? (
                                          <button
                                            type="button"
                                            className="btn btn-dark dropdown-toggle mx-1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            More
                                          </button>
                                        ) : null}
                                        <ul className="dropdown-menu">
                                          {hasPermission(
                                            "/admin/question-settings/questions/duplicate"
                                          ) ? (
                                            <li>
                                              <a
                                                style={{ color: "#647194" }}
                                                href="/"
                                                className="dropdown-item"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  goToDuplicate(row);
                                                }}
                                              >
                                                Duplicate
                                              </a>
                                            </li>
                                          ) : null}
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={11} className="text-center">
                                  No records
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className=""
                    id="example1_info"
                    role="status"
                    aria-live="polite"
                  >
                    <b>Total Records : {datalength ? datalength : "0"}</b>
                  </div>

                  {datalength && datalength > 0 ? (
                    <CustomPagination
                      datalength={datalength}
                      paramName={"page"}
                      itemperpage={itemperpage}
                      currentPage={page}
                      setPage={setPage}
                      query={"true"}
                      pageRoute={[
                        {
                          name: "Question",
                          path: `/admin/question-settings/sets/view/${params.id}`,
                        },
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {showdefault?.type === SURVEY_TYPES.SURVEY ? (
              <div className="">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h6 className="main-content-label">
                        Submitted Survey Data
                      </h6>
                      <div className="d-flex align-items-center">
                        {allUsersExportFlag ? (
                          <>
                            <button
                              className="btn ripple btn-main-primary signbtn mr-2"
                              disabled={surveyDatalength === 0}
                              onClick={exportAllUsers}
                            >
                              Export Report
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn ripple btn-main-primary signbtn mr-2"
                            disabled={surveyDatalength === 0}
                            onClick={exportAllUsers}
                          >
                            Export Report
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="table-responsive position-relative">
                      <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                          <tr>
                            <th>Submitted By</th>
                            <th>Email</th>
                            {/* <th>Questions</th> */}
                            <th>Created Date</th>
                            <th className="action_head">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <tr>
                              <td colSpan={5}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {surveyData.length ? (
                                surveyData.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {data && data?.user_data?.name ? (
                                          <Link
                                            to={`/admin/user-management/users/view/${data?.user_id}`}
                                          >
                                            {data?.user_data?.name}
                                          </Link>
                                        ) : (
                                          "N/A"
                                        )}
                                      </td>
                                      <td>
                                        {data && data?.user_data?.email ? (
                                          <Link
                                            to={`/admin/user-management/users/view/${data?.user_id}`}
                                          >
                                            {data?.user_data?.email}
                                          </Link>
                                        ) : (
                                          "N/A"
                                        )}
                                      </td>
                                      {/* <td>{data?.questions_data?.length}</td> */}
                                      <td>
                                        {data?.createdAt
                                          ? formateDate(data?.createdAt)
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          <button
                                            className="btn ripple btn-main-primary signbtn"
                                            onClick={() =>
                                              handleViewClick(
                                                data?.questions_data
                                              )
                                            }
                                          >
                                            View
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center">
                                    No records
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      <Modal
                        backdrop="static"
                        size="full"
                        className="invitation-modal modal-height-auto"
                        open={isModalVisible}
                        onClose={closeModal}
                      >
                        <Modal.Header>
                          <Modal.Title>Submitted survey details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <table
                            id="questions-table"
                            className="table table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>Question Type</th>
                                <th>Is Required</th>
                                <th>Answer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedQuestion?.map((question, index) => (
                                <tr key={index}>
                                  <td>
                                    {question?.question_title
                                      ? question?.question_title
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {question?.question_type
                                      ? SpecialCharacter(
                                          capitalizeFirstLetterOfEachWord(
                                            question?.question_type
                                          )
                                        )
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {question?.is_required === "true" ||
                                    question?.is_required === true
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  {/* <td>
                                                                    {Array.isArray(question?.answer)
                                                                        ? question?.answer.join(', ')
                                                                        : question?.answer}
                                                                </td> */}
                                  <td>
                                    <ShowAnswer
                                      question={question}
                                      setVideoPlaySource={playVideo}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button onClick={closeModal} appearance="subtle">
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>

                    <div
                      className=""
                      id="example1_info"
                      role="status"
                      aria-live="polite"
                    >
                      <b>
                        Total Records :{" "}
                        {surveyDatalength ? surveyDatalength : "0"}
                      </b>
                    </div>
                    {surveyDatalength && surveyDatalength > 0 ? (
                      <CustomPagination
                        datalength={surveyDatalength}
                        paramName="survey_page"
                        itemperpage={surveyItemperpage}
                        currentPage={surveyPage}
                        setPage={setSurveyPage}
                        query={"true"}
                        pageRoute={[
                          {
                            name: "Question set",
                            path: `/admin/question-settings/sets/view/${params.id}`,
                          },
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            {showdefault?.type === SURVEY_TYPES.QUESTION_SET ? (
              <div className="">
                <div className="card custom-card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <h6 className="main-content-label">Submitted Forms</h6>
                      <div className="d-flex align-items-center">
                        {allUsersExportFlagNew ? (
                          <>
                            <button
                              className="btn ripple btn-main-primary signbtn mr-2"
                              disabled={formDatalength === 0}
                              onClick={exportAllUsersNew}
                            >
                              Export Report
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn ripple btn-main-primary signbtn mr-2"
                            disabled={formDatalength === 0}
                            onClick={exportAllUsersNew}
                          >
                            Export Report
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="table-responsive position-relative">
                      <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                          <tr>
                            <th>Total Questions</th>
                            {/* <th>Total Answered Question</th> */}
                            {/* <th>Questions</th> */}
                            <th>Submitted By</th>
                            <th>Email</th>
                            <th>Created Date</th>
                            <th className="action_head">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loader ? (
                            <tr>
                              <td colSpan={5}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {formData.length ? (
                                formData.map((data, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{data?.questions_data?.length}</td>
                                      <td>
                                        <Link
                                          to={`/admin/user-management/users/view/${data.user_id}`}
                                        >
                                          {data?.user_data?.name ? data.user_data.name : "N/A"}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/admin/user-management/users/view/${data.user_id}`}
                                        >
                                          {data?.user_data?.email ? data.user_data.email : "N/A"}
                                        </Link>
                                      </td>
                                      <td>
                                        {data?.createdAt
                                          ? formateDate(data?.createdAt)
                                          : "N/A"}
                                      </td>
                                      <td>
                                        <div className="d-flex">
                                          <button
                                            className="btn ripple btn-main-primary signbtn"
                                            onClick={() =>
                                              handleViewClick(
                                                data?.questions_data
                                              )
                                            }
                                          >
                                            View
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center">
                                    No records
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                      <Modal
                        backdrop="static"
                        size="full"
                        className="invitation-modal modal-height-auto"
                        open={isModalVisible}
                        onClose={closeModal}
                      >
                        <Modal.Header>
                          <Modal.Title>Submitted forms</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {/* {formData?.length > 0 &&
                          formData.some((data) => data?.user_data) ? (
                            <table
                              id="questions-table"
                              className="table table-bordered table-hover"
                            >
                              <thead>
                                <tr>
                                  <th>Submitted By</th>
                                  <th>Email</th>
                                </tr>
                              </thead>
                              <tbody>
                                {formData.map((data, index) =>
                                  data.user_data ? (
                                    <tr key={index}>
                                      <td>
                                        <Link
                                          to={`/admin/user-management/users/view/${data.user_id}`}
                                        >
                                          {data.user_data.name}
                                        </Link>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/admin/user-management/users/view/${data.user_id}`}
                                        >
                                          {data.user_data.email}
                                        </Link>
                                      </td>
                                    </tr>
                                  ) : null
                                )}
                              </tbody>
                            </table>
                          ) : (
                            null
                          )} */}
                          <table
                            id="questions-table"
                            className="table table-bordered table-hover"
                          >
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>Question Type</th>
                                <th>Is Required</th>
                                <th>Answer</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedQuestion?.map((question, index) => (
                                <tr key={index}>
                                  <td>
                                    {question?.question_title
                                      ? question?.question_title
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {question?.question_type
                                      ? SpecialCharacter(
                                          capitalizeFirstLetterOfEachWord(
                                            question?.question_type
                                          )
                                        )
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {question?.is_required === "true" ||
                                    question?.is_required === true
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td>
                                    <ShowAnswer
                                      question={question}
                                      setVideoPlaySource={playVideo}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button onClick={closeModal} appearance="subtle">
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <div
                      className=""
                      id="example1_info"
                      role="status"
                      aria-live="polite"
                    >
                      <b>
                        Total Records : {formDatalength ? formDatalength : "0"}
                      </b>
                    </div>
                    {formDatalength && formDatalength > 0 ? (
                      <CustomPagination
                        datalength={formDatalength}
                        paramName="form_page"
                        itemperpage={formItemperpage}
                        currentPage={formPage}
                        setPage={setFormPage}
                        query={"true"}
                        pageRoute={[
                          {
                            name: "Question set",
                            path: `/admin/question-settings/sets/view/${params.id}`,
                          },
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : null}
            <div className="mt-5 back-button">
              <button
                className="btn ripple btn-dark"
                type="button"
                onClick={() => navigate(`/admin/question-settings/sets/list/1`)}
              >
                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        backdrop="static"
        className="invitation-modal modal-height-auto"
        open={Boolean(videoPlaySource)}
        onClose={stopVideoPlayer}
      >
        <Modal.Header>
          <Modal.Title>
            Playing : {getFileNameFromURL(videoPlaySource)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {videoPlaySource ? (
            <VideoPlayer src={videoPlaySource} type={videoSourceType} />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuestionSetView;
