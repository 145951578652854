import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toHtml from "html-react-parser";
import * as staffService from "../../services/staff.services";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import { decideRole, formateDate, getFileNameFromURL, showStatus } from "../../../../utils/commonfunction";
import ShowAnswer from "../../shared/ShowAnswer";
import VideoPlayer from "../../../user/shared/VideoPlayer";

const StaffView = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Staff", url: "/admin/staff/list/1" },
    { title: "View", url: "" },
  ];
  let [videoSourceType, setVideoSourceType] = useState("video");
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  const videoPlayerToggler = useRef();

  useEffect(() => {
    staffService
      .Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  const playVideo = (video,type) => {
    setVideoPlaySource(video);
    setVideoSourceType(type);
    videoPlayerToggler?.current?.click();
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View Staff</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <table
                      id="simple-table"
                      className="table  table-bordered table-hover"
                    >
                      <tbody>
                        <tr>
                          <th>Profile</th>
                          <td>
                            <img
                              src={
                                showdefault && showdefault.profile_image
                                  ? showdefault.profile_image
                                  : "N/A"
                              }
                              alt="profile"
                              style={{ height: "100px" }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th> First Name</th>
                          <td>
                            {showdefault && showdefault.first_name
                              ? showdefault.first_name
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <th>Last Name</th>
                          <td>
                            {showdefault && showdefault.last_name
                              ? showdefault.last_name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Email</th>
                          <td>
                            {showdefault && showdefault.email
                              ? showdefault.email
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Role</th>
                          <td>{decideRole(showdefault.role)}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {showdefault && showdefault.status === 0
                              ? toHtml(showStatus(showdefault.status))
                              : toHtml(showStatus(showdefault.status))}
                          </td>
                        </tr>
                        <tr>
                          <th>Created Date</th>
                          <td>
                            {showdefault && showdefault.createdAt
                              ? formateDate(showdefault.createdAt)
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {showdefault?.questions_data && showdefault.questions_data.length > 0 && (
                          <div className="mb-4">
                            <div className="border rounded p-3">
                              <h4 className="tx-15 text-uppercase mb-3">Extra Info</h4>
                              <div className="pt-10 pb-0">
                                {showdefault.questions_data.map((questionItem, index) => (
                                  <div key={index} className="mb-1">
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        {questionItem?.question_title || "N/A"}:
                                      </label>
                                      <div className="mb-0 text-capitalize">
                                      <ShowAnswer question={questionItem} setVideoPlaySource={playVideo} parentClass="admin-view-registration"/>
                                     </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                    <div className="mt-5">
                      <button
                        className="btn ripple btn-dark"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
      <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#videoPlayerModal" ref={videoPlayerToggler}></button>
           <div className="modal fade" id="videoPlayerModal" data-bs-backdrop="false" data-bs-keyboard="false" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="videoPlayerModalLabel">Playing : {getFileNameFromURL(videoPlaySource)}</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onMouseDown={(event)=>{
                              event.preventDefault();
                              return false;
                          }} onClick={() => {
                              setTimeout(() => {
                              setVideoPlaySource(null);
                              }, 300);
                          }}></button>
                      </div>
                      <div className="modal-body">
                          {videoPlaySource ?
                              <VideoPlayer src={videoPlaySource} type={videoSourceType}/>
                              : null
                          }
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
};

export default StaffView;
