import axiosInstance from "../../../utils/axios";

const path = "web/sis-user";

  export const InvitationDetails = async (_id, status) => {
    return await axiosInstance().get(
      `${path}/invitation-detail?_id=${_id}`
    );
  };

  export const InvitationAccept = async (_id, status) => {
    return await axiosInstance().get(
      `${path}/invitation?_id=${_id}&status=${status}`
    );
  };

  export const CompleteProfileSISUser = async (values) => {
    return await axiosInstance().post(`${path}/complete-profile`, values)
}

export const QuestionList = async (values) => {
  return await axiosInstance().post(`${path}/question-list`, values)
}

export const eventList = async () => {
  return await axiosInstance().get(`${path}/event-list`)
}

export const bookingList = async (values) => {
  return await axiosInstance().post(`${path}/rooms/bookings/list`, values);
}


export const BookingDetail = async (id) => {
  return await axiosInstance().get(`${path}/bookings/details?o_id=${id}`);
}

export const cancelBooking = async (id) => {
  return await axiosInstance().post(`${path}/rooms/bookings/cancel`, id);
}
export const StatusDetails = async (id) => {
  return await axiosInstance().get(`${path}/event-detail?o_id=${id}`);
}
export const StatusChange = async (values) => {
  return await axiosInstance().post(`${path}/change-booth-attendant-status`, values)
}
export const BoothStatusDetails = async (id) => {
  return await axiosInstance().get(`${path}/booth-status-detail?o_id=${id}`);
}