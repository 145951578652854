import React, { useEffect, useState } from 'react';
import { Formik, ErrorMessage, Field } from 'formik';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {encodeValue, handleServerValidations, setLocalKey, removeSpacesAndCharacters, globalLoader, getTranslatedGender, selectSalutation } from '../../../utils/commonfunction';
import { GENDER, SWAL_SETTINGS, } from '../../../utils/Constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useTranslation } from 'react-i18next';
import {  UserDetails, updateUserprofile } from '../../user/services/webuser.service';
import { useSelector } from 'react-redux';
import FrontLoader from '../../globalComponents/FrontLoader';

const BackOfficeProfile = () => {
  const navigate = useNavigate();
  const [showdefault, setShowDefault] = useState({});
  const [previewimage, setPreviewImage] = useState("")
  const [updateData, setUpdateData] = useState('false')
  const { t } = useTranslation();
  const [dynamicValidations, setDynamicValidations] = useState({});
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  useEffect(() => {
    UserDetails()
      .then((data) => {
        setLoader(false);
        setShowDefault(data && data?.data ? data.data : []);
        setPreviewImage(data?.data?.profile_image);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [updateData]);

  useEffect(() => {
    let obj = {};
    getGlobalData && getGlobalData?.settings && getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.map((item, index) => {
      if(item == 'gender'){
        obj.gender = Yup.string().oneOf(Object.values(getTranslatedGender()),t('web_lbl_invalid_err_salutation')).required(t('web_registration_err_salutation'))
      }
      else if(item == 'title'){
        obj.title = Yup.string().trim().required(t('web_lbl_err_title'));
      }
      else if(item == 'first_name'){
        obj.first_name = Yup.string().trim().required(t('web_registration_err_firstname'));
      }
      else if(item == 'middle_name'){
        obj.middle_name = Yup.string().trim().required(t('web_lbl_err_middle_name'));
      }
      else if(item == 'last_name'){
        obj.last_name = Yup.string().trim().required(t('web_registration_err_lastname'))
      }
      else if(item == 'event_function'){
        obj.event_function = Yup.string().trim().required(t('web_lbl_err_function'));
      }
      else if(item == 'phone_number'){
        obj.phone_number = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number'))
        .test("min-length", (t('web_registration_err_min_mobile_number')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number')), (value) => {
          if(value?.trim()?.length > 13){
            return false
          }else{
            return true
          }
        })
      }else if(item == 'phone_number_work'){
        obj.phone_number_work = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number_work'))
        .test("min-length",  (t('web_registration_err_min_mobile_number_work')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number_work')), (value) => {
          if(value?.trim()?.length > 14){
            return false
          }else{
            return true
          }
        })
      }
      else if(item == 'files'){
        obj.files = Yup.mixed().required(t('web_lbl_err_image')).test(
          'fileType',
         (t('web_lbl_err_file_format')),
          (value) => {
            if (!value) {
              const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
              return supportedFormats.includes(value.type);
            } else {
              return true
            }
          }
        )
      }
    })
    setDynamicValidations(obj);
  },[getGlobalData]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('web_registration_err_email')),
    ...dynamicValidations
  });

  const isFieldRequired = (fieldName) => {
    return getGlobalData && getGlobalData?.settings &&  getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.includes(fieldName);};

  function isJson(data) {
      try {
          JSON.parse(data);
      } catch (e) {
          return false;
      }
      return true;
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          title: showdefault && showdefault?.title ? showdefault.title : "",
          first_name: showdefault && showdefault?.first_name ? showdefault.first_name : "",
          middle_name: showdefault && showdefault?.middle_name ? showdefault.middle_name : "",
          last_name: showdefault && showdefault?.last_name ? showdefault.last_name : "",
          email: showdefault && showdefault?.email ? showdefault.email : "",
          gender: showdefault && showdefault?.gender ? selectSalutation(showdefault.gender) : "",
          event_function: showdefault && showdefault?.event_function ? showdefault.event_function : "",
          dialCodePhone: showdefault && showdefault?.phone_number && isJson(showdefault.phone_number) ? JSON.parse(showdefault.phone_number).code : showdefault?.phone_number?.code ? showdefault?.phone_number?.code:'',
          phone_number: showdefault && showdefault?.phone_number && isJson(showdefault.phone_number) ? JSON.parse(showdefault.phone_number).number :showdefault?.phone_number?.number ? showdefault?.phone_number?.number: "",
          dialCodePhoneWork: showdefault && showdefault?.phone_number_work && isJson(showdefault.phone_number_work) ? JSON.parse(showdefault.phone_number_work).code :  showdefault?.phone_number_work?.code ? showdefault?.phone_number_work?.code:'',
          phone_number_work: showdefault && showdefault?.phone_number_work && isJson(showdefault.phone_number_work) ? JSON.parse(showdefault.phone_number_work).number :showdefault?.phone_number_work?.number ? showdefault?.phone_number_work?.number: "",
          files: showdefault && showdefault?.profile_image ? showdefault.profile_image : "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          globalLoader(true);
          let formData = new FormData();
          formData.append('title', values.title);
          formData.append('first_name', values.first_name);
          formData.append('middle_name', values.middle_name);
          formData.append('last_name', values.last_name);
          formData.append('event_function', values.event_function);
          formData.append('phone_number', JSON.stringify({code : values.dialCodePhone, number: removeSpacesAndCharacters(values.phone_number) }));
          formData.append('phone_number_work', JSON.stringify({code : values.dialCodePhoneWork, number: removeSpacesAndCharacters(values.phone_number_work)}));
          formData.append('gender', values.gender);
          formData.append('email', values.email);
          formData.append('image', values.files);
          updateUserprofile(formData).then(response => {
            globalLoader(false);
            if (response.success) {
              setLocalKey("user", JSON.stringify(response.data?.user))
              Swal.fire({
                icon: 'success',
                text: response.message,
                ...SWAL_SETTINGS
              })
              document.getElementById('back-office-user-name').innerText =  response.data?.user?.name
              document.getElementById('back-office-user-profile').src = response.data?.user?.profile_image
              resetForm({ values: '' });
                setUpdateData(!updateData);
              if(response?.data?.verify_email){
                let email = encodeURIComponent(encodeValue(showdefault?.email))
                let visibleEmail = encodeURIComponent(encodeValue(values.email))
                navigate(`/back-office/otp-verificaiton?email=${email}&visible_email=${visibleEmail}&type=update_email`)
              }
            } else {
              Swal.fire({
                icon: 'error',
                text: handleServerValidations(response),
                ...SWAL_SETTINGS
              })
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          // and other goodies
        }) => (
          <div className="row">
            <div className="col-md-9 m-auto ">
              <h3 className="dash-title">{t('web_heading_profile')}</h3>
              <div className="dash-box-shadow ">
                <form onSubmit={handleSubmit}>
                {loader ? (
                    <FrontLoader />
                  ) : (
                    <>
                <div className="row">
                  <div className="col-md-12 mb-4">
                    <div className="upload-photo">
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                          <input className='form-control imgInput' id="imageUpload" name="files" accept="image/*" type="file" onChange={(event) => {
                                setFieldValue("files", event.currentTarget.files[0] || "");
                                if(event.currentTarget.files.length === 1){
                                  setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
                                }
                                else{
                                  setPreviewImage("")
                                }
                              }} />
                              <span className='text-danger d-flex text-left'><ErrorMessage name={"files"} /></span>
                          <label htmlFor="imageUpload" className="img-upload-icon">
                            <i className="ri-edit-line" />
                          </label>
                        </div>
                        <div className="avatar-preview">
                          <div
                            id="imagePreview"
                            className="avatar-preview-img"
                            style={{
                              backgroundImage: `url(${previewimage})`
                            }}  
                          ></div>
                        </div>
                      </div>
                      <label className='w-100 text-center text-md-start'>{t('web_lbl_upload_profile')}{isFieldRequired('files') && <small>*</small>}</label>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="form-label">{t('web_lbl_title')}{isFieldRequired('title') && <small>*</small>}</label>
                    <div className="input-group">
                      <input className={"form-control inputwidth" + (errors.title && touched.title ? " is-invalid state-invalid" : "")}
                      placeholder={t('web_lbl_enter_your_title')}
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title} />
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"title"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{t('web_lbl_salutation')}{isFieldRequired('gender') && <small>*</small>}</label>
                    <div className="select-down-arrow">
                        <Field as='select' id="gender" onChange={handleChange} name={"gender"} className={"form-control select2 text-capitalize"+ (errors.gender && touched.gender ? " is-invalid state-invalid" : "")}>
                          <option value="" label={t('web_lbl_select_salutation')}>Select{" "}</option>
                          {getTranslatedGender() && Object.values(getTranslatedGender()).map((option, i) => {
                          return (
                                <option key={i} value={option}>
                                    {option}
                                </option>
                            );
                          })}
                        </Field>
                    </div>
                  <span className='text-danger d-flex text-left'>{errors.gender && touched.gender && errors.gender}</span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_first_name')}{isFieldRequired('first_name') && <small>*</small>}</label>
                    <div className="input-group">
                      <input className={"form-control inputwidth" + (errors.first_name && touched.first_name ? " is-invalid state-invalid" : "")}
                        placeholder={t('web_lbl_enter_your_first_name')}
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name} />
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"first_name"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_middle_name')}{isFieldRequired('middle_name') && <small>*</small>}</label>
                    <div className="input-group">
                        <input className={"form-control inputwidth" + (errors.middle_name && touched.middle_name ? " is-invalid state-invalid" : "")}
                        placeholder={t('web_lbl_enter_your_middle_name')}
                        name="middle_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.middle_name} />
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"middle_name"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_last_name')}{isFieldRequired('last_name') && <small>*</small>}</label>

                    <div className="input-group">
                        <input className={"form-control inputwidth" + (errors.last_name && touched.last_name ? " is-invalid state-invalid" : "")}
                        placeholder={t('web_lbl_enter_your_last_name')}
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name} />
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"last_name"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_function')}{isFieldRequired('event_function') && <small>*</small>}</label>

                    <div className="input-group">
                      <input className={"form-control inputwidth" + (errors.event_function && touched.event_function ? " is-invalid state-invalid" : "")}
                        placeholder={t('web_lbl_enter_function_name')}
                        name="event_function"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.event_function} />
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"event_function"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_phone_number')}{isFieldRequired('phone_number') && <small>*</small>}</label>

                     <div className="input-group">
                    <PhoneInput 
                      country={"de"}
                      value={values.dialCodePhone + values.phone_number} 
                      placeholder={t('web_lbl_enter_your_phone_number')} 
                      className="form-control"
                      buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                      containerStyle={{padding: 0}} 
                      inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                      onChange={(value, data, event, formattedValue) => {
                        setFieldValue("dialCodePhone", formattedValue.substring(0, formattedValue.indexOf(" ")))
                        setFieldValue(`phone_number`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                      }} 
                      />
                    </div> 
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"phone_number"} /></span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_phone_number_work')}{isFieldRequired('phone_number_work') && <small>*</small>}</label>

                    <div className="input-group">
                      <PhoneInput 
                        country={"de"}
                        buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                        containerStyle={{padding: 0}}
                        inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                        name="phone_number_work"
                        placeholder={t('web_lbl_enter_your_phone_number_work')} 
                        className="form-control"
                        value={values.dialCodePhoneWork + values.phone_number_work} 
                        forceDialCode={true} 
                        onChange={(value, data, event, formattedValue,) => {
                          setFieldValue("dialCodePhoneWork", formattedValue.substring(0, formattedValue.indexOf(" ")))
                          
                          setFieldValue(`phone_number_work`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                        }} 
                      />
                        
                    </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"phone_number_work"} /></span>
                  </div>
                  <div className="form-group text-start mb-3">
                  <label className="form-label">{t('web_lbl_email')}<small>*</small></label>
                      <input className={"form-control inputwidth" + (errors.email && touched.email ? " is-invalid state-invalid" : "")}
                      placeholder={t('web_lbl_enter_your_email')}
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      autoComplete="off" />
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"email"} /></span>
                  </div>
                  <div className="col-md-12 mt-4">
                  <button className="btn btn-primary" type="submit">{t('web_lbl_update_profile')}</button>
                  </div>
                </div>
                  </>
                  )}
                </form>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}
export default BackOfficeProfile;