import React, { useEffect, useRef, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrums from "../../../common/breadcrumbs";
import {
  SpecialCharacter,
  decideRole,
  formateDate,
  globalLoader,
  handleServerValidations,
  hasPermission,
} from "../../../../../utils/commonfunction";
import Swal from "sweetalert2";
import {
  GENDER,
  STAFF_ROLE,
  SWAL_SETTINGS,
  showFilterlist,
} from "../../../../../utils/Constants";
import {
  Add,
  DistributionListUsers,
  DistributionUsersExcel,
  UsersAdd,
} from "../../../services/distributionlist.services";
import { Button, Loader, Modal } from "rsuite";
import CustomPagination from "../../../common/custompagination";
import CustomRangepicker from "../../../common/rangepicker";
import StatusFilter from "../../../common/statusFilter";
import $ from "jquery";
import * as eventServices from "../../../services/events.services";

const DistributionAdd = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let formikRef = useRef();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    type: "",
  });
  const [open] = useState(false);
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Distribution List", url: "/admin/distribution-list/list/1" },
    { title: "Add", url: "" },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
  });
  const [secondModal, setSecondModal] = useState(false);
  const [userdatalength, setUserDataLength] = useState(0);
  /********** MULTI SELECT FOR MODAL>>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  /********** MULTI SELECT FOR MODAL <<<<<<********* */
  const [usersData, setUsersData] = useState([]);
  const [datalength, setDataLength] = useState(0);
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const fileInputRef = useRef();
  /********** MULTI SELECT FOR LIST <<<<<<********* */
  const [selectedNewRecords, setSelectedNewRecords] = useState([]);
  const [selectedAllNewData, setSelectedAllNewData] = useState(false);
  const selectAllNewCheckbox = useRef();
  /********** MULTI SELECT FOR LIST<<<<<<********* */
  const [query] = useSearchParams();
  const [skippedUsersModal, setSkippedUsersModal] = useState(false);
  const [skippedUserData, setSkippedUserData] = useState([]);
  const [globalsearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [resetdate, setResetDate] = useState(false);
  const [eventOptions, setEventOptions] = useState([]);
  const [roleOptions] = useState(
    Object.keys(STAFF_ROLE));
  const [fileType] = useState(
    Object.keys(GENDER).map((item) => {
      return {
        label: item,
        value: GENDER[item],
      };
    })
  );

  useEffect(() => {
    setInitialValues({
      title: state?.title ? state?.title : "",
    });
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    onClick();
  }, [page, globalsearch, search]);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const resetFilter = (e) => {
    e.preventDefault();
    setSearch({});
    setGlobalSearch("");
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilter")[0].selectedIndex = 0;
    $("#event")[0].selectedIndex = 0;
    $("#role")[0].selectedIndex = 0;
  };

  const handleEventChange = (e) => {
    if (e.target.value) {
      prepareSearch("event_id", e.target.value);
    } else {
      prepareSearch("event_id", "");
    }
  };

  const onClick = () => {
    try {
      let formData = new FormData();
      formData.append("page", query.get("page"));
      formData.append("per_page", itemperpage);
      formData.append("global_search", globalsearch);
      formData.append("search", JSON.stringify(search));
      // formData.append("distribution_id", dataId);
      DistributionListUsers(formData)
        .then((response) => {
          if (response?.success) {
            setDataLength(response?.data?.total_records);
            setUsersData(
              response && response.data && response.data.list
                ? response.data.list
                : []
            );
            setSelectedAllNewData(
              response && response.data && response.data.data_ids
                ? response.data.data_ids
                : []
            );
            setPage(
              response && response.data && response.data.page
                ? response.data.page
                : 1
            );
            setLoader(false);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            text: err?.message,
            ...SWAL_SETTINGS,
          });
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  /********** MULTI SELECT FOR MODAL >>>>>>********* */
  useEffect(() => {
    if (secondModal) {
      if (selectedRecords.length === datalength) {
        selectAllCheckbox.current.indeterminate = false;
        selectAllCheckbox.current.checked = true;
      } else if (selectedRecords.length) {
        selectAllCheckbox.current.indeterminate = true;
      } else {
        selectAllCheckbox.current.indeterminate = false;
      }
    }
  }, [selectedRecords, secondModal]);

  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectAll = (check) => {
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelected = (data) => {
    return selectedRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT FOR MODAL <<<<<<<<<********* */

  /********** MULTI SELECT FOR LIST <<<<<<<<<********* */

  useEffect(() => {
    if (open) {
      if (selectedNewRecords.length === datalength) {
        selectAllNewCheckbox.current.indeterminate = false;
        selectAllNewCheckbox.current.checked = true;
      } else if (selectedNewRecords.length) {
        selectAllNewCheckbox.current.indeterminate = true;
      } else {
        selectAllNewCheckbox.current.indeterminate = false;
      }
    }
  }, [selectedNewRecords, open]);

  const selectNewRecord = (userData, check) => {
    if (check) {
      setSelectedNewRecords((previousState) => [
        ...previousState,
        userData._id,
      ]);
    } else {
      setSelectedNewRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectNewAll = (check) => {
    if (check) {
      setSelectedNewRecords(selectedAllNewData);
    } else {
      setSelectedNewRecords([]);
    }
  };
  const isNewSelected = (data) => {
    return selectedNewRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT FOR LIST <<<<<<<<<********* */

  const handleUploadExcel = (e) => {
    globalLoader(true);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      DistributionUsersExcel(formData)
        .then((response) => {
          setTimeout(() => {
            if (response?.success) {
              if (response?.data?.data_ids?.length > 0) {
                fileInputRef.current.value = "";
                setSecondModal(true);
                setUserData(response?.data?.users);
                setSelectedRecords(response?.data?.data_ids);
                setSelectedAllData(response?.data?.data_ids);
                setUserDataLength(response?.data?.users?.length);
                setSkippedUserData(response?.data?.skippedUsers);
              } else {
                Swal.fire({
                  icon: "error",
                  text: "No registered users found",
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
            globalLoader(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          globalLoader(false);
        });
    }
  };

  const onOkayClick = () => {
    try {
      let formData = new FormData();
      formData.append("title", formikRef?.current?.values?.title);
      Add(formData)
        .then((response) => {
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response?.message,
              ...SWAL_SETTINGS,
            });
            formData.append("distribution_id", response?.data?._id);
            formData.append("user_ids", JSON.stringify(selectedRecords));
            UsersAdd(formData)
              .then((response) => {
                if (response?.success) {
                  Swal.fire({
                    icon: "success",
                    text: response?.message,
                    ...SWAL_SETTINGS,
                  });
                  navigate(`/admin/distribution-list/list/1`);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((err) => {
                Swal.fire({
                  icon: "error",
                  text: err?.message,
                  ...SWAL_SETTINGS,
                });
              });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearch("gender", e.target.value);
    } else {
      prepareSearch("gender", "");
    }
  };

  const handleRoleChange = (e) => {
    if (e.target.value) {
      prepareSearch("role", Number(e.target.value));
    } else {
      prepareSearch("role", "");
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={formikRef}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            if (
              values?.type === "by_existing_users" &&
              selectedNewRecords?.length <= 0
            ) {
              Swal.fire({
                icon: "error",
                text: "Select minimum one user",
                ...SWAL_SETTINGS,
              });
              globalLoader(false);
            } else if (values?.type === "upload_excel") {
              Swal.fire({
                icon: "error",
                text: "Upload excel",
                ...SWAL_SETTINGS,
              });
              globalLoader(false);
            } else if (
              values?.type !== "upload_excel" &&
              values?.type !== "by_existing_users" &&
              values?.title
            ) {
              Swal.fire({
                icon: "error",
                text: "Select required field",
                ...SWAL_SETTINGS,
              });
              globalLoader(false);
            } else {
              let formData = new FormData();
              formData.append("title", values?.title);
              Add(formData)
                .then((response) => {
                  setSubmitting(false);
                  if (response?.success) {
                    Swal.fire({
                      icon: "success",
                      text: response?.message,
                      ...SWAL_SETTINGS,
                    });
                    navigate(`/admin/distribution-list/list/1`);
                    if (selectedNewRecords?.length > 0) {
                      formData.append("distribution_id", response?.data?._id);
                      formData.append(
                        "user_ids",
                        JSON.stringify(selectedNewRecords)
                      );
                      UsersAdd(formData)
                        .then((response) => {
                          if (response?.success) {
                            Swal.fire({
                              icon: "success",
                              text: response?.message,
                              ...SWAL_SETTINGS,
                            });
                            navigate(`/admin/distribution-list/list/1`);
                          } else {
                            Swal.fire({
                              icon: "error",
                              text: handleServerValidations(response),
                              ...SWAL_SETTINGS,
                            });
                          }
                        })
                        .catch((err) => {
                          Swal.fire({
                            icon: "error",
                            text: err?.message,
                            ...SWAL_SETTINGS,
                          });
                        });
                    }
                  } else {
                    Swal.fire({
                      icon: "error",
                      text: handleServerValidations(response),
                      ...SWAL_SETTINGS,
                    });
                  }
                })
                .catch((error) => {
                  console.log("error ====> ", error);
                });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">
                          Add New Distribution List
                        </h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {" "}
                            Title:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"title"} />
                          </span>
                        </div>
                        <div className="d-flex p-0 m-0">
                          <div className="form-check black-check ms-3">
                            <input
                              name="upload_excel"
                              className="form-check-input"
                              type="radio"
                              value={"by_existing_users"}
                              id="by_existing_users"
                              onChange={() => {
                                setFieldValue("type", "by_existing_users");
                                setFieldTouched("title", true);
                              }}
                              onClick={() => onClick()}
                            />
                            <label
                              className="form-check-label cp"
                              htmlFor="by_existing_users"
                            >
                              By Existing Users
                              <span className="requirestar">*</span>
                            </label>
                          </div>
                          <div className="form-check black-check ms-5">
                            <input
                              name="upload_excel"
                              className="form-check-input"
                              type="radio"
                              value={"upload_excel"}
                              id="upload_excel"
                              onChange={(e) => {
                                setFieldValue("type", "upload_excel");
                                setFieldTouched("title", true);
                                setSelectedNewRecords([]);
                              }}
                            />
                            <label
                              className="form-check-label cp"
                              htmlFor="upload_excel"
                            >
                              Upload Excel<span className="requirestar">*</span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {values?.type === "by_existing_users" &&
                      values?.title !== "" ? (
                        <div className="">
                          {/* <div className='d-flex justify-content-between align-items-center mb-4'>
                                                    <h6 className="main-content-label"><span></span></h6>
                                                </div> */}
                          <div className="d-flex justify-content-between align-items-center mb-4">
                            <h6 className="main-content-label">
                              <span></span>
                            </h6>
                            <div className="d-flex align-items-center">
                              <div className="form-group mb-0 me-3">
                                <div className="form-group mb-0 rangepicker_container filter_design">
                                  <i className="fa fa-search calender_icon"></i>
                                  <input
                                    type="search"
                                    className="form-control"
                                    value={globalsearch}
                                    placeholder="Search"
                                    onChange={(e) => {
                                      setGlobalSearch(e.target.value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="me-3">
                                <CustomRangepicker
                                  GetDateRange={(e) => {
                                    prepareSearch("createdAt", e);
                                  }}
                                  resetdate={resetdate}
                                />
                              </div>
                              <div
                                className="form-group mb-0 me-3 filter_icon_container filter_design"
                                style={{ width: "110px" }}
                              >
                                <StatusFilter
                                  data={showFilterlist}
                                  prepareSearch={prepareSearch}
                                />
                              </div>
                              <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                                <i className="fa fa-filter mr-2 filter_icon"></i>
                                <div className="select-down-arrow">
                                  <select
                                    className="form-control cp"
                                    onChange={handleFilterChange}
                                    id="resetFilter"
                                    style={{ width: "130px" }}
                                  >
                                    <option value="">Salutation</option>
                                    {fileType &&
                                      fileType.length > 0 &&
                                      fileType.map((option, i) => {
                                        return (
                                          <option key={i} value={option.value}>
                                            {option.label}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                              <div className="me-3">
                                <div className="form-group mb-0 filter_icon_container filter_design">
                                  <i className="fa fa-filter mr-2 filter_icon"></i>
                                  <div className="select-down-arrow">
                                    <select
                                      className="form-control cp"
                                      onChange={handleRoleChange}
                                      id="role"
                                    >
                                      <option value="" label="Select role">
                                        Select{" "}
                                      </option>
                                      {roleOptions &&
                                        roleOptions.map((option, i) => {
                                          return (
                                            <option
                                              className="text-capitalize"
                                              key={i}
                                              value={STAFF_ROLE[option]}
                                            >
                                              {SpecialCharacter(option)}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="me-3">
                                <div className="form-group mb-0 filter_icon_container filter_design">
                                  <i className="fa fa-filter mr-2 filter_icon"></i>
                                  <div className="select-down-arrow">
                                    <select
                                      className="form-control cp"
                                      onChange={handleEventChange}
                                      id="event"
                                    >
                                      <option value="" label="Select event">
                                        Select{" "}
                                      </option>
                                      {eventOptions &&
                                        eventOptions.map((option, i) => {
                                          return (
                                            <option key={i} value={option._id}>
                                              {option.title}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="reset"
                                value="Reset"
                                onClick={resetFilter}
                                className="btn btn-warning float-right mr-2"
                              >
                                Reset Filter
                              </button>
                            </div>
                          </div>
                          <div className="table-responsive position-relative">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                              <thead>
                                <tr>
                                  <th
                                    className={
                                      "position-relative select_head " +
                                      (hasPermission(
                                        "/admin/distribution-list/users/list/multi-select"
                                      )
                                        ? ""
                                        : "d-none")
                                    }
                                  >
                                    <div className="select-check-container">
                                      <label
                                        htmlFor="selectAllCheck"
                                        style={{ lineHeight: "unset" }}
                                        className="ckbox  cp"
                                      >
                                        <input
                                          ref={selectAllNewCheckbox}
                                          id="selectAllCheck"
                                          checked={
                                            datalength &&
                                            datalength ===
                                              selectedNewRecords.length
                                          }
                                          onChange={(e) =>
                                            handleSelectNewAll(e.target.checked)
                                          }
                                          type="checkbox"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </th>
                                  <th>Salutation</th>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Role</th>
                                  <th>Status</th>
                                  <th>Created Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loader ? (
                                  <tr>
                                    <td colSpan={8}>
                                      <Loader />
                                    </td>
                                  </tr>
                                ) : (
                                  <>
                                    {usersData.length ? (
                                      usersData.map((data, i) => {
                                        return (
                                          <tr
                                            key={i}
                                            className={
                                              isNewSelected(data)
                                                ? "selected"
                                                : ""
                                            }
                                          >
                                            <td
                                              className={
                                                "position-relative " +
                                                (hasPermission(
                                                  "/admin/distribution-list/users/list/multi-select"
                                                )
                                                  ? ""
                                                  : "d-none")
                                              }
                                            >
                                              <div className="select-check-container">
                                                <label
                                                  htmlFor={data?._id + "input"}
                                                  style={{
                                                    lineHeight: "unset",
                                                  }}
                                                  className="ckbox cp"
                                                >
                                                  <input
                                                    id={data?._id + "input"}
                                                    checked={isNewSelected(
                                                      data
                                                    )}
                                                    onChange={(e) =>
                                                      selectNewRecord(
                                                        data,
                                                        e?.target?.checked
                                                      )
                                                    }
                                                    type="checkbox"
                                                    className="form-check select-check cp"
                                                  />
                                                  <span></span>
                                                </label>
                                              </div>
                                            </td>
                                            <td className="text-capitalize">
                                              {data?.gender
                                                ? data?.gender
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {data?.name ? data.name : "N/A"}
                                            </td>
                                            <td>
                                              {data?.email ? data.email : "N/A"}
                                            </td>
                                            <td>{decideRole(data?.role)}</td>
                                            <td>
                                              {data.status === 1 ? (
                                                <button
                                                  type="button"
                                                  className="badge badge-success"
                                                >
                                                  Active
                                                </button>
                                              ) : (
                                                <button
                                                  type="button"
                                                  className="badge badge-danger"
                                                >
                                                  Inactive
                                                </button>
                                              )}
                                            </td>
                                            <td>
                                              {data?.createdAt
                                                ? formateDate(data.createdAt)
                                                : "N/A"}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td colSpan={8} className="text-center">
                                          No records
                                        </td>
                                      </tr>
                                    )}
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div
                            className=""
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                          >
                            <b>
                              Total Records : {datalength ? datalength : "0"}
                            </b>
                          </div>
                          {datalength && datalength > 0 ? (
                            <CustomPagination
                              datalength={datalength}
                              paramName={"page"}
                              itemperpage={itemperpage}
                              currentPage={page}
                              setPage={setPage}
                              query={"true"}
                              pageRoute={[
                                {
                                  name: "Distribution List",
                                  path: `/admin/distribution-list/add`,
                                },
                              ]}
                            />
                          ) : (
                            ""
                          )}

                          <div className="d-flex justify-content-between align-items-center mt-5">
                            <h6 className="ms-2 mt-1 mb-0">
                              {selectedNewRecords?.length
                                ? "Selected " +
                                  selectedNewRecords?.length +
                                  " of records " +
                                  datalength
                                : ""}
                            </h6>
                            <h6 className="ms-2 mt-1 mb-0">
                              <span></span>
                            </h6>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {values?.type === "upload_excel" &&
                      values?.title !== "" ? (
                        <form
                          id="reader_id"
                          style={{
                            height: "300px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div className="text-center ">
                            <label htmlFor="file_reader">
                              <input
                                ref={fileInputRef}
                                type={"file"}
                                className="d-none"
                                onChange={handleUploadExcel}
                                accept=".xls,.xlsx"
                                id="file_reader"
                              />
                              <i
                                className="fa fa-upload cp"
                                aria-hidden="true"
                                style={{ fontSize: "7em", color: "#3598ff" }}
                              ></i>
                            </label>
                            <p className="text-muted mb-0">
                              Please upload excel file.
                            </p>
                            <a
                              className="my-0 download-file"
                              href={`${process.env.REACT_APP_API_URL.replace(
                                "/webservice/api/v1",
                                ""
                              )}/uploads/default/distribution_list_sample.xlsx`}
                              download
                            >
                              Download sample file.
                            </a>
                          </div>
                        </form>
                      ) : (
                        <></>
                      )}

                      <div className="mt-5">
                        <button className="btn btn-info mr-2" type="submit">
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Submit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          onClick={() =>
                            navigate(`/admin/distribution-list/list/1`)
                          }
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
      <Modal
        size={"lg"}
        open={secondModal}
        backdrop={"static"}
        onClose={() => {
          setSecondModal(false);
        }}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Selected users</Modal.Title>
          <div className="mt-4"></div>
        </Modal.Header>
        <div className="clearfix"></div>
        <div className="w-100 ovr-scroll">
          <table className="table table-hover cp table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th
                  className={
                    "position-relative select_head " +
                    (hasPermission(
                      "/admin/distribution-list/users/list/multi-select"
                    )
                      ? ""
                      : "d-none")
                  }
                >
                  <div className="select-check-container">
                    <label
                      htmlFor="selectAllCheck1"
                      style={{ lineHeight: "unset" }}
                      className="ckbox  cp"
                    >
                      <input
                        ref={selectAllCheckbox}
                        id="selectAllCheck1"
                        checked={
                          userdatalength &&
                          userdatalength === selectedRecords.length
                        }
                        onChange={(e) => handleSelectAll(e.target.checked)}
                        type="checkbox"
                      />
                      <span></span>
                    </label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {userData?.length ? (
                    userData.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className={isSelected(item) ? "selected" : ""}
                        >
                          <td
                            className={
                              "position-relative " +
                              (hasPermission(
                                "/admin/distribution-list/users/list/multi-select"
                              )
                                ? ""
                                : "d-none")
                            }
                          >
                            <div className="select-check-container">
                              <label
                                htmlFor={item?._id + "input"}
                                style={{ lineHeight: "unset" }}
                                className="ckbox cp"
                              >
                                <input
                                  id={item?._id + "input"}
                                  checked={isSelected(item)}
                                  onChange={(e) =>
                                    selectRecord(item, e?.target?.checked)
                                  }
                                  type="checkbox"
                                  className="form-check select-check cp"
                                />
                                <span></span>
                              </label>
                            </div>
                          </td>
                          <td className="text-capitalize">
                            {item?.name ? item?.name : "N/A"}
                          </td>
                          <td>{item?.email ? item?.email : "N/A"}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="" id="example1_info" role="status" aria-live="polite">
          <b className="mt-2">
            Total Records : {userdatalength ? userdatalength : "0"}
          </b>
        </div>
        {skippedUserData?.length > 0 ? (
          <div className="mt-4 d-flex">
            <b className="">Skipped Users : {skippedUserData?.length}</b>
            <a
              href="/"
              className="ms-2"
              onClick={(e) => {
                e.preventDefault();
                setSkippedUsersModal(true);
              }}
            >
              View
            </a>
          </div>
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h6 className="ms-2 mt-1 mb-0">
            {selectedRecords?.length
              ? "Selected " +
                selectedRecords?.length +
                " of records " +
                userdatalength
              : ""}
          </h6>
          <h6 className="ms-2 mt-1 mb-0">
            <span></span>
          </h6>
          <div>
            <Button
              onClick={() => {
                setSecondModal(false);
              }}
              appearance="subtle"
            >
              Cancel
            </Button>
            <Button
              className="ms-2"
              disabled={selectedRecords?.length === 0}
              onClick={() => onOkayClick()}
              appearance="primary"
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        size={"lg"}
        open={skippedUsersModal}
        backdrop={"static"}
        onClose={() => {
          setSkippedUsersModal(false);
        }}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Skipped Users Details</Modal.Title>
          <div className="mt-4"></div>
        </Modal.Header>
        <div className="clearfix"></div>
        <div className="w-100 ovr-scroll">
          <table className="table table-hover cp table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={1}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {skippedUserData?.length ? (
                    skippedUserData.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{item}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={1} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="" id="example1_info" role="status" aria-live="polite">
          <b className="mt-2">Total Records : {skippedUserData?.length}</b>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h6 className="ms-2 mt-1 mb-0">
            <span></span>
          </h6>
          <div>
            <Button
              onClick={() => {
                setSkippedUsersModal(false);
              }}
              appearance="subtle"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DistributionAdd;
