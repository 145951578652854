import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as emailLogsServices from "../../services/emaillogs.services";
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import {
  SWAL_SETTINGS,
  EMAIL_STATUS,
  EMAIL_TYPE,
  EMAIL_LOG_CATEGORIES
} from "../../../../utils/Constants";
import {
  SpecialCharacter,
  capitalizeFirstLetterOfEachWord,
  formateDate,
  globalLoader,
  hasPermission,
  removeSpecialCharacter,
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import * as eventServices from "../../services/events.services";


const EmailLogs = () => {
  const [status] = useState([{ name: "Status", status__id: "" }, { name: "Success", status__id: EMAIL_STATUS.SUCCESS }, { name: "Pending", status__id: EMAIL_STATUS.PENDING }, { name: "In progress", status__id: EMAIL_STATUS.IN_PROGRESS }, { name: "Failed", status__id: EMAIL_STATUS.FAILED }]);
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({"createdAt":{"start":new Date(),"end":new Date()}});
  const [globalsearch, setGlobalSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [eventOptions, setEventOptions] = useState([]);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Email Logs", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);
  const [date,SetDate]=useState(true)
  const [emailType] = useState(Object.keys(EMAIL_TYPE).map((item => {
    return {
      label: item, value: EMAIL_TYPE[item]
    }
  })));
  const [emailCategory] = useState(Object.keys(EMAIL_LOG_CATEGORIES).map((item => {
    return {
      label: item, value: EMAIL_LOG_CATEGORIES[item]
    }
  })));
  


  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("type", typeSearch);
      formData.append("category", categorySearch);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      emailLogsServices
        .List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, typeSearch,categorySearch]);

  useEffect(() => {
    eventServices.Options().then((response) => {
      if (response.success) {
        setEventOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const resendEmail = (log_id) => {
    globalLoader(true);
    emailLogsServices
      .ResendEmail({ log_id: log_id })
      .then((response) => {
        if (response.success) {
          globalLoader(false);
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error", error);
      });
  };

  const getStatus = (status) => {
    if (status === EMAIL_STATUS.PENDING) {
      return <span className="badge badge-warning">Pending</span>;
    } else if (status === EMAIL_STATUS.IN_PROGRESS) {
      return <span className="badge badge-info">In Progress</span>;
    } else if (status === EMAIL_STATUS.SUCCESS) {
      return <span className="badge badge-success">Success</span>;
    } else if (status === EMAIL_STATUS.FAILED) {
      return <span className="badge badge-danger">Failed</span>;
    }
  };

  const viewfunction = (row) => {
    navigate(`/admin/email-logs/view/${row._id}`);
  };


  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
    SetDate(false)
    setResetDate(false);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setTypeSearch("");
    setCategorySearch("")
    setSearch({});
    setResetDate(true);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilter")[0].selectedIndex = 0;
    $("#categorySearch")[0].selectedIndex = 0;
    $("#event")[0].selectedIndex = 0;
  };

  const handleEventChange = (e) => {
    if (e.target.value) {
      prepareSearch("event", e.target.value)
    } else {
      prepareSearch("event", "")
    }
  };


  const handleFilterChange = (e,filterBy) => {
    if (e.target.value) {
      filterBy=='type' ? setTypeSearch(e.target.value) : setCategorySearch(e.target.value)
    } else {
      filterBy=='type' ? setTypeSearch(""):setCategorySearch("")
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Email Logs </h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                    setdate={date}
                  />
                </div>
                <div className="me-3">
                  <StatusFilter
                    data={status}
                    prepareSearch={prepareSearch}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={(e)=>handleFilterChange(e,'type')} id="resetFilter" style={{ width: "130px" }}>
                        <option value="">
                          Type
                        </option>
                        {emailType && emailType.length > 0 && emailType.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={(e)=>handleFilterChange(e,'category')} id="categorySearch" style={{ width: "130px" }}>
                        <option value="">
                          Category
                        </option>
                        {emailCategory && emailCategory.length > 0 && emailCategory.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {removeSpecialCharacter(option.label)}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={handleEventChange} id="event">
                      <option value="" label="Select event">Select{" "}</option>
                      {eventOptions && eventOptions.map((option, i) => {
                            return (
                              <option  key={i} value={option._id}>
                                 {option.title}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    {/* <th className="sr_head">S.No</th> */}
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email</span>
                      </div>
                    </th>
                    <th>Event</th>
                    <th>Category</th>
                    <th>
                      <div className="sorting_column">
                        <span>Email Type</span>
                      </div>
                    </th>

                    <th>
                      <div className="sorting_column">
                        <span>Usage</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="subject"
                        />
                      </div>
                    </th>
                    <th>Email Send Time</th>
                    <th>
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    <th className="status_head">Status</th>
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={11}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i} >
                              {/* <td>{serialno + (i + 1)}</td> */}
                              <td>{row?.name ? row.name : "N/A"}</td>
                              <td title={row?.email}>
                                {row?.email ? row.email : "N/A"}
                              </td>
                              <td>{row?.event_data?.title ? row.event_data.title : "N/A"}</td>
                              <td>{row?.category ? SpecialCharacter(capitalizeFirstLetterOfEachWord(row.category)) : "N/A"}</td>
                              <td>
                                {/* {row?.category ? row?.category : "N/A"} */}
                                {row?.send_type === "automatic" ? <span>Automatic</span> : <span>Regular</span>}
                              </td>
                              <td>
                                {row?.email_subject ? row.email_subject : "N/A"}
                              </td>
                              <td>
                                {row?.email_sent_time
                                  ? formateDate(row?.email_sent_time)
                                  : "N/A"}
                              </td>
                              <td>   {row?.createdAt
                                ? formateDate(row?.createdAt)
                                : "N/A"}</td>
                              <td>{getStatus(row?.status)}</td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                  {hasPermission('/admin/email-logs/resend') ?
                                    <>
                                     {/* || row?.category === EMAIL_LOG_CATEGORIES.INVITATION  */}
                                      {row?.status === EMAIL_STATUS.FAILED?
                                        <button
                                          className="btn ripple btn-success mlAction"
                                          onClick={() => {
                                            resendEmail(row?._id);
                                          }}
                                        >
                                          Resend
                                        </button>
                                        : null}
                                    </>
                                    : null}

                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={11} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "promotions",
                    path: "/admin/email-logs/list",
                  },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailLogs;
