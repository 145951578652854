import axiosInstance from "../../../utils/axios";

const path = "admin/lead"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const LeadManagementDelete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const LeadManagementStatus = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}
export const Options = async (event_id) => {
    return await axiosInstance().get(`${path}/options?event_id=${event_id}`)
}

export const ExcelRead = async (values) => {
    return await axiosInstance().post(`${path}/read-excel`, values);
}

export const InviteLeads = async (values) => {
    return await axiosInstance().post(`${path}/invite-lm-users`, values);
}