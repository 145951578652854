import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import {
  formateDate,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import {
  LeadManagementDelete,
  List,
  Options,
} from "../../services/leadManagement.services";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import $ from "jquery";
import { Button, Modal } from "rsuite";
import { ErrorMessage, Formik } from "formik";
import * as eventsService from '../../services/events.services';
import * as staffServices from '../../services/staff.services';

function BoothAttendantsList() {
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [options, setOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("");
/********** MULTI SELECT >>>>>>********* */
 const [selectedRecords, setSelectedRecords] = useState([]);
 const [selectedAllData, setSelectedAllData] = useState(false);
 const selectAllCheckbox = useRef();
 /********** MULTI SELECT <<<<<<********* */
 const [userId,setUserId] = useState([]);

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("event_id", params.id);
      eventsService.ListBoothAttendants(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list.booth_attendant_data ? data.data.list.booth_attendant_data : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
        /********** MULTI SELECT >>>>>>********* */
        setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
        /********** MULTI SELECT <<<<<<********* */
        setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    itemperpage,
    params.pgno,
    params.id,
  ]);

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    // $("#resetFilter")[0].selectedIndex = 0;
  };
  // reset filter function end

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end


  const handleCloseModal = () => {
    setModal(false);
    setStatus("");
  };

/********** MULTI SELECT <<<<<<<<<********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
          selectAllCheckbox.current.indeterminate = false;
          selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
          selectAllCheckbox.current.indeterminate = true;
        } else {
          selectAllCheckbox.current.indeterminate = false;
        }
      }, [selectedRecords]);
    
      const selectRecord = (userData, check) => {
        if (check) {
          setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
          setSelectedRecords((previousState) =>
            previousState.filter((item) => item !== userData._id)
          );
        }
      };
    
      const handleSelectAll = (check) => {
        console.log("selectedAllData", selectedAllData);
        if (check) {
          setSelectedRecords(selectedAllData);
        } else {
          setSelectedRecords([]);
        }
      };
    
      const isSelected = (data) => {
        return selectedRecords.filter((item) => String(data?._id) === String(item)).length > 0;
      };
/********** MULTI SELECT <<<<<<<<<********* */

useEffect(() => {
    eventsService.Details(params.id).then(response => {
      if (response && response.success) {
        setOptions(response?.data);
      }
    }).catch(error => {
      console.log("error=====>", error)
      setLoader(false);
    })
  }, [params.id])

  const handleStatusChange = (userId) => {
    setUserId(userId);
    setModal(true);
  };

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">Booth Attendants</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalsearch}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="me-3">
            <CustomRangepicker
              GetDateRange={(e) => {
                prepareSearch("createdAt", e);
              }}
              resetdate={resetdate}
            />
          </div>
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
            <th className="position-relative select_head ">
                <div className="select-check-container">
                  <label
                    htmlFor="selectAllCheck"
                    style={{ lineHeight: "unset" }}
                    className="ckbox  cp"
                  >
                    <input
                      ref={selectAllCheckbox}
                      id="selectAllCheck"
                      checked={
                        datalength && datalength === selectedRecords.length
                      }
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      type="checkbox"
                    />
                    <span></span>
                  </label>
                </div>
              </th>
              <th>
                <div className="sorting_column">
                  <span>Name</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="name"
                  />
                </div>
              </th>
              <th>
                <div className="sorting_column">
                  <span>Email</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="email"
                  />
                </div>
              </th>
              <th>Status</th>
              <th className="created_head">
                <div className="sorting_column">
                  <span>Created Date</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    defaultSorting={defaultSorting}
                    column="createdAt"
                  />
                </div>
              </th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {list.length ? (
                  list.map((row, i) => {
                    return (
                      <tr key={i} className={isSelected(row) ? "selected" : ""}>
                         <td className="position-relative">
                          <div className="select-check-container">
                            <label
                              htmlFor={row?._id + "input"}
                              style={{ lineHeight: "unset" }}
                              className="ckbox cp"
                            >
                              <input
                                id={row?._id + "input"}
                                checked={isSelected(row)}
                                onChange={(e) =>
                                  selectRecord(row, e?.target?.checked)
                                }
                                type="checkbox"
                                className="form-check select-check cp"
                              />
                              <span></span>
                            </label>
                          </div>
                        </td>
                        <td className="text-capitalize">
                          {row?.name ? row.name : "N/A"}
                        </td>
                        <td>{row?.email ? row.email : "N/A"}</td>
                        <td>{row?.status ? row.status : "N/A" }</td>
                        <td>
                          {row?.createdAt ? formateDate(row.createdAt) : "N/A"}
                        </td>
                        <td>
                          <button
                            className="btn ripple btn-main-primary signbtn"
                            onClick={() => handleStatusChange(row?._id)}
                          >
                            Change Status
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/event-booth-attendants/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={modal}
        onClose={() => handleCloseModal()}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Select Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                status: "",
              }}
              validate={(values) => {
                const error = {};
                if (!status) error.status = "This field is required";
                return error;
              }}
              onSubmit={(values, { resetForm }) => {
                let formData = new FormData();
                formData.append("status", status);
                formData.append("event_id", params.id);
                if(selectedRecords && selectedRecords?.length > 0)
                    {
                   formData.append("user_id", JSON.stringify(selectedRecords));
                }
                else{
                    formData.append("user_id", JSON.stringify([userId]));
                }
                eventsService.StatusChange(formData).then(response => {
                  if (response.success) {
                    Swal.fire({
                      icon: 'success',
                      text: response.message,
                      ...SWAL_SETTINGS
                    })
                    resetForm({ values: '' });
                    setModal(false);
                    setStatusUpdate(!statsupdate);
                  } else {
                    Swal.fire({
                      icon: 'error',
                      text: handleServerValidations(response),
                      ...SWAL_SETTINGS
                    })
                  }
                }).catch(error => {
                  console.log("error ====> ", error);
                })
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-12 text-center form-group">
                      <label className="text-left d-flex">
                        Select Status:<span className="requirestar">*</span>
                      </label>
                      <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.status}
                            onChange={(e) => {
                              setFieldValue("status", e.target.value);
                              setStatus(e?.target?.value);
                            }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {options && Array.isArray(options.status_booth_attendants) && options.status_booth_attendants.map((option, i) => (
                                 <option key={i} >
                                       {option}
                                         </option>
                                        ))}
                          </select>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"status"} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        handleCloseModal();
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
         {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
         {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                <li>
                <li>
                  <button className="btn ripple btn-main-primary signbtn" 
                  onClick={() => {
                    setModal(true);
                  }}
                  >
                   Change Status
                    </button>
                </li>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
    </div>
  );
}

export default BoothAttendantsList;
