import axiosInstance from "../../../utils/axios";

const path = "web/info-counter";

export const eventList = async () => {
  return await axiosInstance().get(`${path}/event-list`)
}

export const SISUserList = async (values) => {
  return await axiosInstance().post(`${path}/sis-user/list`, values);
}
export const SISUserAddApi = async (values) => {
  return await axiosInstance().post(`${path}/sis-user/add`, values);
}
export const SISUserDetail = async (id) => {
  return await axiosInstance().get(`${path}/sis-user/detail?o_id=${id}`);
}
export const SISUserEdit = async (values) => {
  return await axiosInstance().post(`${path}/sis-user/edit`, values);
}
export const SISUserStatus = async (values) => {
  return await axiosInstance().post(`${path}/sis-user/change-status`, values)
}
export const NameBadgeOptions = async (values) => {
  return await axiosInstance().post(`${path}/name-badge/list`, values)
}