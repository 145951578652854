import React, { useEffect, useState } from "react";
import { Field, Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import {  useLocation, useNavigate } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import {
  GENDER,
  SWAL_SETTINGS,
} from "../../../../utils/Constants";
import {
  blockInvalidChar,
  getNameFromList,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import  {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";
import ReactCountryFlag from "react-country-flag";
import * as leadManagementService from "../../services/leadManagement.services";

const LeadManagementAdd = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [leadOptions, setLeadOptions] = useState([]);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Lead Management", url: "/admin/lead-management/list/1" },
    { title: "Add", url: "" },
  ];
  const [initialValues, setInitialValues] = useState({
    title: "",
    email: "",
    gender: "",
    first_name: "",
    middle_name: "",
    last_name: "",
    phone_number: "",
    countryCodePhone: "",
    dialCodePhone: "+" + getCountryCallingCode(getCountries()[0]),
    manager_id: "",
  })
  
  useEffect(() => {
    setInitialValues({
      title: state && state?.title ? state?.title : "",
      email: state && state?.email ? state?.email : "",
      gender: state && state?.gender ? state?.gender : "",
      first_name: state && state?.first_name ? state?.first_name : "",
      middle_name: state && state?.middle_name ? state?.middle_name : "",
      last_name: state && state?.last_name ? state?.last_name : "",
      phone_number: state && state?.phone_number && state?.phone_number?.number ? state?.phone_number?.number : "",
      countryCodePhone: state && state?.phone_number && state?.phone_number?.countryCode ? state?.phone_number?.countryCode : "",
      dialCodePhone: state && state?.phone_number && state?.phone_number?.code ? state?.phone_number?.code : "+" + getCountryCallingCode(getCountries()[0]),
      manager_id:state && state?.manager_id ? state?.manager_id : "",
    })
    setDataLoaded(true);
  }, [])

  useEffect(() => {
    leadManagementService.Options().then((response) => {
      if (response.success) {
        setLeadOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const showDropDown = (country) => {
    return (
      <label>
        {"   " + getNameFromList(country)} {" +"}
        {getCountryCallingCode(country)}
      </label>
    );
  };

  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .oneOf(Object.values(GENDER), "Invalid salutation selection")
      .required("Salutation is required"),
    first_name: Yup.string()
      .required("First name is required")
      .test("no-spaces", "First name is required", (value) => value.trim()),
    middle_name: Yup.string(),
    last_name: Yup.string()
      .required("Last name is required")
      .test("no-spaces", "Last name is required", (value) => value.trim()),
    email: Yup.string().email().required("Email is required"),
    phone_number: Yup.string()
      .trim()
      .required("Phone number is required")
      .test(
        "min-length",
        "Phone number minimum length should be 4 digits",
        (value) => {
          if (value?.trim()?.length < 5) {
            return false;
          } else {
            return true;
          }
        }
      )
      .test(
        "max-length",
        "Phone number cannot be more than 13 digits",
        (value) => {
          if (value?.trim()?.length > 13) {
            return false;
          } else {
            return true;
          }
        }
      ),
      manager_id: Yup.string()
      .required("Manager id is required"),
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log("values", values);
            setSubmitted(true);
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("first_name", values.first_name);
            formData.append("middle_name", values.middle_name);
            formData.append("last_name", values.last_name);
            formData.append("gender", values.gender);
            formData.append("email", values.email);
            formData.append("manager_id", values.manager_id);
            if (values.phone_number && values.dialCodePhone) {
              formData.append(
                "phone_number",
                JSON.stringify({
                  code: values.dialCodePhone,
                  number: values.phone_number,
                  countryCode: values.countryCodePhone,
                })
              );
            }
            leadManagementService.Add(formData)
              .then((response) => {
                setSubmitting(false);
                console.log("response", response);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  resetForm({ values: "" });
                  navigate(`/admin/lead-management/list/1`);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  setSubmitted(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">Add Lead Management</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            Title:
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                          />
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="gender" className="text-left d-flex">
                            Salutation:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className="select-down-arrow ">
                            <Field
                              as="select"
                              id="gender"
                              name={"gender"}
                              className="form-control select2 text-capitalize"
                            >
                              <option value="" label="Select Salutation">
                                Select{" "}
                              </option>
                              {GENDER &&
                                Object.values(GENDER).map((option, i) => {
                                  return (
                                    <option key={i} value={option}>
                                      {option}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"gender"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="first_name"
                            className="text-left d-flex"
                          >
                            First Name:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="first_name"
                            id="first_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.first_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"first_name"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label
                            htmlFor="middle_name"
                            className="text-left d-flex"
                          >
                            Middle Name:{" "}
                          </label>
                          <input
                            name="middle_name"
                            id="middle_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.middle_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                           <ErrorMessage name={"middle_name"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="last_name" className="text-left d-flex">
                            Last Name:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="last_name"
                            id="last_name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.last_name}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"last_name"} />
                          </span>
                        </div>

                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="email" className="text-left d-flex">
                            Email:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"email"} />
                          </span>
                        </div>
                        <div className="col-lg-6 text-center form-group">
                          <label
                            className="text-left d-flex"
                            htmlFor="phone_number"
                          >
                            Phone number:<span className="requirestar">*</span>
                          </label>
                          <div className="form-control d-flex">
                            <select
                              className="selectInput"
                              style={{
                                height: "100%",
                                paddingLeft: 0,
                                paddingTop: 0,
                                marginTop: 0,
                                border: 0,
                                width: 40,
                              }}
                              value={values.countryCodePhone}
                              onChange={(event) => {
                                console.log("event", event);
                                setFieldValue(
                                  "countryCodePhone",
                                  event.target.value
                                );
                                setFieldValue(
                                  "dialCodePhone",
                                  "+" + getCountryCallingCode(event.target.value)
                                );
                              }}
                            >
                              {getCountries().map((country) => (
                                <>

                                  <option key={country} value={country}>
                                    <ReactCountryFlag countryCode={country}

                                    />
                                    {showDropDown(country)}
                                  </option>
                                </>
                              ))}
                            </select>
                            <label
                              style={{ padding: 0, margin: 0, paddingTop: 2 }}
                            >
                              {values.dialCodePhone}
                            </label>
                            <input
                              className={"phoneInput"}
                              name="phone_number"
                              style={{ border: 0 }}
                              onChange={(event) => {
                                setFieldValue(`phone_number`, event.target.value);
                              }}
                              value={`${values.phone_number}`}
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"phone_number"} />
                          </span>
                        </div>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="manager_id" className="text-left d-flex">
                            Select Manager:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className="select-down-arrow ">
                            <Field
                              as="select"
                              id="manager_id"
                              name={"manager_id"}
                              className="form-control select2 text-capitalize"
                            >
                              <option value="" label="Select Manager">
                                Select{" "}
                              </option>
                              {leadOptions &&
                                leadOptions.length > 0 &&
                                leadOptions.map((option, i) => {
                                  return (
                                    <option key={i} value={option._id}>
                                      {option.name}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"manager_id"} />
                          </span>
                        </div>
                        <div className="mt-5">
                          <button
                            className="btn btn-info mr-2"
                            type="submit"
                            disabled={submitted ? true : null}
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            Submit
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            disabled={submitted ? true : null}
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

export default LeadManagementAdd;
