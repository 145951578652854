import React from 'react';
import { Navigate } from 'react-router-dom';
import {  getUser, setLocalKey } from '../../../utils/commonfunction';
import { STAFF_ROLE } from '../../../utils/Constants';

const InfoCounterPrivateRoute = ({ component: Component }) => {
    
    const getuserkey = getUser() || {};
    
    if(getuserkey && getuserkey.token){

    }else{
        if(window.location.pathname!=="/" && window.location.pathname!=="/login")
        {
            setLocalKey("return_url", window.location.href);
        }
    }
    return (
        getuserkey && getuserkey.token && getuserkey.role===STAFF_ROLE.INFO_COUNTER  ? <Component /> : <Navigate to="/login" />
    )
}

export default InfoCounterPrivateRoute;