import React, { useEffect, useState } from 'react'
import { Field, Formik, ErrorMessage } from 'formik'
import Swal from "sweetalert2"
import {  useLocation, useNavigate } from 'react-router-dom'
import * as staffService from '../../services/staff.services'
import Breadcrums from '../../common/breadcrumbs';
import { ATLEAST_ONE_CAPITAL_REGEX, ATLEAST_ONE_NUMBER_REGEX, ATLEAST_ONE_SMALL_REGEX, ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH, SWAL_SETTINGS } from '../../../../utils/Constants';
import { SpecialCharacter, handleServerValidations } from '../../../../utils/commonfunction';
import * as Yup from 'yup';
import { STAFF_ROLE } from '../../../../utils/Constants';
import * as eventServices from "../../services/events.services";
import { useSelector } from 'react-redux'


const StaffAdd = (props) => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  let globalData = useSelector((state) => state.globaldetail);

  const myGlobalData = useSelector((state) => state.globalData);
  const [getGlobalData, setGlobalData] = useState({});
  const [role, setRole] = useState(Object.keys(STAFF_ROLE));
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      const globalSettingData=JSON.parse(myGlobalData?.data)
      setGlobalData(JSON.parse(myGlobalData?.data));
      setRole(Object.keys(STAFF_ROLE)
      .filter(key => {
          if (globalSettingData?.settings?.enable_sis_management === true &&  globalSettingData?.settings?.enable_lead_management === true  ) {
              return key !== "USER";
          } 
          else if(globalSettingData?.settings?.enable_sis_management === false &&  globalSettingData?.settings?.enable_lead_management === true){
            return key !== "USER" && key !== "INFO_COUNTER" && key !== "BOOTH_ATTENDANTS";
          }
          else if(globalSettingData?.settings?.enable_sis_management === true &&  globalSettingData?.settings?.enable_lead_management === false){
            return key !== "USER" && key !== "LM_BACK_OFFICE" && key !== "LEAD_COORDINATOR";
          }
          else{
            return key !== "USER" && key !== "INFO_COUNTER" && key !== "BOOTH_ATTENDANTS" && key !== "LM_BACK_OFFICE" && key !== "LEAD_COORDINATOR" ;
          } 
      }))
    }
  },[myGlobalData]);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    role:"",
    files: "",
  });
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Staff", url: "/admin/staff/list/1" }, { title: "Add", url: "" },];
  const [newshow, setNewShow] = useState("false");
  const [conformshow, setConformShow] = useState("false");
  const [eventOptions, setEventOptions] = useState([]);
  const [id, setId] = useState("");

  useEffect(() => {
    setInitialValues({
      first_name: state && state.first_name ? state.first_name : "",
      last_name: state && state.last_name ? state.last_name : "",
      email: state && state.email ? state.email : "",
      password: "",
      confirm_password: "",
      role: state && state.role ? state.role : "",
      files: "",
      event_id:state && state.event_id ? state.event_id : "",
    });
    setDataLoaded(true);
  },[])

  useEffect(() => {
    eventServices.Options().then((response) => {
      if (response.success) {
        setEventOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email address').required("Email is required"),
    password: Yup.string()
    .required("Password is required")
    .matches(
      ATLEAST_ONE_SMALL_REGEX,
      "Password should have at least 1 lowercase letter"
    )
    .matches(
      ATLEAST_ONE_CAPITAL_REGEX,
      "Password should have at least 1 capital letter"
    )
    .matches(
      ATLEAST_ONE_NUMBER_REGEX,
      "Password should have at least 1 number"
    )
    .matches(
      ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
      "Password should have at least 1 special character"
    )
    .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long")
    .max(
      PASSWORD_MAX_LENGTH,
      "Password cannot be more than 20 characters long"
    ),
    confirm_password: Yup.string().required("Confirm password is required").oneOf([Yup.ref("password"), null], "Passwords don't match"),
    role: Yup.string().required("Role is required"),
    // event_id: Yup.string()
    // .required("This field is required"),
});

  const handleshow = (event) => {
    if (event === "newpwd") {
      setNewShow(!newshow);
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow);
    }
  };


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        validate={values => {
          const error = {};
          if (values?.role == STAFF_ROLE.LM_BACK_OFFICE || values?.role == STAFF_ROLE.LEAD_COORDINATOR || values?.role == STAFF_ROLE.BOOTH_ATTENDANTS || values?.role == STAFF_ROLE.INFO_COUNTER) {
            if (!id) error.event_id = "This field is required";
        }
          return error;
      }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitted(true)
          let formData = new FormData();
          formData.append('profile_image', values.files);
          formData.append('first_name', values.first_name);
          formData.append('last_name', values.last_name);
          formData.append('email', values.email);
          formData.append('password', values.password);
          formData.append('confirm_password', values.confirm_password);
          formData.append('role',values.role);
          if(values?.role == STAFF_ROLE.LM_BACK_OFFICE || values?.role == STAFF_ROLE.LEAD_COORDINATOR || values?.role == STAFF_ROLE.BOOTH_ATTENDANTS || values?.role == STAFF_ROLE.INFO_COUNTER){
            formData.append('event_id',id);
          }
          staffService.Add(formData).then(response => {
            setSubmitting(false);
            if (response.success) {
              Swal.fire({
                icon: 'success',
                text: response.message,
                ...SWAL_SETTINGS
              })
              setTimeout(() => {
                resetForm({ values: '' });
                navigate(`/admin/staff/list/1`)
              }, 2000);
            } else {
              Swal.fire({
                icon: 'error',
                text: handleServerValidations(response),
                ...SWAL_SETTINGS
              })
              setSubmitted(false)
            }
          }).catch(error => {
            console.log("error ====> ", error);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="row row-sm">
            <div className="col-lg-12 col-md-12 animation_fade">
              <div className="card custom-card">
                <div className="card-body">
                  <div>
                    <h6 className="main-content-label mb-3">Add New Staff</h6>
                  </div>
                  <div className="row row-sm">
                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'> First Name:<span className="requirestar">*</span> </label>
                      <input
                        name='first_name'
                        id='first_name'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                        className='form-control' />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"first_name"} /></span>
                    </div>
                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'> Last Name:<span className="requirestar">*</span> </label>
                      <input
                        name='last_name'
                        id='last_name'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                        className='form-control' />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"last_name"} /></span>
                    </div>

                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor='title' className='text-left d-flex'>Email:<span className="requirestar">*</span> </label>
                      <input
                        name='email'
                        id='email'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        className='form-control'
                      />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"email"} /></span>
                    </div>

                    <div className='col-md-6 text-center form-group'>
                      <label htmlFor='title' className='text-left d-flex'>Password:<span className="requirestar">*</span> </label>
                      <div className="input-group cp">
                      <input
                        name='password'
                        id='password'
                        type={!newshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className='form-control' />
                          <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("newpwd");
                              }}
                            >
                              {!newshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                            </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"password"} /></span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor='title' className='text-left d-flex'>Confirm Password:<span className="requirestar">*</span> </label>
                      <div className="input-group cp">
                      <input
                        name='confirm_password'
                        id='confirm_password'
                        type={!conformshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        className='form-control'
                      />
                        <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("confirepwd");
                              }}
                            >
                              {!conformshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                          </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"confirm_password"} /></span>
                    </div>
                     <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2">
                          <option value="" label="Select role">Select{" "}</option>
                          {role && role.map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {SpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div>
                    {values?.role == STAFF_ROLE.LM_BACK_OFFICE || values?.role == STAFF_ROLE.LEAD_COORDINATOR || values?.role == STAFF_ROLE.BOOTH_ATTENDANTS || values?.role == STAFF_ROLE.INFO_COUNTER ?
                    <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Event:<span className="requirestar">*</span></label>
                        <div className="form-group">
                          <div className="select-down-arrow">
                            <select
                              className="form-control select2"
                              value={values.event_id}
                              onChange={(e) => {setFieldValue('event_id', e.target.value);
                              setId(e?.target?.value);
                            }}
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              {eventOptions &&
                                eventOptions.length > 0 &&
                                eventOptions.map((option, i) => {
                                  return (
                                    <option key={i} value={option._id}>
                                      {option.title}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"event_id"} /></span>
                        </div>
                      </div>
                      : null}
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='files' className='text-left d-flex'>Profile:</label>
                      <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                          setFieldValue("files", event.currentTarget.files[0] || "");
                          if (event.currentTarget.files.length === 1) {
                              setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]));
                          } else {
                              setPreviewImage("");
                          }
                        }} 
                      />
                    </div>
                    {
                      previewimage ?
                        <div className='mb-4'>
                          <img src={previewimage} style={{ height: "100px" }} alt={""} />
                        </div> : ""
                    }
                    <div className="mt-5" >
                      <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                        Submit
                      </button>
                      <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      ) : (
        <></>
      )}
    </>
  )
}

export default StaffAdd