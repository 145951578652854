import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as eventsService from '../../services/events.services';
import Loader from '../../common/loader'
import { addSpecialCharacter, formateDate, handleServerValidations, removeCommas, globalLoader, writeMyExcel, removeSpecialCharacter, capitalizeFirstLetter, sanitizeAns } from '../../../../utils/commonfunction';
import CustomPagination from "../../common/custompagination";
import { Tooltip, Whisper } from 'rsuite';
import * as eventServices from '../../services/events.services';
import { CSVLink } from 'react-csv';
import Swal from 'sweetalert2';
import { EXPORT_EXTENSION_TYPE, EVENT_TYPES, QUESTION_TYPES, REGISTRATION_TYPE, SWAL_SETTINGS } from '../../../../utils/Constants';
import { useSelector } from 'react-redux';
import $ from "jquery";
import CustomRangepicker from '../../common/rangepicker';
import StatusFilter from '../../common/statusFilter'


const ViewRegistrations = (props) => {
  const [search, setSearch] = useState({});
  const [globalsearch] = useState("");
  const [registrationsearch, setRegistrationSearch] = useState("");
  const params = useParams();
  const [resetdate, setResetDate] = useState(false);
  const [sorting] = useState({});
  const leadData = useSelector((state) => state.designportal);
  const [loader, setLoader] = useState(false);
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const [registrationsList, setRegistrationsList] = useState([]);
  const navigate = useNavigate();
  const [allUsersToExport] = useState([]);
  const [allUsersExportFlag] = useState(false);
  const [updateComponent, setUpdateComponent] = useState(false);
  const [datalength, setDataLength] = useState("");
  const [csvHeaders, setCsvHeaders] = useState(["Creation date", "Change Date", "Status", "Newsletter Subscription", "Salutation", "Title", "First Name", "Middle Name", "Last Name", "Function", "Phone Number", "Email", "Attendees Start Date", "Attendees End Date", "Hotel Name", "Room Type", "Check In", "Check Out", "Number Of Persons", "Vegetarian", "Vegan", "Allergic", "Allergic reason", "Passport number", "Surname", "First name", "Date of Birth", "Place of birth", "Nationality", "Date of issue", "Date of expiry", "Authority Country", "Guest added?", "Guest Salutation", "Guest Title", "Guest First name ", "Guest Last name", "Guest Email", "Vegetarian", "Vegan", "Allergic","Allergic reason","Passport number", "Surname", "First name", "Date of Birth", "Place of birth", "Nationality", "Date of issue", "Date of expiry", "Authority/Country","Employee code","Employee number"]);
  const exportAllButton = useRef();
  const [showdefault, setShowDefault] = useState({})


  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", page);
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("registration_search", registrationsearch);
    formData.append("event_id", params.id);
    eventsService.RegistrationsList(formData)
      .then((response) => {
        if (response?.success) {
          setRegistrationsList(response.data.list);
          setDataLength(response.data.total_records);
          setLoader(false);
        }
      })
      .catch(err => console.log("err", err))
  }, [updateComponent, registrationsearch, search, page])

  useEffect(() => {
    eventsService.Details(params.id).then(response => {
      if (response && response.success) {
        setShowDefault(response.data ? response.data : [])
      }
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])


  const handleViewDetails = (registrationId, event_id) => {
    const to = `/admin/event-management/events/view/${registrationId}`;

    /**Pass event_id in the state object*/
    const state = { event_id };
    navigate(to, { state });
  };

  const setStatus = (status) => {
    if (status == 0) {
      return <span className='badge badge-warning'>Deactive</span>
    } else if (status == 1) {
      return <span className='badge badge-success'>Active</span>
    } else if (status == 2) {
      return <span className='badge badge-danger'>Cancelled</span>
    }else if (status === 3) {
      return <span className='badge badge-success'>Accepted</span>
    } else if (status === 4) {
      return <span className='badge badge-danger'>Declined</span>
    }
  }

  const settlePrices = (registration_id, price_type) => {
    Swal.fire({
      customClass: 'swal-wide',
      title: 'Are you sure?',
      text: "Your all payment are settled ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: leadData?.data?.background_colors?.primary_active_color,
      cancelButtonColor: leadData?.data?.colors?.danger,
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.isConfirmed) {
        eventServices.SettlePrice({
          registration_id: registration_id,
          price_type: price_type
        }).then((response) => {
          if (response.success) {
            setUpdateComponent(!updateComponent);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        }).catch((err) => {
          console.log("err", err);
        })
      }
    })
  }

  const handleInvoiceDetails = (registrationId, event_id) => {
    const to = `/admin/event-management/events/view/invoice/${registrationId}`;

    /**Pass event_id in the state object*/
    const state = { event_id };
    navigate(to, { state });
  };


  /********** Search or filter function start >>>>>>********* */
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  /********** Search or Filter Function End >>>>>>********* */

  /********** reset filter function start >>>>>>********* */
  const resetFilter = (e) => {
    e.preventDefault();
    setRegistrationSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;

  };
  /********** reset filter function start >>>>>>********* */

  /** This function is used to export all  register user's data */
  const exportAllUsers = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("event_id", params?.id);
    eventServices
      .RegistrationsList(formData)
      .then((response) => {
        let data = response && response?.data && response?.data?.list ? response.data.list : [];
        let tempHeaders = { length: 0, list: [] }
        let tempHeadersFinally = [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.primary_user?.agenda_data?.length) {
            lengthArr = Array(item?.primary_user?.agenda_data?.length).fill([]);
          }
          item?.primary_user?.agenda_data?.forEach((element, i) => {
            if (lengthArr[i]?.length < element?.elements?.length) {
              lengthArr[i] = Array(element?.elements?.length).fill("N/A");
            }
          });
        });
        let guestLengthArr = [];
        data?.forEach((item, index) => {
          if (guestLengthArr?.length < item?.guest_user?.agenda_data?.length) {
            guestLengthArr = Array(item?.guest_user?.agenda_data?.length).fill([]);
          }
          item?.guest_user?.agenda_data?.forEach((element, i) => {
            if (guestLengthArr[i]?.length < element?.elements?.length) {
              guestLengthArr[i] = Array(element?.elements?.length).fill("N/A");
            }
          });
        });
        let allDynamicQuestions = [
          ...(data[0].event_data?.question_data?.map((q=> { return {...q, title:`(Dynamic Data Primary) ${q?.title}`}})) || []),
          ...(data[0].event_data?.secondary_question_data?.map((q=> { return {...q, title:`(Dynamic Data Guest) ${q?.title}`}})) || [])
        ];
        let row = data.map((item) => {
          const tempRow = [
            removeCommas(formateDate(item?.createdAt)),
            removeCommas(formateDate(item?.updatedAt)),
            setStatusText(item?.status),
            item?.user_data?.subscribed_for_newsletter === true ? "Subscribed" : " Not Subscribed",
            removeCommas(item?.user_data?.gender) ? removeCommas(item.user_data.gender) : "N/A",
            removeCommas(item?.user_data?.title) ? removeCommas(item.user_data.title) : "N/A",
            removeCommas(item?.user_data?.first_name) ? removeCommas(item.user_data.first_name) : "N/A",
            removeCommas(item?.user_data?.middle_name) ? removeCommas(item.user_data.middle_name) : "N/A",
            removeCommas(item?.user_data?.last_name) ? removeCommas(item.user_data.last_name) : "N/A",
            removeCommas(item?.user_data?.event_function) ? removeCommas(item.user_data.event_function) : "N/A",
            removeCommas(item?.user_data?.phone_number?.number) ? removeCommas(item?.user_data?.phone_number.number) : "N/A",
            // item?.user_data?.phone_number_work?.number ? item.user_data.phone_number_work?.number : "N/A",
            removeCommas(item?.user_data?.email) ? removeCommas(item.user_data.email) : "N/A",
            removeCommas(item?.start_date) ? removeCommas(formateDate(item.start_date)) : "N/A",
            removeCommas(item?.end_date) ? removeCommas(formateDate(item.end_date)) : "N/A",
            removeCommas(item?.hotel_data?.name) ? removeCommas(item?.hotel_data.name) : "N/A",
            removeCommas(item?.room_type) ? removeCommas(removeSpecialCharacter(capitalizeFirstLetter(item?.room_type))) : "N/A",
            removeCommas(formateDate(item?.check_in)),
            removeCommas(formateDate(item?.check_out)),
            item?.add_guest === true ? "2" : "1",
            item?.primary_user?.vegetarian === true ? "Yes" : "No",
            item?.primary_user?.vegan === true ? "Yes" : "No",
            // item?.primary_user?.allergic === true ? "Yes" : "No",
            item?.primary_user?.allergic === true ? "Yes" : "No",
            item?.primary_user?.allergic === true ? removeCommas(item?.primary_user?.allergy_reason) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.passport_number) ? removeCommas(item.primary_user.visa_info.passport_number) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.surname) ? removeCommas(item.primary_user.visa_info.surname) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.first_name) ? removeCommas(item.primary_user.visa_info.first_name) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.date_of_birth) ? removeCommas(formateDate(item.primary_user.visa_info.date_of_birth)) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.place_of_birth) ? removeCommas(item.primary_user.visa_info.place_of_birth) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.nationality) ? removeCommas(item.primary_user.visa_info.nationality) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.date_of_issue) ? removeCommas(formateDate(item.primary_user.visa_info.date_of_issue)) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.date_of_expiry) ? removeCommas(formateDate(item.primary_user.visa_info.date_of_expiry)) : "N/A",
            removeCommas(item?.primary_user?.visa_info?.authority) ? removeCommas(item.primary_user.visa_info.authority) : "N/A",
            item?.add_guest === true ? "Yes" : "No",
            removeCommas(item?.guest_user?.gender) ? removeCommas(item.guest_user.gender) : "N/A",
            removeCommas(item?.guest_user?.title) ? removeCommas(item.guest_user.title) : "N/A",
            removeCommas(item?.guest_user?.first_name) ? removeCommas(item.guest_user.first_name) : "N/A",
            removeCommas(item?.guest_user?.last_name) ? removeCommas(item.guest_user.last_name) : "N/A",
            removeCommas(item?.guest_user?.email) ? removeCommas(item.guest_user.email) : "N/A",
            item?.guest_user?.vegetarian === true ? "Yes" : "No",
            item?.guest_user?.vegan === true ? "Yes" : "No",
            // item?.guest_user?.allergic === true ? "Yes" : "No",
            item?.guest_user?.allergic === true ? "Yes" : "No",
            item?.guest_user?.allergic === true ? removeCommas(item?.guest_user?.allergy_reason) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.passport_number) ? removeCommas(item.guest_user.visa_info.passport_number) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.surname) ? removeCommas(item.guest_user.visa_info.surname) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.first_name) ? removeCommas(item.guest_user.visa_info.first_name) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.date_of_birth) ? removeCommas(formateDate(item.guest_user.visa_info.date_of_birth)) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.place_of_birth) ? removeCommas(item.guest_user.visa_info.place_of_birth) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.nationality) ? removeCommas(item.guest_user.visa_info.nationality) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.date_of_issue) ? removeCommas(formateDate(item.guest_user.visa_info.date_of_issue)) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.date_of_expiry) ? removeCommas(formateDate(item.guest_user.visa_info.date_of_expiry)) : "N/A",
            removeCommas(item?.guest_user?.visa_info?.authority) ? removeCommas(item.guest_user.visa_info.authority) : "N/A",
            removeCommas(item?.user_data?.extra_info?.employee_code) ? removeCommas(item?.user_data?.extra_info?.employee_code) : "N/A",
            removeCommas(item?.user_data?.extra_info?.employee_number) ? removeCommas(item?.user_data?.extra_info?.employee_number) : "N/A",
          ];
          const tempData = []
          for (let i = 0; i < lengthArr?.length; i++) {
            tempData.push(`Primary agenda ${i + 1}`)
            tempRow.push(removeCommas(item?.primary_user?.agenda_data[i]?.name ?? "N/A"));
            if (item?.primary_user?.agenda_data[i]?.elements || lengthArr[i]?.length) {
              for (let j = 0; j < lengthArr[i]?.length; j++) {
                tempData.push(`Primary element ${j + 1}`)
                tempRow.push(removeCommas(item?.primary_user?.agenda_data[i]?.elements[j]?.name ?? "N/A"));
              }
            }
          }
          for (let i = 0; i < guestLengthArr?.length; i++) {
            tempData.push(`Guest agenda ${i + 1}`)
            tempRow.push(removeCommas(item?.guest_user?.agenda_data[i]?.name ?? "N/A"));
            if (item?.guest_user?.agenda_data[i]?.elements || guestLengthArr[i]?.length) {
              for (let j = 0; j < guestLengthArr[i]?.length; j++) {
                tempData.push(`Guest element ${j + 1}`)
                tempRow.push(removeCommas(item?.guest_user?.agenda_data[i]?.elements[j]?.name ?? "N/A"));
              }
            }
          }
          if (tempData?.length > tempHeaders?.length) {
            tempHeaders = { length: tempData?.length, list: tempData }
          }

          let dynamicQuestionValues = [];
          let questions = item && item?.primary_user && item?.primary_user.questions_data ? item.primary_user.questions_data : [];
          let guestQuestions = item && item?.guest_user && item?.guest_user?.questions_data ? item.guest_user.questions_data : [];
          /** seting dynamic questions values <<<< */
          allDynamicQuestions.forEach(() => {
            dynamicQuestionValues.push("N/A");
          });
          questions.forEach((question, i) => {
            const qTitle = `(Dynamic Data Primary) ${question?.question_title}`;
            const headingIndex = allDynamicQuestions.findIndex(q=> q?._id === question?.question_id);
            dynamicQuestionValues[headingIndex] = sanitizeAns(question);
          });
          guestQuestions.forEach((question, i) => {
            const qTitle = `(Dynamic Data Guest) ${question?.question_title}`;
            const headingIndex = allDynamicQuestions.findIndex(q=> q?._id === question?.question_id);
            dynamicQuestionValues[headingIndex] = sanitizeAns(question);
          });
          /** seting dynamic questions values <<<< */
          tempHeadersFinally = [...csvHeaders, ...tempHeaders?.list, ...(allDynamicQuestions.map(item=> item.title))];
          return [...tempRow, ...dynamicQuestionValues];
        });
        writeMyExcel([tempHeadersFinally, ...row], `Standard Registration Report-${addSpecialCharacter(registrationsList[0]?.event_data?.title)}.xlsx`).then(() => {
          setTimeout(() => {
            globalLoader(false);
          }, 1000);
        }).catch((err) => {
          console.log(err);
        });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };
  // useEffect(() => {
  //   if (allUsersToExport.length) {
  //     setAllUsersExportFlag(true);
  //     setTimeout(() => {
  //       setAllUsersExportFlag(false);
  //       exportAllButton?.current?.link?.click();
  //     }, 1000);
  //   }
  // }, [allUsersToExport]);
  /**function is used to export all register user's data end */

  const setStatusText = (status) => {
    if (status === 0) {
      return 'Deactive';
    } else if (status === 1) {
      return 'Active';
    } else if (status === 2) {
      return 'Cancelled';
    }else if (status === 3) {
      return <span className='badge badge-success'>Accepted</span>
    } else if (status === 4) {
      return <span className='badge badge-danger'>Declined</span>
    }
  };

  const getPriceDetails = (priceStatus, amount) => {
    if (priceStatus === "refundable" && amount !== 0) {
      return <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Refundable amount</Tooltip>}>
        {typeof amount === 'number' ? <span className='badge badge-danger'>{"-" + amount.toFixed(2)}</span> : <span className='badge badge-danger'>{"0"}</span>}
      </Whisper>
    } else if (priceStatus === "payable" && amount !== 0 && typeof amount === 'number') {
      return <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Payable amount</Tooltip>}>
        {typeof amount === 'number' ? <span className='badge badge-success'>{"+" + amount.toFixed(2)}</span> : <span className='badge badge-success'>{"0"}</span>}
      </Whisper>
    } else {
      return "0"
    }
  }

  const settlePriceButton = (registration_id, priceStatus, amount) => {
    if (priceStatus === "refundable" && amount !== 0) {
      return <a style={{ color: "#647194" }}
        href="/"
        className="dropdown-item"
        onClick={(e) => { e.preventDefault(); settlePrices(registration_id, "refundable") }}
      >
        Refund Done
      </a>
    } else if (priceStatus === "payable" && amount !== 0) {
      return <a style={{ color: "#647194" }}
        className="dropdown-item"
        href="/"
        onClick={(e) => { e.preventDefault(); settlePrices(registration_id, "payable") }}
      >
        Payment Done
      </a>
    } else if (priceStatus === "payable" && amount === 0) {
      return <></>
    }
  }
  const [registrationStatus] = useState([{ name: "Status", status__id: "" }, { name: "Deactive", status__id: REGISTRATION_TYPE.DEACTIVE }, { name: "Active", status__id: REGISTRATION_TYPE.ACTIVE }, { name: "Cancelled", status__id: REGISTRATION_TYPE.CANCELLED }]);


  const handleCancelRegistration = (_id) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        eventServices.CancelEventRegistration({ _id: _id }).then((response) => {
          if (response?.success) {
            setUpdateComponent(!updateComponent);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        }).catch((error) => {
          console.log("error", error);
        })
      }
    });
  }

  const handleAcceptRegistration = (_id) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You want to accept this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, accept it!",
    }).then((result) => {
      if (result.isConfirmed) {
        eventServices.EventRegistrationInvitationStatus(_id,3).then((response) => {
          if (response?.success) {
            setUpdateComponent(!updateComponent);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        }).catch((error) => {
          console.log("error", error);
        })
      }
    });
  }

  const handleRejectRegistration = (_id) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You want to reject this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, reject it!",
    }).then((result) => {
      if (result.isConfirmed) {
        eventServices.EventRegistrationInvitationStatus(_id,4).then((response) => {
          if (response?.success) {
            setUpdateComponent(!updateComponent);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        }).catch((error) => {
          console.log("error", error);
        })
      }
    });
  }

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label"><span></span></h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={registrationsearch}
                placeholder="Search"
                onChange={(e) => {
                  setRegistrationSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="me-3">
            <CustomRangepicker
              GetDateRange={(e) => {
                prepareSearch("createdAt", e);
              }}
              resetdate={resetdate}
            />
          </div>
          <div className="me-3">
            <StatusFilter
              data={registrationStatus}
              prepareSearch={prepareSearch}
            />
          </div>
          {
            allUsersExportFlag ?
              <>
                <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
                <CSVLink
                  ref={exportAllButton}
                  style={{ display: 'none' }}
                  data={allUsersToExport}
                  asyncOnClick={true}
                  headers={csvHeaders}
                  filename={`Standard Registration Report-${addSpecialCharacter(registrationsList[0]?.event_data?.title)}.${EXPORT_EXTENSION_TYPE}`}
                >
                  Export Report
                </CSVLink>
              </>
              : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
          }
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>City</th>
              <th>Company Name</th>
              <th>Country</th>
              <th>Payable/Refundable</th>
              <th className="status_head">Status</th>
              <th className="created_head">Updated By</th>
              <th className="created_head">Created Date</th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={10}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {registrationsList.length ? (
                  registrationsList.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row?.user_data?.name ? row?.user_data?.name : "N/A"}</td>
                        <td>{row?.user_data?.email ? row?.user_data?.email : "N/A"}</td>
                        <td>{row?.city ? row?.city : "N/A"}</td>
                        <td>{row?.company_name ? row?.company_name : "N/A"}</td>
                        <td>{row?.country ? row?.country : "N/A"}</td>
                        <td>{row?.refundable ? getPriceDetails("refundable", Number(row?.refundable_amount)) : getPriceDetails("payable", Number(row?.payable_amount > 0 ? row?.payable_amount : 0))}</td>
                        <td>{typeof row.status !== 'undefined' ? setStatus(row?.status) : "N/A"}</td>
                        <td>{row?.updatedBy ? row?.updatedBy?.name : "N/A"}</td>
                        <td>{row?.createdAt ? formateDate(row?.createdAt) : "N/A"}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() => handleViewDetails(row._id, params.id)}
                            >
                              View
                            </button>
                            <button
                              className="btn ripple btn-main-primary signbtn ms-1"
                              onClick={() => navigate(`/admin/event-management/events/registration-edit/${params.id}?invitation_id=${row?.invitation_id}&reg_id=${row?._id}&user_id=${row?.user_data?._id}`)}
                            >
                              Edit
                            </button>
                            {(row?.refundable && row?.refundable_amount) || (row?.payable && row?.payable_amount) || row?.status !== 2 ? (
                              <div className="btn-group mlAction">
                                <button
                                  type="button"
                                  className="btn btn-dark dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  More
                                </button>
                                <ul className="dropdown-menu">
                                  <li>{row?.refundable ? settlePriceButton(row?._id, "refundable", row?.refundable_amount) : settlePriceButton(row?._id, "payable", row?.payable_amount)}</li>
                                  {row?.registration_cost > 0 ? (
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleInvoiceDetails(row._id, params.id)}
                                      >
                                        Invoice
                                      </button>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {row && row?.status !== 2 ? (
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleCancelRegistration(row._id)}
                                      >
                                        Cancel
                                      </button>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                   {showdefault && showdefault?.event_type === EVENT_TYPES.OPEN_PENDING_APPROVAL && row.status==0 ? (
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleAcceptRegistration(row._id)}
                                      >
                                        Accept
                                      </button>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {showdefault && showdefault?.event_type === EVENT_TYPES.OPEN_PENDING_APPROVAL && row.status==0 ? (
                                    <li>
                                      <button
                                        className="dropdown-item"
                                        onClick={() => handleRejectRegistration(row._id)}
                                      >
                                        Reject
                                      </button>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </ul>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div
        className=""
        id="example1_info"
        role="status"
        aria-live="polite"
      >
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/registrations/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
    </div>
  )
}

export default ViewRegistrations;