import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import $ from "jquery";
import Loader from "../../../common/loader";
import { showFilterlist, SWAL_SETTINGS } from "../../../../../utils/Constants";
import CustomRangepicker from "../../../common/rangepicker";
import { formateDate, handleServerValidations, hasPermission, TrimText } from "../../../../../utils/commonfunction";
import Sorting from "../../../common/sorting";
import CustomPagination from "../../../common/custompagination";
import Breadcrums from "../../../common/breadcrumbs";
import StatusFilter from "../../../common/statusFilter";
import { DistributionDelete, DistributionList, DistributionStatus } from "../../../services/distributionlist.services";

const DistributionListTable = () => {

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [statsupdate, setStatusUpdate] = useState("false");
    const [datalength, setDataLength] = useState(0);
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const breadcrumbs = [
        { title: "Dashboard", url: "/admin/dashboard" },
        { title: "Distribution List", url: "" },
    ];
    const [resetdate, setResetDate] = useState(false);
    const selectAllCheckboxData = useRef();
    const [selectedUserData, setSelectedUserData] = useState([]);
    const [selectedAllUserData, setSelectedAllUserData] = useState(false);


    useEffect(() => {
        setLoader(true);
        if (search) {
            const formData = new FormData();
            formData.append("page", params.pgno);
            formData.append("per_page", itemperpage);
            formData.append("sort", JSON.stringify(sorting));
            formData.append("search", JSON.stringify(search));
            formData.append("global_search", globalsearch);
            DistributionList(formData)
                .then((data) => {
                    setDataLength(data.data && data.data.total_records ? data.data.total_records : []);
                    setList(data && data.data && data.data.list ? data.data.list : []);
                    setSelectedAllUserData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
                    setPage(data && data.data && data.data.page ? data.data.page : 1);
                    setLoader(false);
                })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        }
    }, [location, statsupdate, sorting, search, globalsearch, itemperpage, params.pgno]);

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedUserData.length === datalength) {
            selectAllCheckboxData.current.indeterminate = false;
            selectAllCheckboxData.current.checked = true;
        } else if (selectedUserData.length) {
            selectAllCheckboxData.current.indeterminate = true;
        } else {
            selectAllCheckboxData.current.indeterminate = false;
        }
    }, [selectedUserData]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedUserData((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedUserData((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAllData = (check) => {
        if (check) {
            setSelectedUserData(selectedAllUserData);
        } else {
            setSelectedUserData([]);
        }
    };
    const isSelectedData = (data) => {
        return (selectedUserData.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT END<<<<<<<<<********* */


    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    };
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };

    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        setSearch({});
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate);
        $("#defaultstatus")[0].selectedIndex = 0;

    };

    function Deletefunction(data) {
        Swal.fire({
            customClass: "swal-wide",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#403fad",
            cancelButtonColor: "#f1388b",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData();
                formdata.append("o_id", JSON.stringify(ids));
                DistributionDelete(formdata)
                    .then((response) => {
                        setStatusUpdate(!statsupdate);
                        if (response?.success) {
                            Swal.fire({
                                icon: "success",
                                text: response?.message,
                                ...SWAL_SETTINGS,
                            });
                            /** removing deleted ids from selectedRecords */
                            setSelectedUserData((data) => data.filter((item) => { return ids.indexOf(item) === -1}));
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("deleteError");
                    });
            }
        });
    }

    const ChangeStatus = (data, Num) => {
        let ids = Array.isArray(data) ? data : [data];
        const formData = new FormData()
        formData.append("o_id", JSON.stringify(ids));
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                DistributionStatus(formData).then(response => {
                    if (response?.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response?.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    const viewfunction = (row) => {
        navigate(`/admin/distribution-list/view/${row._id}?page=1`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/distribution-list/${params.pgno}/edit/${row._id}?page=1`);
      };

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h6 className="main-content-label">Distribution List</h6>
                            <div className="d-flex align-items-center">
                                <div className="form-group mb-0 me-3">
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input
                                            type="search"
                                            className="form-control"
                                            value={globalsearch}
                                            placeholder="Search"
                                            onChange={(e) => {
                                                setGlobalSearch(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker
                                        GetDateRange={(e) => {
                                            prepareSearch("createdAt", e);
                                        }}
                                        resetdate={resetdate}
                                    />
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button
                                    type="reset"
                                    value="Reset"
                                    onClick={resetFilter}
                                    className="btn btn-warning float-right mr-2"
                                >
                                    Reset Filter
                                </button>
                                {hasPermission('/admin/distribution-list/add') ?
                                <button
                                    className="btn ripple btn-main-primary signbtn mr-2"
                                    onClick={() => navigate(`/admin/distribution-list/add?page=1`)}
                                >
                                    Add New
                                </button>
                                :null}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " + (hasPermission('/admin/distribution-list/multi-select') ? '' : "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheckData" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckboxData}
                                                        id="selectAllCheckData"
                                                        checked={
                                                            datalength && datalength === selectedUserData.length
                                                        }
                                                        onChange={(e) => handleSelectAllData(e.target.checked)} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="sorting_column">
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>Users</th>
                                        <th>Created By</th>
                                        <th>Status</th>
                                        <th>
                                            <div className="sorting_column">
                                                <span>Created Date</span>
                                                <Sorting
                                                    sort={sorting}
                                                    handleSort={handleSort}
                                                    defaultSorting={defaultSorting}
                                                    column="createdAt"
                                                />
                                            </div>
                                        </th>

                                        <th className='action_head'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ? (
                                        <tr>
                                            <td colSpan={9}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {list.length ? (
                                                list.map((row, i) => {
                                                    return (
                                                        <tr key={i} className={isSelectedData(row) ? "selected" : ""}>
                                                            <td className={"position-relative " + (hasPermission('/admin/distribution-list/multi-select') ? '' : "d-none")}>
                                                                <div className="select-check-container">
                                                                    <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                        className="ckbox cp">
                                                                        <input
                                                                            id={row?._id + "input"}
                                                                            checked={isSelectedData(row)}
                                                                            onChange={(e) =>
                                                                                selectRecord(row, e?.target?.checked)
                                                                            }
                                                                            type="checkbox"
                                                                            className="form-check select-check cp" />
                                                                        <span></span>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td>{row?.title ? TrimText(row.title, 20) : "N/A"}</td>
                                                            <td>{row?.count ? row.count : "0"}</td>
                                                            <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link> : "N/A"}</td>
                                                            {hasPermission('/admin/distribution-list/status') ?
                                                                <td>{row?.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                                    : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive</button>}
                                                                </td>
                                                                :
                                                                <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                            }
                                                            <td>
                                                                {row.createdAt
                                                                    ? formateDate(row.createdAt)
                                                                    : "N/A"}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">

                                                                    <button className="btn ripple btn-main-primary signbtn " onClick={() => { viewfunction(row) }}>View</button>
                                                                    {hasPermission('/admin/distribution-list/*/edit/*') ? 
                                                                    <button
                                                                        className="btn ripple btn-success mlAction "

                                                                        onClick={() => {
                                                                            goToEdit(row);
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    :null}
                                                                    {hasPermission('/admin/distribution-list/delete') ?
                                                                        <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(row?._id); }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                        : null}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={9} className="text-center">
                                                        No records
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className=""
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                        >
                            <b>Total Records : {datalength ? datalength : "0"}</b>
                        </div>
                        {datalength && datalength > 0 ? (
                            <CustomPagination
                                datalength={datalength}
                                itemperpage={itemperpage}
                                currentPage={page}
                                setPage={setPage}
                                pageRoute={[
                                    { name: "Distribution List", path: "/admin/distribution-list/list" },
                                ]}
                            />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>

            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedUserData.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedUserData.length} {selectedUserData.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                                {hasPermission('/admin/distribution-list/status') ?

                                    <li>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedUserData, 1) }}>Active</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/distribution-list/status') ?

                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedUserData, 0) }}>Inactive</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/distribution-list/delete') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedUserData) }}>Delete</button>
                                    </li>
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}

        </>
    );
};

export default DistributionListTable;
