import axiosInstance from "../../../utils/axios";
const path = "web/other"

export const sharedFiles = async (values) => {
  return await axiosInstance().post(`${path}/users/media/list`, values);
}

export const eventRegistrationList = async (values) => {
  return await axiosInstance().post(`${path}/events/registrations/list`, values);
}

export const eventRegistrationCancel = async (values) => {
  return await axiosInstance().post(`${path}/events/registrations/cancel`, values);
}

export const invitationList = async (values) => {
  return await axiosInstance().post(`${path}/invitations/list`, values);
}

export const CheckHotelAvailability = async (values) => {
  return await axiosInstance().post(`${path}/events/check-hotel-availability`, values);
}

export const OldPricing = async (values) => {
  return await axiosInstance().post(`${path}/events/registrations/old-pricing`, values);
}

export const UserAnnouncements = async (values) => {
  return await axiosInstance().post(`${path}/announcements/list`, values);
}

export const UserAnnouncementsView = async (id) => {
  return await axiosInstance().get(`${path}/announcements/details?o_id=${id}`);
}

export const transactionList = async (values) => {
  return await axiosInstance().post(`${path}/transactions/list`, values);
}

export const countClick = async (values) => {
  return await axiosInstance().post(`${path}/count-clicks`, values);
}
