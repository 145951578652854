import React, { useState, useEffect, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { LeadEdit, LeadView } from "../../../user/services/lead.services";
import Swal from "sweetalert2";
import {
  getTranslatedGender,
  globalLoader,
  handleServerValidations,
  prepareQuestionsFormData,
  removeSpacesAndCharacters,
  selectSalutation,
} from "../../../../utils/commonfunction";
import {
  GENDER,
  QUESTION_SET_CATEGORIES,
  SWAL_SETTINGS,
} from "../../../../utils/Constants";
import PhoneInput from "react-phone-input-2";
import {
  QuestionList,
} from "../../services/common.services";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import DynamicQuestions from "../../../user/shared/DynamicQuestions";
import { useTranslation } from "react-i18next";


const BackOfficeLeadEdit = () => {
  const childRef = useRef();
  const params = useParams();
  const [questionList, setQuestionList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const navigate = useNavigate();
  const [questionFilesUploadPath] = useState("lm-users");
  const [showdefault, setShowDefault] = useState({});
  const [answerList, setAnswerList] = useState([]);
  const { t } = useTranslation();
  const [dataLoaded,setDataLoaded] = useState(false);
  let reducerData = useSelector((state) => state.manageLmBackOffice);

  useEffect(() => {
    window.addEventListener("eventChangedByLMUser", function (e) {
      setRefreshList(!refreshList);
    });
  }, []);

  useEffect(() => {
    const formData = new FormData();
    if (reducerData) {
      formData.append("event_id", reducerData);
    }
    formData.append("type", QUESTION_SET_CATEGORIES.TRADE_FAIR_QUESTIONNAIRE);
    QuestionList(formData)
      .then((response) => {
        setQuestionList(response?.data || []);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, [reducerData]);

  useEffect(() => {
    LeadView(params.id)
      .then((data) => {
        setShowDefault(data && data.data ? data.data : []);
        setAnswerList(data && data.data && data.data.questions_data);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  const validationSchema = Yup.object().shape({
    gender: Yup.string()
        .oneOf(Object.values(getTranslatedGender()), t('web_lbl_invalid_err_salutation'))
        .required("Salutation is required"),
    first_name: Yup.string()
        .required(t('web_registration_err_firstname'))
        .test("no-spaces", (t('web_registration_err_firstname')), (value) => value.trim()),
    middle_name: Yup.string(),
    last_name: Yup.string()
        .required(t('web_registration_err_lastname'))
        .test("no-spaces", (t('web_registration_err_lastname')), (value) => value.trim()),
    email: Yup.string()
        .email(t('web_lbl_err_invalid_email'))
        .required(t('web_registration_err_email')),
    phone_number: Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number'))
        .test(
            "min-length",
            (t('web_registration_err_min_mobile_number')),
            (value) => value?.trim()?.length >= 4
        )
        .test(
            "max-length",
            (t('web_registration_err_max_mobile_number')),
            (value) => value?.trim()?.length <= 13
        ),
    acceptance: Yup.string()
        .test("is_draft", (t('web_registration_err_required')), (value) => {
            return (value === "true" || value === true)
        })
});

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          email: showdefault && showdefault.email ? showdefault.email : "",
          title: showdefault && showdefault.title ? showdefault.title : "",
          gender: showdefault && showdefault.gender ? selectSalutation(showdefault.gender) : "",
          first_name:
            showdefault && showdefault.first_name ? showdefault.first_name : "",
          middle_name:
            showdefault && showdefault.middle_name
              ? showdefault.middle_name
              : "",
          last_name:
            showdefault && showdefault.last_name ? showdefault.last_name : "",
          phone_number:
            showdefault &&
            showdefault?.phone_number &&
            showdefault?.phone_number.number
              ? showdefault?.phone_number.number
              : "",
          countryCodePhone:
            showdefault &&
            showdefault?.phone_number &&
            showdefault?.phone_number.countryCode
              ? showdefault?.phone_number.countryCode
              : "",
          dialCodePhone:
            showdefault &&
            showdefault?.phone_number &&
            showdefault?.phone_number.code
              ? showdefault?.phone_number.code
              : "",
          acceptance: showdefault && Boolean(showdefault.acceptance),
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
        const formResult = childRef?.current?.submit();
          if (!formResult?.isValid) {
            return;
          }
          globalLoader(true);
          let formData = new FormData();
          if (reducerData) {
            formData.append("event_id", reducerData);
          }
          formData.append("title", values.title);
          formData.append("first_name", values.first_name);
          formData.append("middle_name", values.middle_name);
          formData.append("last_name", values.last_name);
          formData.append("gender", values.gender);
          formData.append("email", values.email);
          formData.append(
            "phone_number",
            JSON.stringify({
              code: values.dialCodePhone,
              number: removeSpacesAndCharacters(values.phone_number),
            })
          );
          formData.append("is_draft", values.is_draft);
          formData.append("acceptance", values?.acceptance);
          formData.append("o_id", params.id);
          let result = childRef?.current?.getValues();
          let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
          LeadEdit(finalForm)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  globalLoader(false);
                  navigate(`/back-office/leads/list/1`);
                }, 1000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
              globalLoader(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <div className="wraper-inner cpt bg-grey">
            <section className="inner-space pt-0">
              <div className="w-100 px-3">
                <form
                  onSubmit={async (e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="row">
                    <div className="col-md-9 m-auto ">
                      <h3 className="inner-title mb-0">{t('web_lbl_edit_lead')}</h3>
                      <div className="dash-box-shadow space-b">
                        <div className="row add-coustomer">
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">{t("web_lbl_title")}</label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_title')}
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                              />
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_salutation")}
                              <small>*</small>
                            </label>
                            <div className="select-down-arrow">
                              <Field
                                as="select"
                                id="gender"
                                onChange={handleChange}
                                name={"gender"}
                                className="form-control"
                              >
                                <option value="" label={t('web_lbl_select_salutation')}>
                                  Select{" "}
                                </option>
                                {getTranslatedGender() &&
                                  Object.values(getTranslatedGender()).map((option, i) => {
                                    return (
                                      <option key={i} value={option}>
                                        {option}
                                      </option>
                                    );
                                  })}
                              </Field>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.gender && touched.gender && errors.gender}
                            </span>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_first_name")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_first_name')}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.first_name &&
                                  touched.first_name &&
                                  errors.first_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">{t("web_lbl_middle_name")}</label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_middle_name')}
                                name="middle_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.middle_name}
                              />
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_last_name")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_last_name')}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.last_name &&
                                  touched.last_name &&
                                  errors.last_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_email")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_email')}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.email && touched.email && errors.email}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_phone_number")}<small>*</small>
                            </label>
                            <div className="">
                              <PhoneInput
                                country={"de"}
                                value={
                                  values.dialCodePhone + values.phone_number
                                }
                                placeholder={t('web_lbl_enter_your_phone_number')}
                                className="form-control"
                                buttonStyle={{
                                  background: "#fff",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  borderTop: 0,
                                  borderBottom: 0,
                                  borderLeft: 0,
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                }}
                                containerStyle={{ padding: 0 }}
                                inputStyle={{
                                  width: "-webkit-fill-available",
                                  height: "100%",
                                  marginLeft: 10,
                                  border: 0,
                                }}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue
                                ) => {
                                  setFieldValue(
                                    "dialCodePhone",
                                    formattedValue.substring(
                                      0,
                                      formattedValue.indexOf(" ")
                                    )
                                  );
                                  setFieldValue(
                                    `phone_number`,
                                    formattedValue.substring(
                                      formattedValue.indexOf(" ") + 1
                                    )
                                  );
                                }}
                              />
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.phone_number &&
                                touched.phone_number &&
                                errors.phone_number}
                            </span>
                          </div>
                          {questionList?.length > 0 ? (
                            <h4 className="mb-3">{t('web_lbl_more_info')}</h4>
                          ) : null}
                          <div className="form-group mb-4 col-md-12">
                            {questionList?.length > 0 && dataLoaded
                              ? 
                              <div>
                              <DynamicQuestions ref={childRef} questions={questionList} showCount={false}
                              questionTitleClass="" defaultValues={answerList} footer="" videoUploadPath={questionFilesUploadPath}/>
                              </div>
                              : null}
                          </div>
                          <div className="form-group col-md-12">
                            <div className="form-check black-check ">
                              <input
                                name="acceptance"
                                className="form-check-input cp"
                                type="checkbox"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.acceptance}
                                checked={values.acceptance}
                                id="acceptance"
                              ></input>
                              <label
                                htmlFor="acceptance"
                                className="form-label"
                              >
                                {t('web_lbl_terms_conditions')}<small>*</small>
                              </label>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.acceptance &&
                                touched.acceptance &&
                                errors.acceptance}
                            </span>
                          </div>
                          <div className="btn-group mt-4 col-md-12">
                            <button className="btn btn-primary" type="submit" 
                            onClick={() => { childRef?.current?.submit();}}>
                            {t('web_lbl_update')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </Formik>
    </>
  );
};
export default BackOfficeLeadEdit;
