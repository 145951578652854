import React, { useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import LMUsersHeader from './lmusersheader';
import LMUsersFooter from './lmusersfooter';
import { EventOptions } from '../../user/services/lead.services';
import {  globalLoader } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const LMUserslayout = () => {

    // const location = useLocation();
    // const [assignedEvents, setAssignedEvents] = useState([]);
    let { i18n, t } = useTranslation();

    let reducerData = useSelector(state => state.manageLmBackOffice);
    

    /** Get assigned events for logged in user */
    useEffect(() => {
        globalLoader(true);
        EventOptions().then((response) => {
            if (response && response?.success) {
                // setAssignedEvents(response && response.data? response.data: []);
                // let selectedEvtID = getLocalKey("selectedEventByLMUser");
                globalLoader(false);
                // if(response && response.data && response.data.length){
                //     let selectedEvent = response.data.filter(event=> event._id === selectedEvtID);
                //     if(!selectedEvtID && !selectedEvent.length){
                //         setLocalKey("selectedEventByLMUser",response.data[0]._id);
                //     }
                // }
            } else {
                console.log("error");
                globalLoader(false);
            }
        }).catch((err) => {
            console.log(err);
            globalLoader(false);
        });
    }, []);
   /** Deep links */
   useEffect(() => {
        try{
            let url = "";
            if(window && window.location && window.location.href){
                let origin = window.location.origin ? window.location.origin : '';
                url = window.location.href.replace(origin, '');
                if(url[0] === "/" || url[0] === "\\"){
                    url = url.slice(1, url.length);
                }
            }
            // document.location=`eventmanagement://eventmanagement?${url}`;
        }catch(err){
            console.log("Deep link error", err);
        }
    }, []);
    return (
        <>
            <LMUsersHeader />
            {console.log(reducerData)}
            <div style={{paddingTop:'75px',minHeight: "60vh"}}>
                {reducerData && reducerData !== "" ?
                <>
                    <Outlet/>
                </>
                :
                    <div className="wraper-inner cpt bg-grey">
                        <section className="inner-space pt-0">
                            <div className="container" style={{ minHeight: "60vh" }}>
                                <div className="dash-box-shadow">
                                    <h3 className='text-center'>{t('web_lbl_no_events_found')}</h3>
                                </div>
                            </div>
                        </section>
                    </div>
                }
                
            </div>
            <LMUsersFooter />
        </>
    )
}

export default LMUserslayout;