import React, { useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getLocalKey, handleServerValidations, logOutUser } from "../../../utils/commonfunction";
import { STAFF_ROLE ,SWAL_SETTINGS} from "../../../utils/Constants";
import { ProfileDelete } from "../services/webuser.service";

function SettingMenu(props) {
  let sidebarContainer = useRef();
  const loc = useLocation();
  const { t } = useTranslation();
  const [isPhone, setIsPhone] = useState(false);
  const [getuserkey, setGetUserkey] = useState();
  const navigate = useNavigate();
  const leadData = useSelector((state) => state.designportal);

  useEffect(() => {
    if(window.innerWidth > 500){
      setIsPhone(false);
    }else{
      setIsPhone(true);
    }
  },[])

  useEffect(() => {
    try {
        const getuserkey = JSON.parse(getLocalKey("user"));
        setGetUserkey(getuserkey);
    } catch (err) {
        setGetUserkey(null)
    }
}, [loc])

  const logout = () => {
    Swal.fire({
      customClass: "swal-wide",
      title: t('web_swal_are_you_sure'),
      text: t('lbl_logout_text'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor:
        leadData?.data?.background_colors?.primary_active_color,
      cancelButtonColor:
        leadData?.data?.background_colors?.footer_background_color,
      confirmButtonText: t('web_swal_yes'),
      cancelButtonText: t('web_swal_no')
    }).then((result) => {
      if (result.isConfirmed) {
        props.onClose(false)
        logOutUser(navigate);
      }
    });
  };

  const  DeleteProfile = (data) => {
     Swal.fire({
      customClass: "swal-wide",
      title: t('web_swal_are_you_sure'),
      text: t('profile_delete_text'),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor:
        leadData?.data?.background_colors?.primary_active_color,
      cancelButtonColor:
        leadData?.data?.background_colors?.footer_background_color,
      confirmButtonText: t('web_swal_yes'),
      cancelButtonText: t('web_swal_no')
    }).then((result) => {
      if (result.isConfirmed) {
        ProfileDelete()
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              logOutUser(navigate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  const handleResponsiveMenu = () => {
    if(isPhone){
      props.onClose(false)
    }
  }


  return (
    <div id="setting_open" className={`side-bar-L ${props.showMenu === true ? "open" : ""}`} ref={sidebarContainer}>
      <div className="sidebar-body">
        <a
          href="/"
          className="close-side-btn"
          onClick={(e) => {
            e.preventDefault();
            if(props.onClose){
              props.onClose(false);
            }
          }}
        >
          <i className="ri-close-line"></i>
        </a>
        <ul className="sidebar-link-list">
          <li
            className={
              loc.pathname.includes("/settings/profile") ? " active" : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/profile"}>
              <figure>
                <i className="ti ti-user"></i>
              </figure>
              {t("web_sidebar_profile")}
            </Link>
          </li>
          <li
            className={
              loc.pathname.includes("/settings/change-password")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/change-password"}>
              <figure>
                <i className="ti ti-lock"></i>
              </figure>
              {t("web_sidebar_change_password")}
            </Link>
          </li>
          {getuserkey?.role!==STAFF_ROLE.LM_BACK_OFFICE ? 
            <li
            className={
              loc.pathname.includes("/settings/event-registrations")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/event-registrations/list/1"}>
              <figure>
                <i className="ti ti-calendar-event"></i>
              </figure>
              {t("web_sidebar_event_registration")}
            </Link>
          </li>
          :null
          }
          {getuserkey?.role !== STAFF_ROLE.LM_BACK_OFFICE && getuserkey?.role !== STAFF_ROLE.BOOTH_ATTENDANTS ?
            <li
            className={
              loc.pathname.includes("/settings/shared-files") ? " active" : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/shared-files/1"}>
              <figure>
                <i className="ti ti-files"></i>
              </figure>
              {t("web_sidebar_shared_files")}
            </Link>
          </li>
          :null
          }
          {getuserkey?.role!==STAFF_ROLE.LM_BACK_OFFICE ?
            <li
            className={
              loc.pathname.includes("/settings/invitation-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/invitation-list/1"}>
              <figure>
                <i className="ti ti-link"></i>
              </figure>
              {t("web_sidebar_invitation_list")}
            </Link>
          </li>
          :null
          }
          {getuserkey?.role!==STAFF_ROLE.LM_BACK_OFFICE ?
            <li
            className={
              loc.pathname.includes("/settings/transaction-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/settings/transaction-list/1"}>
              <figure>
                <i className="ri-bank-line"></i>
              </figure>
              {t("web_sidebar_transaction_list")}
            </Link>
          </li>
          :null
          }
          <li>
            <a href="/" onClick={(e) => {e.preventDefault();logout()}}>
              <figure>
                <i className="ti ti-logout"></i>
              </figure>
              {t("web_sidebar_logout")}
            </a>
          </li>
            <li>
            <a href="/" onClick={(e) => {e.preventDefault();DeleteProfile()}}>
              <figure>
              <i className="ri-delete-bin-6-line"></i>
              </figure>
              {t("web_sidebar_delete_profile")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SettingMenu;