import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import * as eventServices from "../../services/events.services";
import {
  formateDate,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import { SWAL_SETTINGS } from "../../../../utils/Constants";

function DripContent() {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [search] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [globalSearchNew, setGlobalSearchNew] = useState("");
  const params = useParams();
  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [countLength, setCountLength] = useState("");
  const [itemperpage] = useState(10);
  const [countPerPage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [countPage, setCountPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [secondModal] = useState(false);
  // const [eventOptions, setEventOptions] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", query.get('page'));
      // formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("event_id", params.id);
      eventServices
        .DripContentList(formData)
        .then((data) => {
          setDataLength(data?.data?.total_records);
          setList(
            data && data?.data && data?.data?.list ? data?.data?.list : []
          );
          setPage(data && data?.data && data?.data.page ? data?.data?.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    secondModal,
    itemperpage,
    params.pgno,
    params.id,
  ]);

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", query.get('count_page'))
      formData.append("per_page", countPerPage);
      formData.append("global_search", globalSearchNew);
      formData.append("event_id", params.id);
      eventServices
        .Counts(formData)
        .then((data) => {
          setCountLength(data?.data?.total_records);
          setData(
            data && data?.data && data?.data?.list ? data?.data?.list : []
          );
          setCountPage(data && data?.data && data?.data.page ? data?.data?.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [countPerPage,params.id,countPerPage,globalSearchNew]);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          // setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  // sorting function start
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
  };

  const resetFilterNew = (e) => {
    e.preventDefault();
    setGlobalSearchNew("");;
  };

  const handleViewDetails = (id, event_id) => {
    const to = `/admin/event-management/events/view/link-statics-details/1`;
    const state = { event_id,id };
    navigate(to, { state });
  };

  const deleteDripContentFunction = (data) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("event_id", data?.event_id);
        formdata.append("question_set_id", data?.question_set_id);
        formdata.append("title", data?.title);
        formdata.append("option", data?.option);
        eventServices
          .DeleteDripContent(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  };

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">Drip Content Emails</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalsearch}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <div className="me-3">
                <CustomRangepicker
                  GetDateRange={(e) => {
                    prepareSearch("createdAt", e);
                  }}
                  resetdate={resetdate}
                />
              </div> */}
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
          <button
            className="btn ripple btn-main-primary signbtn"
            onClick={() =>
              navigate(`/admin/event-management/events/view/drip-content-add`, {
                state: params?.id,
              })
            }
          >
            Add Emails
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Title</th>
              <th>Option</th>
              <th>Time</th>
              <th>
                <div className="sorting_column">
                  <span>Created Date</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="createdAt"
                  />
                </div>
              </th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {list?.length ? (
                  list?.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td className="text-capitalize">
                          {row?.title ? row?.title : "N/A"}
                        </td>
                        <td className="text-capitalize">
                          {row?.option ? row?.option : "N/A"}
                        </td>
                        <td>
                          {row?.schedule_time_in_hours === 0 &&
                          row?.schedule_time_in_minutes === 0
                            ? "0"
                            : row?.schedule_time_in_hours === 0 &&
                              row?.schedule_time_in_minutes != 0
                            ? `${row?.schedule_time_in_minutes} Minutes`
                            : row?.schedule_time_in_hours != 0 &&
                              row?.schedule_time_in_minutes === 0
                            ? `${row?.schedule_time_in_hours} Hours`
                            : `${row?.schedule_time_in_hours} Hours ${row?.schedule_time_in_minutes} Minutes`}
                        </td>

                        <td>
                          {row?.createdAt ? formateDate(row?.createdAt) : "N/A"}
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-success"
                              type="button"
                              onClick={() =>
                                navigate(
                                  `/admin/event-management/events/view/drip-content-add`,
                                  { state: params?.id }
                                )
                              }
                            >
                              Edit
                            </button>
                            <button
                              className="btn ripple btn-secondary ms-2"
                              type="button"
                              onClick={() => {
                                deleteDripContentFunction(row);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          paramName={"page"}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          query={"true"}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/event-drip-content/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      <div className="d-flex justify-content-between align-items-center mb-4 mt-3">
        <h6 className="main-content-label">Links Statics</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalSearchNew}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearchNew(e.target.value);
                }}
              />
            </div>
          </div>
          <button
            type="reset"
            value="Reset"
            onClick={resetFilterNew}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Click Counts</th>
              <th>Link</th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={3}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {data.length ? (
                  data.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row?.count ? row.count : "N/A"}</td>
                        <td>
                          {row?._id ? row._id : "N/A"}
                        </td>
                        <td>
                          <div className="d-flex">
                          <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() =>
                                handleViewDetails(row?._id, params?.id)
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {countLength ? countLength : "0"}</b>
      </div>
      {countLength && countLength > 0 ? (
        <CustomPagination
          datalength={countLength}
          paramName={"count_page"}
          itemperpage={countPerPage}
          currentPage={countPage}
          setPage={setCountPage}
          query={"true"}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/event-drip-content/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default DripContent;
