import React, { useEffect} from 'react'
import Header from './header'
import Footer from './footer'
import { Outlet, useLocation } from 'react-router-dom'
import { getSessionKey, getUser, setSessionKey, isApp } from '../../../utils/commonfunction';
import { SOCKET_CLIENT} from '../../../utils/socket';

const Userlayout = () => {
//     const location = useLocation();
//    useEffect(()=>{
//     /** Joining this user for notifications */
//     const user = getUser();
//     if(user && user.role === 3){
//         let joinRequest = {
//             room: user._id
//         };
//         SOCKET_CLIENT.emit('notificationJoin', joinRequest, (error) => {
//             if(error){
//                 console.log("*****Socket connection error on notificationJoin", error);
//             }
//         });
//     }
    
//    },[location]);
   
   /** Deep links */
//    useEffect(() => {
//     if(window.innerWidth < 500 && !isApp()){
//         try{
//             let url = "";
//             if(window && window.location && window.location.href){
//                 let origin = window.location.origin ? window.location.origin : '';
//                 url = window.location.href.replace(origin, '');
//                 if(url[0] === "/" || url[0] === "\\"){
//                     url = url.slice(1, url.length);
//                 }
//                 url+="?running_in_app=true";
//             }
//             document.location=`eventmanagement://eventmanagement?${url}`;
//         }catch(err){
//             console.log("Deep link error", err);
//         }
//     }
//     }, []);

   /** Firebase notifications >>>>> */
//    const handleNotification = (message, showMessage = true) => {
//        /** Requesting for permission */
//        if(typeof Notification != "undefined" && Notification){
//            if (Notification.permission === 'granted') {
//                if (showMessage) {
//                    /** Show message */
//                    const myNotification = new Notification(message.notification.title, {
//                        body: message.notification.body,
//                        icon:`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/portal-design/default_logo_short.png`,
//                        data: {
//                            key1: 'value1',
//                            key2: 'value2',
//                            // Add any custom data you want to associate with the notification
//                          },
//                    });
//                    myNotification.onclick = function(data){
//                        console.log("ll",data);
//                    }
//                }
//            }
//        }
//    };
//     useEffect(()=>{
//         requestForToken();
//         handleNotification({}, false);
//         onMessage(messaging,(data)=>{
//             console.log("firebase DAta ",data);
//             handleNotification(data);
//         })
//     },[])
    /** Firebase notifications <<<<< */

    return (
        <>
            <Header />
            <div>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Userlayout;