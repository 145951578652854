import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Loader } from "rsuite";
import { LeadView } from "../../user/services/lead.services";
import { formateDateWithMonth,formateDate, getFileNameFromURL } from "../../../utils/commonfunction";
import { Rating } from "react-simple-star-rating";
import ShowAnswer from "../../admin/shared/ShowAnswer";
import VideoPlayer from "../../user/shared/VideoPlayer";
import { useTranslation } from "react-i18next";

const ViewLead = () => {
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [previewImages, setPreviewImages] = useState([]);
  let [videoSourceType, setVideoSourceType] = useState("video");
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  const videoPlayerToggler = useRef();
  const { t } = useTranslation();



  useEffect(() => {
    LeadView(params.id)
      .then((data) => {
        setShowDefault(data && data.data ? data.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  const playVideo = (video,type) => {
    setVideoPlaySource(video);
    setVideoSourceType(type);
    videoPlayerToggler?.current?.click();
  };

  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="inner-space">
          <div className="container" style={{ minHeight: "60vh" }}>
            <nav className="breadcrumb text-center mb-4">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`/`}>{t("web_header_heading_home")}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {showdefault?.name}
                </li>
              </ol>
            </nav>
            <div className="p-0">
                    {Object.keys(showdefault).length > 0 ? (
                      <>
                        <div className="mb-4">
                          <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                            <div className="me-md-5">
                              <h4 className="tx-25 text-uppercase mb-3">
                              {t('web_lbl_general_info')}
                              </h4>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                               {t('web_lbl_title')}:
                                </label>
                                <p className="mb-0 text-capitalize">
                                  {showdefault?.title
                                    ? showdefault.title
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_salutation')}:
                                </label>
                                <p className="mb-0 text-capitalize">
                                  {showdefault?.gender
                                    ? showdefault.gender
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_first_name')}:
                                </label>
                                <p className="mb-0 text-capitalize">
                                  {showdefault?.first_name
                                    ? showdefault.first_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_middle_name')}:
                                </label>
                                <p className="mb-0 text-capitalize">
                                  {showdefault?.middle_name
                                    ? showdefault.middle_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_last_name')}:
                                </label>
                                <p className="mb-0 text-capitalize">
                                  {showdefault?.last_name
                                    ? showdefault.last_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_email')}:
                                </label>
                                <p className="mb-0">
                                  {showdefault?.email
                                    ? showdefault.email
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_phone_number')}:
                                </label>
                                {showdefault?.phone_number?.number
                                    ? showdefault?.phone_number?.number
                                    : "N/A"}
                              </div> 
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_created_at')}:
                                </label>
                                {showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4">
                            <h4 className="tx-15 text-uppercase mb-3">
                            {t('web_lbl_about_event')}
                            </h4>
                            <div className="pt-10 pb-0">
                            <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_event_title')}:
                                </label>
                                <p className="mb-0">
                                  {showdefault?.event_data?.title ?  showdefault.event_data.title : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                {t('web_lbl_event_dates')}:

                                </label>
                                <p className="mb-0">
                                  {showdefault?.event_data?.start_date
                                    ? formateDateWithMonth(
                                        showdefault?.event_data?.start_date
                                      )
                                    : "N/A"}{" "}
                                  -{" "}
                                  {showdefault?.event_data?.end_date
                                    ? formateDateWithMonth(
                                        showdefault?.event_data?.end_date
                                      )
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {showdefault?.questions_data && showdefault.questions_data.length > 0 && (
                          <div className="mb-4">
                            <div className="border rounded p-4">
                              <h4 className="tx-15 text-uppercase mb-3">{t('web_lbl_more_info')}</h4>
                              <div className="pt-10 pb-0">
                                {showdefault.questions_data.map((questionItem, index) => (
                                  <div key={index} className="mb-1">
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        {questionItem?.question_title || "N/A"}:
                                      </label>
                                      <div className="mb-0 text-capitalize">
                                      <ShowAnswer question={questionItem} setVideoPlaySource={playVideo} parentClass="user-view-registration"/>
                                     </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
          </div>
        </section>
      </div>
      <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#videoPlayerModal" ref={videoPlayerToggler}></button>
           <div className="modal fade" id="videoPlayerModal" data-bs-backdrop="false" data-bs-keyboard="false" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="videoPlayerModalLabel">{t('web_dynamic_question_playing')} : {getFileNameFromURL(videoPlaySource)}</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onMouseDown={(event)=>{
                              event.preventDefault();
                              return false;
                          }} onClick={() => {
                              setTimeout(() => {
                              setVideoPlaySource(null);
                              }, 300);
                          }}></button>
                      </div>
                      <div className="modal-body">
                          {videoPlaySource ?
                              <VideoPlayer src={videoPlaySource} type={videoSourceType}/>
                              : null
                          }
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
};

export default ViewLead;
