import React, { useState, useEffect, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import {
  getTranslatedGender,
  globalLoader,
  handleServerValidations,
  prepareQuestionsFormData,
  removeSpacesAndCharacters,
} from "../../../utils/commonfunction";
import {
    ATLEAST_ONE_CAPITAL_REGEX,
    ATLEAST_ONE_NUMBER_REGEX,
    ATLEAST_ONE_SMALL_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  GENDER,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  QUESTION_SET_CATEGORIES,
  SWAL_SETTINGS,
} from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SISUserAddApi } from "../services/common.services";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import DynamicQuestions from "../../user/shared/DynamicQuestions";
import { QuestionList } from "../../BackOffice/services/common.services";

const SISUserAdd = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [newshow, setNewShow] = useState("false");
  const [conformshow, setConformShow] = useState("false");
  const [previewimage, setPreviewImage] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const [questionFilesUploadPath] = useState("lm-users");
  const childRef = useRef();
 
  const [initialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    files:"",
    dialCodePhone: "",
    phone_number: "",
    dialCodePhoneWork: "",
    phone_number_work:"",
    middle_name:""
  });

  const [getGlobalData, setGlobalData] = useState({});
  const [dynamicValidations, setDynamicValidations] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);

  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

   let reducerData = useSelector((state) => state.manageLmBackOffice);

   useEffect(() => {
    if(reducerData){
      const formData = new FormData();
      formData.append("event_id", reducerData);
      formData.append("type", QUESTION_SET_CATEGORIES.BOOTH_ATTENDANTS_REGISTRATION);
      QuestionList(formData)
        .then((response) => {
          setQuestionList(response?.data || []);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [reducerData]);

   useEffect(() => {
    let obj = {};
    getGlobalData && getGlobalData?.settings && getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.map((item, index) => {
      if(item == 'gender'){
        obj.gender = Yup.string().oneOf(Object.values(getTranslatedGender()),t('web_lbl_invalid_err_salutation')).required(t('web_registration_err_salutation'))
      }
      else if(item == 'title'){
        obj.title = Yup.string().trim().required(t('web_lbl_err_title'));
      }
      else if(item == 'first_name'){
        obj.first_name = Yup.string().trim().required(t('web_registration_err_firstname'));
      }
      else if(item == 'middle_name'){
        obj.middle_name = Yup.string().trim().required(t('web_lbl_err_middle_name'));
      }
      else if(item == 'last_name'){
        obj.last_name = Yup.string().trim().required(t('web_registration_err_lastname'))
      }
      else if(item == 'password'){
        obj.password = Yup.string()
       .required(t('web_lbl_err_password'))
       .matches(ATLEAST_ONE_SMALL_REGEX, (t('web_lbl_err_password_lower')))
       .matches(ATLEAST_ONE_CAPITAL_REGEX, (t('web_lbl_err_password_capital')))
       .matches(ATLEAST_ONE_NUMBER_REGEX, (t('web_lbl_err_password_number')))
       .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, (t('web_lbl_err_password_special_character')))
       .min(PASSWORD_MIN_LENGTH, (t('web_lbl_err_password_8_character_long')))
       .max(PASSWORD_MAX_LENGTH, (t('web_lbl_err_password_more_than')))
         }
       else if(item == 'confirm_password'){
         obj.confirm_password =  Yup.string().required(t('web_lbl_err_confirm_password')).oneOf([Yup.ref("password"), null], (t('web_lbl_err_password_and_confirm')))
         }
      else if(item == 'phone_number'){
        obj.phone_number = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number'))
        .test("min-length", (t('web_registration_err_min_mobile_number')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number')), (value) => {
          if(value?.trim()?.length > 13){
            return false
          }else{
            return true
          }
        })
      }else if(item == 'phone_number_work'){
        obj.phone_number_work = Yup.string()
        .trim()
        .required(t('web_lbl_err_phone_number_work'))
        .test("min-length",  (t('web_registration_err_min_mobile_number_work')), (value) => {
          if(value?.trim()?.length < 4){
            return false
          }else{
            return true
          }
        })
        .test("max-length", (t('web_registration_err_max_mobile_number_work')), (value) => {
          if(value?.trim()?.length > 14){
            return false
          }else{
            return true
          }
        })
      }
      else if(item == 'files'){
        obj.files = Yup.mixed().required(t('web_lbl_err_image')).test(
          'fileType',
         (t('web_lbl_err_file_format')),
          (value) => {
            if (!value) {
              const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
              return supportedFormats.includes(value.type);
            } else {
              return true
            }
          }
        )
      }
    })
    setDynamicValidations(obj);
  },[getGlobalData]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().trim().required(t('web_registration_err_email')),
    ...dynamicValidations
  });

  const isFieldRequired = (fieldName) => {
    return getGlobalData && getGlobalData?.settings &&  getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.includes(fieldName);};

  const handleshow = (event) => {
    if (event === "newpwd") {
      setNewShow(!newshow);
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formResult = childRef?.current?.submit();
            if (!formResult?.isValid) {
            return;
          }
          globalLoader(true);
          let formData = new FormData();
          if (reducerData) {
            formData.append("event_id", reducerData);
          }
        formData.append('gender', values.gender);
        formData.append('title', values.title);
        formData.append('profile_image', values.files);
        formData.append('first_name', values.first_name);
        formData.append('middle_name', values.middle_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('confirm_password', values.confirm_password);
        formData.append('phone_number', JSON.stringify({code : values.dialCodePhone, number: removeSpacesAndCharacters(values.phone_number) }));
        formData.append('phone_number_work', JSON.stringify({code : values.dialCodePhoneWork, number: removeSpacesAndCharacters(values.phone_number_work)}));
        let result = childRef?.current?.getValues();
        let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
          SISUserAddApi(finalForm).then((response) => {
              setSubmitting(false);
              globalLoader(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  navigate(`/info-counter/sis-user/list/1`);
                }, 1000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            }).catch((error) => {
              console.log("error ====> ", error);
              globalLoader(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <div className="wraper-inner cpt bg-grey">
            <section className="inner-space pt-0">
              <div className="w-100 px-3">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-9 m-auto ">
                      <h3 className="inner-title mb-0 mt-3">{t('web_lbl_lead_add')}</h3>
                      <div className="dash-box-shadow space-b">
                        <div className="row add-coustomer">
                         <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_title")}{isFieldRequired('title') && <small>*</small>}
                              </label>
                              <div className="input-group">
                                <input
                                  className="form-control"
                                  placeholder={t('web_lbl_enter_your_title')}
                                  name="title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.title}
                                />
                                  </div>
                                <span className="text-danger d-flex text-left">
                                {errors.title &&
                                  touched.title &&
                                  errors.title}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_salutation")}
                                {isFieldRequired('gender') && <small>*</small>}
                              </label>
                              <div className="select-down-arrow">
                                <Field
                                  as="select"
                                  id="gender"
                                  onChange={handleChange}
                                  name={"gender"}
                                  className="form-control"
                                >
                                  <option value="" label={t('web_lbl_select_salutation')}>
                                    Select{" "}
                                  </option>
                                  {getTranslatedGender() &&
                                    Object.values(getTranslatedGender()).map((option, i) => {
                                      return (
                                        <option key={i} value={option}>
                                          {option}
                                        </option>
                                      );
                                    })}
                                </Field>
                              </div>
                              <span className="text-danger d-flex text-left">
                                {errors.gender &&
                                  touched.gender &&
                                  errors.gender}
                              </span>
                            </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_first_name")}{isFieldRequired('first_name') && <small>*</small>}
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_first_name')}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.first_name &&
                                  touched.first_name &&
                                  errors.first_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_middle_name")}{isFieldRequired('middle_name') && <small>*</small>}
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_middle_name')}
                                name="middle_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.middle_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.middle_name &&
                                  touched.middle_name &&
                                  errors.middle_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_last_name")}{isFieldRequired('last_name') && <small>*</small>}
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_last_name')}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.last_name &&
                                  touched.last_name &&
                                  errors.last_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_email")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_email')}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.email && touched.email && errors.email}
                              </span>
                            </div>
                          </div>
                          <div className='form-group  mb-4 col-md-6'>
                          <label className="form-label">
                          {t("web_lbl_password")}{isFieldRequired('password') && <small>*</small>}
                            </label>
                      <div className="input-group cp">
                      <input
                        name='password'
                        id='password'
                        type={!newshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        className='form-control' 
                        placeholder={t('web_lbl_enter_your_password')}
                        />
                          <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("newpwd");
                              }}
                            >
                              {!newshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                            </div>
                            <span className="text-danger d-flex text-left">
                                {errors.password && touched.password && errors.password}
                              </span>
                    </div>
                    <div className="form-group  mb-4 col-md-6">
                    <label className="form-label">
                    {t("web_lbl_confirm_password")}{isFieldRequired('confirm_password') && <small>*</small>}
                            </label>
                      <div className="input-group cp">
                      <input
                        name='confirm_password'
                        id='confirm_password'
                        type={!conformshow === true ? "text" : "password"}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.confirm_password}
                        className='form-control'
                        placeholder={t('web_lbl_enter_your_confirm_password')}
                      />
                        <span
                              className="input-group-text"
                              onClick={() => {
                                handleshow("confirepwd");
                              }}
                            >
                              {!conformshow === true ? (
                                <i className="far fa-eye-slash"></i>
                              ) : (
                                <i className="far fa-eye"></i>
                              )}
                            </span>
                          </div>
                          <span className="text-danger d-flex text-left">
                                {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                              </span>
                    </div>
                    <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_phone_number')}{isFieldRequired('phone_number') && <small>*</small>}</label>

                     <div className="input-group">
                    <PhoneInput 
                      country={"de"}
                      value={values.dialCodePhone + values.phone_number} 
                      placeholder={t('web_lbl_enter_your_phone_number')} 
                      className="form-control"
                      buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                      containerStyle={{padding: 0}} 
                      inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                      onChange={(value, data, event, formattedValue) => {
                        setFieldValue("dialCodePhone", formattedValue.substring(0, formattedValue.indexOf(" ")))
                        setFieldValue(`phone_number`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                      }} 
                      />
                    </div> 
                    <span className="text-danger d-flex text-left">
                                {errors.phone_number && touched.phone_number && errors.phone_number}
                              </span>
                  </div>
                  <div className="col-md-6 mb-3">
                  <label className="form-label">{t('web_lbl_phone_number_work')}{isFieldRequired('phone_number_work') && <small>*</small>}</label>

                    <div className="input-group">
                      <PhoneInput 
                        country={"de"}
                        buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,borderTopLeftRadius:'10px', borderBottomLeftRadius:'10px'}}
                        containerStyle={{padding: 0}}
                        inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                        name="phone_number_work"
                        placeholder={t('web_lbl_enter_your_phone_number_work')} 
                        className="form-control"
                        value={values.dialCodePhoneWork + values.phone_number_work} 
                        forceDialCode={true} 
                        onChange={(value, data, event, formattedValue,) => {
                          setFieldValue("dialCodePhoneWork", formattedValue.substring(0, formattedValue.indexOf(" ")))
                          
                          setFieldValue(`phone_number_work`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                        }} 
                      />
                        
                    </div>
                    <span className="text-danger d-flex text-left">
                                {errors.phone_number_work && touched.phone_number_work && errors.phone_number_work}
                              </span>
                  </div>
                    <div className='form-group  mb-4 col-md-6'>
                    <label className="form-label">
                    {t('web_lbl_info_counter_profile')}{isFieldRequired('files') && <small>*</small>}
                            </label>
                      <input className='form-control imgInput' id="files" name="files" accept="image/*" type="file" onChange={(event) => {
                          setFieldValue("files", event.currentTarget.files[0] || "");
                          if (event.currentTarget.files.length === 1) {
                              setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]));
                          } else {
                              setPreviewImage("");
                          }
                        }} 
                      />
                      <span className="text-danger d-flex text-left">
                                {errors.files && touched.files && errors.files}
                              </span>
                    {
                      previewimage ?
                        <div className='mb-4 mt-2'>
                          <img src={previewimage} style={{ height: "100px" }} alt={""} />
                        </div> : ""
                    }
                    </div>
                    {questionList.length > 0 ? (
                            <h4 className="mb-3">{t('web_lbl_more_info')}</h4>
                          ) : null}
                          <div className="form-group mb-4 col-md-12">
                            {questionList.length > 0
                              ?
                              <div>
                              <DynamicQuestions ref={childRef} questions={questionList} showCount={false}
                              questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath}/>
                              </div>
                               : null} 
                          </div>
                          <div className="btn-group mt-4 col-md-12">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              onClick={() => {
                                childRef?.current?.submit();
                              }}
                            >
                              {t('web_lbl_save')}
                            </button>
                            <button className="btn btn-dark ms-2" type='button'  onClick={() => navigate(-1)}>
                            {t('web_lbl_back')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </Formik>
    </>
  );
};
export default SISUserAdd;
