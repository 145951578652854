import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import * as eventServices from "../../services/events.services";
import {  STAFF_ROLE } from "../../../../utils/Constants";
import {
  SpecialCharacter,
  decideRole,
  formateDate,
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import $ from "jquery";


function LMRegistration() {
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [list, setList] = useState([]);
  const [statsupdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [secondModal] = useState(false);
  const [roleOptions] = useState(
    Object.keys(STAFF_ROLE).filter(
      (key) =>
        key !== "USER" &&
        key !== "ADMIN" &&
        key !== "STAFF" &&
        key !== "SUB_ADMIN"
    )
  );

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("type_search", typeSearch);
      formData.append("event_id", params.id);
      eventServices
        .LMUserRegistrationList(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    secondModal,
    itemperpage,
    params.pgno,
    params.id,
    typeSearch,
  ]);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          // setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  // sorting function start
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function start
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setTypeSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#role")[0].selectedIndex = 0;
  };

  const handleViewDetails = (LMUserRegistrationId, event_id) => {
    const to = `/admin/event-management/events/view/lm-user-registration-details/${LMUserRegistrationId}`;
    const state = { event_id };
    navigate(to, { state });
  };


  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">LM User Registrations</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalsearch}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="me-3">
            <CustomRangepicker
              GetDateRange={(e) => {
                prepareSearch("createdAt", e);
              }}
              resetdate={resetdate}
            />
          </div>
          <div className="me-3">
              <div className="form-group mb-0 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" 
                  onChange={(e) => {
                  setTypeSearch(e.target.value);
                }} 
                id="role" 
                style={{ width: "180px" }}>
                    <option value="">
                      Type
                    </option>
                    {roleOptions && roleOptions.length > 0 && roleOptions.map((option, i) => {
                      return (
                        <option key={i} value={STAFF_ROLE[option]}>
                           {SpecialCharacter(option)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
          {/* {allUsersExportFlag ? (
                <>
                  <button
                    className="btn ripple btn-main-primary signbtn mr-2 ms-2"
                    disabled={datalength === 0}
                    onClick={exportAllUsers}
                  >
                    Export Report
                  </button>
                </>
              ) : (
                <button
                  className="btn ripple btn-main-primary signbtn mr-2 ms-2"
                  disabled={datalength === 0}
                  onClick={exportAllUsers}
                >
                  Export Report
                </button>
              )} */}
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>
                <div className="sorting_column">
                  <span>Created Date</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="createdAt"
                  />
                </div>
              </th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {list.length ? (
                  list.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row?.user_data?.name ? row?.user_data?.name : "N/A"}</td>
                        <td>{row?.user_data?.email ? row?.user_data?.email : "N/A"}</td>
                        <td>{decideRole(row?.user_data?.role)}</td>
                        <td>
                          {formateDate(row?.createdAt)}
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() =>
                                handleViewDetails(row?._id, params?.id)
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/event-lm-user-registration-list/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default LMRegistration;
