import React, { useState, useEffect, useRef } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { LeadAdd } from "../../user/services/lead.services";
import Swal from "sweetalert2";
import {
  getTranslatedGender,
  globalLoader,
  handleServerValidations,
  prepareQuestionsFormData,
  removeSpacesAndCharacters,
} from "../../../utils/commonfunction";
import {
  GENDER,
  QUESTION_SET_CATEGORIES,
  SWAL_SETTINGS,
} from "../../../utils/Constants";
import PhoneInput from "react-phone-input-2";
import { QuestionList } from "../../BackOffice/services/common.services";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicQuestions from "../../user/shared/DynamicQuestions";
import { useTranslation } from "react-i18next";


const LeadCoordinatorLeadAdd = () => {
  const childRef = useRef();
  const location = useLocation();
  const formikRef = useRef(null);
  const [questionList, setQuestionList] = useState([]);
  const navigate = useNavigate();
  const [questionFilesUploadPath] = useState("lm-users");
  const { t } = useTranslation();
  let reducerData = useSelector(state => state.manageLmBackOffice);


  const [initialValues] = useState({
    title: "",
    gender: "",
    first_name: location?.state?.first_name? location?.state?.first_name : "",
    middle_name: location?.state?.middle_name? location?.state?.middle_name : "",
    last_name: location?.state?.last_name? location?.state?.last_name : "",
    email:location?.state?.email? location?.state?.email : "",
    phone_number:location?.state?.phone_number && location?.state?.phone_number?.number? location?.state?.phone_number?.number : "",
    countryCodePhone: "",
    dialCodePhone: location?.state?.phone_number && location?.state?.phone_number?.code ? location?.state?.phone_number?.code : "",
    is_draft: false,
    acceptance: false,
  });


  useEffect(() => {
    const formData = new FormData();
    if (reducerData) {
      formData.append("event_id", reducerData);
    }
    formData.append("type", QUESTION_SET_CATEGORIES.TRADE_FAIR_QUESTIONNAIRE);
    QuestionList(formData)
      .then((response) => {
        setQuestionList(response?.data || []);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, [reducerData]);

  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .oneOf(Object.values(getTranslatedGender()), t('web_lbl_invalid_err_salutation'))
      .required("Salutation is required"),
    first_name: Yup.string()
      .required(t('web_registration_err_firstname'))
      .test("no-spaces",  (t('web_registration_err_firstname')), (value) => value.trim()),
    middle_name: Yup.string(),
    last_name: Yup.string()
      .required(t('web_registration_err_lastname'))
      .test("no-spaces", (t('web_registration_err_lastname')), (value) => value.trim()),
    email: Yup.string()
      .email(t('web_lbl_err_invalid_email'))
      .required(t('web_registration_err_email')),
    phone_number: Yup.string()
      .trim()
      .required(t('web_lbl_err_phone_number'))
      .test(
        "min-length",
        (t('web_registration_err_min_mobile_number')),
        (value) => value?.trim()?.length >= 4
      )
      .test(
        "max-length",
        (t('web_registration_err_max_mobile_number')),
        (value) => value?.trim()?.length <= 13
      ),
    acceptance: Yup.string().test(
      "is_draft",
      (t('web_registration_err_required')),
      (value) => {
        return value === "true" || value === true;
      }
    ),
  });

  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formResult = childRef?.current?.submit();
          if (values?.is_draft === false && !formResult?.isValid) {
            return;
          }
          globalLoader(true);
          let formData = new FormData();
          if (reducerData) {
            formData.append("event_id", reducerData);
          }
          formData.append("title", values.title);
          formData.append("first_name", values.first_name);
          formData.append("middle_name", values.middle_name);
          formData.append("last_name", values.last_name);
          formData.append("gender", values.gender);
          formData.append("email", values.email);
          formData.append("phone_number", JSON.stringify({ code: values.dialCodePhone, number: removeSpacesAndCharacters(values.phone_number) }));
          formData.append("is_draft", values.is_draft);
          formData.append("acceptance", values?.acceptance);
          let result = childRef?.current?.getValues();
          let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
          LeadAdd(finalForm)
            .then((response) => {
              setSubmitting(false);
              globalLoader(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  navigate(`/lead-coordinator/leads/list/1`);
                }, 1000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
              globalLoader(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <div className="wraper-inner cpt bg-grey">
            <section className="inner-space pt-0">
              <div className="w-100 px-3">
                <form
                  onSubmit={async (e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="row">
                    <div className="col-md-9 m-auto ">
                      <h3 className="inner-title mb-0">{t('web_lbl_add_lead_manually')}</h3>
                      <div className="dash-box-shadow space-b">
                        <div className="row add-coustomer">
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">{t("web_lbl_title")}</label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_title')}
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                              />
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_salutation")}
                              <small>*</small>
                            </label>
                            <div className="select-down-arrow">
                              <Field
                                as="select"
                                id="gender"
                                onChange={handleChange}
                                name={"gender"}
                                className="form-control"
                              >
                                <option value="" label={t('web_lbl_select_salutation')}>
                                  Select{" "}
                                </option>
                                {getTranslatedGender() &&
                                  Object.values(getTranslatedGender()).map((option, i) => {
                                    return (
                                      <option key={i} value={option}>
                                        {option}
                                      </option>
                                    );
                                  })}
                              </Field>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.gender && touched.gender && errors.gender}
                            </span>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_first_name")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_first_name')}
                                name="first_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.first_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.first_name &&
                                  touched.first_name &&
                                  errors.first_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">{t("web_lbl_middle_name")}</label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_middle_name')}
                                name="middle_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.middle_name}
                              />
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_last_name")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_last_name')}
                                name="last_name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.last_name}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.last_name &&
                                  touched.last_name &&
                                  errors.last_name}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_email")}<small>*</small>
                            </label>
                            <div>
                              <input
                                className="form-control"
                                placeholder={t('web_lbl_enter_your_email')}
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                              />
                              <span className="text-danger d-flex text-left">
                                {errors.email && touched.email && errors.email}
                              </span>
                            </div>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label className="form-label">
                            {t("web_lbl_mobile_number")}<small>*</small>
                            </label>
                            <div className="">
                              <PhoneInput
                                country={"de"}
                                value={
                                  values.dialCodePhone + values.phone_number
                                }
                                placeholder={t('web_lbl_enter_your_phone_number')}
                                className="form-control"
                                buttonStyle={{
                                  background: "#fff",
                                  paddingLeft: 5,
                                  paddingRight: 5,
                                  borderTop: 0,
                                  borderBottom: 0,
                                  borderLeft: 0,
                                  borderTopLeftRadius: "10px",
                                  borderBottomLeftRadius: "10px",
                                }}
                                containerStyle={{ padding: 0 }}
                                inputStyle={{
                                  width: "-webkit-fill-available",
                                  height: "100%",
                                  marginLeft: 10,
                                  border: 0,
                                }}
                                onChange={(
                                  value,
                                  data,
                                  event,
                                  formattedValue
                                ) => {
                                  setFieldValue(
                                    "dialCodePhone",
                                    formattedValue.substring(
                                      0,
                                      formattedValue.indexOf(" ")
                                    )
                                  );
                                  setFieldValue(
                                    `phone_number`,
                                    formattedValue.substring(
                                      formattedValue.indexOf(" ") + 1
                                    )
                                  );
                                }}
                              />
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.phone_number &&
                                touched.phone_number &&
                                errors.phone_number}
                            </span>
                          </div>
                          {questionList.length > 0 ? (
                            <h4 className="mb-3">{t('web_lbl_more_info')}</h4>
                          ) : null}
                          <div className="form-group mb-4 col-md-12">
                            {questionList.length > 0
                              ?
                              <div>
                              <DynamicQuestions ref={childRef} questions={questionList} showCount={false}
                              questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath}/>
                              </div>
                              : null}
                          </div>

                          <div className="form-group col-md-12">
                            <div className="form-check black-check ">
                              <input
                                name="acceptance"
                                className="form-check-input"
                                type="checkbox"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.acceptance}
                                id="acceptance"
                              ></input>
                              <label
                                htmlFor="acceptance"
                                className="form-label"
                              >
                                 {t('web_lbl_terms_conditions')}<small>*</small>
                              </label>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.acceptance &&
                                touched.acceptance &&
                                errors.acceptance}
                            </span>
                          </div>
                          <div className="btn-group mt-4 col-md-12">
                            <button className="btn btn-primary" type="submit" onClick={() => {
                              childRef?.current?.submit();
                              setFieldValue("is_draft", false);
                            }}>
                             {t('web_lbl_save')}
                            </button>
                            <button
                              className="btn btn-warning ms-3"
                              type="submit"
                              onClick={() => {
                                setFieldValue("is_draft", true);
                              }}
                            >
                            {t('web_lbl_save_as_draft')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          </div>
        )}
      </Formik>
    </>
  );
};
export default LeadCoordinatorLeadAdd;
