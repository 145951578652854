import axiosInstance from "../../../utils/axios";

const path = "web/sis-user"

export const RoomList = async (values) => {
    return await axiosInstance().post(`${path}/rooms/list`, values);
}
export const RoomBooking = async (values) => {
    return await axiosInstance().post(`${path}/rooms/bookings/add`, values);
}
export const RoomDetails = async (id) => {
    return await axiosInstance().get(`${path}/rooms/details?o_id=${id}`);
}
export const QuestionList = async (values) => {
    return await axiosInstance().post(`${path}/question-list`, values)
}
export const GetUserList = async (values) => {
    return await axiosInstance().post(`${path}/user-list`, values)
}
export const EditRoomBooking = async (values) => {
    return await axiosInstance().post(`${path}/rooms/bookings/update`, values);
}

export const ReadUsersExcel = async (values) => {
    return await axiosInstance().post(`${path}/user-excel`, values);
}