import axiosInstance from "../../../utils/axios";

const path = "admin/content-blocks"

export const ContentBlocksList = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const AddContentBlock = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const ContentBlocksEdit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const ContentBlocksDelete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const ContentBlocksDetails = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const ContentBlocksStatus = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const ContentBlocksOptions = async (values) => {
    return await axiosInstance().post(`${path}/options`, values)
}