import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { base64ToFile, isApp, removeSpecialCharacter } from "../../../utils/commonfunction";
import { ScanImage } from "../services/common.services";
import { useNavigate } from "react-router-dom";
import Croppie from 'croppie';
import 'croppie/croppie.css';
import { useFormik } from 'formik';
import { GetVoiceMemoToken } from "../../BackOffice/services/common.services";
import { SOCKET_CLIENT } from "../../../utils/socket";
import { useTranslation } from "react-i18next";



const LeadCoordinatorScanLead = () => {
  const navigate = useNavigate();
  const scanInput = useRef();
  const cropRef = useRef(null);
  const fromGalleryInput = useRef();
  const inputResetBTN = useRef();
  const [isScanning, setIsScanning] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hideCropper, setHideCropper] = useState(true);
  const [cropInstance, setCropInstance] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [scanFinished, setScanFinished] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if(cropRef.current && selectedFile){
        const neCropInstance = new Croppie(cropRef.current, {
            viewport: { width: 296, height: 200, type: 'square' },
            boundary: { width: 300, height: 300 },
            showZoomer: true,
            enableResize: true,
            mouseWheelZoom:true
        });
        neCropInstance.bind({
            url: URL.createObjectURL(selectedFile),
            zoom:0
        });
        setCropInstance(neCropInstance);

        return () => {
            // cropInstance.destroy();
        };
    }
}, [selectedFile]);

function formatVCardData(data){
  let newData = {
    email_addresses:data && data.email ? data.email:[],
    phone_numbers:data && data.phone ? data.phone:[]
  };
  let obj = {
    name: data?.fullName ? data?.fullName : null,
    first_name: data?.firstName ? data?.firstName : '',
    middle_name: '',
    last_name: data?.lastName ? data?.lastName : '',
    email: data && data.email && data.email[0] ? data.email[0] : '',
    phone_number: data && data.phone && data.phone[0] ? data.phone[0] : '',
  };
  setImageData(newData);
  dataForm.setValues(obj);
  setIsScanning(false);
  setScanFinished(true);
}

useEffect(() => {
  /**Listening for v-card scan result */
    SOCKET_CLIENT.on('shareASCListener', (data) => {
        console.log('shareASCListener',data);
        if(data && data.action === "scan-v-card"){
          try{
            let jsonData = JSON.parse(data.data);
            formatVCardData(jsonData);
          }catch(err){
            console.log("Error in parsing info",err);
          }
        }
    });
}, []);

  function handleFileSelect(e) {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setHideCropper(false);
    }
  }

  const getCroppedImage = () => {
    setHideCropper(true);
    if (cropInstance) {
      cropInstance.result({
        type: 'base64',
        size: 'viewport',
      }).then((data) => {
        setCroppedImg(data);
      }).catch((error) => {
        console.error('Error getting cropped image:', error);
      });
    }
  };

  function handleScanning() {
    setIsScanning(true);
    const formData = new FormData();
    formData.append('image', base64ToFile(croppedImg,selectedFile.name));
    ScanImage(formData).then((response) => {
      setIsScanning(false);
      setScanFinished(true);
      if (response?.success) {
        setImageData(response?.data);
        setSelectedFile(null);
        let name = response?.data?.name ? response?.data?.name : null;
        let nameArray = name.split(' ');
        let obj = {
          name: response?.data?.name ? response?.data?.name : null,
          first_name: nameArray && nameArray[0] ? nameArray[0] : '',
          middle_name: '',
          last_name: nameArray && nameArray[1] ? nameArray[1] : '',
          email: response?.data?.email_addresses && response?.data?.email_addresses?.length > 0 ? response?.data?.email_addresses[0] : '',
          phone_number: response?.data?.phone_numbers && response?.data?.phone_numbers?.length > 0 ? response?.data?.phone_numbers[0] : '',
        };
        /** Handling middle name and last name */
        if(nameArray && nameArray.length === 3){
          obj.middle_name = nameArray[1];
          obj.last_name = nameArray[2];
        }
        dataForm.setValues(obj);
        console.log('values => ',obj);
      }
    }).catch((err) => {
      console.log("err", err);
    });
  }

  const dataForm = useFormik({
    initialValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    },
    onSubmit: values => {
      // /** Handling phone number */
      let phoneNumberArray = removeSpecialCharacter(values.phone_number).split(' ');
      let phoneCode = "";
      let phoneNumber = "";
      let response = {
        ...values
      };
      if(phoneNumberArray.length){
        if(phoneNumberArray[0].length > 3){
          phoneCode = "+49"; /** Country phone code of DE */
          phoneNumber = phoneNumberArray.join("");
        }else{
          phoneCode = phoneNumberArray[0];
          phoneNumber = phoneNumberArray.slice(1, phoneNumberArray.length).join("");  
        }
        response['phone_number'] = { code: phoneCode, number: phoneNumber };
      }
      console.log("response =>",response)
      navigate('/lead-coordinator/leads/add', { state: response });
    },
  });
  function resetPrevious(){
    inputResetBTN?.current?.click();
    setIsScanning(false);
    setSelectedFile(null);
    setHideCropper(true);
    setCroppedImg(null);
    setImageData(null);
    setScanFinished(false);
  }

  function ScanVCard(){
    resetPrevious(); 
    GetVoiceMemoToken({slug:'scan-v-card'}).then((response)=>{
      if(response.success){
        const appData = {action:'scan-VCard', data:{uploadToken:response?.data?.token}};
        window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
        let joinRequest = {
          room:response?.data?.token
        };
        SOCKET_CLIENT.emit('shareASCJoin', joinRequest, (error) => {
          if (error) {
            alert(error);
          }
        })
      }else{
        Swal.fire({
          icon: 'error',
          title: response?.message,
        });
      }
    }).catch((err)=>{
      Swal.fire({
        icon: 'error',
        title: err?.message,
      });
    });
  }


  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="inner-space pt-0">
          <div className="w-100 px-3">
            <div className="row">
              <div className="col-md-9 m-auto ">
                <h3 className="inner-title mb-0">{t('web_lbl_scan_lead')}</h3>
                <div className="dash-box-shadow space-b">
                  <div className="row add-coustomer">
                    <div className="col-12">
                      <div className="d-block mb-3">
                        <form onSubmit={(e) => { e.preventDefault() }}>
                          <button className="btn btn-primary me-2" type="button" onClick={() => { 
                            const appData = {action:'capture-business-card', data:{}};
                            window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
                            resetPrevious(); scanInput?.current?.click() 
                            }} disabled={isScanning}>
                            {/* Capture Business Card */}
                            {t('web_lbl_capture_business_card')}
                            <input className="d-none" type="file" ref={scanInput} onChange={(e) => {
                              handleFileSelect(e);
                            }} accept='image/*' capture="environment" />
                          </button>
                          <button className="btn btn-primary me-2 mt-2 mt-md-0" type="button" onClick={() => { resetPrevious(); fromGalleryInput?.current?.click() }} disabled={isScanning}>
                          {t('web_lbl_upload_business_card')}

                            <input className="d-none" type="file" ref={fromGalleryInput} onChange={(e) => {
                              handleFileSelect(e);
                            }} accept='image/*' />
                          </button>
                          {
                            isApp() ?
                              <button className="btn btn-primary me-2 mt-2 mt-md-0" type="button" onClick={ScanVCard} disabled={isScanning}>
                                {/* Scan V-Card */}
                          {t('web_lbl_scan_v_card')}

                              </button> : null
                          }
                          <button type="reset" className="d-none" ref={inputResetBTN}></button>
                        </form>
                      </div>

                      {selectedFile && !hideCropper ?
                        <div className="cropper-container">
                          <ul className="button-list">
                            <li className="me-2">
                              <button className="m_btn danger" onClick={() => {
                                inputResetBTN?.current.click();
                                setSelectedFile(null);
                                setHideCropper(true);
                              }}>
                                <i className="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </li>
                            <li>
                              <button className="m_btn success" onClick={getCroppedImage}>
                                <i className="fa fa-check" aria-hidden="true"></i>
                              </button>
                            </li>
                          </ul>
                          {/* Cropper here */}
                          <div ref={cropRef}></div>
                        </div>
                        : null}
                      <div>
                        {selectedFile && hideCropper && !scanFinished? (
                          <div className="cropper-container">
                            <div style={{ position: 'relative' }}>
                              <img className="border" src={croppedImg} />
                              {isScanning && (
                                <div className="center h-100">
                                  <div className="square h-100 position-relative">
                                    <div className="scan scan-animation"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <button className="btn btn-primary d-flex m-auto mt-4" type="button" onClick={handleScanning} disabled={isScanning}>
                            {t('web_lbl_scan')}
                            </button>
                          </div>
                        ) : null}

                        {scanFinished?
                          <>
                            {imageData && Object.keys(imageData).length?
                              <div>
                                <form onSubmit={dataForm.handleSubmit}>
                                  <div className="row">
                                    {
                                      dataForm.values.first_name?
                                        <div className="col-12 mb-2">
                                          <label htmlFor="first_name">{t('web_lbl_first_name')} :&nbsp;</label>
                                          <input className="border-0 outline-0" id="first_name" name="first_name" type="text" readOnly={true} onChange={dataForm.handleChange}
                                            value={dataForm.values.first_name}
                                          />
                                        </div>
                                      :null
                                    }
                                    {
                                      dataForm.values.middle_name?
                                        <div className="col-12 mb-2">
                                          <label htmlFor="middle_name">{t('web_lbl_middle_name')} :&nbsp;</label>
                                          <input className="border-0 outline-0" id="middle_name" name="middle_name" type="text" readOnly={true} onChange={dataForm.handleChange}
                                            value={dataForm.values.middle_name}
                                          />
                                        </div>
                                      :null
                                    }
                                    {
                                      dataForm.values.last_name?
                                        <div className="col-12 mb-2">
                                          <label htmlFor="last_name">{t('web_lbl_last_name')} :&nbsp;</label>
                                          <input className="border-0 outline-0" id="last_name" name="last_name" type="text" readOnly={true} onChange={dataForm.handleChange}
                                            value={dataForm.values.last_name}
                                          />
                                        </div>
                                      :null
                                    }
                                    {
                                      dataForm.values.email?
                                        <div className="col-12 mb-2">
                                          <div className="d-flex">
                                          <label htmlFor="email" className="no-wrap">{t('web_lbl_email')} :&nbsp;</label>
                                          <div className="d-flex flex-wrap">
                                            {imageData?.email_addresses?.map((record, i)=>{
                                              return (
                                                <div className="custom-checks cp mb-1 me-1" key={i}>
                                                  <input className="custom-checks-input" id={"email"+i} name="email" type="radio" readOnly={true} onChange={dataForm.handleChange}
                                            checked={dataForm.values.email === record} value={record}/>
                                                  <label className="cp" htmlFor={"email"+i}>
                                                    {record}
                                                  </label>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          </div>
                                        </div>
                                      :null
                                    }
                                    {
                                      dataForm.values.phone_number?
                                        <div className="col-12 mb-2">
                                          <div className="d-flex">
                                          <label htmlFor="phone_number" className="no-wrap">{t('web_lbl_phone_number')} :&nbsp;</label>
                                          <div className="d-flex flex-wrap">
                                            {imageData?.phone_numbers?.map((record, i)=>{
                                              return (
                                                <div className="custom-checks cp mb-1 me-1" key={i}>
                                                  <input className="custom-checks-input" id={"phone_number" + i} name="phone_number" type="radio" readOnly={true} onChange={dataForm.handleChange}
                                                    checked={dataForm.values.phone_number === record} value={record} />
                                                  <label className="cp" htmlFor={"phone_number" + i}>
                                                    {record}
                                                  </label>
                                                </div>
                                              )
                                            })}
                                          </div>
                                          </div>
                                        </div>
                                      :null
                                    }
                                  </div>
                                  <button className="btn btn-primary mt-4" type="submit">{t('web_lbl_user_info')}</button>
                                </form>
                              </div>
                              :<div>
                                {/* <p>Unable to read data. Please try again.</p> */}
                                <p>{t('web_lbl_read_data')}</p>
                              </div>
                            }
                          </>
                          :null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};
export default LeadCoordinatorScanLead;
