import React,{useEffect,useState} from 'react'
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { CMS_PAGES_POSITIONS } from '../../../utils/Constants';
import toHtml from 'html-react-parser';
import * as globalSettingsService from "../../user/services/common.services";
import { useTranslation } from 'react-i18next';
import { getLocalKey } from '../../../utils/commonfunction';
import * as commonService from "../../user/services/common.services";


const SISUserFooter = () => {

    const [companyMenuData, setCompanyMenuData] = useState([]);
    const [impMenuData, setImpMenuData] = useState([]);
    const leadData = useSelector((state) => state.designportal);
    const globalDetails = useSelector((state) => state.globaldetail);
    const [surveysData, setSurveysData] = useState(null);
    const loc = useLocation();
    const { t } = useTranslation();
   const [getuserkey, setGetUserkey] = useState();

   const [getGlobalData, setGlobalData] = useState({});
   const myGlobalData = useSelector((state) => state.globalData);
   useEffect(() => {
       if (myGlobalData && myGlobalData?.data) {
           const settingsData = JSON.parse(myGlobalData?.data);
           setGlobalData(settingsData);
       }
   }, [myGlobalData]);


    
    useEffect(() => {
        commonService.menuOptions({position:CMS_PAGES_POSITIONS.FOOTER}).then((response) => {
            let companyMenu = [];
            let linksMenu = [];
            response?.data?.forEach(element => {
                if(element.footer_menu_name === 'company'){
                    companyMenu.push(element);
                }else{
                    linksMenu.push(element);
                }
            })
            setCompanyMenuData(companyMenu);
            setImpMenuData(linksMenu);
        }).catch((error) => {
            console.log("error",error);
        })
    },[loc])

    useEffect(() => {
        globalSettingsService.globalSetting()
          .then((response) => {
            setSurveysData(response?.data.surveys);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }, []);

      useEffect(() => {
        try {
            const getuserkey = JSON.parse(getLocalKey("user"));
            setGetUserkey(getuserkey);
        } catch (err) {
            setGetUserkey(null)
        }
    }, [loc])

    return (
        <>
            <footer className="bg-black">
                <div className="footer-box">
                    <div className="container">
                    <div className="row">
                        <div className="col-md-4 footer-content me-auto">
                        <div className="footer-top-left">
                            <figure className="mb-4">
                            {/* <a href="index.php">
                                <img src={getGlobalData?.portal_design?.reverse_logo} alt="reverse logo" />
                            </a> */}
                            <img src={getGlobalData?.portal_design?.reverse_logo} alt="reverse logo" />
                            </figure>
                            <p className="pe-5">{getGlobalData?.settings?.footer_text}</p>
                        </div>
                        </div>
                        <div className="col-md-2 footer-content">
                        <h3>{t('web_footer_company')}</h3>
                        <ul className="footer-link">
                            {companyMenuData && companyMenuData.map((item,index) => {
                                return(
                                    <li key={index}>
                                        <Link to={`/sis-user/org-info/${item?.slug}`}>{item?.title}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                        </div>
                        {/* <div className="col-md-2 footer-content">
                        <h3>{t('web_footer_links')}</h3>
                        <ul className="footer-link">
                            {impMenuData && impMenuData.map((item,index) => {
                                return(
                                    <li key={index}>
                                        <Link to={`/sis-user/org-info/${item?.slug}`}>{item?.name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                        </div> */}
                        <div className="col-md-3 footer-content ps-5">
                        {getGlobalData?.settings?.social_media ?    
                            <>
                                <h3>{t('web_footer_connect')}</h3>
                                <ul className="social-link">
                                {getGlobalData?.settings?.social_media.map((item, index) => (
                                    <li key={index}>
                                    <a href={item?.url} target="_blank" rel="noreferrer">
                                    {toHtml(item?.icon)}
                                    </a>
                                    </li>
                                ))
                                }
                                </ul>
                            </> : <></>
                        }
                        </div>
                    </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                    <div className="text-center">
                        <p>{getGlobalData?.settings?.copy_right_text}</p>
                    </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default SISUserFooter