import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as emailTemplateService from '../../../services/email.template';
import CustomPagination from '../../../common/custompagination';
import Sorting from '../../../common/sorting';
import Loader from '../../../common/loader';
import Breadcrums from "../../../common/breadcrumbs";
import $ from 'jquery';
import { SWAL_SETTINGS, showFilterlist, EMAIL_TEMPLATE_CATEGORY } from '../../../../../utils/Constants';
import { CamelCaseSpecialCharacter, formateDate, handleServerValidations, hasPermission, SpecialCharacter, TrimText } from '../../../../../utils/commonfunction';
import StatusFilter from '../../../common/statusFilter';
import CustomRangepicker from '../../../common/rangepicker';
import { Button, Modal } from 'rsuite';



const EmailTemplatesTable = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [statsupdate, setStatusUpdate] = useState("false");
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Automatic Emails ", url: "" }];
    const [resetdate, setResetDate] = useState(false);
    const [emailModal, setEmailModal] = useState(false);
    const [emailData, setEmailData] = useState(false);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */

    const queryParams = new URLSearchParams(location.search);
    const devParam = queryParams.get('dev');
    const showAddButton = devParam === 'true';
    const [isDevMode, setIsDevMode] = useState(showAddButton);

    const [categories, setCategories] = useState(Object.keys(EMAIL_TEMPLATE_CATEGORY)
        .filter(key => {
            return key == "DEFAULT" || key == "USER_INVITATION"
        }))

    useEffect(() => {
        setIsDevMode(showAddButton);
    }, [showAddButton]);


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            // formData.append("type", EMAIL_TEMPLATE_CATEGORY.DEFAULT)
            emailTemplateService.List(formData).then(data => {
                setDataLength(data.data.total_records);
                setList(data && data.data && data.data.list ? data.data.list : []);
                /********** MULTI SELECT >>>>>>********* */
                setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
                /********** MULTI SELECT <<<<<<********* */
                setPage(data && data.data && data.data.page ? data.data.page : 1);
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch]);

    const viewfunction = (row) => {
        navigate(`/admin/cms/default-email-template/view/${row._id}`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/cms/default-email-template/${params.pgno}/edit/${row._id}`)
    }

    const ChangeStatus = (data, Num) => {
        let ids = Array.isArray(data) ? data : [data];
        const formData = new FormData();
        formData.append("o_id", JSON.stringify(ids))
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                emailTemplateService.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData();
                formdata.append("o_id", JSON.stringify(ids));
                emailTemplateService.Delete(formdata).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        /** removing deleted ids from selectedRecords */
                        setSelectedRecords((data) => data.filter((item) => {
                            return ids.indexOf(item) === -1;
                        }));
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch((error) => {
                    console.log("deleteError", error)
                });
            }
        })
    }

    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT <<<<<<<<<********* */

    const previewEmail = (_id) => {
        setEmailModal(true);
        emailTemplateService.Details(_id).then(response => {
            setEmailData(response && response.data ? response.data : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }


    const handleFilterChange = (e) => {
        if (e.target.value) {
            prepareSearch("category", e.target.value)
        } else {
            prepareSearch("category", "")
        }
    };
    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        {/* DEV MODE >>>>  */}
                        {/* {
                            devmode ?
                                <div className='d-flex justify-content-between align-items-center'>
                                    <button type='button' className="btn btn-outline-primary exit_dev_mode" onClick={(e) => { logoutDevMode(); }}>Exit Developer Mode</button>
                                </div> : ""
                        } */}
                        {/*DEV MODE <<<< */}
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">Automatic Emails </h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>

                                <div className="me-3">
                                    <div className="form-group mb-0 filter_icon_container filter_design">
                                        <i className="fa fa-filter mr-2 filter_icon"></i>
                                        <div className="select-down-arrow">
                                            <select className="form-control cp" onChange={handleFilterChange} id="category">
                                                <option value="" label="Select category">Select{" "}</option>
                                                {categories && categories.map((option, i) => {
                                                    return (
                                                        <option className="text-capitalize" key={i} value={EMAIL_TEMPLATE_CATEGORY[option]}>
                                                            {SpecialCharacter(option)}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                {hasPermission('/admin/cms/default-email-template/add') ?
                                    <>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/cms/default-email-template/add?userInvitation=true`)}>Add User Invitation Template</button>
                                        {/* {isDevMode && (
                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/cms/default-email-template/add?dev=true`)}>Add New</button>
                                        )} */}
                                    </>
                                    : null}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " + (hasPermission('/admin/cms/default-email-template/multi-select') ? '' : "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckbox}
                                                        id="selectAllCheck"
                                                        checked={
                                                            datalength && datalength === selectedRecords.length
                                                        }
                                                        onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>

                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Slug</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Category</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Subject</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th>
                                            Updated By
                                        </th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={10}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i} className={isSelected(row) ? "selected" : ""}>
                                                    <td className={"position-relative " + (hasPermission('/admin/cms/default-email-template/multi-select') ? '' : "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={row?._id + "input"}
                                                                    checked={isSelected(row)}
                                                                    onChange={(e) =>
                                                                        selectRecord(row, e?.target?.checked)
                                                                    }
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>{row.title ? TrimText(row.title, 20) : "N/A"}</td>
                                                    <td>{row.slug ? row.slug : "N/A"}</td>
                                                    <td>{row.category ? (CamelCaseSpecialCharacter(row.category)) : "Default"}</td>
                                                    <td title={row.subject}>{row.subject ? row.subject : "N/A"}</td>
                                                    <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                    {hasPermission('/admin/cms/default-email-template/status') ?
                                                        <td>{row.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                            : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive</button>}
                                                        </td>
                                                        :
                                                        <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                    }

                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>
                                                            {hasPermission('/admin/cms/promotional-email-templates/*/edit/*') ?
                                                                <button className="btn ripple btn-success mlAction" onClick={() => { goToEdit(row) }}>Edit</button>
                                                                : null}
                                                            {hasPermission('/admin/cms/default-email-template/delete') ?
                                                                <>
                                                                    {isDevMode && (
                                                                        <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(row?._id) }}>Delete</button>
                                                                    )}
                                                                </>
                                                                : null}
                                                            {hasPermission('/admin/cms/default-email-template/more') ?
                                                                <div className="btn-group mlAction">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-dark dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        More
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        {hasPermission('/admin/cms/default-email-template/preview') ?

                                                                            <li>

                                                                                <button
                                                                                    type="button"
                                                                                    className="dropdown-item"
                                                                                    onClick={() => previewEmail(row?._id)}
                                                                                >
                                                                                    Preview
                                                                                </button>
                                                                            </li>
                                                                            : null}
                                                                    </ul>
                                                                </div>
                                                                : null}
                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={10} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "email-templates", path: "/admin/cms/default-email-template/list" }]} /> : ""}
                    </div>
                </div>
            </div>
            {/* tablesection end */}
            {/*DEV MODE >>>> */}
            {/* <button type="button" className='d-none' data-bs-toggle="modal" id='developerModeBtn' href="#developerMode" role="button"></button>
            <div className="modal fade" id="developerMode" aria-hidden="true" aria-labelledby="developerModeLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <form onSubmit={activeDevMode} className='w-100'>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="developerModeLabel">
                                    Developer Mode
                                </h1>
                                <button type="button" className="btn-close model_close_btn" id='developerModeClose' data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='usernameDev' className='text-left d-flex'>Username:<span className="requirestar">*</span></label>
                                        <input name='usernameDev' id='usernameDev' type='text' placeholder='Enter username' className='form-control' autoComplete='new-password' required />
                                    </div>
                                    <div className='col-md-12 text-center form-group'>
                                        <label htmlFor='passwordDev' className='text-left d-flex'>Password:<span className="requirestar">*</span></label>
                                        <input name='passwordDev' id='passwordDev' placeholder='********' type='password' className='form-control' autoComplete='new-password' required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-primary" type='submit'>Login</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div> */}
            {/*DEV MODE <<<< */}

            <Modal size={"lg"} open={emailModal} onClose={() => setEmailModal(false)}>
                <Modal.Header className="mb-3">
                    <Modal.Title>Preview Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="border p-4 rounded">
                        <h5 className="mb-3">{emailData && emailData.subject}</h5>
                        <div dangerouslySetInnerHTML={{ __html: emailData && emailData.description ? emailData.description : "N/A" }}></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setEmailModal(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                                {hasPermission('/admin/cms/default-email-template/status') ?
                                    <li>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedRecords, 1) }}>Active</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/cms/default-email-template/status') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedRecords, 0) }}>Inactive</button>
                                    </li>
                                    : null}
                                <li>
                                    {hasPermission('/admin/cms/default-email-template/delete') ?
                                        <>
                                            {isDevMode && (
                                                <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>
                                            )}
                                        </>
                                        : null}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
        </>
    )
}

export default EmailTemplatesTable