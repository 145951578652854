import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';
import * as eventsService from '../../services/events.services';
import { EVENT_TYPES } from '../../../../utils/Constants';

const View = (props) => {
  const params = useParams();
  const loc = useLocation();
  const [showdefault, setShowDefault] = useState({});

  useEffect(() => {
    eventsService.Details(params.id).then(response => {
      if (response && response.success) {
        setShowDefault(response.data ? response.data : [])
      }
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])


  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Events", url: "/admin/event-management/events/list/1" }, { title: "View", url: "" }];
  useEffect(() => {
    eventsService.Details(params.id).then(response => {
      if (response && response.success) {
        setShowDefault(response.data ? response.data : [])
      }
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">{showdefault?.title}</h6>
              </div>
              <div className="tabs-wrap">
                <div className="profile-tab tab-menu-heading mb-4">
                  <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === "/admin/event-management/events/view/event/" + params?.id ? " active" : "")}
                      aria-current="page"
                      to={"/admin/event-management/events/view/event/" + params?.id}
                    >
                      Event
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/view/hotels/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/hotels/${params?.id}/1`}
                    >
                      Hotels
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/view/agenda/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/agenda/${params?.id}/1`}
                    >
                      Agenda
                    </Link>
                    {showdefault.event_type === EVENT_TYPES.INVITATION_ONLY ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/invitations/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/invitations/${params?.id}?page=1`}
                      >
                        Invitations
                      </Link>
                      : null}
                    <Link
                      className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/registrations/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/registrations/${params?.id}/1`}
                    >
                      Registrations
                    </Link>
                    <Link
                      className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/attendees/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/attendees/${params?.id}/1`}
                    >
                      Attendees
                    </Link>
                    <Link
                      className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-announcements`) ? " active" : "")}
                      to={`/admin/event-management/events/view/event-announcements/${params?.id}/1`}
                    >
                      Announcements
                    </Link>
                    <Link
                      className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/checkIn-checkOut/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/checkIn-checkOut/${params?.id}/1`}
                    >
                      Checkin/Checkout
                    </Link>
                    <Link
                      className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-transactions/${params?.id}`) ? " active" : "")}
                      to={`/admin/event-management/events/view/event-transactions/${params?.id}/1`}
                    >
                      Transactions
                    </Link>
                    {showdefault?.lead_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-leads/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-leads/${params?.id}/1`}
                      >
                        Leads
                      </Link>
                      : null}
                    {showdefault?.lead_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-lm-user-invitation/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-lm-user-invitation/${params?.id}/1`}
                      >
                        LM User Invitation
                      </Link>
                      : null}
                    {showdefault?.lead_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-lm-user-registration-list/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-lm-user-registration-list/${params?.id}/1`}
                      >
                        LM User Registration
                      </Link>
                      : null}
                    {showdefault?.lead_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-drip-content/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-drip-content/${params?.id}?page=1&count_page=1`}
                      >
                        Drip Content
                      </Link>
                      : null}
                       {showdefault?.sis_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-sis-user-invitation/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-sis-user-invitation/${params?.id}/1`}
                      >
                        SIS User Invitation
                      </Link>
                      : null}
                        {showdefault?.sis_management === true ?
                      <Link
                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/event-management/events/view/event-booth-attendants/${params?.id}`) ? " active" : "")}
                        to={`/admin/event-management/events/view/event-booth-attendants/${params?.id}/1`}
                      >
                       Booth Attendants
                      </Link>
                      : null}
                  </nav>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default View;