import React, { useEffect, useState } from 'react'
import * as webother from "../../../user/services/webother.service"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { TrimText, globalLoader, getLocalKey, getHomeUrl } from '../../../../utils/commonfunction';
import { ANNOUNCEMENT_TYPES, STAFF_ROLE } from '../../../../utils/Constants';
import SpeakerImage from '../../../../assets/user/images/speker-icon.png'
import CustomPagination from '../../../admin/common/custompagination';
import { useTranslation } from 'react-i18next';
import FrontLoader from '../../../globalComponents/FrontLoader';
import { useSelector } from 'react-redux';


const UserAnnouncements = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [UserAnnouncements, setUserAnnouncements] = useState([]);
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const [getuserkey, setGetUserkey] = useState();
    const loc = useLocation();
    const [loader,setLoader]= useState();

    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.announcement){
            navigate("/404");
        }
      }
    },[myGlobalData]);



    useEffect(() => {
      try {
          const getuserkey = JSON.parse(getLocalKey("user"));
          setGetUserkey(getuserkey);
      } catch (err) {
          setGetUserkey(null)
      }
  }, [loc])



    useEffect(() => {
        // globalLoader(true);
        setLoader(true);
        const formData = new FormData()
        formData.append("page", params.pgno);
        formData.append("per_page", itemperpage);
        formData.append("type", ANNOUNCEMENT_TYPES.GENERAL);
        webother.UserAnnouncements(formData).then((response) => {
            if (response && response?.success) {
                setUserAnnouncements(response && response.data && response.data.list ? response.data.list : []);
                setDataLength(response?.data?.total_records);
                setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
                // globalLoader(false);
                setLoader(false);
            } else {
                console.log("error");
                // globalLoader(false);
                setLoader(false);
            }
        }).catch((err) => {
            console.log(err)
            // globalLoader(false);
            setLoader(false);
        })
    }, [page, params.pgno,itemperpage])

    useEffect(() => {
        try {
            const getuserkey = JSON.parse(getLocalKey("user"));
            setGetUserkey(getuserkey);
        } catch (err) {
            setGetUserkey(null)
        }
    }, [loc])

    
    const handleAnnouncementClick = (data) => {
        if (getuserkey?.role === STAFF_ROLE.BOOTH_ATTENDANTS) {
          navigate(`/sis-user/announcements/details/${data._id}`, { state: { data } });
        } 
        else if (getuserkey?.role === STAFF_ROLE.INFO_COUNTER) {
          navigate(`/info-counter/announcements/details/${data._id}`, { state: { data } });
        } 
        else {
          navigate(`/announcements/details/${data._id}`, { state: { data } });
        }
      };
      
    return (
        <>
            <div className="wraper-inner cpt bg-grey">
                <section className="inner-space">
                    <div className="container" style={{ minHeight: "60vh" }}>
                        <nav className="breadcrumb text-center mb-4">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to={getHomeUrl(getuserkey?.role)}>{t('web_header_heading_home')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('web_header_heading_announcements')}</li>
                            </ol>
                        </nav>
                        <div className="dash-box-shadow">
                            {loader ? 
                           <FrontLoader/>
                            :
                            <>
                            {UserAnnouncements && UserAnnouncements.length > 0 ? (
                                <>
                                    <ul className="announcements-box-list">
                                        {UserAnnouncements.map((data, i) => (
                                            <li
                                                key={i}
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleAnnouncementClick(data)}
                                            >
                                                <figure>
                                                    <img src={SpeakerImage} alt={`Speaker ${i}`} />
                                                </figure>
                                                <figcaption>
                                                    <span className='announcements-title'>{data?.title}</span>
                                                    <p dangerouslySetInnerHTML={{ __html: `${TrimText(data?.description, 100)}` }} />
                                                </figcaption>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ) : (
                                <h2 style={{ textAlign: "center", fontSize: "25px" }}>
                                   {t('web_lbl_no_records')}
                                </h2>
                            )}
                            </>
                            }
                        </div>
                        {datalength && datalength > 0 ?
                                <div className="mt-4 text-right pagination-main d-flex justify-content-center  pe-4">
                                    <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Announcement", path: "/announcements/list" }]} />
                                </div>
                        : ""}
                    </div>
                </section>
            </div>
        </>
    )
}

export default UserAnnouncements;