import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader'
import { formateDate, showStatus } from '../../../../../utils/commonfunction';
import { NameBadgeDetails } from '../../../services/namebadge.services';
import toHtml from "html-react-parser";


const NameBadgeTemplatesView = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/dashboard" }, { title: "Name Badge Templates ", url: "/admin/cms/name-badge-templates/list/1" }, { title: "View", url: "" },]


    useEffect(() => {
        NameBadgeDetails(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Name Badge Template </h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                        <tr>
                                           <th>Front Image</th>
                                            <td>{showdefault && showdefault.front_image ? <img src={showdefault.front_image}  alt="frontImage" style={{ height: "100px" }} /> : 'N/A' }</td>
                                             </tr>
                                             <tr>
                                           <th>Back Image</th>
                                            <td>{showdefault && showdefault.back_image ? <img src={showdefault.back_image}  alt="backImage" style={{ height: "100px" }} /> : 'N/A' }</td>
                                             </tr>
                                             <tr>
                                                <th>Title</th>
                                                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Size</th>
                                                <td>{showdefault && showdefault.size ? showdefault.size : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Is Badge Double Sided</th>
                                                <td>{showdefault && showdefault.is_badge_double_sided === true || showdefault && showdefault.is_badge_double_sided === "true"  ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Modified Date</th>
                                                <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_front_badge ? showdefault.description_front_badge : "N/A" }}></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_back_badge ? showdefault.description_back_badge : "N/A" }}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NameBadgeTemplatesView