import React from 'react'
import {  Outlet } from 'react-router-dom'
import BackOfficeSettingMenu from './backOfficeSettingMenu'


const BackOfficeSettings = () => {

  return (
    <>
    <section className='dash-main' style={{marginTop: '-65px'}}>
    <BackOfficeSettingMenu/>
    
    <div className="dash-main-R" style={{minHeight:'75vh'}}>
        <div className="dash-side-R-body">
            <Outlet/>
        </div>
    </div>
    </section>
    </>
  )
}

export default BackOfficeSettings