import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import Swal from "sweetalert2";
import * as authService from '../services/auth.services';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SWAL_SETTINGS } from '../../../utils/Constants';
import { encodeValue, handleServerValidations, setLocalKey } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';




const UserForgetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    return (

        <>
            <div className="wraper-inner cpt">
                <section className="accounts-page section-padding">
                    <div className="container">
                        <div className="accounts-page-box row">
                            <div className="accounts-page-box-left col-md-7">
                                <figure>
                                    {getGlobalData?.settings?.login_bg_image ?
                                        <img src={getGlobalData?.settings?.login_bg_image} alt="LoginImage" /> :
                                        <Skeleton count={1} style={{height:'500px'}}/>
                                    }
                                </figure>
                                <div className="account-content">
                                    <div dangerouslySetInnerHTML={{ __html: `${getGlobalData?.settings?.login_content ? getGlobalData.settings.login_content : "N/A"}` }}></div>
                                </div>
                            </div>
                            <div className="accounts-page-box-right col-md-5">
                                <div className="accounts-page-box-inner-L">
                                    <Formik
                                        initialValues={{ email: '' }}
                                        validate={values => {
                                            const errors = {};
                                            if (!values.email) {
                                                errors.email = t('web_registration_err_email');
                                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                                errors.email = t('web_registration_err_valid_email');
                                            }
                                            return errors;
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            let formData = new FormData();
                                            formData.append("email", values.email.toLowerCase());
                                            authService.forgetPassword(formData).then(response => {
                                                console.log("user", response)
                                                if (response.success) {
                                                    setLocalKey("user", JSON.stringify(response.data))
                                                    Swal.fire({
                                                        icon: 'success',
                                                        text: response.message,
                                                        ...SWAL_SETTINGS
                                                    })
                                                    setTimeout(() => {
                                                        let email = encodeURIComponent(encodeValue(values.email))
                                                        navigate(`/otp-verificaiton?email=${email}&type=password_reset`)
                                                    }, 2000);
                                                } else {
                                                    Swal.fire({
                                                        icon: 'error',
                                                        text: handleServerValidations(response),
                                                        ...SWAL_SETTINGS
                                                    })
                                                    setTimeout(() => {
                                                    }, 2000);
                                                }
                                            }).catch(error => {
                                                console.log("error: ", error);
                                            })
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,
                                            // and other goodies
                                        }) => (
                                            <div className="common-bg-form">
                                                <div className="top-from-headning text-left mb-4">
                                                    <figure>
                                                        <img src={getGlobalData?.portal_design?.logo_transparent} alt="Logo" />
                                                    </figure>
                                                    <h3>{t('web_forget_password')}</h3>
                                                    <p>{t('web_forget_password_message')}</p>
                                                </div>
                                                <form className="form-box login-form" onSubmit={handleSubmit}>
                                                    <div className="form-group mb-4">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            placeholder={t('web_lbl_enter_your_email')}
                                                            className="form-control"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete="off"
                                                        />
                                                        <span className='text-danger text-start d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                    </div>
                                                    <div className="btn-box text-center mt-4 ">
                                                        <button className="btn btn-primary w-100 h-50">{t('web_lbl_submit')}</button>
                                                    </div>
                                                    <div className="bottom-text mt-4 text-center">
                                                        <Link to={"/login"}>{t('web_forget_password_login')}</Link>
                                                    </div>
                                                </form>
                                            </div>
                                        )}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    )
}
export default UserForgetPassword;