import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as eventServices from '../../services/events.services';
import { formateDate, capitalizeFirstLetter, formateDateWithMonth,globalLoader } from '../../../../utils/commonfunction';
import Breadcrums from '../../common/breadcrumbs';
import QRCode from "react-qr-code";
import moment from 'moment';
import Swal from 'sweetalert2';
import LogoImage from '../../../../assets/user/images/logo.png'
import { useSelector } from 'react-redux';
import { BookingsDetails } from '../../services/roommanagement.services';

const ViewBookingDetails = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [bookingDetails, setBookingDetails] = useState({});
    const location = useLocation();
    const { state } = location;
    const { room_id } = state || {};
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Room Management", url: "/admin/room-management/list/1" }, { title: "View", url: `/admin/room-management/view/bookings/list/${room_id}/1` }, { title: "View Booking info", url: "" }];

    useEffect(() => {
        const { bookingId } = params;
        if (bookingId) {
            BookingsDetails(bookingId)
                .then((response) => {
                    if (response?.success) {
                        setBookingDetails(response.data);
                    }
                })
                .catch(err => console.log("err", err));
        }
    }, [params]);


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm viewregisration">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card main-content-body-profile">
                        <div className="tab-content">
                            <div className="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                <h4 className="tx-15 text-uppercase mb-3">Booking Info</h4>
                                <div className="card-body p-0 border p-0 rounded-10">
                                    <div className="p-4">
                                    {Object.keys(bookingDetails).length > 0 ? (
                                        <div>
                                             <p className="pb-0">
                                            <label>Title :</label> {bookingDetails && bookingDetails?.title ? bookingDetails.title  : "N/A"}
                                        </p>
                                        <p className="pb-0">
                                            <label>Event Name :</label> {bookingDetails && bookingDetails?.event_data?.title ? bookingDetails?.event_data?.title  : "N/A"}
                                        </p>
                                        <p className="pb-0">
                                            <label>Room Name :</label> {bookingDetails && bookingDetails?.room_data?.name ? bookingDetails?.room_data?.name  : "N/A"}
                                        </p>
                                        <p className="pb-0">
                                            <label>Start Date :</label> {bookingDetails && bookingDetails.start_date ? formateDate(bookingDetails.start_date) : "N/A"}
                                        </p>
                                        <p className="pb-0">
                                            <label>End Date :</label> {bookingDetails && bookingDetails.end_date ? formateDate(bookingDetails.end_date) : "N/A"}
                                        </p>
                                        <p className="pb-0">
                                            <label>Created Date :</label> {bookingDetails && bookingDetails.createdAt ? formateDate(bookingDetails.createdAt) : "N/A"}
                                        </p>
                                    </div>
                                ) : (
                                    <></>
                                )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewBookingDetails;

