import React, { useEffect, useState } from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';
import * as eventsService from '../../services/events.services';
import { EVENT_TYPES } from '../../../../utils/Constants';
import { RoomsDetails } from '../../services/roommanagement.services';

const RoomView = (props) => {
  const params = useParams();
  const loc = useLocation();
  const [showdefault, setShowDefault] = useState({});

  useEffect(() => {
    RoomsDetails(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);


  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Rooms", url: "/admin/room-management/list/1" }, { title: "View", url: "" }];

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">{showdefault.name}</h6>
              </div>
              <div className="tabs-wrap">
                <div className="profile-tab tab-menu-heading mb-4">
                  <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                    <Link
                      className={"nav-link linkactive " + (loc.pathname === "/admin/room-management/view/rooms/" + params?.id ? " active" : "")}
                      aria-current="page"
                      to={"/admin/room-management/view/rooms/" + params?.id}
                    >
                     Room
                    </Link>
                    <Link
                      className={"nav-link linkactive " + (loc.pathname.includes(`/admin/room-management/view/bookings/list/${params?.id}`) ? " active" : "")}
                      to={`/admin/room-management/view/bookings/list/${params?.id}/1`}
                    >
                      Booking
                    </Link>
                  </nav>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RoomView;