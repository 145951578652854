import axiosInstance from "../../../utils/axios";
const path = "web/user"

export const updateUserprofile = async (values) => {
    return await axiosInstance().post(`${path}/edit-profile`, values)
}
export const changepassword = async (values) => {
    return await axiosInstance().post(`${path}/change-password`, values);
}

export const changePassword = async (values) => {
    return await axiosInstance().post(`${path}/change-password`, values);
}

export const ProfileDetails = async () => {
    return await axiosInstance().get(`${path}/edit-profile-details`);
  }
export const UserDetails = async (values) => {
    return await axiosInstance().get(`${path}/edit-profile-details`, values);
}

export const ProfileDelete = async (values) => {
    return await axiosInstance().post(`${path}/delete-profile`,values);
}

