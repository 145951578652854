import React, { useEffect, useRef, useState } from 'react';
import * as otherServices from '../services/common.services';

const Home4 = () => {
    const [bannerData, setBannerData] = useState([]);
    const homeSliderRef = useRef();

    useEffect(() => {
        otherServices.cmsBanner().then((response) => {
            setBannerData(response?.data?.list);
        }).catch((err) => {
            console.log("err", err);
        })
    }, []);

    /** trigger the home slider start event */
    useEffect(() => {
        if (homeSliderRef.current) {
            setTimeout(() => {
                const event = new CustomEvent("startSlider");
                document.dispatchEvent(event);
            }, 100);
        }
    }, [homeSliderRef, bannerData]);

    return (
        <>
            <div className="wraper-inner cpt">
                <div className="owl-carousel owl-theme" ref={homeSliderRef}>
                    {bannerData && bannerData.map((item, index) => (
                        <div className="item" key={index}>
                            <section
                                className="banner-sec"
                                style={{
                                    background: `url(${item?.image}) no-repeat center center`
                                }}>
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-md-8 m-auto pt-5 banner-sec-content">
                                            <h1>{item?.title}</h1>
                                            <div dangerouslySetInnerHTML={{ __html: `${item?.description ? item?.description : "N/A"}` }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    ))}
                </div>
                <section className="about-us section-padding about-us-style-4" id="about-us">
                    <div className="container">
                        <div className="row align-items-center gap-5">
                            <div className="col-md-12">
                                <figure className='text-start'>
                                    <img
                                        alt=""
                                        src="https://images.unsplash.com/photo-1472214103451-9374bd1c798e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                    />
                                </figure>
                            </div>
                            <div className="col-md-8 mx-auto">
                                <div className="heading-card text-center">
                                    <small>ABOUT US</small>
                                    <h2>
                                        Hello you to achieve more, one click at a time.
                                        asdff&nbsp; SSSSS
                                    </h2>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s, when an unknown
                                        printer took a galley of type and scrambled it to make a
                                        type specimen book.
                                    </p>
                                    <p>
                                        It has survived not only five centuries, but also the leap
                                        into electronic typesetting, remaining essentially
                                        unchanged. It was popularised in the 1960s with the
                                        release of Letraset sheets containing Lorem Ipsum
                                        passages.
                                    </p>
                                </div>
                                <div className="btn-group-box mt-2 text-center">
                                    <a className="btn btn-primary btn-icon">Read more </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="how-its-works section-padding how-its-style-4">
                    <div className="container">
                        <div className="heading-card text-center mb-5 col-md-5 m-auto">
                            <h2 className="text-white">How it works</h2>
                            <p className="text-off">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="works-card">
                                    <figure><img src="/static/media/works-icon-1.05d00b03c4dde9bddaa9b620d3f2a794.svg" /></figure>
                                    <div className="figcaption">
                                        <h3>Create an account</h3>
                                        <p>Recruit and train a team of event staff and volunteers to assist with various tasks such as
                                            registration, ushering, crowd management, and customer service. Clearly communicate their
                                            roles and responsibilities.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="works-card">
                                    <figure><img src="/static/media/works-icon-2.c72e770360558523d972db9c7734a33f.svg" /></figure>
                                    <div className="figcaption">
                                        <h3>Execute the event</h3>
                                        <p>On the day of the event, ensure all necessary arrangements are in place and oversee the
                                            execution of the event plan. Monitor the schedule, troubleshoot issues, and provide support
                                            to staff and attendees as needed</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="works-card">
                                    <figure><img src="/static/media/works-icon-3.7687a48bda4c58c2da477fe8c7846f6d.svg" /></figure>
                                    <div className="figcaption">
                                        <h3>Evaluate the event</h3>
                                        <p>After the event, gather feedback from attendees, staff, and stakeholders. Evaluate the
                                            success of the event based on the objectives defined earlier. Identify areas of improvement
                                            and collect data for future events.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Home4;