import axiosInstance from "../../../utils/axios";

const path = "admin/name-badge"

export const NameBadgeList = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const NameBadgeAdd = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const  NameBadgeEdit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const  NameBadgeDelete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const  NameBadgeDetails  = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const NameBadgeStatus = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}


