import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey} from '../../../utils/commonfunction';
import CheckPermission from './checkPermission';
import { STAFF_ROLE } from '../../../utils/Constants';

const PrivateRoute = ({ component: Component, route }) => {
    const userData = getLocalKey("user") ? JSON.parse(getLocalKey("user")) : {};
    const allowedRolesForAdminPanel = [STAFF_ROLE.ADMIN, STAFF_ROLE.STAFF, STAFF_ROLE.SUB_ADMIN];
    localStorage.setItem("i18nextLng","en");
    localStorage.setItem("system_language","en");
    return (
        <>
            {(function(){
                if(Object.keys(userData).length && allowedRolesForAdminPanel.indexOf(userData.role) >= 0){
                    return <CheckPermission Component={Component} route={route}/>
                }else{
                    return <Navigate to="/admin/login" />;
                }
            })()}
        </>
    )
}

export default PrivateRoute;