import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {  logOutUser } from "../../../utils/commonfunction";

function BackOfficeSettingMenu(props) {
  let sidebarContainer = useRef();
  const loc = useLocation();
  const { t } = useTranslation();
  const [isPhone, setIsPhone] = useState(false);
  const navigate = useNavigate();


  const leadData = useSelector((state) => state.designportal);

  useEffect(() => {
    if (window.innerWidth > 500) {
      setIsPhone(false);
    } else {
      setIsPhone(true);
    }
  }, [])

  const logout = () => {
    Swal.fire({
      customClass: "swal-wide",
      title: t('web_swal_are_you_sure'),
      text: t('lbl_logout_text'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor:
        leadData?.data?.background_colors?.primary_active_color,
      cancelButtonColor:
        leadData?.data?.background_colors?.footer_background_color,
      confirmButtonText: t('web_swal_yes'),
      cancelButtonText: t('web_swal_no')
    }).then((result) => {
      if (result.isConfirmed) {
        logOutUser(navigate);
      }
    });
  };
  const handleResponsiveMenu = () => {
    if (isPhone) {
      props.onClose(false)
    }
  }

  return (
    <div id="setting_open" className={`side-bar-L ${props.showMenu === true ? "open" : ""}`} ref={sidebarContainer}>
      <div className="sidebar-body">
        <a
          href="/"
          className="close-side-btn"
          onClick={(e) => {
            e.preventDefault();
            if (props.onClose) {
              props.onClose(false);
            }
          }}
        >
          <i className="ri-close-line"></i>
        </a>
        <ul className="sidebar-link-list">
          <li
            className={
              loc.pathname.includes("/back-office/settings/profile") ? " active" : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/back-office/settings/profile"}>
              <figure>
                <i className="ti ti-user"></i>
              </figure>
              {t("web_sidebar_profile")}
            </Link>
          </li>
          <li
            className={
              loc.pathname.includes("/back-office/settings/change-password")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/back-office/settings/change-password"}>
              <figure>
                <i className="ti ti-lock"></i>
              </figure>
              {t("web_sidebar_change_password")}
            </Link>
          </li>
          <li
            className={
              loc.pathname.includes("/back-office/settings/invitation-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/back-office/settings/invitation-list/1"}>
              <figure>
              <i className="ti ti-link"></i>
              </figure>
              {t('web_heading_invitation_list')}
            </Link>
          </li>
          <li
            className={
              loc.pathname.includes("/back-office/settings/registration-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/back-office/settings/registration-list/1"}>
              <figure>
              <i className="ti ti-calendar-event"></i>
              </figure>
              {t('web_lbl_registration_list')}
            </Link>
          </li>
          <li>
            <a href="/" onClick={(e) => { e.preventDefault(); logout() }}>
              <figure>
                <i className="ti ti-logout"></i>
              </figure>
              {t("web_sidebar_logout")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BackOfficeSettingMenu;