import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as dashboardService from "../services/dashboard.services";
import { formatDateFromTime, TrimText } from "../../../utils/commonfunction";
import { Bar } from "react-chartjs-2";
import { Chart, LinearScale, registerables } from "chart.js";
import { DateRangePicker, SelectPicker } from "rsuite";
import Loader from "../common/loader";
Chart.register(...registerables, LinearScale);

const Dashboard = () => {
  const [showDashboardCount, setShowDashboardCount] = useState({});
  const [recentEventRegistrations, setRecentEventRegistrations] = useState([]);
  const [recentUserRegistrations, setRecentUserRegistrations] = useState([]);
  const [userOverviewTimeLimit, setUserOverviewTimeLimit] = useState(
    "last_month"
  );
  const [
    registrationOverviewTimeLimit,
    setRegistrationOverviewTimeLimit,
  ] = useState("last_month");
  const [userChartData, setUserChartData] = useState({
    labels: [],
    counts: [],
  });
  const [registrationChartData, setRegistrationChartData] = useState({
    labels: [],
    counts: [],
  });
  const navigate = useNavigate();
  const [userCustomRange, setUserCustomRange] = useState(null);
  const [registrationCustomRange, setRegistrationCustomRange] = useState(null);
  const [showUserDatePicker, setShowUserDatePicker] = useState(false);
  const [showRegistrationDatePicker, setShowRegistrationDatePicker] = useState(
    false
  );
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    dashboardService
      .Counts()
      .then((response) => {
        setShowDashboardCount(response && response.data ? response.data : {});
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, []);

  useEffect(() => {
    setLoader(true);
    dashboardService
      .recentEventRegistration()
      .then((response) => {
        setRecentEventRegistrations(
          response && response.data && response.data.list
            ? response.data.list
            : []
        );
        setLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });

    dashboardService
      .recentUserRegistration()
      .then((response) => {
        setRecentUserRegistrations(
          response && response.data && response.data.list
            ? response.data.list
            : []
        );
        setLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  }, []);

  useEffect(() => {
	setLoader(true);
    if (userOverviewTimeLimit !== "custom_range" || userCustomRange) {
      dashboardService
        .UserOverview(userOverviewTimeLimit, JSON.stringify(userCustomRange))
        .then((response) => {
          setUserChartData(response && response.data ? response.data : []);
		  setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [userOverviewTimeLimit, userCustomRange]);

  useEffect(() => {
	setLoader(true);
    if (
      registrationOverviewTimeLimit !== "custom_range" ||
      registrationCustomRange
    ) {
      dashboardService
        .RegistrationOverview(
          registrationOverviewTimeLimit,
          JSON.stringify(registrationCustomRange)
        )
        .then((response) => {
          setRegistrationChartData(
            response && response.data && response.data ? response.data : []
          );
		  setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [registrationOverviewTimeLimit, registrationCustomRange]);

  const UserOptions = {
    indexAxis: "x",
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      y: { ticks: { precision: 0 } },
    },
  };

  const showAllUsers = (row) => {
    navigate(`/admin/user-management/users/list/1`);
  };

  const showAllEvents = (row) => {
    navigate(`/admin/event-management/events/list/1`);
  };
  const chartFilterOptions = [
    { label: "Last 7 days", value: "this_week" },
    { label: "Last 30 days", value: "last_month" },
    { label: "Last year", value: "last_year" },
    { label: "Custom range", value: "custom_range" },
  ];

  const handleUserSelect = (value) => {
    setUserOverviewTimeLimit(value);
    if (value === "custom_range") {
      setShowUserDatePicker(true);
    } else {
      setShowUserDatePicker(false);
      setUserCustomRange("");
    }
  };

  const handleRegistrationSelect = (value) => {
    setRegistrationOverviewTimeLimit(value);
    if (value === "custom_range") {
      setShowRegistrationDatePicker(true);
    } else {
      setShowRegistrationDatePicker(false);
      setUserCustomRange("");
    }
  };

  return (
    <>
      <div className="row row-sm animation_fade">
        <div className="col-sm-12 col-lg-12 col-xl-12">
          <div className="row row-sm">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/user-management/users/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          USERS
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {showDashboardCount && showDashboardCount.users
                              ? showDashboardCount.users
                              : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/event-management/events/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16v9zm-4.785-6.145a.428.428 0 1 0 0-.855.426.426 0 0 0-.43.43c0 .238.192.425.43.425zm.336.563h-.672v4.105h.672V8.418zm-6.867 4.105v-2.3h2.261v-.61H4.684V7.801h2.464v-.61H4v5.332h.684zm3.296 0h.676V9.98c0-.554.227-1.007.953-1.007.125 0 .258.004.329.015v-.613a1.806 1.806 0 0 0-.254-.02c-.582 0-.891.32-1.012.567h-.02v-.504H7.98v4.105z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          EVENTS
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {showDashboardCount && showDashboardCount.events
                              ? showDashboardCount.events
                              : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/activity-forum/activities/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.5a1 1 0 0 0-.8.4l-1.9 2.533a1 1 0 0 1-1.6 0L5.3 12.4a1 1 0 0 0-.8-.4H2a2 2 0 0 1-2-2V2zm7.194 2.766a1.688 1.688 0 0 0-.227-.272 1.467 1.467 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 5.734 4C4.776 4 4 4.746 4 5.667c0 .92.776 1.666 1.734 1.666.343 0 .662-.095.931-.26-.137.389-.39.804-.81 1.22a.405.405 0 0 0 .011.59c.173.16.447.155.614-.01 1.334-1.329 1.37-2.758.941-3.706a2.461 2.461 0 0 0-.227-.4zM11 7.073c-.136.389-.39.804-.81 1.22a.405.405 0 0 0 .012.59c.172.16.446.155.613-.01 1.334-1.329 1.37-2.758.942-3.706a2.466 2.466 0 0 0-.228-.4 1.686 1.686 0 0 0-.227-.273 1.466 1.466 0 0 0-.469-.324l-.008-.004A1.785 1.785 0 0 0 10.07 4c-.957 0-1.734.746-1.734 1.667 0 .92.777 1.666 1.734 1.666.343 0 .662-.095.931-.26z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          ACTIVITY FEEDS
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {showDashboardCount &&
                            showDashboardCount.activity_feeds
                              ? showDashboardCount.activity_feeds
                              : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3">
              <div className="card custom-card">
                <div className="card-body cp">
                  <Link to={`/admin/staff/list/1`}>
                    <div className="card-item">
                      <div className="card-item-icon card-icon cp">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          className="bi bi-people-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                        </svg>
                      </div>
                      <div className="card-item-title mb-2">
                        <label className="main-content-label tx-13 font-weight-bold mb-1">
                          STAFFS
                        </label>
                      </div>
                      <div className="card-item-body">
                        <div className="card-item-stat">
                          <h4 className="font-weight-bold">
                            {showDashboardCount && showDashboardCount.staff
                              ? showDashboardCount.staff
                              : "0"}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="card custom-card mg-b-20">
                <div className="card-body" style={{ height: "351px" }}>
                  <div className="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                    <div>
                      <label className="main-content-label mb-4">
                        Recent User Registration
                      </label>
                    </div>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={showAllUsers}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      {" "}
                      View All
                    </button>
                  </div>
                  <div className="table-responsive tasks">
                    <table className="table card-table table-vcenter text-nowrap mb-0 border">
                      <thead>
                        <tr>
                          <th className="wd-lg-10p">User Name</th>
                          <th className="wd-lg-20p">Email</th>
                          <th className="wd-lg-20p">Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ? (
                          <tr>
                            <td colSpan={3}>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                            {recentUserRegistrations.length ? (
                              recentUserRegistrations.map((user, index) => (
                                <tr key={index}>
                                  <td>
                                    {user?.name ? (
                                      <Link
                                        to={`/admin/user-management/users/view/${user._id}?page=1&chat_page=1`}
                                      >
                                        {TrimText(user.name, 20)}
                                      </Link>
                                    ) : (
                                      "N/A"
                                    )}
                                  </td>
                                  <td>
                                    {user?.email
                                      ? TrimText(user.email, 20)
                                      : "N/A"}
                                  </td>
                                  <td>
                                    {user?.createdAt
                                      ? formatDateFromTime(user?.createdAt)
                                      : "N/A"}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan={3} className="text-center">
                                  No records
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card custom-card mg-b-20">
                <div className="card-body" style={{ height: "351px" }}>
                  <div className="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                    <div>
                      <label className="main-content-label mb-4">
                        Recent Event Registration
                      </label>
                    </div>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={showAllEvents}
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                      }}
                    >
                      View All
                    </button>
                  </div>
                  <div className="table-responsive tasks">
                    <table className="table card-table table-vcenter text-nowrap mb-0 border">
                      <thead>
                        <tr>
                          <th className="wd-lg-10p">Event</th>
                          <th className="wd-lg-10p">User Name</th>
                          <th className="wd-lg-10p">Email</th>
                          <th className="wd-lg-20p">Created Date</th>
                        </tr>
                      </thead>
                      <tbody>
					  {loader ? (
                          <tr>
                            <td colSpan={4}>
                              <Loader />
                            </td>
                          </tr>
                        ) : (
                          <>
                        {recentEventRegistrations.length ? (
                          recentEventRegistrations.map((event, index) => (
                            <tr key={index}>
                              <td>
                                {event?.event_data?.title ? (
                                  <Link
                                    to={`/admin/event-management/events/view/event/${event.event_data._id}`}
                                  >
                                    {TrimText(event?.event_data?.title, 20)}
                                  </Link>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                {event?.user_data?.name ? (
                                  <Link
                                    to={`/admin/user-management/users/view/${event.user_data._id}?page=1&chat_page=1`}
                                  >
                                    {TrimText(event.user_data.name, 20)}
                                  </Link>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                {event?.user_data?.email
                                  ? TrimText(event?.user_data?.email, 20)
                                  : "N/A"}
                              </td>
                              <td>
                                {event?.createdAt
                                  ? formatDateFromTime(event?.createdAt)
                                  : "N/A"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              No records
                            </td>
                          </tr>
                        )}
						</>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card custom-card mg-b-20">
                <div className="card-body">
                  <div className="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                    <div>
                      <label className="main-content-label mb-4">
                        Registered Users
                      </label>
                    </div>
                    <div className="ml-auto d-flex align-items-center">
                      <SelectPicker
                        defaultValue={"last_month"}
                        cleanable={false}
                        onSelect={handleUserSelect}
                        size="sm"
                        data={chartFilterOptions}
                        className="dashboard-filter-select"
                        searchable={false}
                      />
                      {showUserDatePicker && (
                        <div className="form-group mb-0 ms-2 rangepicker_container filter_design">
                          <DateRangePicker
                            size="sm"
                            placeholder={"Select Date"}
                            placement="bottomEnd"
                            onChange={(e) => {
                              setUserCustomRange(
                                e && e.length === 2
                                  ? { start: e[0], end: e[1] }
                                  : null
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
				  {loader ? (
                              <Loader />
                        ) : (
                          <>
                    {userChartData &&
                    userChartData.counts &&
                    Object.keys(userChartData.counts).length > 0 ? (
                      <Bar
                        datasetIdKey="id"
                        options={UserOptions}
                        data={{
                          labels:
                            userChartData &&
                            userChartData.labels &&
                            userChartData.labels.length > 0
                              ? userChartData.labels
                              : [],
                          datasets: [
                            {
                              label: "Users",
                              data:
                                userChartData &&
                                userChartData.counts &&
                                userChartData.counts.length > 0
                                  ? userChartData.counts
                                  : [],
                              borderWidth: 2,
                              borderRadius: 15,
                              borderColor: "rgba(65, 64, 169, 0.8)",
                              backgroundColor: "rgba(65, 64, 169, 0.8)",
                            },
                          ],
                        }}
                      />
                    ) : (
                      <div className="border-0 text-center">
                        No Record Found
                      </div>
                    )}
				       </>
                         )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card custom-card mg-b-20">
                <div className="card-body">
                  <div className="card-header border-bottom-0 pt-0 pl-0 pr-0 d-flex">
                    <div>
                      <label className="main-content-label mb-4">
                        Event Registrations
                      </label>
                    </div>
                    <div className="ml-auto d-flex align-items-center">
                      <SelectPicker
                        size="sm"
                        defaultValue={"last_month"}
                        cleanable={false}
                        onSelect={handleRegistrationSelect}
                        data={chartFilterOptions}
                        searchable={false}
                        className="dashboard-filter-select"
                      />
                      {showRegistrationDatePicker && (
                        <div className="form-group mb-0 ms-2 rangepicker_container filter_design">
                          <DateRangePicker
                            size="sm"
                            placeholder={"Select Date"}
                            placement="bottomEnd"
                            onChange={(e) => {
                              setRegistrationCustomRange(
                                e && e.length === 2
                                  ? { start: e[0], end: e[1] }
                                  : null
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="">
				  {loader ? (
                              <Loader />
                        ) : (
                          <>
                    {registrationChartData &&
                    registrationChartData.counts &&
                    Object.keys(registrationChartData.counts).length > 0 ? (
                      <Bar
                        datasetIdKey="id"
                        options={UserOptions}
                        data={{
                          labels:
                            registrationChartData &&
                            registrationChartData.labels &&
                            registrationChartData.labels.length > 0
                              ? registrationChartData.labels
                              : [],
                          datasets: [
                            {
                              label: "Registrations",
                              data:
                                registrationChartData &&
                                registrationChartData.counts &&
                                registrationChartData.counts.length > 0
                                  ? registrationChartData.counts
                                  : [],
                              borderWidth: 2,
                              borderRadius: 15,
                              borderColor: "rgba(65, 64, 169, 0.8)",
                              backgroundColor: "rgba(65, 64, 169, 0.8)",
                            },
                          ],
                        }}
                      />
                    ) : (
                      <div className="border-0 text-center">
                        No Record Found
                      </div>
                    )}
					 </>
                         )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
