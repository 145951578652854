import React, { useEffect, useState, useRef } from "react";
import { ErrorMessage, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import { GENDER, SWAL_SETTINGS, showFilterlist } from "../../../../utils/Constants";
import { handleServerValidations, TrimText, formateDate, globalLoader } from "../../../../utils/commonfunction";
import * as Yup from "yup";
import { BookingsDetails, UpdateBooking, RoomsDetails, ReadUsersExcel } from "../../services/roommanagement.services";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import { Button, Calendar, DatePicker, Modal, SelectPicker, Steps } from "rsuite";
import CustomRangepicker from "../../common/rangepicker";
import StatusFilter from "../../common/statusFilter";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";
import $ from "jquery";
import moment from "moment";
import { List } from "../../services/usermgmt.services";

const EditBooking = () => {
  const formikRef = useRef()
  const navigate = useNavigate();
  let fileInputRef = useRef();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [saveType, setSaveType] = useState("");
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Room Management", url: "/admin/room-management/list/1" }, { title: "View", url: `/admin/room-management/view/bookings/list/${showdefault?.room_data?._id}/1` }, { title: "Edit Booking", url: "" }];
  const [InviteMemberModal, setInviteMemberModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [showSelectedUsers, setShowSelectedUsers] = useState(false);
  const [userdatalength, setUserDataLength] = useState(0);
  const selectAllCheckbox = useRef();
  const [selectedAllDataNew, setSelectedAllDataNew] = useState(false);
  const [globalsearchNews, setGlobalSearchNews] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const [itemPerPageModal] = useState(10);
  const [dataPageNo, setDataPageNo] = useState(1);
  const [searchNew, setSearchNew] = useState({});
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [selectedRoomId, setSelectedRoomId] = useState(false);
  const [selectedRoomDetail, setSelectedRoomDetail] = useState(false);
  const [excelModal, setExcelModal] = useState(false);


  const [step, setStep] = React.useState(0);
  const [file, setFile] = useState("");
  const [skippedUsers, setSkippedUsers] = useState(null);
  const [selectedManualUser, setSelectedManualUser] = useState([]);
  const [selectedNewManualUser, setSelectedNewManualUser] = useState([]);
  const [selectedPrevManualUser, setSelectedPrevManualUser] = useState([]);
  const [selectedManualUserData, setSelectedManualUserData] = useState(false);

  useEffect(() => {
    BookingsDetails(params.bookingId)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setSelectedRoomId(response && response.data && response.data.room_data && response.data.room_data?._id ? response.data.room_data?._id : null)
        setSelectedAllDataNew(response && response.data && response.data.invitees ? response.data.invitees : []);
        setSelectedUser(response && response.data && response.data.invitees ? response.data.invitees : []);
        setSelectedManualUser(response && response.data && response.data.manual_invitees ? response.data.manual_invitees : []);
        setSelectedPrevManualUser(response && response.data && response.data.manual_invitees ? response.data.manual_invitees : [])
        setShowSelectedUsers(true);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);


  useEffect(() => {
    if (selectedRoomId) {
      RoomsDetails(selectedRoomId)
        .then((response) => {
          setSelectedRoomDetail(response && response.data ? response.data : []);
        })
        .catch((error) => {
          console.log("error=====>", error);
        });
    }
  }, [selectedRoomId]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
    start_date: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    end_date: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
  });


  useEffect(() => {
    let formData = new FormData();
    formData.append('global_search', globalsearchNews);
    formData.append("per_page", itemPerPageModal);
    formData.append("page", dataPageNo);
    formData.append("search", JSON.stringify({ subscribed_for_newsletter: true, ...searchNew }));
    List(formData)
      .then((response) => {
        setLoader(true)
        setUserDataLength(response?.data?.total_records);
        setUserData(response?.data?.list);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [globalsearchNews, searchNew, statsupdate, dataPageNo]);

  useEffect(() => {
    console.log(selectedUser)
    if (InviteMemberModal) {
      if (selectedUser.length === userdatalength) {
        selectAllCheckbox.current.indeterminate = false;
        selectAllCheckbox.current.checked = true;
      } else if (selectedUser.length) {
        selectAllCheckbox.current.indeterminate = true;
      } else {
        selectAllCheckbox.current.indeterminate = false;
      }

    }
  }, [selectedUser, InviteMemberModal]);

  const handleInvitation = () => {
    // setSelectedUser([]);
    console.log(selectedUser)
    setInviteMemberModal(true);
  };


  const prepareSearchNew = (key, value) => {
    let sr = { ...searchNew };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearchNew(sr);
  };

  const selectUser = (userData, check) => {
    if (check) {
      setSelectedUser((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedUser((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelect = (check) => {
    if (check) {
      setSelectedUser(selectedAllDataNew);
    } else {
      setSelectedUser([]);
    }
  };
  const isSelectedNew = (item) => {
    return selectedUser.filter((data) => item._id === data).length > 0;
  };

  /**********Handle members selection when invite members >>>>>>********* */

  const handleNextButtonClick = () => {
    setShowSelectedUsers(true);
    setInviteMemberModal(false);
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearchNew("gender", e.target.value)
    } else {
      prepareSearchNew("gender", "")
    }
  };

  const [fileType] = useState(Object.keys(GENDER).map((item => {
    return {
      label: item, value: GENDER[item]
    }
  })));


  const selectRow = (item) => {
    let id = item?._id;
    $("#" + id + "label").trigger("click");
  };
  const resetFilterNew = (e) => {
    e.preventDefault();
    setGlobalSearchNews("");
    setSearchNew({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilterNew")[0].selectedIndex = 0;
  };


  const formattedValue = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
    return formattedDate;
  }


  const setEndDate = (e) => {
    let room_booking_interval = selectedRoomDetail && selectedRoomDetail.bookable_interval ? selectedRoomDetail.bookable_interval : null
    if (room_booking_interval) {
      const start_date = new Date(e.target.value);
      const end_date = new Date(start_date.getTime() + room_booking_interval * 60000);
      const formattedEndDate = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
      console.log(formattedEndDate, "formattedEndDate");
      formikRef?.current?.setFieldValue('end_date', formattedEndDate)
    }
  }


  const readExcelFile = (e) => {
    globalLoader(true);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      ReadUsersExcel(formData)
        .then((response) => {
          setTimeout(() => {
            if (response?.success) {
              setFile(e?.target?.files[0]);
              let newManualUser = response?.data?.list && response?.data?.list.length>0 ? response?.data?.list.map(user=>user.email) : []
              setSelectedNewManualUser(newManualUser) ;
              const newUsers = newManualUser;
              const prevUsers = selectedPrevManualUser;
              const emails = new Set(prevUsers); 
              const filteredNewUsers = newUsers.filter(user => !emails.has(user));
              const newData=[...prevUsers, ...filteredNewUsers]
              setSelectedManualUser(newData);
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
              document.getElementById("file_reader").value = "";
            }
            globalLoader(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          globalLoader(false);
        });
    }
  };

  const onOkayClick = () => {
    setSelectedManualUserData(JSON.stringify(selectedManualUser));
    setStep(step + 1);
  };

  const handleRemoveManualUser = (email) => {
    setSelectedManualUser(selectedManualUser.filter(user => user !== email));
  };
  const modalData = (steps) => {
    if (steps === 0) {
      return (
        <div className="my-3 upload-button " style={{height:'560px'}}>
          <form
            id="reader_id"
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center ">
              <label htmlFor="file_reader">
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  id="file_reader"
                  onChange={readExcelFile}
                  accept=".xls,.xlsx,.csv"
                />
                <i
                  className="fa fa-upload cp"
                  aria-hidden="true"
                  style={{ fontSize: "7em", color: "#3598ff" }}
                ></i>
              </label>
              <p className="text-muted mb-0">Please upload excel file.</p>
              <a
                className="download-file my-0"
                href={`${process.env.REACT_APP_API_URL.replace(
                  "/webservice/api/v1",
                  ""
                )}/configs/default/LM-users-invitation-sample.xlsx`}
                download
              >
                Download sample file.
              </a>
            </div>
          </form>
        </div>
      );
    } else if (steps === 1) {
      return (
        <div className="mt-4" style={{height:'560px'}}>
          <div className="table-responsive">
            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {console.log("selectedNewManualUser", selectedNewManualUser)}
                  {selectedNewManualUser.length ? (
                    selectedNewManualUser.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.toLowerCase()}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={selectedManualUser?.headings?.length}
                        className="text-center"
                      >
                        No records
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
          </div>
          <Modal.Footer className="mt-4">
            <div className="chek-box-card mt-3 d-flex justify-content-end"></div>
            <Button
              appearance="primary"
              onClick={() => {
                setStep(step - 1);
              }}
              type="button"
              className="mt-2"
            >
              Previous
            </Button>

            <Button
              appearance="primary"
              onClick={() => {
                onOkayClick();
              }}
              type="submit"
              className="mt-2"
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      );
    } else if (steps === 2) {
      return (
        <div style={{height:'560px'}}>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <h1>Success</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p>Total Skipped Users : {skippedUsers ? skippedUsers : 0}</p>
          </div>

          <Modal.Footer className="mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                appearance="primary"
                onClick={() => {
                  setExcelModal(false);
                  setStep(0)
                }}
                type="submit"
                className="mt-2"
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </div>
      );
    }
  };



  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          title: showdefault && showdefault.title ? showdefault.title : "",
          start_date:
            showdefault && showdefault.start_date
              ? formattedValue(showdefault?.start_date)
              : "",
          end_date:
            showdefault && showdefault.end_date
              ? formattedValue(showdefault?.end_date)
              : "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let formData = new FormData();
          formData.append("title", values.title);
          formData.append("start_date", values.start_date);
          formData.append("end_date", values.end_date);
          formData.append('_id', params.bookingId);
          if (selectedUser && selectedUser.length > 0 && selectedUser != null) {
            formData.append("invitees", JSON.stringify(selectedUser));
          }
          if (selectedManualUser && selectedManualUser.length > 0) {
            let emails = selectedManualUser.map(user=>user)
            formData.append("manualInvitees", JSON.stringify(emails));
          }
          UpdateBooking(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                if (saveType !== 'Save') {
                  setTimeout(() => {
                    resetForm({ values: "" });
                    navigate(`/admin/room-management/view/bookings/list/${showdefault?.room_data?._id}/1`);
                  }, 1000);
                }
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Edit Booking{" "}
                      </h6>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="title" className="text-left d-flex">
                          Title:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="title"
                          id="title"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"title"} />
                        </span>
                      </div>

                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="start_date"
                          className="text-left d-flex"
                        >
                          Start Date :<span className="requirestar">*</span>{" "}
                        </label>

                        <input
                          name='start_date'
                          id='start_date'
                          type='datetime-local'
                          onChange={(e) => { handleChange(e); setEndDate(e) }}
                          onBlur={handleBlur}
                          onClick={(e) => e.target.showPicker()}
                          value={values.start_date}
                          className='form-control' />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"start_date"} />
                        </span>
                      </div>
                      {/* <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="start_date"
                          className="text-left d-flex"
                        >
                          End Date :<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                                name='end_date'
                                id='end_date'
                                type='datetime-local'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={(e) => e.target.showPicker()}
                                value={values.end_date}
                                className='form-control' />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"end_date"} />
                        </span>
                      </div> */}


                      <div className="col-md-6 text-center form-group">
                        <div class="invite-member-container mt-4">
                          <button class="btn ripple btn-main-primary signbtn cp" type="button" onClick={() => handleInvitation()}>Invite Member</button>
                          {showSelectedUsers && selectedUser?.length > 0 && (
                            <div class="selected-members">
                              Total Selected Members: <span>({selectedUser?.length})</span>
                            </div>
                          )}
                          {selectedManualUser && selectedManualUser?.length > 0 && (
                            <div class="selected-members">
                              Total Selected Manual Members: <span>({selectedManualUser?.length})</span>
                            </div>
                          )}
                        </div>


                      </div>
                      {selectedManualUser && selectedManualUser.length > 0 && (
                        <>
                          <h4>Manual Users</h4>
                          <div className="manual-invitees-chips mt-2">
                            {selectedManualUser.map((user, index) => (
                              <div key={index} className="chip">
                                {user}
                                <button className="close-btn" type="button" onClick={() => handleRemoveManualUser(user)}>
                                  &times;
                                </button>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                      <div className="mt-5">
                        <button
                          onClick={() => {
                            setSaveType("Save");
                          }}
                          className="btn btn-info mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setSaveType("");
                          }}
                          className="btn btn-success mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save & Exit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Modal size={"550"} open={InviteMemberModal} backdrop={'static'} onClose={() => { setInviteMemberModal(false) }}>
        <Modal.Header className="mb-3">
          <Modal.Title>Select members</Modal.Title>
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <i className="fa fa-search calender_icon"></i>
                <input type="search"
                  className="form-control"
                  placeholder="Search"
                  value={globalsearchNews}
                  onChange={(e) => setGlobalSearchNews(e.target.value)}
                />
              </div>
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <CustomRangepicker
                  GetDateRange={(e) => {
                    prepareSearchNew("createdAt", e);
                  }}
                  resetdate={resetdate}
                />
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" onChange={handleFilterChange} id="resetFilterNew" style={{ width: "130px" }}>
                    <option value="">
                      Salutation
                    </option>
                    {fileType && fileType.length > 0 && fileType.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design" style={{ width: "110px" }}>
                <StatusFilter
                  data={showFilterlist}
                  prepareSearch={prepareSearchNew}
                />
              </div>
              <button
                type="reset"
                value="Reset"
                onClick={resetFilterNew}
                className="btn btn-warning float-right mr-2"
              >
                Reset Filter
              </button>

              <button
                type="button"
                value="upload_excel"
                className="btn btn-success float-right mr-2"
                onClick={() => {
                  setExcelModal(true);
                  // setInviteMemberModal(false)
                }}
              >
                Invite Manually
              </button>
            </div>
          </div>
        </Modal.Header>
        <div className="clearfix"></div>
        <div className="w-100">
          <table className="table table-hover table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th className="position-relative select_head">
                  <div className="select-check-container">
                    <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                      className="ckbox  cp">
                      <input
                        ref={selectAllCheckbox}
                        id="selectAllCheck"
                        checked={
                          userdatalength && userdatalength === selectedUser.length
                        }
                        onChange={(e) => handleSelect(e.target.checked)} type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Salutation</th>
                <th>Status</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              <>
                {userData.length ? userData.map((item, i) => {
                  return <tr
                    key={i}
                    onClick={() => selectRow(item)}
                    className={isSelectedNew(item) ? "selected" : ""}
                  >
                    <td>
                      <label htmlFor={item?._id + "input"} style={{ lineHeight: 'unset' }}
                        className="ckbox cp">
                        <input
                          id={item?._id + "input"}
                          checked={isSelectedNew(item)}
                          onChange={(e) =>
                            selectUser(item, e?.target?.checked)
                          }
                          type="checkbox"
                          className="form-check select-check cp" />
                        <span></span>
                      </label>
                    </td>
                    <td className="text-capitalize">{item?.name ? TrimText(item.name, 20) : "N/A"}</td>
                    <td>{item?.email ? TrimText(item.email, 20) : "N/A"}</td>
                    <td className="text-capitalize">{item?.gender}</td>
                    <td>
                      {item.status === 1 ? (
                        <button
                          className="badge badge-success"
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          className="badge badge-danger"

                        >
                          Inactive
                        </button>
                      )}
                    </td>

                    <td>
                      {item.createdAt
                        ? formateDate(item.createdAt)
                        : "N/A"}
                    </td>
                  </tr>
                }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}</>
            </tbody>
          </table>
        </div>
        <div
          className=""
          id="example1_info"
          role="status"
          aria-live="polite"
        >
          <b>Total Records : {userdatalength ? userdatalength : "0"}</b>
        </div>
        {userdatalength && userdatalength > 0 ? (
          <CustomPagination
            datalength={userdatalength}
            itemperpage={itemPerPageModal}
            currentPage={dataPageNo}
            setPage={setDataPageNo}
            pageRoute={[
              { name: "Room Management", path: "/admin/room-management/list" },
            ]}
            modalPagination={'modalPagination'}
          />
        ) : (
          ""
        )}
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h6 className="ms-2 mt-1 mb-0">{selectedUser.length ? "Selected " + selectedUser.length + " of records " + userdatalength : ""}</h6>
          <div>
            {/* <Button onClose={() => { setInviteMemberModal(false)}} appearance="subtle">
                  Cancel
                </Button> */}
            <Button
              disabled={selectedUser.length === 0}
              onClick={handleNextButtonClick}
              appearance="primary"
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>

      <Modal size={"lg"} open={excelModal} backdrop={'static'} onClose={() => { setExcelModal(false) }}>
        <Modal.Header className="mb-3">
          <Modal.Title>Select members</Modal.Title>
          {/* <div className="mt-4">
            <div className="d-flex align-items-center">
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <i className="fa fa-search calender_icon"></i>
                <input type="search"
                  className="form-control"
                  placeholder="Search"
                  value={globalsearchNews}
                  onChange={(e) => setGlobalSearchNews(e.target.value)}
                />
              </div>
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <CustomRangepicker
                  GetDateRange={(e) => {
                    prepareSearchNew("createdAt", e);
                  }}
                  resetdate={resetdate}
                />
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" onChange={handleFilterChange} id="resetFilterNew" style={{ width: "130px" }}>
                    <option value="">
                      Salutation
                    </option>
                    {fileType && fileType.length > 0 && fileType.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design" style={{ width: "110px" }}>
                <StatusFilter
                  data={showFilterlist}
                  prepareSearch={prepareSearchNew}
                />
              </div>
              <button
                type="reset"
                value="Reset"
                onClick={resetFilterNew}
                className="btn btn-warning float-right mr-2"
              >
                Reset Filter
              </button>

              <button
                type="button"
                value="upload_excel"
                className="btn btn-success float-right mr-2"
                onClick={() => setExcelModal(true)}
              >
                Invite Manually
              </button>
            </div>
          </div> */}
        </Modal.Header>
        <div>
          <Steps current={step}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Preview" />
            <Steps.Item title="Success" />
          </Steps>
          <div>{modalData(step)}</div>
        </div>
      </Modal>


    </>
  );
};

export default EditBooking;
