import React from 'react'
import {  Outlet } from 'react-router-dom'
import SisUserSettingMenu from './settingMenu'


const SisUserSettings = () => {

  return (
    <>
    <section className='dash-main'>
    <SisUserSettingMenu/>
    <div className="dash-main-R" style={{minHeight:'75vh'}}>
        <div className="dash-side-R-body">
            <Outlet/>
        </div>
    </div>
    </section>
    </>
  )
}

export default SisUserSettings