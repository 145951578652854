import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as commonService from "../../user/services/common.services";
import { STAFF_ROLE, SWAL_SETTINGS } from '../../../utils/Constants';
import { getHomeUrl, getLocalKey, handleServerValidations } from '../../../utils/commonfunction';
import { SOCKET_CLIENT } from '../../../utils/socket';
import CustomPagination from '../../admin/common/custompagination';
import { useTranslation } from 'react-i18next';



const UserNotifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [datalength, setDataLength] = useState("");
	const [itemperpage] = useState(12);
	const [page, setPage] = useState(1);
	const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [getuserkey, setGetUserkey] = useState();
    const loc = useLocation();

    useEffect(() => {
        const formData = new FormData();
		formData.append("page", params.pgno);
		formData.append("per_page", itemperpage);
        commonService.NotificationsList(formData).then((response)=>{
            if(response.success){
                setNotifications(response?.data?.list || []);
                setDataLength(response?.data?.total_records);
                setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
                let unreadNotif = response?.data?.list.filter((item)=> {
                    return item.is_read === false;
                });
                if(unreadNotif.length){
                    let ids = unreadNotif.map(item=>item._id);
                    commonService.NotificationsMarkAsRead({ids:JSON.stringify(ids)}).then(()=>{
                        /**Unread notification marked as read on this page */
                        const evt = new Event("refreshNotifications");
                        window.dispatchEvent(evt);
                    })
                }
            }else{
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS
                });
            }
        }).catch((err)=>{
            console.log("****Error while getting notifications****",err);
        });
    }, [page,itemperpage,params.pgno])


    useEffect(()=>{
        /** Listening for notifications */
        SOCKET_CLIENT.on('notificationReceive', (data) => {
            Swal.fire({
                icon: "info",
                text: data.title || data.message,
                ...SWAL_SETTINGS,
                timer:4000
            });
            setNotifications([data, ...notifications]);
        });
   },[notifications]);

   function goToNotification(notification) {
    if (notification.redirection === true) {
        navigate(notification.redirect_to);
    }
}
useEffect(() => {
    try {
        const getuserkey = JSON.parse(getLocalKey("user"));
        setGetUserkey(getuserkey);
    } catch (err) {
        setGetUserkey(null)
    }
}, [loc])


    return (
        <>
            <div className="wraper-inner cpt bg-grey">
                <section className="inner-space">
                    <div className="container" style={{ minHeight: "60vh" }}>
                        <nav className="breadcrumb text-center mb-4">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    {/* <Link to={`/`}>{t('web_header_heading_home')}</Link>
                                     */}
                                    <Link to={getHomeUrl(getuserkey?.role)}>{t('web_header_heading_home')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{t('web_lbl_notification')}</li>
                            </ol>
                        </nav>
                        <div className="dash-box-shadow">
                            {notifications && notifications.length > 0 ?
                                <ul className="announcements-box-list notification-list h-auto">
                                    {notifications.map((notification, i) => {
                                        return <li className={'cp'+(i!==notifications.length-1?' border-bottom':'')+(notification.is_read?'':' unread')} key={i} onClick={(e) => { goToNotification(notification) }}>
                                        <figure>
                                            <i className="ri-notification-3-fill bell" ></i>
                                        </figure>
                                        <figcaption className='border-0 p-0'>
                                            <span className='notif-title'>{notification?.title}</span>
                                            <span className='notif-message'>{notification?.message}</span>
                                        </figcaption>
                                    </li>
                                    })}
                                </ul>
                                :
                                <h2 style={{ textAlign: "center", fontSize: "25px" }}>
                                   {t('web_lbl_no_records')}
                                </h2>
                            }
                        </div>
                    </div>
                    {datalength && datalength > 0 ?
                  <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                    <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "notifications", path: "/notifications" }]} /> 
                  </div>
              : ""}
                </section>
            </div>
        </>
    )
}

export default UserNotifications;