import React, { useEffect,  useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  formateDate,
  globalLoader,
  handleServerValidations,
  isApp,
  removeCommas,
  removeSpecialCharacter,
  writeMyExcel,
} from "../../../../utils/commonfunction";
import CustomPagination from "../../../admin/common/custompagination";
import { LeadDelete, LeadList } from "../../../user/services/lead.services";
import $ from "jquery";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { LEADTYPES, SWAL_SETTINGS } from "../../../../utils/Constants";
import FrontLoader from "../../../globalComponents/FrontLoader";

const BackOfficeLeadList = () => {
  const params = useParams();
  const [dateFilterValue, setDateFilterValue] = useState();
  const [leadData, setLeadData] = useState([]);
  const [globalsearch, setGlobalSearch] = useState("");
  const [questionSearch, setQuestionSearch] = useState("");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const [refreshList] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [allUsersExportFlag] = useState(false);
  const [search, setSearch] = useState({});
  const [statsupdate, setStatusUpdate] = useState("false");
  const [type] = useState(
    Object.keys(LEADTYPES).map((item) => {
      return {
        label: item,
        value: LEADTYPES[item],
      };
    })
  );
  const location = useLocation();
  const { state } = location;
  const { typeFilter } = state || {};
  let reducerData = useSelector((state) => state.manageLmBackOffice);
  const [loader,setLoader] = useState(true);

  useEffect(() => {
    let mySearch = {...search};
    if(typeFilter){
      mySearch['type'] = typeFilter;
    }
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("global_search", globalsearch);
    formData.append("question_search", questionSearch);
    formData.append("search", JSON.stringify(mySearch));
    if (reducerData) {
      formData.append("event_id", reducerData);
    }
    LeadList(formData)
      .then((response) => {
        if (response && response?.success) {
          setLeadData(
            response && response.data && response.data.list
              ? response.data.list
              : []
          );
          setDataLength(response?.data?.total_records);
          setPage(
            response && response?.data && response?.data?.page
              ? response?.data?.page
              : 1
          );
          setLoader(false);
        }else {
          console.log("error");
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    page,
    params.pgno,
    itemperpage,
    globalsearch,
    refreshList,
    search,
    reducerData,
    statsupdate,
    questionSearch
  ]);

  /** This function is used to export all leads  */
  const exportAllLeads = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("event_id", reducerData);
    LeadList(formData)
      .then((response) => {
        let data =
          response && response?.data && response?.data?.list
            ? response.data.list
            : [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.questions_data?.length) {
            lengthArr = Array(item?.questions_data?.length).fill([]);
          }
        });
        let rows = data.map((item) => {
          const tempRow = [
            removeCommas(item?.name),
            removeCommas(item?.email),
            removeCommas(item?.event_data?.title),
            removeCommas(
              item?.is_draft === true || item?.is_draft === true
                ? "Draft"
                : "Completed"
            ),
            removeCommas(item?.lm_back_office_data?.name)
              ? removeCommas(item?.lm_back_office_data?.name)
              : "N/A",
            removeCommas(formateDate(item?.createdAt)),
          ];
          for (let i = 0; i < lengthArr?.length; i++) {
            let questionTitle;
            if (item && item?.questions_data && item?.questions_data[i]) {
              questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            } else {
              questionTitle = "N/A";
            }
            let answer;
            if (item && item.questions_data && item.questions_data[i]) {
              answer = item.questions_data[i].answer ?? "N/A";
            } else {
              answer = "N/A";
            }

            if (questionTitle === "N/A" && answer === "N/A") {
              tempRow.push("N/A");
            } else {
              tempRow.push(
                `${capitalizeFirstLetter(
                  removeSpecialCharacter(questionTitle)
                )}: ${answer}`
              );
            }
          }
          return tempRow;
        });

        const headers = [
          "Name",
          "Email",
          "Event",
          "Type",
          "Created By",
          "Created Date",
          ...lengthArr.map((_, index) => `Extra Info`),
        ];

        const fileName = `lead-report.xlsx`;
        const excelData = [headers, ...rows];
        if (isApp()) {
          let data = {excelData,fileName};
          const appData = { action: 'export-excel', data: data};
          window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
          globalLoader(false);
        } else {
          writeMyExcel(excelData, fileName).then(() => {
              setTimeout(() => {
                globalLoader(false);
              }, 1000);
          }).catch((err) => {
            console.log(err);
          });
        }
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };
 /** This function is used to export all submitted leads end */

  const handleLeadClick = (data) => {
    navigate(`/back-office/leads/details/${data._id}`, { state: { data } });
  };

  const goToEdit = (data) => {
    if (data.is_draft === true || data.is_draft === "true") {
      navigate(`/back-office/draft-customers/edit/${data._id}`);
    } else {
      navigate(`/back-office/leads/edit/${data._id}`);
    }
  };

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setQuestionSearch("");
    prepareSearch();
    setSearch({});
    setDateFilterValue('');
    $("#resetFilter")[0].selectedIndex = 0;
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearch("type", e.target.value);
    } else {
      prepareSearch("type", "");
    }
  };

  function deleteFunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: (t('web_swal_are_you_sure')),
      text: (t('web_swal_revert_this')),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: (t('web_swal_yes_delete')),
      cancelButtonText : (t('web_lbl_button_cancel'))
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("o_id", data?._id);
        LeadDelete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }

  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="inner-space pt-0">
          <div className="container" style={{ minHeight: "60vh" }}>
            <div className="user-box-head d-flex justify-content-between mb-4">
              <h3 className="inner-title mb-0">
                {t("web_header_heading_leads")}
              </h3>
              <div className="user-right filter-responsive d-flex align-items-center">
                <div className="search-box ms-1">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder={t('web_lbl_search')}
                    value={globalsearch}
                    onChange={(e) => {
                      setGlobalSearch(e.target.value);
                    }}
                  />
                  <button className="btn-search">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
                <div className="search-box ms-1">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder={t('web_lbl_search_by_question')}
                    value={questionSearch}
                    onChange={(e) => {
                      setQuestionSearch(e.target.value);
                    }}
                  />
                  <button className="btn-search">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
                <div className="form-group ms-1">
                  <input
                    className="form-control"
                    type="date"
                    value={dateFilterValue}
                    onChange={(e) => {
                      setDateFilterValue(e.target.value);
                      prepareSearch("createdAt", e.target.value);
                    }}
                    onClick={(e) => {
                      e.target.showPicker();
                    }}
                    placeholder={t('web_lbl_filter_by_date')}
                  ></input>
                </div>
                <div className="search-box ms-1">
                  <select
                    className="form-select cp"
                    onChange={handleFilterChange}
                    defaultValue={typeFilter}
                    id="resetFilter"
                  >
                    <option value="">{t('web_lbl_type')}</option>
                    {type &&
                      type.length > 0 &&
                      type.map((option, i) => {
                        return (
                          <option
                            key={i}
                            value={option?.value}
                          >
                            {option?.label}
                          </option>
                        );
                      })}
                  </select>
                  <button className="btn-search">
                  </button>
                </div>
                <button
                  className="btn btn-warning ms-1"
                  onClick={(e) => {
                    resetFilter(e);
                  }}
                >
                  {t("web_lbl_reset_filter")}
                </button>
                {allUsersExportFlag ? (
                  <>
                    <button
                      className="btn btn-info ms-1 "
                      disabled={datalength === 0}
                      onClick={exportAllLeads}
                    >
                      {t("web_lbl_export_leads")}
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-info ms-1 "
                    disabled={datalength === 0}
                    onClick={exportAllLeads}
                  >
                    {t("web_lbl_export_leads")}
                  </button>
                )}
              </div>
            </div>
            <div className="adv-filters-sec d-flex mb-3"></div>
            <div className="dash-box-shadow p-3 table-responsive">
            {loader ? 
            <FrontLoader/>
            :
            <>
              {leadData && leadData.length > 0 ? (
                <table className="table table-bordered adv-table mb-0">
                  <thead>
                    <tr>
                      <th>{t("web_lbl_salutation")}</th>
                      <th>{t("web_lbl_first_name")}</th>
                      <th>{t("web_lbl_last_name")}</th>
                      <th>{t("web_lbl_email")}</th>
                      <th>{t("web_lbl_type")}</th>
                      <th>{t("web_lbl_created_by")}</th>
                      <th>{t("web_lbl_created_at")}</th>
                      <th>{t("web_lbl_action")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leadData.map((data, i) => (
                      <tr key={i}>
                        <td>{data?.gender ? data?.gender : "N/A"}</td>
                        <td>{data?.first_name ? data?.first_name : "N/A"}</td>
                        <td>{data?.last_name ? data?.last_name : "N/A"}</td>
                        <td>{data?.email ? data.email : "N/A"}</td>
                        <td>
                          {data?.is_draft === true ||
                          data?.is_draft === "true" ? (
                            <span className="">{t("web_lbl_draft")}</span>
                          ) : (
                            <span className="">{t("web_lbl_completed")}</span>
                          )}
                        </td>
                        <td>
                          {data?.creator_data?.name
                            ? data.creator_data.name
                            : "N/A"}
                        </td>
                        <td>{formateDate(data?.createdAt)}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn table-btn-icon btn-primary"
                              onClick={() => handleLeadClick(data)}
                            >
                              <i className="ri-eye-fill"></i>
                            </button>
                            <button
                              className="btn table-btn-icon btn-warning ms-2"
                              onClick={() => {
                                goToEdit(data);
                              }}
                            >
                              <i className="ri-edit-2-fill"></i>
                            </button>
                            <button
                              className="btn table-btn-icon btn-danger ms-2"
                              onClick={() => {
                                deleteFunction(data);
                              }}
                            >
                              <i className="ri-delete-bin-fill"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h2 style={{ textAlign: "center", fontSize: "25px" }}>
                  {t("web_lbl_no_records")}
                </h2>
              )}
            </>
             }
            </div>
            {datalength && datalength > 0 ? (
              <div className="mt-4 text-right pagination-main d-flex justify-content-center  pe-4">
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    { name: "Lead", path: "/back-office/leads/list" },
                  ]}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default BackOfficeLeadList;
