import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import * as eventDetails from "../services/eventdetailservice";
import {
  calculatePercentage,
  capitalizeFirstLetter,
  formateDateWithMonth,
  getDate,
  getDayBetweenDates,
  getFormatedTime,
  getUser,
  globalLoader,
  handleServerValidations,
  myToFixed,
  prepareQuestionsFormData,
  removeSpecialCharacter,
  DT,
  selectSalutation,
  getTranslatedGender,
} from "../../../utils/commonfunction";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { EMAIL_REGEX, EVENT_TYPES, GENDER, ROOM_TYPE, STAFF_ROLE, SWAL_SETTINGS } from "../../../utils/Constants";
import toHtml from "html-react-parser";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as commonServices from "../services/common.services";
import * as otherServices from "../services/webother.service";
import * as eventServices from "../services/eventdetailservice";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import * as $ from "jquery";
import { useTranslation } from 'react-i18next';
import DynamicQuestions from "../shared/DynamicQuestions";


function RegistrationStepTwo() {
  const user = getUser();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState([]);
  const [params] = useSearchParams();
  const [apiDone, setApiDone] = useState(false);
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const [invitationDetailsData, setInvitationDetailsData] = useState("");
  const [registrationId, setRegistrationId] = useState("");
  const [stateData, setStateData] = useState([]);
  const [guestAgenda, setGuestAgenda] = useState([]);
  const [primaryAgendaDetails, setPrimaryAgendaDetails] = useState([]);
  const [guestAgendaDetails, setGuestAgendaDetails] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [availableHotelList, setAvailableHotelList] = useState([]);
  const [checkInDate, setCheckInDate] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [dynamicValidations, setDynamicValidations] = useState({});
  const [dynamicValidationsForInvoice, setDynamicValidationsForInvoice] =
    useState({});
  const [registrationData, setRegistrationData] = useState([]);
  const [guestValidation, setGuestValidation] = useState(registrationData?.add_guest);
  // const [disableBtn, setDisableBtn] = useState(false);
  const [formikHandle, setFormikHandle] = useState(0);
  const [finalHotelData, setFinalHotelData] = useState({
    nights: 0,
    sub_total: 0,
  });
  const [agendaPricing, setAgendaPricing] = useState([]);
  const [primaryAgendaSubtotal, setPrimaryAgendaSubtotal] = useState(0);
  const [editApiDone, setEditApiDone] = useState(true);
  const [guestAgendaSubtotal, setGuestAgendaSubtotal] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [oldPricing, setOldPricing] = useState({});

  const formikRef = useRef();
  const ChoosePaymentTrigger = useRef();
  const { t } = useTranslation();
  const [secondApiDone, setSecondApiDone] = useState(false);
  const dynamicQuestionsRef = useRef();
  const [questionFilesUploadPath] = useState("registrations/questions");
  const url = window.location.href;
  let urlNew = url;
  let urlObj = new URL(urlNew);
  let params2 = new URLSearchParams(urlObj.search);
  const invitationValue = params2.get("invitation") ?params2.get("invitation") : params2.get("invitation_id") ?  params2.get("invitation_id") :null;
  useEffect(()=>{
    if(window?.registrationFormStatus && window?.registrationFormStatus === true){
      /**Proceed with the second step */
    }else{
      if(params.get("edit") === true || params.get("edit") === "true"){
        navigate(`/event-registration?event_id=${params.get("event_id")}&invitation_id=${params.get("invitation_id")}&reg_id=${params.get("reg_id")}&edit=true`);
      }else{
        navigate(`/event-registration?event_id=${params.get("event_id")}&invitation_id=${params.get("invitation_id")}`);
      }
    }
  },[]);
  useEffect(() => {
    setStateData(state?.values);
    eventDetails
      .EventDetail(params.get("event_id"),invitationValue)
      .then((response) => {
        if (response.success) {
          setDetailsData(response?.data);
          setAvailableHotelList(response?.data?.hotels_data);
          setGuestAgenda(response?.data?.agenda_data);
          if (params.get("edit") === true || params.get("edit") === "true") {
            // setFormikHandle(
            //   response?.data?.secondary_question_set_data?.questions
            //     ? response?.data?.secondary_question_set_data?.questions.length
            //     : 0
            // );
          } else{
            setFormikHandle(response?.data?.secondary_question_set_data?.questions.length);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [formikRef?.current?.values.accommodation]);

  useEffect(() => {
    if (params.get("edit") === true || params.get("edit") === "true") {
      setEditApiDone(false);
      eventServices
        .EventRegistrationDetails(params.get("reg_id"))
        .then((response) => {
          if (response.success) {
            setRegistrationData(response?.data);
            if (response?.data?.add_guest) {
              setGuestValidation(true);
            }
            if (params.get("edit") === true || params.get("edit") === "true") {
              setFormikHandle(response?.data?.guest_user?.questions_data
                ? response?.data?.guest_user?.questions_data.length
                : 0
              );
            }
            setEditApiDone(true);
            let selectedAgenda =
              response?.data &&
                response?.data?.guest_user &&
                response?.data?.guest_user?.agenda
                ? response?.data?.guest_user?.agenda
                : [];
            if (detailsData) {
              guestAgenda &&
                guestAgenda.forEach((item, index) => {
                  selectedAgenda &&
                    selectedAgenda.forEach((agenda, agendaIndex) => {
                      if (item?._id === agenda?.agendaId) {
                        item.elements &&
                          item.elements.forEach((element, elementIndex) => {
                            agenda.elementIds &&
                              agenda.elementIds.forEach(
                                (selectedElement, i) => {
                                  if (selectedElement === element._id) {
                                    element.checked = true;
                                  }
                                }
                              );
                          });
                      }
                    });
                });
              handleElementPrice();
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
          setEditApiDone(true);
        });

    } else {
      setEditApiDone(true);
    }
  }, [detailsData]);

  useEffect(() => {
    let primaryAgenda = [];
    state &&
      state?.values &&
      state?.values?.pricing &&
      state?.values?.pricing?.primaryAgenda &&
      state?.values?.pricing?.primaryAgenda.forEach((item, index) => {
        let singleAgenda = {};
        singleAgenda = {
          name: item?.elementPrice?.name,
          price: item?.elementPrice?.price,
          tax: item?.elementPrice?.tax_rate,
        };
        primaryAgenda.push(singleAgenda);
      });
    setPrimaryAgendaDetails([...primaryAgenda]);
    let subTotal = 0;
    primaryAgenda.forEach((item, index) => {
      let price = item.price;
      let tax = calculatePercentage(item.tax, price);
      subTotal += price + tax;
    });
    setPrimaryAgendaSubtotal(subTotal);
  }, [state?.values?.pricing?.primaryAgenda]);

  useEffect(() => {
    otherServices.OldPricing({ registration_id: params.get("reg_id") }).then((response) => {
      if (response?.success) {
        setOldPricing(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    let guestAgenda = [];
    agendaPricing &&
      agendaPricing.forEach((item, index) => {
        let singleAgenda = {};
        singleAgenda = {
          name: item?.elementPrice?.name,
          price: item?.elementPrice?.price,
          tax: item?.elementPrice?.tax_rate,
        };
        guestAgenda.push(singleAgenda);
      });
    setGuestAgendaDetails([...guestAgenda]);
    let subTotal = 0;
    guestAgenda.forEach((item, index) => {
      let price = item.price;
      let tax = calculatePercentage(item.tax, price);
      subTotal += price + tax;
    });
    setGuestAgendaSubtotal(subTotal);
  }, [agendaPricing]);

  useEffect(() => {
    let obj = {};
    if (guestValidation) {
      obj.gender = Yup.string().trim().required(t('web_registration_err_salutation'));
      obj.firstName = Yup.string().trim().required(t('web_registration_err_firstname'));
      obj.lastName = Yup.string().trim().required(t('web_registration_err_lastname'));
      obj.passportNumber = Yup.string().test(
        "check_visa",
        t('web_registration_err_passport_number'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.surname = Yup.string().test(
        "check_visa",
        t('web_registration_err_surname'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.visaFirstName = Yup.string().test(
        "check_visa",
        t('web_registration_err_visa_firstname'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfBirth = Yup.string().test(
        "check_visa",
        t('web_registration_err_date_of_birth'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfExpiry = Yup.string().test(
        "check_visa",
        t('web_registration_err_date_of_expiry'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.dateOfIssue = Yup.string().test(
        "check_visa",
        t('web_registration_err_date_of_issue'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.nationality = Yup.string().test(
        "check_visa",
        t('web_registration_err_nationality'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.authority = Yup.string().test(
        "check_visa",
        t('web_registration_err_authority'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.placeOfBirth = Yup.string().test(
        "check_visa",
        t('web_registration_err_place_of_birth'),
        (value) => {
          if (formikRef?.current?.values.guestVisaInvitation) {
            return Boolean(value);
          } else {
            return true;
          }
        }
      );
      obj.mobileNumber = Yup.string()
        .trim()
        .required(t('web_registration_err_mobile_number'))
        .test(
          "min-length",
          t('web_registration_err_min_mobile_number'),
          (value) => {
            const numberWithCode = value.substring(value.indexOf(" ") + 1);
            if (numberWithCode.trim().length < 4) {
              return false;
            } else {
              return true;
            }
          }
        )
        .test(
          "max-length",
          t('web_registration_err_max_mobile_number'),
          (value) => {
            const numberWithCode = value.substring(value.indexOf(" ") + 1);
            if (numberWithCode.trim().length > 14) {
              return false;
            } else {
              return true;
            }
          }
        );
      obj.email = Yup.string()
        .trim()
        .required(t("web_registration_err_email"))
        .matches(
          EMAIL_REGEX,
          t('web_registration_err_valid_email')
        );
      obj.specificValue = Yup.string().test(
        "test-value",
        t("web_registration_err_specify_text"),
        (value) => {
          if (formikRef?.current?.values?.allergic) {
            if (value) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      );
    }
    setDynamicValidations(obj);
  }, [guestValidation]);

  useEffect(() => {
    if (params.get('invitation_id') && params.get('invitation_id')!="null" && params.get('invitation_id')!="undefined") {
    commonServices
      .InvitationsDetailsByEmail(params.get("invitation_id"))
      .then((response) => {
        if (response.success) {
          setInvitationDetailsData(response?.data);
        }
        setApiDone(true);
      })
      .catch((err) => {
        console.log("error", err);
      });
    }
    else{
      setApiDone(true);
    }
  }, []);

  useEffect(() => {
    if(params.get('edit') === true || params.get('edit') === "true"){
      if (detailsData  && registrationData){
        let preSelectedAns = [];
        // let questionArr = [];
        // TO BE reiewed if questions are not correct
        let questionArr = registrationData?.guest_user?.questions_data.length ? registrationData?.guest_user?.questions_data : [];
        questionArr.forEach(item => {
          // let keyName = "";
          // let obj = invitationDetailsData.question_sequence.find(val => {
          //   return val.question === item.question_id
          // })
          // if (obj) {
          //   keyName = obj.field.toString().toLowerCase();
          // }
          let singleAns = {}
            singleAns = {
              question_id: item.question_id,
              question_title: item.question_title,
              answer: item.answer
            }
          preSelectedAns.push(singleAns)
        })
        setPreSelectedValues([...preSelectedAns]);
        setSecondApiDone(true);
      }
    }else{
      if (detailsData && invitationDetailsData) {
        let preSelectedAns = [];
        let questionArr = detailsData.primary_question_set_data?.questions.length ? detailsData.primary_question_set_data.questions : [];
        questionArr.forEach(item => {
          let keyName = "";
          let obj = invitationDetailsData.question_sequence.find(val => {
            return val.question === item._id
          })
          if (obj) {
            keyName = obj.field.toString().toLowerCase();
          } 
          let singleAns = {}
            singleAns = {
              question_id: item._id,
              question_title: item.title,
              ...(item.question_type === 'likeStatus' ? {answer: 0} : { answer: invitationDetailsData.fields[keyName] })
            }
          preSelectedAns.push(singleAns)
        })
        setPreSelectedValues([...preSelectedAns]);
      }
        setSecondApiDone(true);
    }
  }, [invitationDetailsData, detailsData, registrationData])

  useEffect(() => {
    let obj = {};
    if (totalPrice !== 0) {
      obj.companyName = Yup.string()
        .trim()
        .required(t("web_registration_err_company_name"));
      obj.address = Yup.string().trim().required(t("web_registration_err_address"));
      obj.postalCode = Yup.string().matches(/^[0-9a-zA-Z]+$/, t('web_registration_err_valid_postal_code')).required(t('web_registration_err_postal_code'));
      obj.city = Yup.string().trim().required(t('web_registration_err_city'));
      obj.country = Yup.string().trim().required(t('web_registration_err_country'));
    }
    setDynamicValidationsForInvoice(obj);
  }, [totalPrice]);

  useEffect(() => {
    if (registrationData && detailsData && registrationData?.guest_user?.questions_data?.length !== detailsData?.secondary_question_set_data?.questions?.length) {
      // let arr = []
      detailsData?.secondary_question_set_data?.questions.forEach(item => {
          if (!registrationData?.guest_user?.questions_data.includes(val => val.question_id === item.question_id)) {
            // console.log("kasdjfkj", item)
          }
      })
    }
  }, [registrationData, detailsData]);

  function validateElements(index = null) {
    let errors = [];
    if (isFormSubmitted || index === null) {
      for (let i = 0; i < guestAgenda.length; i++) {
        const max = guestAgenda[i].maximum_bookable_element;
        const min = guestAgenda[i].minimum_bookable_element;
        let selectedElements = guestAgenda[i].elements.filter(
          (item) => item.checked
        ).length;
        if (min) {
          if (min <= selectedElements) {
            $(`#error-min-${guestAgenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-min-${guestAgenda[i]._id}-${i}`).text(
              DT(t("web_registration_err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))])
            );
            errors.push({
              message: DT(t("web_registration_err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]),
              id: `error-min-${guestAgenda[i]._id}-${i}`,
            });
          }
        }
        if (max) {
          if (max >= selectedElements) {
            $(`#error-max-${guestAgenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-max-${guestAgenda[i]._id}-${i}`).text(
              DT(t("web_registration_err_max_agenda"),[max])
            );
            errors.push({
              message: DT(t("web_registration_err_max_agenda"),[max]),
              id: `error-max-${guestAgenda[i]._id}-${i}`,
            });
          }
        }
      }
    } else {
      const max = guestAgenda[index].maximum_bookable_element;
      const min = guestAgenda[index].minimum_bookable_element;
      let selectedElements = guestAgenda[index].elements.filter(
        (item) => item.checked
      ).length;
      if (min) {
        if (min <= selectedElements) {
          $(`#error-min-${guestAgenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-min-${guestAgenda[index]._id}-${index}`).text(DT(t("web_registration_err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]));
          errors.push({
            message: DT(t("web_registration_err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]),
            id: `error-min-${guestAgenda[index]._id}-${index}`,
          });
        }
      }
      if (max) {
        if (max >= selectedElements) {
          $(`#error-max-${guestAgenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-max-${guestAgenda[index]._id}-${index}`).text(DT(t("web_registration_err_max_agenda"),[max]));
          errors.push({
            message: DT(t("web_registration_err_max_agenda"),[max]),
            id: `error-max-${guestAgenda[index]._id}-${index}`,
          });
        }
      }
    }
    return errors;
  }

  const handleElementPrice = () => {
    let allElementPrice = [];
    guestAgenda &&
      guestAgenda.forEach((item, index) => {
        item?.elements?.forEach((element, eIndex) => {
          if (element?.checked) {
            allElementPrice.push({ elementPrice: element });
          }
        });
      });
    setAgendaPricing(allElementPrice);
  };

  const initialValues = {
    title: "",
    gender: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    vegan: false,
    vegitarian: false,
    allergic: false,
    specificValue: "",
    passportNumber: "",
    surname: "",
    visaFirstName: "",
    dateOfBirth: "",
    dateOfIssue: "",
    nationality: "",
    placeOfBirth: "",
    dateOfExpiry: "",
    authority: "",
    guestVisaInvitation: false,
    questionData: [...preSelectedValues],
    // questionData: registrationData && registrationData?.guest_user && registrationData?.guest_user?.questions_data ? [registrationData?.guest_user?.questions_data ? [...registrationData?.guest_user?.questions_data] : []] : [detailsData?.secondary_question_set_data?.questions ? [...detailsData?.secondary_question_set_data?.questions] : []],
    add_guest: false,
    userPermission: false,
    companyName: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    accommondation: false,
    checkIn: "",
    checkOut: "",
    roomType: "",
    hotel:
      availableHotelList && availableHotelList === 1
        ? availableHotelList[0]._id
        : "Select",
  };
  // console.log("aniii",detailsData?.primary_question_set_data?.questions)

  // const initialValuesForEdit = {
  //   gender:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.gender
  //       ? registrationData?.guest_user?.gender
  //       : "",
  //   firstName:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.first_name
  //       ? registrationData.guest_user.first_name
  //       : "",
  //   middleName:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.middle_name
  //       ? registrationData.guest_user.middle_name
  //       : "",
  //   lastName:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.last_name
  //       ? registrationData.guest_user.last_name
  //       : "",
  //   mobileNumber:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.mobile_number
  //       ? registrationData.guest_user.mobile_number
  //       : "",
  //   email:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.email
  //       ? registrationData.guest_user.email
  //       : "",
  //   vegan:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.vegan
  //       ? registrationData.guest_user.vegan
  //       : false,
  //   vegitarian:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.vegetarian
  //       ? registrationData.guest_user.vegetarian
  //       : false,
  //   allergic:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.allergic
  //       ? registrationData.guest_user.allergic
  //       : false,
  //   specificValue:
  //     registrationData &&
  //     registrationData?.guest_user &&
  //     registrationData?.guest_user?.allergic_reason
  //       ? registrationData.guest_user.allergic_reason
  //       : "",
  //   passportNumber: "",
  //   surname: "",
  //   visaFirstName: "",
  //   dateOfBirth: "",
  //   dateOfIssue: "",
  //   nationality: "",
  //   placeOfBirth: "",
  //   dateOfExpiry: "",
  //   authority: "",
  //   guestVisaInvitation: false,
  //   questionData: [...preSelectedValues],
  //   add_guest:
  //     registrationData && registrationData?.add_guest
  //       ? registrationData?.add_guest
  //       : false,
  //   userPermission: false,
  //   companyName: "",
  //   address: "",
  //   postalCode: "",
  //   city: "",
  //   country: "",
  //   accommondation: false,
  //   checkIn: "",
  //   checkOut: "",
  //   roomType: "",
  //   hotel:
  //     detailsData?.hotels_data && detailsData.hotels_data.length == 1
  //       ? detailsData.hotels_data[0]._id
  //       : "Select",
  // };

  const validationObject = {
    ...dynamicValidationsForInvoice,
    ...dynamicValidations,
    checkIn: Yup.string().test(
      "check_acommondation",
      t('web_registration_err_check_in'),
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          return Boolean(value);
        } else {
          return true;
        }
      }
    ),
    checkOut: Yup.string().test(
      "check_acommondation",
      t('web_registration_err_check_out'),
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          return Boolean(value);
        } else {
          return true;
        }
      }
    ),
    hotel: Yup.string().test(
      "check_acommondation",
      t('web_registration_err_hotel'),
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          if (value.length > 10) {
            return Boolean(value);
          } else {
            return false
          }
        } else {
          return true;
        }
      }
    ),
    roomType: Yup.string().test(
      "check_acommondation",
      t('web_registration_err_room_type'),
      (value) => {
        if (formikRef?.current?.values.accommondation) {
          if (value !== "Select") {
            return Boolean(value);
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    ),
  };

  const validationSchema = Yup.object().shape(validationObject);

  const selectAgendaElement = (parentArray, items, value, agendaIndex) => {
    const newArray = parentArray.elements.map((i) => {
      if (i._id === items._id) {
        i.checked = value;
        return i;
      } else {
        return i;
      }
    });

    const selectedAgenda = { ...parentArray, elements: newArray };
    let anotherAgendas = [...guestAgenda];
    anotherAgendas[agendaIndex] = selectedAgenda;
    setGuestAgenda(anotherAgendas);
  };

  useEffect(() => {
    if (params.get("edit") === true || params.get("edit") === "true") {
      checkHotelPrice();
    }
  }, [
    formikRef?.current?.values?.hotel,
    formikRef?.current?.values?.roomType,
    formikRef?.current?.values?.checkIn,
    formikRef?.current?.values?.checkOut,
    invitationDetailsData,
    detailsData,
    registrationData,
  ]);

  const onSubmitHandler = (values) => {
    if (values.userPermission) {
      if (params.get("edit") === true || params.get("edit") === "true") {
        if (detailsData?.secondary_registrants_allowed_to_agenda_options) {
          if (values.add_guest) {
            if (validateElements().length) {
              return;
            }
          }
        }

        globalLoader(true);
        let editRegistrationSubmissionData = {
          event_id: detailsData?._id,
          invitation_id: invitationValue,
          registration_id: params.get("reg_id"),
          accommodation: values.accommondation,
          add_guest: values.add_guest,
          vegan: state.values.vegan,
          vegetarian: state.values.vegeterian,
          allergic: state.values.allergic,
          specifyText: state.values.specifyText,
          visaInvitation: state.values.visaInvitation,
          start_date: state && state.values && state.values.startDate ? state.values.startDate : detailsData?.start_date,
          end_date: state && state.values && state.values.endDate ? state.values.endDate : detailsData?.end_date,
          check_in: getDate(values.checkIn, "YYYY-MM-DD", false),
          check_out: getDate(values.checkOut, "YYYY-MM-DD", false),
          room_type: values.roomType,
          hotel: values.hotel,
          company_name: values.companyName,
          address: values.address,
          postal_code: values.postalCode,
          city: values.city,
          country: values.country,
          passport_number: state.values.passportNumber,
          surname: state.values.surname,
          first_name: state.values.firstName,
          date_of_birth: state.values.dateOfBirth,
          date_of_issue: state.values.dateOfIssue,
          place_of_birth: state.values.placeOfBirth,
          nationality: state.values.nationality,
          date_of_expiry: state.values.dateOfExpiry,
          authority: state.values.authority,
          agenda: state.values.agenda,
          total_price: totalPrice,
          guest_details: {
            agenda: guestAgenda,
            gender: values.gender,
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            mobileNumber: values.mobileNumber,
            email: values.email,
            vegan: values.vegan,
            vegitarian: values.vegitarian,
            allergic: values.allergic,
            specificValue: values.specificValue,
            passportNumber: values.passportNumber,
            surname: values.surname,
            visaFirstName: values.visaFirstName,
            dateOfBirth: values.dateOfBirth,
            dateOfIssue: values.dateOfIssue,
            nationality: values.nationality,
            placeOfBirth: values.placeOfBirth,
            dateOfExpiry: values.dateOfExpiry,
            authority: values.authority,
            guestVisaInvitation: values.guestVisaInvitation,
            questionData: values.questionData,
          },
        };
        if(window.registrationFinalForm){
          let finalForm = window.registrationFinalForm;
          if(!window.registrationStep2Submitted){
            window.registrationFinalForm.append('main_data', JSON.stringify(editRegistrationSubmissionData));
            if(values.add_guest){
              let questionResult = dynamicQuestionsRef?.current?.getValues();
              finalForm = prepareQuestionsFormData(questionResult, window.registrationFinalForm, questionFilesUploadPath, "stepTwo");
            }
            window.registrationStep2Submitted = true;
          }
          commonServices.EditEventRegistration(finalForm).then((registrationResponse) => {
            console.log(registrationResponse,"registrationResponse")
            if (registrationResponse.success) {
              window.registrationStep2Submitted = false;
              Swal.fire({
                icon: "success",
                text: registrationResponse.message,
                ...SWAL_SETTINGS,
              });
              // navigate("/settings/event-registrations/list/1");
              if (oldPricing?.grand_total < totalPrice) {
                // globalLoader(true);
                // commonServices.createPayment({
                //   amount: Number(totalPrice) - Number(oldPricing?.grand_total),
                //   registration_id: registrationResponse?.data?.registration_id,
                //   event_id: registrationResponse?.data?.event_id
                // }).then((response) => {
                //   if (response && response?.success) {
                //     window.location.href = response?.data?.paymentUrl;
                //     globalLoader(false);
                //   } else {
                //     globalLoader(false);
                //   }
                // }).catch((err) => {
                //   console.log("err", err);
                //   globalLoader(false);
                // })
                setRegistrationId(registrationResponse?.data?.registration_id);
                ChoosePaymentTrigger.current.click();
                globalLoader(false);
              } else {
                globalLoader(false);
                navigate("/settings/event-registrations/list/1");
              }
            } else {
              globalLoader(false);
              Swal.fire({
                icon: "error",
                text: handleServerValidations(registrationResponse),
                ...SWAL_SETTINGS,
              });
            }
          }).catch((error) => {
            globalLoader(false);
            console.log("error", error);
          })
        }else{
          navigate(`/event-registration?event_id=${params.get("event_id")}&invitation_id=${params.get("invitation_id")}&reg_id=${params.get("reg_id")}&edit=true`);
        }
      } 
      else {
        if (detailsData?.secondary_registrants_allowed_to_agenda_options) {
          if (values.add_guest) {
            if (validateElements().length) {
              return;
            }
          }
        }
        console.log(('state',detailsData));
        globalLoader(true);
        let registrationSubmissionData = {
          event_id: detailsData._id,
          invitation_id: invitationValue,
          accommodation: values.accommondation,
          add_guest: values.add_guest,
          vegan: state.values.vegan,
          vegetarian: state.values.vegeterian,
          allergic: state.values.allergic,
          specifyText: state.values.specifyText,
          visaInvitation: state.values.visaInvitation,
          start_date: state && state.values && state.values.startDate ? state.values.startDate : detailsData?.start_date,
          end_date: state && state.values && state.values.endDate ? state.values.endDate : detailsData?.end_date,
          check_in: getDate(values.checkIn, "YYYY-MM-DD", false),
          check_out: getDate(values.checkOut, "YYYY-MM-DD", false),
          room_type: values.roomType,
          hotel: values.hotel,
          company_name: values.companyName,
          address: values.address,
          postal_code: values.postalCode,
          city: values.city,
          country: values.country,
          passport_number: state.values.passportNumber,
          surname: state.values.surname,
          first_name: state.values.firstName,
          date_of_birth: state.values.dateOfBirth,
          date_of_issue: state.values.dateOfIssue,
          nationality: state.values.nationality,
          place_of_birth: state.values.placeOfBirth,
          date_of_expiry: state.values.dateOfExpiry,
          authority: state.values.authority,
          agenda: state.values.agenda,
          total_price: totalPrice,
          guest_details: {
            agenda: guestAgenda,
            gender: values.gender,
            title: values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            mobileNumber: values.mobileNumber,
            email: values.email,
            vegan: values.vegan,
            vegitarian: values.vegitarian,
            allergic: values.allergic,
            specificValue: values.specificValue,
            passportNumber: values.passportNumber,
            surname: values.surname,
            visaFirstName: values.visaFirstName,
            dateOfBirth: values.dateOfBirth,
            dateOfIssue: values.dateOfIssue,
            nationality: values.nationality,
            placeOfBirth: values.placeOfBirth,
            dateOfExpiry: values.dateOfExpiry,
            authority: values.authority,
            guestVisaInvitation: values.guestVisaInvitation,
          },
        };
        if(window.registrationFinalForm){
          let finalForm = window.registrationFinalForm;
          if(!window.registrationStep2Submitted){
            window.registrationFinalForm.append('main_data', JSON.stringify(registrationSubmissionData));
            if(values.add_guest){
              let questionResult = dynamicQuestionsRef?.current?.getValues();
              finalForm = prepareQuestionsFormData(questionResult, window.registrationFinalForm, questionFilesUploadPath, "stepTwo");
            }
            window.registrationStep2Submitted = true;
          }
          commonServices.EventRegistration(finalForm).then((registrationResponse) => {
              if (registrationResponse && registrationResponse.success) {
                window.registrationStep2Submitted = false;
                if(params.get("invitation_id") && params.get("invitation_id")!="null" && detailsData.event_type===EVENT_TYPES.INVITATION_ONLY){
                  commonServices
                  .markInvitation({
                    action: 1,
                    event_id: detailsData._id,
                    invitation_id: invitationValue /* for accepted */,
                    ...(values.add_guest ? { guest_email: values.email } : {}),
                  })
                  .then((data) => {
                    if (data.success) {
                      Swal.fire({
                        icon: "success",
                        text: registrationResponse.message,
                        ...SWAL_SETTINGS,
                      });
                      if (totalPrice > 0) {
                        setRegistrationId(registrationResponse?.data?.registration_id);
                        ChoosePaymentTrigger.current.click();
                        globalLoader(false);
                      } else {
                        globalLoader(false);
                        navigate("/registration-thanks-page");
                      }
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: handleServerValidations(registrationResponse),
                        ...SWAL_SETTINGS,
                      });
                      globalLoader(false);
                    }
                  })
                  .catch((error) => {
                    console.log("error", error);
                    globalLoader(false);
                  });
                }
                else{
                  Swal.fire({
                    icon: "success",
                    text: registrationResponse.message,
                    ...SWAL_SETTINGS,
                  });
                  if (totalPrice > 0) {
                    setRegistrationId(registrationResponse?.data?.registration_id);
                    ChoosePaymentTrigger.current.click();
                    globalLoader(false);
                  } else {
                    globalLoader(false);
                    navigate("/registration-thanks-page");
                  }
                }
                
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(registrationResponse),
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            }).catch((error) => {
              console.log("error", error);
              globalLoader(false);
            });
        }else{
          navigate(`/event-registration?event_id=${params.get("event_id")}&invitation_id=${params.get("invitation_id")}`);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        text: t('web_registration_err_terms_condition'),
        ...SWAL_SETTINGS,
      });
      globalLoader(false);
    }
  };

  const checkValidationError = () => {
    setTimeout(() => {
      if (
        formikRef?.current?.errors &&
        Object.keys(formikRef?.current?.errors).length
      ) {
        Swal.fire({
          icon: "error",
          text: t('web_registration_err_fill_required_field'),
          ...SWAL_SETTINGS,
        });
      }
    }, 100);
  };

  const checkHotelPrice = () => {
    let formikData = formikRef?.current?.values;
    let hotelsData = availableHotelList;
    if (formikData && hotelsData.length) {
      let currentHotel = hotelsData.filter(
        (item) => item?._id === formikData.hotel
      )[0];
      if (currentHotel) {
        setCheckInDate(currentHotel?.start_date);
        setCheckOutDate(currentHotel?.end_date);
      }
      if (
        currentHotel &&
        formikData?.checkIn &&
        formikData?.checkOut &&
        formikData?.roomType
      ) {
        let nights = getDayBetweenDates(
          (new Date(formikData?.checkIn)).setHours(0,0,0,0),
          (new Date(formikData?.checkOut)).setHours(0,0,0,0),
        );
        let quotaDateFormated = currentHotel.quota.map((item) => {
          return {
            date: item.date,
            double_room: item.double_room,
            single_room: item.single_room,
          };
        });
        let roomPrice = 0;
        let breakfastPrice = 0;
        nights.forEach((night) => {
          // let thisNightDate = `${new Date(night).getFullYear()}-${
          //   new Date(night).getMonth() + 1
          // }-${new Date(night).getDate()}`;
          // let newDate = new Date(night);
          // newDate.setUTCHours(23, 59, 59, 1000);
          // let thisNightDate = newDate.toISOString().split("T")[0];
          let quota = quotaDateFormated.filter(
            (quota) => moment(new Date(night)).format("DD-MM-YYYY") === quota.date
          )[0];
          if (quota) {
            if (formikData?.roomType === ROOM_TYPE.Single_Room) {
              roomPrice += quota.single_room.price;
              breakfastPrice += quota.single_room.breakfast_price;
            } else {
              roomPrice += quota.double_room.price;
              breakfastPrice += quota.double_room.breakfast_price;
            }
          }
        });
        let finalData = {
          taxes: {
            breakfast_tax: currentHotel.breakfast_tax,
            city_tax: currentHotel.city_tax,
            hotel_tax: currentHotel.tax,
          },
          prices: {
            hotel_tax: myToFixed(calculatePercentage(currentHotel.tax, roomPrice)),
            room_price: myToFixed(roomPrice),
            breakfast_tax: myToFixed(calculatePercentage(currentHotel.breakfast_tax, breakfastPrice)),
            breakfast: myToFixed(breakfastPrice),
            city_tax: myToFixed(currentHotel.city_tax),
          },
          nights: nights.length,
          sub_total: myToFixed((calculatePercentage(currentHotel.tax, roomPrice) + roomPrice + breakfastPrice + currentHotel.city_tax + calculatePercentage(currentHotel.breakfast_tax, breakfastPrice))),
        };
        setFinalHotelData(finalData);
      }
    }
  };

  const shouldDisabledHotel = (values) => {
    if (availableHotelList.length === 0) {
      return true;
    } else if (availableHotelList.length === 1) {
      return true;
    } else if (values.hotel === "Select") {
      return false;
    } else if (values.hotel === "") {
      return false;
    } else {
      return false;
    }
  }

  const updateHotelList = () => {
    if (
      formikRef?.current?.values?.checkIn &&
      formikRef?.current?.values?.checkOut
    ) {
      otherServices
        .CheckHotelAvailability({
          start_date: moment(formikRef?.current?.values?.checkIn).format(
            "YYYY-MM-DD"
          ),
          end_date: moment(formikRef?.current?.values?.checkOut).format(
            "YYYY-MM-DD"
          ),
          event_id: detailsData?._id,
        })
        .then((response) => {
          if (response?.success) {
            if (response?.data.length <= 0) {
              Swal.fire({
                icon: "error",
                text: t('web_registration_err_hotel_availability'),
                ...SWAL_SETTINGS,
              });
              formikRef?.current?.setFieldValue('hotel', "Select");
            }
            setAvailableHotelList(response?.data);
            formikRef?.current?.setFieldValue('hotel', response?.data && response?.data.length === 1 ? response?.data[0]._id : "Select");
            setTimeout(() => {
              checkHotelPrice();
            }, 1000)
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  // const handlePayPalPayment = () => {
  //   commonServices.createPayment({
  //     amount: totalPrice,
  //     registration_id: registrationId,
  //     event_id: params.get("event_id")
  //   }).then((response) => {
  //     if (response && response?.success) {
  //       window.location.href = response?.data?.paymentUrl;
  //       globalLoader(false);
  //     } else {
  //       globalLoader(false);
  //     }
  //   }).catch((err) => {
  //     console.log("err", err);
  //   })
  // }

  const handlePayPalPayment = () => {
    globalLoader(true);
    commonServices.createPayment({
      amount: totalPrice,
      registration_id: registrationId,
      event_id: detailsData?._id
    }).then((response) => {
      if (response && response.success) {
          window.location.href = response.data.paymentUrl;
          globalLoader(false);
      } else {
        globalLoader(false);
      }
    }).catch((err) => {
      console.log("err", err);
      globalLoader(false);
    });
  };

const handleInvoicePayment =()=>{
  navigate("/invoice-payment-page",{state:{event_type:detailsData?.event_type}});
  document.getElementById('close_button').click();
}

  return (
    <div className="wraper-inner cpt bg-grey">
      {console.log(apiDone,secondApiDone,editApiDone)}
      {apiDone && secondApiDone &&
        editApiDone ? (
        <Formik
          validateOnBlur={false}
          innerRef={formikRef}
          initialValues={{
            ...(params.get("edit") === true || params.get("edit") === "true"
              ? {
                gender:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.gender
                    ? selectSalutation(registrationData?.guest_user?.gender)
                    : "",
                title:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.title
                    ? registrationData?.guest_user?.title
                    : "",
                firstName:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.first_name
                    ? registrationData.guest_user.first_name
                    : "",
                middleName:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.middle_name
                    ? registrationData.guest_user.middle_name
                    : "",
                lastName:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.last_name
                    ? registrationData.guest_user.last_name
                    : "",
                mobileNumber:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.phone_number
                    ? registrationData.guest_user.phone_number
                    : "",
                email:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.email
                    ? registrationData.guest_user.email
                    : "",
                vegan:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.vegan
                    ? registrationData.guest_user.vegan
                    : false,
                vegitarian:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.vegetarian
                    ? registrationData.guest_user.vegetarian
                    : false,
                allergic:
                  registrationData &&
                    registrationData?.guest_user &&
                    registrationData?.guest_user?.allergic
                    ? registrationData.guest_user.allergic
                    : false,
                specificValue: registrationData && registrationData?.guest_user && registrationData?.guest_user?.allergy_reason ? registrationData.guest_user.allergy_reason : "",
                passportNumber: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.passport_number ? registrationData?.guest_user?.visa_info?.passport_number : "",
                surname: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.surname ? registrationData?.guest_user?.visa_info?.surname : "",
                visaFirstName: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.first_name ? registrationData?.guest_user?.visa_info?.first_name : "",
                dateOfBirth: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.date_of_birth ? registrationData?.guest_user?.visa_info?.date_of_birth : "",
                dateOfIssue: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.date_of_issue ? registrationData?.guest_user?.visa_info?.date_of_issue : "",
                nationality: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.nationality ? registrationData?.guest_user?.visa_info?.nationality : "",
                placeOfBirth: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.place_of_birth ? registrationData?.guest_user?.visa_info?.place_of_birth : "",
                dateOfExpiry: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.date_of_expiry ? registrationData?.guest_user?.visa_info?.date_of_expiry : "",
                authority: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info && registrationData?.guest_user?.visa_info?.authority ? registrationData?.guest_user?.visa_info?.authority : "",
                guestVisaInvitation: registrationData && registrationData?.guest_user && registrationData?.guest_user?.visa_invitation ? registrationData?.guest_user?.visa_invitation : "",
                questionData: registrationData && registrationData?.guest_user && registrationData?.guest_user?.questions_data ? [...registrationData?.guest_user?.questions_data] : 
                detailsData && detailsData?.secondary_question_set_data?.questions ? [...detailsData?.secondary_question_set_data?.questions] :[],
                add_guest:
                  registrationData && registrationData?.add_guest
                    ? registrationData?.add_guest
                    : false,
                userPermission: true,
                companyName:
                  registrationData && registrationData?.company_name
                    ? registrationData?.company_name
                    : "",
                address:
                  registrationData && registrationData?.address
                    ? registrationData?.address
                    : "",
                postalCode:
                  registrationData && registrationData?.postal_code
                    ? registrationData?.postal_code
                    : "",
                city:
                  registrationData && registrationData?.city
                    ? registrationData?.city
                    : "",
                country:
                  registrationData && registrationData?.country
                    ? registrationData?.country
                    : "",
                accommondation:
                  registrationData && registrationData?.accommodation
                    ? registrationData?.accommodation
                    : false,
                checkIn:
                  registrationData && registrationData?.check_in
                    ? registrationData?.check_in
                    : "",
                checkOut:
                  registrationData && registrationData?.check_out
                    ? registrationData?.check_out
                    : "",
                roomType:
                  registrationData && registrationData?.room_type
                    ? registrationData?.room_type
                    : "",
                hotel:
                  detailsData?.hotels_data &&
                    detailsData.hotels_data.length === 1
                    ? detailsData.hotels_data[0]._id
                    : registrationData &&
                      registrationData?.hotel_data &&
                      registrationData?.hotel_data?._id
                      ? registrationData?.hotel_data?._id
                      : "Select",
              }
              : initialValues),
          }}
          onSubmit={(values) => {
            let requiredButNotAnswered = []
            values?.questionData?.forEach(question => {
              if (question.is_required && !question.answer) requiredButNotAnswered.push(question)
            })
            if (values?.add_guest) {
              const questionFormResult = dynamicQuestionsRef?.current?.submit();
              if(questionFormResult?.isValid){
                onSubmitHandler(values);
              }else{
                return
              }
            }else{
              onSubmitHandler(values);
            }
          }}
          validationSchema={validationSchema}
          validate={(values) => {
            return {};
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            handleChange,
            handleBlur,
            touched,
            dirty,
          }) => (
            <Form onSubmit={handleSubmit}>
              {console.log("errors",errors)} 
              {console.log("values",values)} 
              <section className="event-page-register mt-5 pb-5">
                <div className="container">
                  <h3 className="inner-title">{t("web_registration")}</h3>
                  <div className="dash-box-shadow-2 position-relative">
                    <h4 className="reg-title">{detailsData?.title}</h4>
                    {detailsData?.event_quota?.price > 0 ? <span className="mb-2 price-text">{t('web_lbl_price')}: <span>{detailsData?.currency?.sign}{detailsData?.event_quota?.price}</span></span> : <span className="mb-2 price-text text-success">{t('web_registration_free')}</span>}
                    <ul className="card-info-list pb-0">
                      <li>
                        <div className="date-info pb-0">
                          <i className="ri-calendar-event-line black-icon" />
                          {formateDateWithMonth(detailsData?.start_date)}
                          {" - "}
                          {formateDateWithMonth(detailsData?.end_date)}
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="step-process">
                    <ul className="progessbar-list d-flex">
                      <li className="active">
                        <span>01</span>
                        <h6>{t("web_registration_step_one")}</h6>
                      </li>
                      <li className="active">
                        <span>02</span>
                        <h6>{t("web_registration_step_two")}</h6>
                      </li>
                    </ul>
                  </div>
                  {detailsData &&
                    detailsData.guest_can_add_secondary === true ? (
                    <div className="col-md-6 mb-3 about-accommodation">
                      <h4 className="reg-title">
                        {t("web_event_registration_guest")}{" "}
                      </h4>
                      <div className="toggle-box d-flex align-items-center justify-content-between mb-2">
                        <label className="form-label">
                          {t("web_event_registration_add_guest")}
                        </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={values.add_guest}
                            onChange={async (e) => {
                              await setFieldValue(
                                "add_guest",
                                e.target.checked
                              );
                              setGuestValidation(e.target.checked);
                              setGuestAgendaSubtotal(0);
                              if (e.target.checked) {
                                await setFieldValue(
                                  "roomType",
                                  ROOM_TYPE.Double_Room
                                );
                              } else {
                                await setFieldValue("roomType", "");
                              }
                              checkHotelPrice();
                            }}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {detailsData &&
                    detailsData.accommodation_offered === true && (
                      // (availableHotelList.length != 0 || values.accommondation) && (
                      <div className="col-md-6 mb-3 about-accommodation">
                        <h4 className="reg-title">
                          {t("web_event_registration_accommodation")}
                        </h4>
                        <div className="toggle-box d-flex align-items-center justify-content-between mb-2">
                          <label className="form-label">
                            {t("web_event_registration_need_accommodation")}
                          </label>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={values.accommondation}
                              onChange={(e) => {
                                setFieldValue(
                                  "accommondation",
                                  e.target.checked
                                );
                                setFinalHotelData({
                                  nights: 0,
                                  sub_total: 0,
                                });
                                if (e.target.checked) {
                                  checkHotelPrice();
                                }
                              }}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </div>
                    )}
                  {values.accommondation && values.accommondation && (
                    <div>
                      <div className="accommodation-checkin">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t("web_event_registration_check_in")}
                              <span className="requirestar">*</span>
                            </label>
                            <div className="date-box date datepicker">
                              <DatePicker
                                showMonthDropdown
                                scrollableYearDropdown
                                showYearDropdown
                                yearDropdownItemNumber={30}
                                onChange={async (date) => {
                                  await setFieldValue("checkIn", date);
                                  await setFieldValue("checkOut", "");
                                  checkHotelPrice();
                                  updateHotelList();
                                }}
                                className={`form-control`}
                                value={
                                  values.checkIn === ""
                                    ? ""
                                    : moment(values.checkIn).format(
                                      "DD/MM/YYYY"
                                    )
                                }
                                minDate={new Date(checkInDate) && new Date()}
                                maxDate={new Date(checkOutDate)}
                              />
                              <span className="input-group-append">
                                <span className="input-cal-icon ">
                                  <i className="ri-calendar-2-line"></i>
                                </span>
                              </span>
                            </div>
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.checkIn &&
                                touched.checkIn &&
                                errors.checkIn}
                            </span>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              {t("web_event_registration_check_out")}
                              <span className="requirestar">*</span>
                            </label>
                            <div className="date-box date datepicker">
                              <DatePicker
                                showMonthDropdown
                                scrollableYearDropdown
                                showYearDropdown
                                yearDropdownItemNumber={30}
                                onChange={async (date) => {
                                  await setFieldValue("checkOut", date);
                                  checkHotelPrice();
                                  updateHotelList();
                                }}
                                className={`form-control`}
                                value={
                                  values.checkOut === ""
                                    ? ""
                                    : moment(values.checkOut).format(
                                      "DD/MM/YYYY"
                                    )
                                }
                                minDate={
                                  new Date(checkInDate) &&
                                  new Date(
                                    values.checkIn ? values.checkIn : new Date()
                                  )
                                }
                                maxDate={new Date(checkOutDate)}
                              />
                              <span className="input-group-append">
                                <span className="input-cal-icon ">
                                  <i className="ri-calendar-2-line"></i>
                                </span>
                              </span>
                            </div>
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.checkOut &&
                                touched.checkOut &&
                                errors.checkOut}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">
                              {t("web_event_registration_hotel")}
                              <span className="requirestar">*</span>
                            </label>
                            <div className="select-down-arrow">
                              <select
                                disabled={shouldDisabledHotel(values)}
                                id="hotel"
                                onChange={async (e) => {
                                  await setFieldValue("hotel", e.target.value);
                                  checkHotelPrice();
                                }}
                                name={"hotel"}
                                value={values.hotel}
                                className={"form-control text-capitalize"}
                              >
                                <option label="Select Hotel">
                                  {t("web_lbl_select")}{" "}
                                </option>
                                {availableHotelList &&
                                  availableHotelList.map((option, i) => {
                                    return (
                                      <option key={i} value={option._id}>
                                        {option.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.hotel && touched.hotel && errors.hotel}
                            </span>
                          </div>
                          <div className="col-md-6 mb-4">
                            <label className="form-label">
                              {t("web_event_registration_type")}
                              <span className="requirestar">*</span>
                            </label>
                            <div className="select-down-arrow">
                              <select
                                disabled={
                                  values.hotel === "Select" || "" ? true : false
                                }
                                as="select"
                                id="roomType"
                                value={values.roomType}
                                onChange={async (e) => {
                                  await handleChange(e);
                                  checkHotelPrice();
                                }}
                                name={"roomType"}
                                className={`form-control text-capitalize ${values.hotel === "Select" || ""
                                  ? "cursor-no-drop"
                                  : ""
                                  }`}
                              >
                                <option label="Select Room Type">
                                  {t("web_lbl_select")}{" "}
                                </option>
                                {values.hotel !== "" &&
                                  Object.keys(ROOM_TYPE).map((option, i) => {
                                    return (
                                      <option
                                        disabled={
                                          values.add_guest === true &&
                                            option === "Single_Room"
                                            ? true
                                            : false
                                        }
                                        key={i}
                                        value={ROOM_TYPE[option]}
                                      >
                                        {removeSpecialCharacter(option)}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.roomType &&
                                touched.roomType &&
                                errors.roomType}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {values.add_guest ? (
                    <div className="dash-box-shadow-2">
                      <div className="workshop-box">
                        <h4 className="reg-title">
                          {t("web_event_registration_guest")}
                        </h4>
                        <div className="accommodation-checkin">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_title")}
                              </label>
                              <input
                                type="text"
                                name="title"
                                placeholder={t("web_lbl_title")}
                                className="form-control"
                                value={values.title}
                                onChange={(event) =>
                                  setFieldValue(`title`, event.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_salutation")}
                                <span className="requirestar">*</span>
                              </label>
                              <div className="select-down-arrow">
                                <Field
                                  as="select"
                                  id="gender"
                                  name={`gender`}
                                  className="form-control select2"
                                  style={{ fontSize: 14 }}
                                >
                                  <option value="" label="Select" className="">
                                    {t("web_lbl_select")}{" "}
                                  </option>
                                  {getTranslatedGender() &&
                                    Object.keys(getTranslatedGender()).length > 0 &&
                                    Object.values(getTranslatedGender()).map((item, i) => {
                                      return (
                                        <option key={i} value={item}>
                                          {capitalizeFirstLetter(item)}
                                        </option>
                                      );
                                    })}
                                </Field>
                              </div>
                              <span className="text-danger d-flex text-left">
                                <ErrorMessage name={`gender`} />
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_first_name")}
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="firstName"
                                placeholder={t("web_lbl_first_name")}
                                className="form-control"
                                value={values.firstName}
                                onChange={(event) =>
                                  setFieldValue(
                                    `firstName`,
                                    event.nativeEvent.target.value
                                  )
                                }
                              />
                              <span className="text-danger d-flex text-left">
                                <ErrorMessage name={`firstName`} />
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_middle_name")}
                              </label>
                              <input
                                type="text"
                                name="middleName"
                                placeholder={t("web_lbl_middle_name")}
                                className="form-control"
                                value={values.middleName}
                                onChange={(event) =>
                                  setFieldValue(
                                    `middleName`,
                                    event.target.value
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_lbl_last_name")}
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="lastName"
                                placeholder={t("web_lbl_last_name")}
                                value={values.lastName}
                                className="form-control"
                                onChange={(event) =>
                                  setFieldValue(
                                    `lastName`,
                                    event.nativeEvent.target.value
                                  )
                                }
                              />
                              <span className="text-danger d-flex text-left">
                                <ErrorMessage name={`lastName`} />
                              </span>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label">
                                {t("web_lbl_mobile_number")}
                                <span className="requirestar">*</span>
                              </label>
                              <PhoneInput
                                type="text"
                                defaultCountry="de"
                                style={{ padding: 0, border: 0 }}
                                countrySelectorStyleProps={{
                                  height: "auto",
                                  buttonStyle: {
                                    height: "100%",
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                  },
                                }}
                                inputStyle={{
                                  height: "100%",
                                  width: "100%",
                                  paddingLeft: 12,
                                  fontSize: 14,
                                }}
                                name="mobileNumber"
                                placeholder={t("web_lbl_mobile_number")}
                                className="form-control"
                                forceDialCode={true}
                                value={values.mobileNumber}
                                onChange={(value, CountryIso2) => {
                                  setFieldValue(`mobileNumber`, value);
                                }}
                              />
                              <span className="text-danger d-flex text-left">
                                <ErrorMessage name={`mobileNumber`} />
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                {t("web_event_registration_email")}
                                <span className="requirestar">*</span>
                              </label>
                              <input
                                type="text"
                                name="email"
                                placeholder={t("web_event_registration_email")}
                                className="form-control"
                                value={values.email}
                                onChange={(event) =>
                                  setFieldValue(
                                    `email`,
                                    event.nativeEvent.target.value
                                  )
                                }
                              />
                              <span className="text-danger d-flex text-left">
                                <ErrorMessage name={`email`} />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="about-dietary mt-3 mb-3">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <h4 className="reg-title">
                              {t("web_event_registration_dietary")}
                            </h4>
                            <h5 className="reg-sub-title mb-2">
                              {t("web_event_registration_diet")}
                            </h5>
                            <div className="chek-box-card mt-3">
                              <div className="form-check black-check">
                                <input
                                  name="vegan"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={values.vegan}
                                  checked={values.vegan}
                                  id="vegan"
                                  onChange={(event) => {
                                    setFieldValue(
                                      `vegan`,
                                      event.target.checked
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cp"
                                  htmlFor="vegan"
                                >
                                  {" "}
                                  {t("web_event_registration_vegan")}
                                </label>
                              </div>
                              <div className="form-check black-check">
                                <input
                                  name="vegitarian"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={values.vegitarian}
                                  checked={values.vegitarian}
                                  id="vegitarian"
                                  onChange={(event) => {
                                    setFieldValue(
                                      `vegitarian`,
                                      event.target.checked
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cp"
                                  htmlFor="vegitarian"
                                >
                                  {t("web_event_registration_vegitarian")}
                                </label>
                              </div>
                              <div className="form-check black-check">
                                <input
                                  name="allergic"
                                  className="form-check-input"
                                  type="checkbox"
                                  value={values.allergic}
                                  checked={values.allergic}
                                  id="allergic"
                                  onChange={(event) => {
                                    setFieldValue(
                                      `allergic`,
                                      event.target.checked
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label cp"
                                  htmlFor="allergic"
                                >
                                  {t("web_event_registration_allergic")}
                                </label>
                              </div>
                            </div>
                            <span className="text-danger d-flex text-left">
                              <ErrorMessage name={`food_prefrences`} />
                            </span>
                            {values.allergic && (
                              <div className="form-group mt-3 please-specify">
                                <label className="form-label">
                                  {t("web_registration_specify")}
                                </label>
                                <input
                                  type="text"
                                  name="specificValue"
                                  placeholder={t('web_registration_specify')}
                                  className="form-control"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.specificValue}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.specificValue &&
                                    touched.specificValue &&
                                    errors.specificValue}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {guestAgenda &&
                        guestAgenda.length > 0 &&
                        detailsData?.secondary_registrants_allowed_to_agenda_options ? (
                        <div className="guestAgenda-box">
                          <h4 className="reg-title">
                            {t("web_event_registration_agenda")}
                          </h4>
                          {guestAgenda &&
                            guestAgenda.map((item, index) => (
                              <div key={index}>
                                <h4 className="reg-title">{capitalizeFirstLetter(item.name)}</h4>
                                <ul className="card-info-list">
                                  <li>
                                    <div className="date-info pb-0">
                                      <i className="ri-calendar-event-line black-icon" />
                                      {item.start_date &&
                                        formateDateWithMonth(
                                          item.start_date
                                        )}{" "}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="card-info pb-0">
                                      <i className="ri-alarm-line black-icon" />
                                      {item.start_time &&
                                        getFormatedTime(item.start_time)}{" "}
                                      -{" "}
                                      {item.end_time &&
                                        getFormatedTime(item.end_time)}
                                    </div>
                                  </li>
                                </ul>
                                {toHtml(item.description)}
                                <div className="radio-group-coustom d-flex row mt-3">
                                  {item.elements.map((items, i) => (
                                    <div
                                      className="form-check radio-coustom mb-2"
                                      key={i}
                                    >
                                      <input
                                        disabled={
                                          !items?.available_quantity
                                            ? true
                                            : false
                                        }
                                        className="form-check-input cp"
                                        type="checkbox"
                                        name="flexRadioDefault"
                                        id={`flexRadioDefault1${i}${index}`}
                                        checked={items?.checked}
                                        onChange={(e) => {
                                          selectAgendaElement(
                                            item,
                                            items,
                                            e.target.checked,
                                            index
                                          );
                                          validateElements(index);
                                          handleElementPrice();
                                          // setDisableBtn(true);
                                        }}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={`flexRadioDefault1${i}${index}`}
                                      >
                                        <h5>{capitalizeFirstLetter(items.name)}</h5>
                                        {items.price > 0 ? (
                                          <span>
                                            {detailsData?.currency?.sign}
                                            {items.price +
                                              (items.price * items.tax_rate) /
                                              100}{" "}
                                            <small>
                                              {t("web_lbl_inclusive")}{" "}
                                              {items.tax_rate}%{t("web_lbl_tax")}
                                            </small>
                                          </span>
                                        ) : (
                                          <span className="text-success">{t('web_registration_free')}</span>
                                        )}

                                        {!items?.available_quantity ? (
                                          <span>
                                            <small className="ms-5 ps-5 text-danger">
                                              {t("web_registration_stock")}
                                            </small>
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                                <span
                                  className="text-danger text-start d-blockerr-spn"
                                  id={`error-min-${item._id}-${index}`}
                                ></span>
                                <span
                                  className="text-danger text-start d-blockerr-spn"
                                  id={`error-max-${item._id}-${index}`}
                                ></span>
                                <hr className="divider less-space" />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div className="more-information">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <h4 className="reg-title">
                              {t("web_event_registration_more")}
                            </h4>
                              {detailsData?.secondary_question_set_data?.questions && detailsData?.secondary_question_set_data?.questions.length ?
                                <DynamicQuestions ref={dynamicQuestionsRef} questions={detailsData?.secondary_question_set_data?.questions} showCount={true}
                                  defaultValues={registrationData?.guest_user?.questions_data}
                                  questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                                : null
                              }
                          </div>
                        </div>
                      </div>
                      {detailsData?.askto_visa_verification ? (
                        <>
                          <h4 className="reg-title">
                            {t("web_event_registration_guest_visa")}
                          </h4>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <div className="toggle-box d-flex align-items-center justify-content-between">
                                <h4>{t("web_event_registration_do_letter")}</h4>
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={values.guestVisaInvitation}
                                    onChange={(e) =>
                                      setFieldValue(
                                        "guestVisaInvitation",
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                          </div>

                          {values.guestVisaInvitation ? (
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_passport_number")}{" "}
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="passportNumber"
                                  id="passportNumber"
                                  placeholder=""
                                  className="form-control"
                                  value={values.passportNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.passportNumber &&
                                    touched.passportNumber &&
                                    errors.passportNumber}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_surname")}  ({t('web_registration_as_passport')})
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="surname"
                                  id="surname"
                                  placeholder=""
                                  className="form-control"
                                  value={values.surname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.surname &&
                                    touched.surname &&
                                    errors.surname}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_first_name")}  ({t('web_registration_as_passport')})
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="visaFirstName"
                                  id="visaFirstName"
                                  placeholder=""
                                  className="form-control"
                                  value={values.visaFirstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.visaFirstName &&
                                    touched.visaFirstName &&
                                    errors.visaFirstName}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_dob")}
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={(date) =>
                                      setFieldValue("dateOfBirth", date)
                                    }
                                    className="form-control"
                                    value={
                                      values.dateOfBirth === ""
                                        ? ""
                                        : moment(values.dateOfBirth).format(
                                          "DD/MM/YYYY"
                                        )
                                    }
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.dateOfBirth &&
                                    touched.dateOfBirth &&
                                    errors.dateOfBirth}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_pob")}
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="placeOfBirth"
                                  id="placeOfBirth"
                                  placeholder=""
                                  className="form-control"
                                  value={values.placeOfBirth}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.placeOfBirth &&
                                    touched.placeOfBirth &&
                                    errors.placeOfBirth}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_nationality")}
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="nationality"
                                  id="nationality"
                                  placeholder=""
                                  className="form-control"
                                  value={values.nationality}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.nationality &&
                                    touched.nationality &&
                                    errors.nationality}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_doi")}
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={(date) =>
                                      setFieldValue("dateOfIssue", date)
                                    }
                                    className="form-control"
                                    value={
                                      values.dateOfIssue === ""
                                        ? ""
                                        : moment(values.dateOfIssue).format(
                                          "DD/MM/YYYY"
                                        )
                                    }
                                    maxDate={new Date()}
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.dateOfIssue &&
                                    touched.dateOfIssue &&
                                    errors.dateOfIssue}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_doe")}
                                  <span className="requirestar">*</span>
                                </label>
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={(date) =>
                                      setFieldValue("dateOfExpiry", date)
                                    }
                                    className="form-control"
                                    value={
                                      values.dateOfExpiry === ""
                                        ? ""
                                        : moment(values.dateOfExpiry).format(
                                          "DD/MM/YYYY"
                                        )
                                    }
                                    minDate={new Date()}
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.dateOfExpiry &&
                                    touched.dateOfExpiry &&
                                    errors.dateOfExpiry}
                                </span>
                              </div>
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  {t("web_event_registration_authority")}
                                  <span className="requirestar">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="authority"
                                  id="authority"
                                  placeholder=""
                                  className="form-control"
                                  value={values.authority}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span className="text-danger text-start d-blockerr-spn">
                                  {errors.authority &&
                                    touched.authority &&
                                    errors.authority}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {totalPrice !== 0 ? (
                    <div>
                      <h4 className="reg-title">
                        {t("web_event_registration_invoice")}
                      </h4>
                      <h5 className="reg-sub-title mb-2">
                        {t("web_event_registration_billing")}
                      </h5>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">
                            {t("web_event_registration_company")}
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            type="text"
                            name="companyName"
                            placeholder=""
                            value={values.companyName}
                            className="form-control"
                            onChange={handleChange}
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.companyName &&
                              touched.companyName &&
                              errors.companyName}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t("web_event_registration_address")}
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            type="text"
                            name="address"
                            placeholder=""
                            className="form-control"
                            value={values.address}
                            onChange={handleChange}
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.address &&
                              touched.address &&
                              errors.address}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t("web_event_registration_zip_code")}
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            type="text"
                            name="postalCode"
                            placeholder=""
                            value={values.postalCode}
                            className="form-control"
                            onChange={handleChange}
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.postalCode &&
                              touched.postalCode &&
                              errors.postalCode}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t("web_event_registration_city")}
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            placeholder=""
                            value={values.city}
                            className="form-control"
                            onChange={handleChange}
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.city && touched.city && errors.city}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            {t("web_event_registration_country")}
                            <span className="requirestar">*</span>
                          </label>
                          <input
                            type="text"
                            name="country"
                            placeholder=""
                            className="form-control"
                            onChange={handleChange}
                            value={values.country}
                          />
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.country &&
                              touched.country &&
                              errors.country}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {finalHotelData?.prices?.room_price ||
                    state?.values?.pricing?.event_price > 0 ||
                    (primaryAgendaDetails && primaryAgendaDetails.length > 0 && primaryAgendaDetails[0].price > 0) ||
                    (guestAgendaDetails && guestAgendaDetails.length > 0 && guestAgendaDetails[0].price > 0) ? (
                    <>
                      {params.get("edit")  === true ||
                        params.get("edit") === "true" ? (
                        <>
                          <h4 className="reg-title">
                            {t("web_event_registration_sub_total")}
                          </h4>
                          <div className="price-details">
                            <div className="price-details-blk grid-box">
                              {state?.values?.pricing?.event_price <= 0 ? (
                                <div className="price-details-blk-in mb-3">
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span className="fw-bold text-success">
                                        {t("web_event_registration_message")}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="price-details-blk-in mb-3">
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span>
                                        {t("web_event_registration_event_fee")}{" "}
                                        {values.add_guest ? "(x 2)" : ""}
                                      </span>
                                      {(function () {
                                        let eventFee = values.add_guest
                                          ? myToFixed(
                                            state?.values?.pricing
                                              ?.event_price * 2
                                          )
                                          : myToFixed(
                                            state?.values?.pricing
                                              ?.event_price
                                          );
                                        function eventData() {
                                          if (
                                            eventFee !== oldPricing?.event_price
                                          ) {
                                            return (
                                              <>
                                                <small
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  {detailsData?.currency?.sign}
                                                  {oldPricing?.event_price
                                                    ? myToFixed(
                                                      oldPricing?.event_price
                                                    )
                                                    : "0"}
                                                </small>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {eventFee}
                                                </span>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {eventFee}
                                                </span>
                                              </>
                                            );
                                          }
                                        }
                                        return (
                                          <div className="ms-auto">
                                            {eventData()}
                                          </div>
                                        );
                                      })()}
                                    </div>
                                  </div>
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span>
                                        {t("web_lbl_tax")} (
                                        {detailsData &&
                                          detailsData?.event_quota &&
                                          detailsData?.event_quota?.tax}
                                        %)
                                      </span>
                                      {(function () {
                                        let eventTax = values.add_guest
                                          ? myToFixed(
                                            calculatePercentage(
                                              detailsData?.event_quota?.tax,
                                              state?.values?.pricing
                                                ?.event_price
                                            ) * 2
                                          )
                                          : myToFixed(
                                            calculatePercentage(
                                              detailsData?.event_quota?.tax,
                                              state?.values?.pricing
                                                ?.event_price
                                            )
                                          );
                                        function eventData() {
                                          if (
                                            eventTax !==
                                            oldPricing?.event_tax_price
                                          ) {
                                            return (
                                              <>
                                                <small
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  {detailsData?.currency?.sign}
                                                  {oldPricing?.event_tax_price
                                                    ? myToFixed(
                                                      oldPricing?.event_tax_price
                                                    )
                                                    : "0"}
                                                </small>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {eventTax}
                                                </span>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {eventTax}
                                                </span>
                                              </>
                                            );
                                          }
                                        }

                                        return (
                                          <div className="ms-auto">
                                            {eventData()}
                                          </div>
                                        );
                                      })()}
                                    </div>
                                  </div>
                                  <hr className="my-2" />
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span className="fw-bold">
                                        {t("web_event_registration_total")}
                                      </span>
                                      {(function () {
                                        let eventSubtotal = values.add_guest
                                          ? state?.values?.pricing
                                            ?.event_price *
                                          2 +
                                          Number(
                                            myToFixed(
                                              calculatePercentage(
                                                detailsData?.event_quota?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              ) * 2
                                            )
                                          )
                                          : Number(
                                            myToFixed(
                                              state?.values?.pricing
                                                ?.event_price +
                                              calculatePercentage(
                                                detailsData?.event_quota
                                                  ?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              )
                                            )
                                          );
                                        function eventSub() {
                                          if (
                                            eventSubtotal !==
                                            oldPricing?.event_subtotal
                                          ) {
                                            return (
                                              <>
                                                <small
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  {detailsData?.currency?.sign}
                                                  {oldPricing?.event_subtotal
                                                    ? myToFixed(
                                                      oldPricing?.event_subtotal
                                                    )
                                                    : "0"}
                                                </small>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(eventSubtotal)}
                                                </span>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(eventSubtotal)}
                                                </span>
                                              </>
                                            );
                                          }
                                        }

                                        return (
                                          <div className="fw-bold ms-auto">
                                            {eventSub()}
                                          </div>
                                        );
                                      })()}
                                    </div>
                                  </div>
                                </div>
                              )}
                              {values.accommondation ||
                                params.get("edit") === true ||
                                (params.get("edit") === "true" &&
                                  values.accommondation) ? (
                                <>
                                  {values.hotel !== "Select" ? (
                                    <div className="price-details-blk-in mb-3">
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_hotel_new"
                                            )}
                                            ({finalHotelData?.nights} x{" "}
                                            {t("web_event_registration_night")})
                                          </span>
                                          {(function () {
                                            let roomPrice = finalHotelData
                                              ?.prices?.room_price
                                              ? finalHotelData?.prices
                                                ?.room_price
                                              : 0;
                                            function eventData() {
                                              if (
                                                roomPrice !==
                                                oldPricing?.hotel_data?.prices
                                                  ?.room_price
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.prices?.room_price
                                                        ? myToFixed(
                                                          oldPricing
                                                            ?.hotel_data
                                                            ?.prices
                                                            ?.room_price
                                                        )
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {roomPrice}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {roomPrice}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_hotel_tax"
                                            )}
                                            (
                                            {finalHotelData?.taxes?.hotel_tax
                                              ? finalHotelData?.taxes?.hotel_tax
                                              : "0"}
                                            %){" "}
                                          </span>
                                          {(function () {
                                            let hotelTax = finalHotelData
                                              ?.prices?.hotel_tax
                                              ? myToFixed(finalHotelData?.prices?.hotel_tax)
                                              : 0;
                                            function eventData() {
                                              if (
                                                hotelTax !==
                                                oldPricing?.hotel_data?.prices
                                                  ?.hotel_tax
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.prices?.hotel_tax
                                                        ? myToFixed(oldPricing?.hotel_data?.prices?.hotel_tax)
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {hotelTax}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {hotelTax}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_breakfast"
                                            )}{" "}
                                          </span>
                                          {(function () {
                                            let Breakfast = finalHotelData
                                              ?.prices?.breakfast
                                              ? finalHotelData?.prices
                                                ?.breakfast
                                              : 0;
                                            function eventData() {
                                              if (
                                                Breakfast !==
                                                oldPricing?.hotel_data?.prices
                                                  ?.breakfast
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.prices?.breakfast
                                                        ? oldPricing?.hotel_data
                                                          ?.prices?.breakfast
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {Breakfast}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {Breakfast}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_breakfast_tax"
                                            )}{" "}
                                            (
                                            {finalHotelData?.taxes
                                              ?.breakfast_tax
                                              ? finalHotelData?.taxes
                                                ?.breakfast_tax
                                              : "0"}
                                            %)
                                          </span>
                                          {/* <span className="ms-auto">
                                        {detailsData?.currency?.sign}
                                        {finalHotelData?.prices?.breakfast_tax ? finalHotelData?.prices?.breakfast_tax : 0}
                                      </span> */}
                                          {(function () {
                                            let breakfastTax = finalHotelData
                                              ?.prices?.breakfast_tax
                                              ? finalHotelData?.prices
                                                ?.breakfast_tax
                                              : 0;
                                            function eventData() {
                                              if (
                                                breakfastTax !==
                                                myToFixed(
                                                  oldPricing?.hotel_data?.prices
                                                    ?.breakfast_tax
                                                )
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.prices?.breakfast_tax
                                                        ? myToFixed(
                                                          oldPricing
                                                            ?.hotel_data
                                                            ?.prices
                                                            ?.breakfast_tax
                                                        )
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {breakfastTax}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {breakfastTax}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {" "}
                                            {t(
                                              "web_event_registration_city_tax"
                                            )}{" "}
                                          </span>
                                          {(function () {
                                            let cityTax = finalHotelData?.prices
                                              ?.city_tax
                                              ? finalHotelData?.prices?.city_tax
                                              : 0;
                                            function eventData() {
                                              if (
                                                cityTax !==
                                                oldPricing?.hotel_data?.prices
                                                  ?.city_tax
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.prices?.city_tax
                                                        ? myToFixed(
                                                          oldPricing
                                                            ?.hotel_data
                                                            ?.prices?.city_tax
                                                        )
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {cityTax}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {cityTax}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="ms-auto">
                                                {eventData()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            {t("web_event_registration_total")}
                                          </span>
                                          {(function () {
                                            let hotelSubtotal =
                                              finalHotelData?.sub_total;
                                            function eventSub() {
                                              if (
                                                hotelSubtotal !==
                                                oldPricing?.hotel_data?.subtotal
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {oldPricing?.hotel_data
                                                        ?.subtotal
                                                        ? myToFixed(
                                                          oldPricing
                                                            ?.hotel_data
                                                            ?.subtotal
                                                        )
                                                        : 0}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {hotelSubtotal}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {hotelSubtotal}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="fw-bold ms-auto">
                                                {eventSub()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {primaryAgendaDetails &&
                                primaryAgendaDetails?.length > 0 ? (
                                <div className="price-details-blk-in mb-3">
                                  <span className="fw-bold">
                                    {t("web_event_registration_primary")}
                                  </span>
                                  {primaryAgendaDetails &&
                                    primaryAgendaDetails.map((item, index) => {
                                      return (
                                        <div className="ps-3" key={index}>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="text-capitalize">
                                                {item.name}
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {myToFixed(item.price)}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>
                                                {t("web_lbl_tax")} ({item.tax}%)
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {myToFixed(
                                                  calculatePercentage(
                                                    item.tax,
                                                    item.price
                                                  )
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          {index ===
                                            primaryAgendaDetails.length - 1 ? (
                                            <></>
                                          ) : (
                                            <hr className="my-2" />
                                          )}
                                        </div>
                                      );
                                    })}
                                  <hr className="my-2" />
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span className="fw-bold">
                                        {t("web_event_registration_total")}
                                      </span>
                                      {(function () {
                                        function eventSub() {
                                          if (
                                            primaryAgendaSubtotal !==
                                            oldPricing?.primary_agenda?.subtotal
                                          ) {
                                            return (
                                              <>
                                                <small
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(
                                                    oldPricing?.primary_agenda
                                                      ?.subtotal
                                                  )}
                                                </small>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(
                                                    primaryAgendaSubtotal
                                                  )}
                                                </span>
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(
                                                    primaryAgendaSubtotal
                                                  )}
                                                </span>
                                              </>
                                            );
                                          }
                                        }

                                        return (
                                          <div className="fw-bold ms-auto">
                                            {eventSub()}
                                          </div>
                                        );
                                      })()}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {values.add_guest ? (
                                <>
                                  {agendaPricing && agendaPricing.length > 0 ? (
                                    <div className="price-details-blk-in mb-3">
                                      <span className="fw-bold">
                                        {t("web_event_registration_guest_user")}
                                      </span>
                                      {guestAgendaDetails &&
                                        guestAgendaDetails.map(
                                          (item, index) => {
                                            return (
                                              <div className="ps-3" key={index}>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span className="text-capitalize">
                                                      {item.name}
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(item.price)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span>
                                                      {t("web_lbl_tax")} (
                                                      {item.tax}%)
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        calculatePercentage(
                                                          item.tax,
                                                          item.price
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                {index ===
                                                  guestAgendaDetails.length -
                                                  1 ? (
                                                  <></>
                                                ) : (
                                                  <hr className="my-2" />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            {t("web_event_registration_total")}
                                          </span>
                                          {(function () {
                                            function eventSub() {
                                              if (
                                                guestAgendaSubtotal !==
                                                oldPricing?.secondary_agenda
                                                  ?.subtotal
                                              ) {
                                                return (
                                                  <>
                                                    <small
                                                      style={{
                                                        textDecoration:
                                                          "line-through",
                                                      }}
                                                    >
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        oldPricing
                                                          ?.secondary_agenda
                                                          ?.subtotal
                                                      )}
                                                    </small>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        guestAgendaSubtotal
                                                      )}
                                                    </span>
                                                  </>
                                                );
                                              } else {
                                                return (
                                                  <>
                                                    <span className="ms-2">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        guestAgendaSubtotal
                                                      )}
                                                    </span>
                                                  </>
                                                );
                                              }
                                            }

                                            return (
                                              <div className="fw-bold ms-auto">
                                                {eventSub()}
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="price-details-blk-in">
                                <div className="price-details-row">
                                  <div className="d-flex">
                                    <h3 className="fw-bold p-0">
                                      {t("web_event_registration_total_new")}
                                    </h3>
                                    <h3 className="fw-bold p-0 ms-auto">
                                      {(function () {
                                        let total =
                                          Number(
                                            values.add_guest
                                              ? state?.values?.pricing
                                                ?.event_price * 2
                                              : state?.values?.pricing
                                                ?.event_price
                                          ) +
                                          Number(
                                            values.add_guest
                                              ? calculatePercentage(
                                                detailsData?.event_quota?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              ) * 2
                                              : calculatePercentage(
                                                detailsData?.event_quota?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              )
                                          ) +
                                          Number(
                                            values.accommondation
                                              ? finalHotelData?.sub_total
                                              : 0
                                          ) +
                                          Number(
                                            primaryAgendaSubtotal
                                              ? primaryAgendaSubtotal
                                              : 0
                                          ) +
                                          Number(
                                            guestAgendaSubtotal
                                              ? guestAgendaSubtotal
                                              : 0
                                          );

                                        function eventSub() {
                                          if (
                                            total !== oldPricing?.grand_total
                                          ) {
                                            setTotalPrice(total);
                                            return (
                                              <>
                                                <small
                                                  style={{
                                                    textDecoration:
                                                      "line-through",
                                                  }}
                                                >
                                                  {detailsData?.currency?.sign}
                                                  {myToFixed(
                                                    oldPricing?.grand_total
                                                  )}
                                                </small>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {total
                                                    ? myToFixed(total)
                                                    : "00"}
                                                </span>
                                              </>
                                            );
                                          } else {
                                            setTotalPrice(total);
                                            return (
                                              <>
                                                <span className="ms-2">
                                                  {detailsData?.currency?.sign}
                                                  {total
                                                    ? myToFixed(total)
                                                    : "00"}
                                                </span>
                                              </>
                                            );
                                          }
                                        }
                                        return (
                                          <div className="fw-bold ms-auto">
                                            {eventSub()}
                                          </div>
                                        );
                                      })()}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                {totalPrice > oldPricing?.grand_total ? (
                                  <h4 className="text-danger">
                                    {t("web_event_registration_payable_price")}{" "}
                                    {detailsData?.currency?.sign}
                                    {myToFixed(
                                      Number(totalPrice) -
                                      Number(oldPricing?.grand_total)
                                    )}
                                  </h4>
                                ) : (
                                  <></>
                                )}
                                {totalPrice < oldPricing?.grand_total ? (
                                  <h4 className="text-success">
                                    {t(
                                      "web_event_registration_refundable_price"
                                    )}{" "}
                                    {detailsData?.currency?.sign}
                                    {myToFixed(
                                      Number(oldPricing?.grand_total) -
                                      Number(totalPrice)
                                    )}
                                  </h4>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <h4 className="reg-title">
                            {t("web_event_registration_sub_total")}
                          </h4>
                          <div className="price-details">
                            <div className="price-details-blk grid-box">
                              <div className="price-details-blk-in mb-3">
                                <div className="price-details-row">
                                  <div className="d-flex">
                                    <span>
                                      {t("web_event_registration_event_fee")}{" "}
                                      {values.add_guest ? "(x 2)" : ""}
                                    </span>
                                    <span className="ms-auto">
                                      {detailsData?.currency?.sign}
                                      {values.add_guest
                                        ? myToFixed(
                                          state?.values?.pricing
                                            ?.event_price * 2
                                        )
                                        : myToFixed(
                                          state?.values?.pricing?.event_price
                                        )}
                                    </span>
                                  </div>
                                </div>
                                <div className="price-details-row">
                                  <div className="d-flex">
                                    <span>
                                      {t("web_lbl_tax")} (
                                      {detailsData &&
                                        detailsData?.event_quota &&
                                        detailsData?.event_quota?.tax}
                                      %)
                                    </span>
                                    <span className="ms-auto">
                                      {detailsData?.currency?.sign}
                                      {values.add_guest
                                        ? myToFixed(
                                          calculatePercentage(
                                            detailsData?.event_quota?.tax,
                                            state?.values?.pricing
                                              ?.event_price
                                          ) * 2
                                        )
                                        : myToFixed(
                                          calculatePercentage(
                                            detailsData?.event_quota?.tax,
                                            state?.values?.pricing
                                              ?.event_price
                                          )
                                        )}
                                    </span>
                                  </div>
                                </div>
                                <hr className="my-2" />
                                <div className="price-details-row">
                                  <div className="d-flex">
                                    <span className="fw-bold">
                                      {t("web_event_registration_total")}
                                    </span>
                                    <span className="fw-bold ms-auto">
                                      {detailsData?.currency?.sign}
                                      {values.add_guest
                                        ? myToFixed(
                                          state?.values?.pricing
                                            ?.event_price *
                                          2 +
                                          calculatePercentage(
                                            detailsData?.event_quota?.tax,
                                            state?.values?.pricing
                                              ?.event_price
                                          ) *
                                          2
                                        )
                                        : myToFixed(
                                          state?.values?.pricing
                                            ?.event_price +
                                          calculatePercentage(
                                            detailsData?.event_quota?.tax,
                                            state?.values?.pricing
                                              ?.event_price
                                          )
                                        )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {values.accommondation ? (
                                <>
                                  {values.hotel !== "Select" ? (
                                    <div className="price-details-blk-in mb-3">
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_hotel_new"
                                            )}{" "}
                                            ({finalHotelData?.nights} x{" "}
                                            {t("web_event_registration_night")})
                                          </span>
                                          <span className="ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.prices?.room_price
                                              ? finalHotelData?.prices
                                                ?.room_price
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_hotel_tax"
                                            )}{" "}
                                            ({finalHotelData?.taxes?.hotel_tax}
                                            %){" "}
                                          </span>
                                          <span className="ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.prices?.hotel_tax
                                              ? finalHotelData?.prices
                                                ?.hotel_tax
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {" "}
                                            {t(
                                              "web_event_registration_breakfast"
                                            )}{" "}
                                          </span>
                                          <span className="ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.prices?.breakfast
                                              ? finalHotelData?.prices
                                                ?.breakfast
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_breakfast_tax"
                                            )}{" "}
                                            (
                                            {
                                              finalHotelData?.taxes
                                                ?.breakfast_tax
                                            }
                                            %)
                                          </span>
                                          <span className="ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.prices
                                              ?.breakfast_tax
                                              ? finalHotelData?.prices
                                                ?.breakfast_tax
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span>
                                            {t(
                                              "web_event_registration_city_tax"
                                            )}{" "}
                                          </span>
                                          <span className="ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.prices?.city_tax
                                              ? finalHotelData?.prices?.city_tax
                                              : 0}
                                          </span>
                                        </div>
                                      </div>
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            {t("web_event_registration_total")}
                                          </span>
                                          <span className="fw-bold ms-auto">
                                            {detailsData?.currency?.sign}
                                            {finalHotelData?.sub_total}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {primaryAgendaDetails &&
                                primaryAgendaDetails.length > 0 ? (
                                <div className="price-details-blk-in mb-3">
                                  <span className="fw-bold">
                                    {t("web_event_registration_primary")}
                                  </span>
                                  {primaryAgendaDetails &&
                                    primaryAgendaDetails.map((item, index) => {
                                      return (
                                        <div className="ps-3" key={index}>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span className="text-capitalize">
                                                {item.name}
                                              </span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {myToFixed(item.price)}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="price-details-row">
                                            <div className="d-flex">
                                              <span>Tax ({item.tax}%)</span>
                                              <span className="ms-auto">
                                                {detailsData?.currency?.sign}
                                                {myToFixed(
                                                  calculatePercentage(
                                                    item.tax,
                                                    item.price
                                                  )
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          {index ===
                                            primaryAgendaDetails.length - 1 ? (
                                            <></>
                                          ) : (
                                            <hr className="my-2" />
                                          )}
                                        </div>
                                      );
                                    })}
                                  <hr className="my-2" />
                                  <div className="price-details-row">
                                    <div className="d-flex">
                                      <span className="fw-bold">
                                        {t("web_event_registration_total")}
                                      </span>
                                      <span className="fw-bold ms-auto">
                                        {detailsData?.currency?.sign}
                                        {primaryAgendaSubtotal
                                          ? myToFixed(primaryAgendaSubtotal)
                                          : "00"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {values.add_guest ? (
                                <>
                                  {agendaPricing && agendaPricing.length > 0 ? (
                                    <div className="price-details-blk-in mb-3">
                                      <span className="fw-bold">
                                        {t("web_event_registration_guest_user")}
                                      </span>
                                      {guestAgendaDetails &&
                                        guestAgendaDetails.map(
                                          (item, index) => {
                                            return (
                                              <div className="ps-3" key={index}>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span className="text-capitalize">
                                                      {item.name}
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(item.price)}
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="price-details-row">
                                                  <div className="d-flex">
                                                    <span>
                                                      Tax ({item.tax}%)
                                                    </span>
                                                    <span className="ms-auto">
                                                      {
                                                        detailsData?.currency
                                                          ?.sign
                                                      }
                                                      {myToFixed(
                                                        calculatePercentage(
                                                          item.tax,
                                                          item.price
                                                        )
                                                      )}
                                                    </span>
                                                  </div>
                                                </div>
                                                {index ===
                                                  guestAgendaDetails.length -
                                                  1 ? (
                                                  <></>
                                                ) : (
                                                  <hr className="my-2" />
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      <hr className="my-2" />
                                      <div className="price-details-row">
                                        <div className="d-flex">
                                          <span className="fw-bold">
                                            {t("web_event_registration_total")}
                                          </span>
                                          <span className="fw-bold ms-auto">
                                            {detailsData?.currency?.sign}
                                            {guestAgendaSubtotal
                                              ? myToFixed(guestAgendaSubtotal)
                                              : "00"}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              <div className="price-details-blk-in">
                                <div className="price-details-row">
                                  <div className="d-flex">
                                    <h3 className="fw-bold p-0">
                                      {t("web_event_registration_total_new")}
                                    </h3>
                                    <h3 className="fw-bold p-0 ms-auto">
                                      {detailsData?.currency?.sign}
                                      {(function () {
                                        let total =
                                          Number(
                                            values.add_guest
                                              ? state?.values?.pricing
                                                ?.event_price * 2
                                              : state?.values?.pricing
                                                ?.event_price
                                          ) +
                                          Number(
                                            values.add_guest
                                              ? calculatePercentage(
                                                detailsData?.event_quota?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              ) * 2
                                              : calculatePercentage(
                                                detailsData?.event_quota?.tax,
                                                state?.values?.pricing
                                                  ?.event_price
                                              )
                                          ) +
                                          Number(
                                            values.accommondation
                                              ? finalHotelData?.sub_total
                                              : 0
                                          ) +
                                          Number(
                                            primaryAgendaSubtotal
                                              ? primaryAgendaSubtotal
                                              : 0
                                          ) +
                                          Number(
                                            guestAgendaSubtotal
                                              ? guestAgendaSubtotal
                                              : 0
                                          );
                                        if (total) {
                                          setTotalPrice(total);
                                          return myToFixed(total);
                                        } else {
                                          setTotalPrice(total);
                                          return "00";
                                        }
                                      })()}
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="about-invoice mt-4">
                    <div className="">
                      <div className="form-check black-check mb-4">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          name="userPermission"
                          value={values.userPermission}
                          onChange={handleChange}
                          id="userPermission"
                          checked={values.userPermission}
                        />
                        <label
                          className="form-check-label cp"
                          htmlFor="userPermission"
                        >{t('web_registration_agree_terms')}</label>
                      </div>
                      <button
                        className="btn btn-secondary"
                        type="submit"
                        onClick={() => {
                          setIsFormSubmitted(true);
                          validateElements();
                          checkValidationError();
                          // setDisableBtn(true);
                        }}
                      >
                        {t("web_event_registration_continue")}
                      </button>
                      <button
                        className="btn btn-secondary ms-2"
                        type="button"
                        onClick={() => {
                          params.get("edit") === true ||
                            params.get("edit") === "true"
                            ? navigate(
                              `/event-registration?event_id=${params.get(
                                "event_id"
                              )}&invitation_id=${params.get(
                                "invitation_id"
                              )}&reg_id=${params.get("reg_id")}&edit=true`,
                              { state: { stateData } }
                            )
                            : navigate(
                              `/event-registration?event_id=${params.get(
                                "event_id"
                              )}&invitation_id=${params.get(
                                "invitation_id"
                              )}`,
                              { state: { stateData } }
                            );
                        }}
                      >
                        {t("web_event_registration_previous")}
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "90vh" }}
        >
          <div id="main-loader" className="main-loader" style={{ display: "none", height: '100vh' }}>
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">{t('web_registration_loading')}...</span>
            </div>
          </div>
        </div>
      )}
      <a className="d-none" ref={ChoosePaymentTrigger} data-bs-toggle="modal" href="#ChoosePayment" role="button"><span></span></a>
      <div className="modal fade" id="ChoosePayment" aria-hidden="true" data-bs-backdrop="static" aria-labelledby="ChoosePaymentLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="announcements-title" id="ChoosePaymentLabel">{t('web_registration_choose_payment')}</span>
              <button type="button" className="btn-close" id="close_button" data-bs-dismiss="modal" style={{display:'none'}} aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
              <p>{t('web_registration_proceed_payment_terms')}.</p>
              </div>
              <button className="btn btn-primary" onClick={() => handlePayPalPayment()}>
                {t('web_registration_pay_by_paypal')}
              </button>
              <button className="btn btn-primary ms-2" onClick={() => handleInvoicePayment()}>
                {t('web_registration_pay_by_invoice')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default RegistrationStepTwo;
