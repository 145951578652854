import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MAX_VOICE_MEMO_SECONDS, QUESTION_TYPES, URL_REGEX } from '../../../utils/Constants';
import { capitalizeFirstLetter, getChunks, getFileNameFromURL, getUser, isApp, pluralString, timeStamp } from '../../../utils/commonfunction';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { Rating } from 'react-simple-star-rating';
import Drawing from '../../../utils/Drawing';
import Swal from "sweetalert2";
import VideoPlayer from './VideoPlayer';
import * as $ from 'jquery';
import { getASCToken } from '../services/common.services';
import { SOCKET_CLIENT } from '../../../utils/socket';
import { useTranslation } from 'react-i18next';


const DynamicQuestions = forwardRef((
  {questions, questionMB="mb-3", parentClass="", requiredMessage="web_registration_err_required", questionTitleClass="", footer=`<hr className="divider">`,
  showCount=false, videoUploadPath="", defaultValues=[]}, 
  ref) => {
    
    const { t } = useTranslation();
  function getAllIndexes() {
    let indexes = [];
    questions.forEach((q, i)=>{
      if(q?.question_type === QUESTION_TYPES.EXTRA_INFORMATION){
        indexes.push(i);
      }
    });
    return indexes;
  }
  const questionsLength = questions.length;
  const [lockDrawing, setLockDrawing] = useState(false);
  let drawingRefs = {};
  let [editDrawing, setEditDrawing] = useState(getDrawingEditFlags());
  let [voiceMemeRecording, setVoiceMemeRecording] = useState(false);
  const recordedVoiceMemoChunks = useRef([]);
  const mediaRecorderRef = useRef(null);
  const [recordingTimer, setRecordingTimer] = useState(0);
  const timerIntervalRef = useRef(null);
  const videoPlayerToggler = useRef(null);
  const [videoPlaySource, setVideoPlaySource] = useState(null);
  const [videoUploadingProgress, setVideoUploadingProgress] = useState({});
  const [changedCounts] = useState(getAllIndexes());


  // console.log('questions',questions);
  // console.log("defaultValues",defaultValues);
  /** ref provided functions >>>> */
  useImperativeHandle(ref, () => ({
    submit,
    getValues
  }));
  function submit(){
    myForm.submitForm();
    return {errors:myForm.errors, isValid:questions.length?myForm.isValid:true, values:myForm.values};
  }
  function getValues(){
    let normalQuestions = [];
    let files = {};
    function modifyAns(q, value=null){
      let ans = {
        question_id:q?._id,
        question_title:q?.title,
        question_type:q?.question_type,
        is_required:q?.is_required,
        answer:value,
        is_name_badge:q?.is_name_badge_question,
        is_dedicated:q?.is_dedicated_questions,
      };
      return ans;
    }
    function handleSmallFileAns(q, value=null){
      if(true || q?.question_type === QUESTION_TYPES.SIGNATURE || q?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING){
        if(value){
          if(typeof value === "string"){
            if(value.length){
              normalQuestions.push(modifyAns(q, value));
            }else{
              normalQuestions.push(modifyAns(q));
            }
          }else if(Array.isArray(value)){
            if(value.length){
              let ans = [];
              let ansFiles = [];
              value.forEach((item)=>{
                if(typeof item === "string"){
                  ans.push(item);
                }else{
                  ansFiles.push(item);
                }
              });
              if(ansFiles.length){
                files[q?._id] = ansFiles;
              }
              normalQuestions.push(modifyAns(q, ans));
            }else{
              normalQuestions.push(modifyAns(q));
            }
          }else{
            files[q?._id] = value;
            normalQuestions.push(modifyAns(q));
          }
        }else{
          normalQuestions.push(modifyAns(q));
        }
      }
    }
    questions?.forEach((q)=>{
      const value = myForm?.values?.questions[q?._id];
      // if(value){
        if(q?.question_type === QUESTION_TYPES.MULTIPLE_CHOICE){
          normalQuestions.push(modifyAns(q, value));
        }else if(q?.question_type === QUESTION_TYPES.SLIDER){
          normalQuestions.push(modifyAns(q, value));
        }else if(q?.question_type === QUESTION_TYPES.RATING){
          normalQuestions.push(modifyAns(q, value));
        }else if(q?.question_type === QUESTION_TYPES.LIKE_DISLIKE){
          normalQuestions.push(modifyAns(q, value));
        }else if(q?.question_type === QUESTION_TYPES.SIGNATURE || q?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING){
          handleSmallFileAns(q, value);
        }else if(q?.question_type === QUESTION_TYPES.PHOTO_UPLOAD){
          handleSmallFileAns(q, value);
        }else if(q?.question_type === QUESTION_TYPES.VOICE_MEMO){
          handleSmallFileAns(q, value);
        }else if(q?.question_type === QUESTION_TYPES.VIDEO_UPLOAD){
          normalQuestions.push(modifyAns(q, value));
        }else if(q?.question_type === QUESTION_TYPES.EXTRA_INFORMATION){
          /**Do nothing in this case */
          // setChangedCounts([5])
        }else{
          normalQuestions.push(modifyAns(q, value));
        }
      // }
    })
    return {
      normalQuestions,
      files
    };
  }
  /** ref provided functions <<<< */
  
  /** Form configs >>>> */
  function getDefaultValue(q){
    if(defaultValues && defaultValues.length){
      let foundQuestion = defaultValues.filter(item=> (item?.question_id) === (q?._id))[0];
      if(foundQuestion && String(foundQuestion.answer)){
        let ans = foundQuestion.answer;
        
        if(typeof ans === 'string' && ans.length){
          return ans;
        }else if(Array.isArray(ans) && ans.length){
          return ans;
        }else if(typeof ans === 'number'){
          return ans;
        }else{
          return null;
        }
      }else{
        return null;
      }
    }else{
      return null;
    }
  }
  function getInitials(){
    let obj = {};
    questions.forEach((q, i)=>{
      if(q?.question_type === QUESTION_TYPES.MULTIPLE_CHOICE){
        obj[q?._id] = getDefaultValue(q) || [];
      }else if(q?.question_type === QUESTION_TYPES.SLIDER){
        obj[q?._id] = getDefaultValue(q) || (q?.question_type ? Number(q?.min_slider_value) : 0);
      }else if(q?.question_type === QUESTION_TYPES.RATING){
        obj[q?._id] = getDefaultValue(q) || 0;
      }else if(q?.question_type === QUESTION_TYPES.LIKE_DISLIKE){
        let ans = getDefaultValue(q);
        obj[q?._id] = ans===0 || ans===1 ? ans : null;
      }else if(q?.question_type === QUESTION_TYPES.SIGNATURE || q?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING){
        // obj[q?._id] = "http://localhost:5000/uploads/banners/drawing.png";
        obj[q?._id] = getDefaultValue(q) || null;
        drawingRefs[`${q?._id}_${q?.question_type}`] = React.createRef();
      }else if(q?.question_type === QUESTION_TYPES.PHOTO_UPLOAD){
        obj[q?._id] = getDefaultValue(q) || [];
      }else if(q?.question_type === QUESTION_TYPES.VIDEO_UPLOAD){
        obj[q?._id] = getDefaultValue(q) || [];
      }else if(q?.question_type === QUESTION_TYPES.EXTRA_INFORMATION){
        /**Do nothing in this case */
        // setChangedCounts([5])
      }else{
        obj[q?._id] = getDefaultValue(q) || "";
      }
    });
    return obj;
  }
  function getValidations(){
    let obj = {};
    questions.forEach((q)=>{
      if(q.is_required){
        if(q?.question_type === QUESTION_TYPES.MULTIPLE_CHOICE){
          obj[q?._id] = Yup.array().test('at-least-one',t(requiredMessage),(value) => value && value.length > 0);
        }else if(q?.question_type === QUESTION_TYPES.SLIDER){
          obj[q?._id] = Yup.number().test('at-least-one',t(requiredMessage),(value) => value && Number(value) >= Number(q?.min_slider_value));
        }else if(q?.question_type === QUESTION_TYPES.RATING){
          obj[q?._id] = Yup.number().test('at-least-one',t(requiredMessage),(value) => value && Number(value) > 0);
        }else if(q?.question_type === QUESTION_TYPES.LIKE_DISLIKE){
          obj[q?._id] = Yup.number().test('at-least-one',t(requiredMessage),(value) => value === 0 || value === 1).nullable(true);
        }else if(q?.question_type === QUESTION_TYPES.PHOTO_UPLOAD){
          obj[q?._id] = Yup.array().test('at-least-one',t(requiredMessage),(value) => value && value.length > 0);
        }else if(q?.question_type === QUESTION_TYPES.VIDEO_UPLOAD){
          obj[q?._id] = Yup.array().test('at-least-one',t(requiredMessage),(value) => value && value.length > 0);
        }else if(q?.question_type === QUESTION_TYPES.EXTRA_INFORMATION){
          /**Do nothing in this case */
        }else{
          obj[q?._id] = Yup.string().required(t(requiredMessage));
        }
      }
    });
    return Yup.object().shape(obj);
  }
  let myValidation = Yup.object().shape({
    questions:getValidations()
  });
  const myForm = useFormik({
    validateOnMount:true,
    initialValues: {
      questions: getInitials(),
    },
    validationSchema:myValidation,
    onSubmit: values => {
      // console.log("Questions", values);
    },
  });
  useEffect(()=>{
    const ev = new CustomEvent('triggerFrameLoaded',{
      detail:{
        increasableHeight: Object.keys((myForm?.errors?.questions || {})).length * 18
      }
    });
    document.querySelector('body').dispatchEvent(ev);
  },[myForm?.values]);
  /** Form configs <<<< */
  // console.log("myForm values", myForm.values);
  function getValidCount(index){
    let skippedArr = changedCounts.filter(item=> item < index);
    return (index + 1) - skippedArr.length;
  }
  function getClassName(index, q){
    let classText = `${parentClass} question_type-${q?.question_type}`;
    if(index!==(questionsLength-1)){
      classText += " "+questionMB;
    }
    return classText;
  }
  function multiSelectIsChecked(question, option){    
    return myForm?.values?.questions[question._id] && myForm?.values?.questions[question._id].length && myForm?.values?.questions[question._id].indexOf(option) >= 0;
  }
  function singleChoiceIsChecked(question, option){    
    return myForm?.values?.questions[question._id] === option;
  }
  function handleMultipleChoice(event, question){
    let value = event.target.value;
    let existingValues = [...myForm?.values?.questions[question._id]];
    if(event.target.checked){
      if(existingValues.indexOf(value) === -1){
        existingValues.push(value);
      }
    }else{
      if(existingValues.indexOf(value) !== -1){
        existingValues.splice(existingValues.indexOf(value), 1);
      }
    }
    myForm.setFieldValue(`questions.${[question._id]}`,existingValues);
  }
  function dataURLtoFile(base64, filename) {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /** Drawing >>>>> */
  function handleDrawingValue(e, type, question) {
    let file = dataURLtoFile(e.getDataURL(), `${type}-${question?._id}-${timeStamp()}.png`);
    myForm.setFieldValue(`questions.${question._id}`,file);
  }
  function clearDrawing(question) {
    myForm.setFieldValue(`questions.${question._id}`,null);
    drawingRefs[`${question._id}_${question?.question_type}`]?.current?.clear();
  }
  function isDrawingEditable(question) {
    if(URL_REGEX.test(myForm?.values?.questions[question._id]) || URL_REGEX.test(editDrawing[question._id])){
      return true;
    }
    return false;
  }
  /** set edit drawing >>> */
  function getDrawingEditFlags(){
    let obj = {};
    questions.forEach((q)=>{
      if(q?.question_type === QUESTION_TYPES.SIGNATURE || q?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING){
        obj[`${q?._id}`] = false;
      }
    });
    return obj;
  }
  function changeEditDrawing(action, question){
    let data = {...editDrawing};
    if(!action){
      clearDrawing(question);
      myForm.setFieldValue(`questions.${question?._id}`, data[question?._id]);
    }
    data[question?._id] = action;
    setEditDrawing(data);
  }
  /** set edit drawing <<<*/
  /** Drawing <<<<< */

  /** Voice memo >>>>> */
  useEffect(() => {
		/**Listening for mobile recordings */
    SOCKET_CLIENT.on('shareASCListener', (resp) => {
      if(resp?.action === "record-audio"){
        try{
          let {metadata, file_url} = JSON.parse(resp?.data);
          myForm.setFieldValue(`questions.${metadata._id}`,file_url);
        }catch(err){
          alert(err);
        }
      }
    });
	}, []);
  const startRecording = (question) => {
   
    if(isApp()){
      getASCToken("record-audio").then((response)=>{
        if(response.success){
          const appData = {action:'record-audio', data:{uploadToken:response?.data?.token, uploadDir: videoUploadPath}, metadata:question};
          window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
          // setVoiceToken(response?.data?.token)
          let joinRequest = {
            room:response?.data?.token
          };
          SOCKET_CLIENT.emit('shareASCJoin', joinRequest, (error) => {
            if (error) {
              alert(error);
            }
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: response?.message,
          });
        }
      }).catch((err)=>{
        Swal.fire({
          icon: 'error',
          title: err?.message,
        });
      });
    return;

    }else{
      if(voiceMemeRecording){
        Swal.fire({
          icon: "warning",
          title: (('web_dynamic_question_swal_message')),
        });
      }else{
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
          navigator.mediaDevices .enumerateDevices() .then((devices) => {
            const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
            if (audioInputDevices.length === 0) {
              Swal.fire({
                icon: "error",
                title: (('web_error_no_audio_device_found')),
              });
            } else {
              navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.ondataavailable = handleDataAvailable;
                mediaRecorder.onstop = () => handleStop(question);
                mediaRecorderRef.current = mediaRecorder;
                recordedVoiceMemoChunks.current = [];
                mediaRecorder.start();
                setVoiceMemeRecording(question?._id);
              }).catch((err) =>
                console.error("Error accessing microphone: ", err)
              );
            }
          }).catch((err) => console.error("Error enumerating devices: ", err));
        } else {
          Swal.fire({
            icon: "error",
            title: (('web_dynamic_question_swal_message_two')),
          });
        }
      }
    }
  }
  const stopRecording = () => {
    setVoiceMemeRecording(false);
    mediaRecorderRef.current.stop();
  };
  const handleDataAvailable = (event) => {
    if (event?.data?.size > 0) {
      recordedVoiceMemoChunks.current.push(event.data);
    }
  };

  const handleStop = (question) => {
    const blob = new Blob(recordedVoiceMemoChunks.current, { type: "audio/webm" });
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result;
      let recordedFile = dataURLtoFile(base64Data, `voice_memo-${question?._id}-${timeStamp()}.mp3`);
      myForm.setFieldValue(`questions.${question?._id}`, recordedFile);
    };
    reader.readAsDataURL(blob);
  };

  function deleteRecordedVoiceMemo(question){
    setVoiceMemeRecording(false);
    myForm.setFieldValue(`questions.${question?._id}`, null);
  }
  const startTimer = () => {
    setRecordingTimer(0);
    timerIntervalRef.current = setInterval(() => {
      setRecordingTimer((prevTimer) => {
        if(prevTimer < MAX_VOICE_MEMO_SECONDS){
          return prevTimer + 1;
        }else{
          stopRecording();
          Swal.fire({
            icon: "warning",
            title: `(DT('web_dynamic_question_swal_message_three',[MAX_VOICE_MEMO_SECONDS])) ${MAX_VOICE_MEMO_SECONDS} ${MAX_VOICE_MEMO_SECONDS<60?(t("web_dynamic_question_swal_message_seconds")):(t("web_dynamic_question_swal_message_minutes"))}.`,
          });
          return prevTimer;
        }
      });
    }, 1000);
  };
  const stopTimer = () => {
    clearInterval(timerIntervalRef.current);
  };
  useEffect(() => {
    if (voiceMemeRecording) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [voiceMemeRecording]);
  function isVoiceMemoEditable(question) {
    if(URL_REGEX.test(myForm?.values?.questions[question?._id])){
      return true;
    }
    return false;
  }
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  function handleSelectVoiceMemo(e, question){
    if(e.target.files[0]){
      myForm.setFieldValue(`questions.${question?._id}`, e.target.files[0]);
    }
  }
  /** Voice memo <<<<< */

  /** Multiple image upload >>>>> */
  const isImageEditable = (img) => {
    if(URL_REGEX.test(img)){
      return true;
    }
    return false;
  };
  const handleImageSelection = (event, question) => {
    if(event?.target?.files?.length){
      const existingFiles = [...myForm.values?.questions[question?._id]];
      Object.values(event?.target?.files).forEach(file=>{
        existingFiles.push(file);
      });
      myForm.setFieldValue(`questions.${question?._id}`, existingFiles);
    }
  };
  const deleteImage = (question, index, inputId) => {
    let files = [...myForm?.values?.questions[question?._id]];
    files.splice(index,1);
    if(files.length === 0){
      document.getElementById(inputId).value = "";
    }
    myForm.setFieldValue(`questions.${question?._id}`,files);
  };
   /** Multiple image upload <<<<< */

  /** Multiple video upload >>>>> */
  const isVideoEditable = (img) => {
    if(URL_REGEX.test(img)){
      return true;
    }
    return false;
  };
  const handleVideoSelection = (event, question) => {
    if(event?.target?.files?.length){
      const existingFiles = [...myForm.values?.questions[question?._id]];
      Object.values(event?.target?.files).forEach(file=>{
        existingFiles.push(file);
      });
      myForm.setFieldValue(`questions.${question?._id}`, existingFiles);
      Promise.allSettled(Object.values(event?.target?.files).map((file,i)=> uploadFilesInChunks(file, question, (existingFiles.length-1)+i))).then((data)=>{
        data.forEach((action, ind)=>{
          if(action?.status === "fulfilled"){
            // console.log("before set valueData",valueData)
            
          }
        })
      }).catch((err)=>{
        console.log(err);
      })
    }
  };
  const deleteVideo = (question, index, inputId) => {
    let files = [...myForm?.values?.questions[question?._id]];
    files.splice(index,1);
    if(files.length === 0){
      document.getElementById(inputId).value = "";
    }
    myForm.setFieldValue(`questions.${question?._id}`,files);
  };
  const playVideo = (question, video) => {
    setVideoPlaySource(video);
    videoPlayerToggler?.current?.click();
  };
  const isVideoUploading = (question, index) => {
    if(videoUploadingProgress && videoUploadingProgress[question?._id] && videoUploadingProgress[question?._id][index]){
      return true;
    }
    return false;
  };
  const updateLoading = (lastUploadedChunk, question, index) => {
    return new Promise((resolve)=>{
      if(lastUploadedChunk?.uploadProgress === 100){
        setVideoUploadingProgress(progress=>{
          let fileProgress = {...progress};
          fileProgress[question?._id]={};
          fileProgress[question?._id][index]=lastUploadedChunk;
          return fileProgress;
        });
        setTimeout(() => {
          setVideoUploadingProgress(progress=>{
            let valueData = [...myForm?.values?.questions[question?._id]];
            valueData[index] = `${process.env.REACT_APP_SERVER_BASE_URL}/uploads/${videoUploadPath}/${lastUploadedChunk?.fileName}`;
            myForm?.setFieldValue(`questions.${question?._id}`, valueData);
            let fileProgress = {...progress};
            fileProgress[question?._id]={};
            delete fileProgress[question?._id][index];
            return fileProgress;
          });
          resolve(true);
        }, 1000);
      }else{
        setVideoUploadingProgress(progress=>{
          let fileProgress = {...progress};
          fileProgress[question?._id]={};
          fileProgress[question?._id][index]=lastUploadedChunk;
          return fileProgress;
        });
        resolve(true);
      }
    })
  };
  const uploadFilesInChunks = (fileToUpload, question, index) => {
    let lastUploadedChunk = {};
    const userData = getUser();
    return new Promise(async (resolve) => {
        let currentChunk = 0;
        const file = fileToUpload;
        const myChunks = await getChunks(file);
        const totalChunks = myChunks.length;
        const timeStamp = (new Date()).getTime();
        function uploadChunk() {
            let formData = new FormData();
            formData.append("name", timeStamp + file.name);
            formData.append("size", file.size);
            formData.append("mimetype", file.mimetype);
            formData.append("tempFilePath", file.tempFilePath);
            formData.append("allChunks", totalChunks);
            formData.append("file_chunk", myChunks[currentChunk]);
            formData.append("currentChunk", currentChunk);
            formData.append("upload_path", videoUploadPath);
            if (currentChunk < totalChunks) {
                $.ajax({
                    url: `${process.env.REACT_APP_API_URL}/web/other/upload-file-chunks`,
                    headers: {
                        "Authorization": `Bearer ${userData && userData.token}`
                    },
                    contentType: false,
                    processData: false,
                    type: 'POST',
                    data: formData,
                    success: function (data) {
                      if (data.success) {
                        lastUploadedChunk = data?.data;
                        updateLoading(data?.data, question, index).then((resultPromise) => {
                          currentChunk++;
                          uploadChunk();
                        });                            
                      }
                    }
                });
            } else {
              resolve(lastUploadedChunk);
            }
        };
        uploadChunk();
    });
  }
   /** Multiple video upload <<<<< */
  return (
    <div>
      <form onSubmit={myForm.handleSubmit}>
          {questions.map((question, index) => {
            if(question?.question_type === QUESTION_TYPES.SHORT_ANS){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <input type="text" className="form-control" name={`questions[${question._id}]`} id={'label'+index}
                    value={myForm?.values?.questions[question._id]} onChange={myForm.handleChange}/>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.LONG_ANS){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <textarea type="text" className="form-control note-min" name={`questions[${question._id}]`} id={'label'+index}
                    value={myForm?.values?.questions[question._id]} onChange={myForm.handleChange} ></textarea>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.MULTIPLE_CHOICE){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                    <ul className="check-list questions-ul">
                      {question?.options?.length && question.options.map((option, i) => (
                          <li key={i} className='pb-0'>
                              <div className="form-check">
                                  <input
                                      type="checkbox"
                                      id={'label'+index+i}
                                      name={`questions[${question._id}]`}
                                      value={option}
                                      checked={multiSelectIsChecked(question, option)}
                                      onChange={(e)=>{handleMultipleChoice(e, question)}}
                                      className={`form-check-input cp`}
                                  />
                                  <label className="form-check-label cp" htmlFor={'label'+index+i}>{capitalizeFirstLetter(option)}</label>
                              </div>
                          </li>
                      ))}
                  </ul>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.SINGLE_CHOICE){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <ul className='check-list questions-ul'>
                    {question?.options?.length && question.options.map((option, i) => (
                      <li key={i} className='pb-0'>
                        <div className="form-radio">
                          <input type="radio" id={'label' + index + i} name={`questions[${question._id}]`} value={option} onChange={myForm.handleChange}
                          checked={singleChoiceIsChecked(question, option)}
                          className={`form-radio-input cp`} />
                          <label className="ms-1 form-radio-label cp" htmlFor={'label' + index + i}>{capitalizeFirstLetter(option)}</label>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.SLIDER){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                    <div className="d-flex align-items-center">
                      <span>{String(question?.min_slider_value)}</span>
                      <div style={{flex:1}} className='mx-1'>
                        <Slider min={question?.min_slider_value ? Number(question?.min_slider_value) : 0} max={question?.max_slider_value ? Number(question?.max_slider_value) : 100} 
                        value={myForm?.values?.questions[question._id]} onChange={(value)=>{ myForm.setFieldValue(`questions.${[question._id]}`,value); }} className='cp'
                        handleLabel={myForm?.values?.questions[question._id]>99?"99+":String(myForm?.values?.questions[question._id])} />
                      </div>
                      <span>{String(question?.max_slider_value)}</span>
                    </div>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.EMOJI){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <ul className="select-face-list questions-ul">
                      {question.emoji_options.map((option, i) => (
                        <li key={i}>
                          <div className="form-radio">
                            <input type="radio" id={'label' + index + i} name={`questions[${question._id}]`} value={option}
                              onChange={myForm.handleChange} className={`form-check-input cp option-emoji-input`} checked={singleChoiceIsChecked(question, option)} />
                            <label className="form-check-label ms-1 cp" htmlFor={'label' + index + i}><span className="option-emoji" style={{ fontSize: '40px' }}>{option}</span></label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.DROPDOWN){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <select className="form-select" name={`questions[${question._id}]`} id={'label'+index} onChange={myForm.handleChange}
                  value={myForm?.values?.questions[question._id]} >
                    <option value={""}>Select</option>
                    {question?.options?.length && question.options.map((option, i) => (
                      <option key={i} value={option}>{capitalizeFirstLetter(option)}</option>
                    ))}
                  </select>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.RATING){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className='d-block'>
                    <Rating initialValue={myForm?.values?.questions[question._id]} onClick={(e) => myForm.setFieldValue(`questions.${[question._id]}`, e)} />
                  </div>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.LIKE_DISLIKE){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <ul className="select-face-list questions-ul">
                    <li>
                      <div className="form-radio">
                        <input type="radio" id={'label-like' + index} name={`questions[${question._id}]`} value={1}
                          onChange={(e)=>{myForm.setFieldValue(`questions.${question._id}`,Number(e.target.value))}} className={`form-like-input cp`} checked={singleChoiceIsChecked(question, 1)} />
                        <label className="form-like-label ms-1 cp" htmlFor={'label-like' + index} title='Like'>
                          <i className="ri-thumb-up-fill cp"></i>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-radio">
                        <input type="radio" id={'label-dislike' + index} name={`questions[${question._id}]`} value={0}
                          onChange={(e)=>{myForm.setFieldValue(`questions.${question._id}`,Number(e.target.value))}} className={`form-like-input cp`} checked={singleChoiceIsChecked(question, 0)} />
                        <label className="form-like-label ms-1 cp" htmlFor={'label-dislike' + index} title='Dislike'>
                          <i className="ri-thumb-down-fill cp"></i>
                        </label>
                      </div>
                    </li>
                    </ul>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.TIME){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <input type="time" className="form-control cp" name={`questions[${question._id}]`} id={'label' + index} onClick={(e) => {
                    e.target.showPicker();
                  }}
                    value={myForm?.values?.questions[question._id]} onChange={myForm.handleChange}/>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.CALENDAR){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <input type="date" className="form-control cp" name={`questions[${question._id}]`} id={'label'+index} onClick={(e) => {
                    e.target.showPicker();
                  }}
                    value={myForm?.values?.questions[question._id]} onChange={myForm.handleChange}/>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.SIGNATURE){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className="d-block" id={`${question?.question_type}-parent-div${question?._id}`}>
                    <div className="position-relative d-inline-block">
                      <Drawing className="rounded-2" ref={drawingRefs[`${question?._id}_${question?.question_type}`]}
                        parentId={`${question?.question_type}-parent-div${question?._id}`}
                        onChange={(e)=>{handleDrawingValue(e, question?.question_type, question)}} />
                      {isDrawingEditable(question) && !editDrawing[question?._id]?<img src={myForm?.values?.questions[question._id]} className='drawn-image' alt={question?.question_type} />:null}                        
                      {lockDrawing?<div className="drawing-lock"></div>:null}                      
                      <ul className="draw-actions-list">
                        <li>
                          <a href="/" title={lockDrawing?"Unlock":"Lock"} onClick={(e) => { e.preventDefault(); setLockDrawing(!lockDrawing); }} className="cp" >
                          {lockDrawing?<i className="ri-lock-fill" style={{color:'var(--active_text_colour)'}}></i>:<i className="ri-lock-unlock-fill text-dark"></i>}
                          </a>
                        </li>
                        {isDrawingEditable(question)?
                        <li>
                          {editDrawing && editDrawing[question?._id] ?
                            <a href="/" title={"Cancel"} onClick={(e) => { e.preventDefault(); changeEditDrawing(false, question); }} className="cp" >
                              <i className="ri-close-line text-dark"></i>
                            </a>
                            : 
                            <a href="/" title={"Edit"} onClick={(e) => { e.preventDefault(); changeEditDrawing(myForm?.values?.questions[question._id], question); }} className="cp" >
                              <i className="ri-edit-2-fill text-dark"></i>
                            </a>
                          }
                        </li>:null
                        }
                        {myForm?.values?.questions[question._id] ? (
                          <li>
                            <a href="/" onClick={(e) => { e.preventDefault(); clearDrawing(question); }} className="cp text-danger" title="Clear" >
                              <i className="ri-delete-bin-6-fill"></i>
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.FREE_HAND_DRAWING){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className="d-block" id={`${question?.question_type}-parent-div${question?._id}`}>
                    <div className="position-relative d-inline-block">
                      <Drawing className="rounded-2" ref={drawingRefs[`${question?._id}_${question?.question_type}`]}
                        parentId={`${question?.question_type}-parent-div${question?._id}`} canvasHeight="400"
                        onChange={(e)=>{handleDrawingValue(e, question?.question_type, question)}} />
                      {isDrawingEditable(question) && !editDrawing[question?._id]?<img src={myForm?.values?.questions[question._id]} className='drawn-image' alt={question?.question_type} />:null}                        
                      {lockDrawing?<div className="drawing-lock"></div>:null}                      
                      <ul className="draw-actions-list">
                        <li>
                          <a href="/" title={lockDrawing?"Unlock":"Lock"} onClick={(e) => { e.preventDefault(); setLockDrawing(!lockDrawing); }} className="cp" >
                          {lockDrawing?<i className="ri-lock-fill" style={{color:'var(--active_text_colour)'}}></i>:<i className="ri-lock-unlock-fill text-dark"></i>}
                          </a>
                        </li>
                        {isDrawingEditable(question)?
                        <li>
                          {editDrawing && editDrawing[question?._id] ?
                            <a href="/" title={"Cancel"} onClick={(e) => { e.preventDefault(); changeEditDrawing(false, question); }} className="cp" >
                              <i className="ri-close-line text-dark"></i>
                            </a>
                            : 
                            <a href="/" title={"Edit"} onClick={(e) => { e.preventDefault(); changeEditDrawing(myForm?.values?.questions[question._id], question); }} className="cp" >
                              <i className="ri-edit-2-fill text-dark"></i>
                            </a>
                          }
                        </li>:null
                        }
                        {myForm?.values?.questions[question._id] ? (
                          <li>
                            <a href="/" onClick={(e) => { e.preventDefault(); clearDrawing(question); }} className="cp text-danger" title="Clear" >
                              <i className="ri-delete-bin-6-fill"></i>
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.VOICE_MEMO){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className="recording-box rounded-2">
                    <div className="d-flex align-item-center">
                      <div className="mic mt-1">
                        <i className={`fas fa-microphone`} style={{
                          fontSize: "3em", color: voiceMemeRecording === question?._id ? "var(--primary_active_color)" : "black",
                        }}
                        ></i>
                      </div>
                      <div className="record-text">
                        <h4>{t('web_dynamic_question_audio_recorder')}</h4>
                        {voiceMemeRecording === question?._id?
                          <p>{t('web_dynamic_question_recording')}: {formatTime(recordingTimer)}</p>
                        :null}                        
                      </div>
                    </div>
                    {myForm?.values?.questions[question._id] ?
                      <>
                        <div className="recording-controls">
                          <div className="recordings d-flex">
                            {isVoiceMemoEditable(question)?
                              <audio style={{ maxHeight: "41px" }} src={myForm?.values?.questions[question._id]} controls />
                              :
                              <audio style={{ maxHeight: "41px" }} src={URL.createObjectURL(myForm?.values?.questions[question._id])} controls />
                            }
                          </div>
                        </div>
                        <div className="buttons">
                          <button onClick={() => {deleteRecordedVoiceMemo(question)}} type="button" className="btn btn-danger" >
                           {t('web_dynamic_question_delete_recording')}
                          </button>
                        </div>
                      </>
                      :
                      <div className="buttons">
                        {voiceMemeRecording === question?._id  ?
                          <button onClick={() => { stopRecording() }} type="button" className="btn btn-secondary mt-1" >
                           {t('web_dynamic_question_stop_recording')}
                          </button>
                          :
                          <div className="btn-group mt-1" role="group" aria-label="Basic example">
                            <button type="button" className="btn btn-primary" onClick={() => { startRecording(question); }}>{t('web_dynamic_question_record')}</button>
                            <button type="button" className="btn btn-dark cp position-relative">
                              <label htmlFor={`voice-memo-select-${question?._id}`} className='cp position-absolute w-100 h-100 d-flex align-items-center justify-content-center'>{t('web_dynamic_question_select')}</label>
                              <input id={`voice-memo-select-${question?._id}`} onChange={(e)=>{handleSelectVoiceMemo(e, question)}} type='file' className='d-none' accept='audio/*'/>
                            </button>
                          </div>
                        }
                      </div>
                    }
                  </div>
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.PHOTO_UPLOAD){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className="files-count-container">
                    <input className="form-control form-control-sm h-auto" type="file" id={'label'+index} onChange={(e)=>{handleImageSelection(e, question)}} 
                    accept='image/*' multiple={true}/>
                    {myForm?.values?.questions[question._id] && myForm?.values?.questions[question._id].length?
                      <span className='files-count'>{myForm?.values?.questions[question._id].length} {pluralString(myForm?.values?.questions[question._id].length, "files","file")} {t('web_dynamic_question_selected')}</span>
                      :null
                    }
                  </div>
                  {myForm?.values?.questions[question._id] && myForm?.values?.questions[question._id].length ?
                    <ul className='question-image-preview questions-ul'>
                      {myForm?.values?.questions[question._id].map((image,imgIndex)=>{
                        return (
                          <li className="pr_img_box" key={imgIndex}>
                            {isImageEditable(image)?
                              <img src={image} alt="Image" />
                              :
                              <img src={URL.createObjectURL(image)} alt="Image" />
                            }
                            <div className="img_options">
                              <button type='button' className='text-danger' onClick={()=>{deleteImage(question, imgIndex, 'label'+index)}}><i className="ri-delete-bin-6-fill"></i></button>
                            </div>
                          </li>
                        )
                      })
                      }
                    </ul>
                    :null
                  }
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.VIDEO_UPLOAD){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label "+questionTitleClass} htmlFor={'label'+index}>{showCount?(getValidCount(index))+".":null} {question?.title} {question?.is_required ? <span className="requirestar">*</span>:null}</label>
                  <div className="files-count-container">
                    <input className="form-control form-control-sm h-auto" type="file" id={'label'+index} onChange={(e)=>{handleVideoSelection(e, question)}} 
                    accept='video/*'/>
                    {myForm?.values?.questions[question._id] && myForm?.values?.questions[question._id].length?
                      <span className='files-count'>{myForm?.values?.questions[question._id].length} {pluralString(myForm?.values?.questions[question._id].length, "files","file")} {t('web_dynamic_question_selected')}</span>
                      :null
                    }
                  </div>
                  {myForm?.values?.questions[question._id] && myForm?.values?.questions[question._id].length ?
                    <ul className='question-video-preview questions-ul'>
                      {myForm?.values?.questions[question._id].map((video,vidIndex)=>{
                        return (
                          <li className="pr_video_box position-relative" key={vidIndex}>
                            <span className='d-flex align-items-center cp play_btn btn-hover no-select' onClick={()=>{playVideo(question, video)}}>
                              <i className="ri-play-fill"></i>
                              {t('web_dynamic_question_video_file')} {vidIndex+1}
                            </span>
                            <span className='vr_divider'></span>
                            <span className='text-danger cp btn-hover no-select' onClick={()=>{deleteVideo(question, vidIndex, 'label'+index)}}><i className="ri-delete-bin-6-fill"></i></span>
                            {isVideoUploading(question, vidIndex) ?
                              <div className='question-video-upload-progress no-select'>
                                <div className="progress">
                                  <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${videoUploadingProgress[question?._id][vidIndex]['uploadProgress']}%` }}></div>
                                </div>
                                <span className="progress-text">{t('web_dynamic_question_uploading')}</span>
                              </div>
                              : null
                            }
                          </li>
                        )
                      })
                      }
                    </ul>
                    :null
                  }
                  {myForm.touched.questions && myForm.touched.questions[question._id] && myForm.errors.questions && myForm.errors.questions[question._id] && (
                    <span className='text-danger'>{myForm.errors.questions[question._id]}</span>
                  )}
                  <div dangerouslySetInnerHTML={{__html:footer}}></div>
                </div>
              )
            } else if(question?.question_type === QUESTION_TYPES.EXTRA_INFORMATION){
              return (
                <div key={index} className={getClassName(index,question)}>
                  <label className={"form-label text-muted pb-0 "+questionTitleClass} htmlFor={'label'+index}>{question?.title}</label>
                  
                  <div dangerouslySetInnerHTML={{__html:question?.description}}></div>
                  {/* <div dangerouslySetInnerHTML={{__html:footer}}></div> */}
                </div>
              )
            }
          })}
        </form>
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#videoPlayerModal" ref={videoPlayerToggler}></button>
        <div className="modal fade" id="videoPlayerModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="videoPlayerModalLabel">{t('web_dynamic_question_playing')} : {getFileNameFromURL(videoPlaySource)}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{setTimeout(() => {
                  setVideoPlaySource(null);
                }, 300);}}></button>
              </div>
              <div className="modal-body">
                {videoPlaySource?
                  <VideoPlayer src={videoPlaySource}/>
                  :null
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  )
});

export default DynamicQuestions;