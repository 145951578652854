import React, { useEffect, useState, useLayoutEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setUserType } from '../../../redux/slices/userType';
import tinycolor from 'tinycolor2';
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import { globalSetting } from '../services/common.services';
import GlobalLoader from './globalLoader';
import { getLocalKey, getSessionKey, logOutUser, setLocalKey, setSessionKey, getUser } from '../../../utils/commonfunction';
import { I18nextProvider } from 'react-i18next';
import i18n, {t} from "i18next";
import { addData } from '../../../redux/slices/globalData';
import LanguageDetector from "i18next-browser-languagedetector";
import { countClick } from '../services/webother.service';
import { SOCKET_CLIENT } from '../../../utils/socket';
import Swal from 'sweetalert2';
import { SWAL_SETTINGS } from '../../../utils/Constants';

function FrontRoot() {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const pathname = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  const navigate = useNavigate();

  useEffect(()=>{
    const user = getUser();
    if(user){
        let joinRequest = {
            room: user._id
        };
        SOCKET_CLIENT.emit('notificationJoin', joinRequest, (error) => {
            if(error){
                console.log("*****Socket connection error on notificationJoin", error);
            }
        });
    }
    
   },[pathname]);

   useEffect(() => {
    SOCKET_CLIENT.on('logoutUser', (data) => {
      console.log("logoutUser data",data)
        Swal.fire({
            icon: "error",
            text: data.message,
            ...SWAL_SETTINGS,
            timer: 4000
        });
        logOutUser(navigate);
    });
}, []);

  useMemo(() => {
    globalSetting().then(async (globalDetails) => {
      dispatch(addData(JSON.stringify(globalDetails?.data)));
        i18n.use(LanguageDetector).init({
          resources: {
            en: {
              translation: globalDetails && globalDetails?.data?.translations_en && globalDetails?.data?.translations_en?.json ? globalDetails?.data?.translations_en?.json : {}
            },
            de: {
              translation: globalDetails && globalDetails?.data?.translations_de && globalDetails?.data?.translations_de?.json ? globalDetails?.data?.translations_de?.json : {}
            }
          }
        });
      /**setting default language */
      let usersSelectedLanguage = getLocalKey("system_language");
      if (!usersSelectedLanguage) {
        i18n.changeLanguage(globalDetails && globalDetails.data && globalDetails?.data?.settings?.default_language ? globalDetails?.data?.settings?.default_language : "en");
      }
      /** setting up portal designs >>>> */
      const portalDesignData = globalDetails?.data?.portal_design || {};
      if(portalDesignData?.custom_css){
        const style = document.createElement("style");
        style.id = "custom_css";
        style.innerHTML = portalDesignData?.custom_css;
        document.querySelector("head").appendChild(style);
      }
      let r = document.querySelector(':root');
      r.style.setProperty('--font-family', portalDesignData?.general?.fontFamily);
      r.style.setProperty('--banner_image', `url(${portalDesignData?.inner_banner})`);
      r.style.setProperty('--header_background_color', portalDesignData?.background_colors?.header_background_color);
      r.style.setProperty('--footer_background_color', portalDesignData?.background_colors?.footer_background_color);
      r.style.setProperty('--footer_bottom_background_color', portalDesignData?.background_colors?.footer_bottom_background_color);
      r.style.setProperty('--light_active_color', tinycolor(portalDesignData?.background_colors?.primary_active_color).setAlpha(0.14));
      r.style.setProperty('--bright_active_color', tinycolor(portalDesignData?.background_colors?.primary_active_color).brighten(8));
      r.style.setProperty('--primary_active_color', portalDesignData?.background_colors?.primary_active_color);
      r.style.setProperty('--secondary_active_color', portalDesignData?.background_colors?.secondary_active_color);
      r.style.setProperty('--fontsize_h2', portalDesignData?.font_sizes?.h2);
      r.style.setProperty('--fontsize_h3', portalDesignData?.font_sizes?.h3);
      r.style.setProperty('--fontsize_h1', portalDesignData?.font_sizes?.h1);
      r.style.setProperty('--fontsize_h4', portalDesignData?.font_sizes?.h4);
      r.style.setProperty('--fontsize_h5', portalDesignData?.font_sizes?.h5);
      r.style.setProperty('--fontsize_h6', portalDesignData?.font_sizes?.h6);
      r.style.setProperty('--paragraph', portalDesignData?.font_sizes?.paragraph);
      r.style.setProperty('--primary_text_color_white', portalDesignData?.font_colors?.primary_color_white);
      r.style.setProperty('--paragraph_text_color', portalDesignData?.font_colors?.paragraph_color);
      r.style.setProperty('--primary_text_color_black', portalDesignData?.font_colors?.primary_color_black);
      r.style.setProperty('--active_text_colour', portalDesignData?.font_colors?.active_color);
      document.getElementById('fav_icon').href = portalDesignData?.favicon_icon;
      document.getElementById('dynamicTitle').innerText = portalDesignData?.general?.title;
      /** setting up portal designs <<<< */
  
      setTimeout(() => {
        dispatch(setGlobalLoader(false));
        setIsLoading(false);
        
      }, 100);
    });
  }, []);

  useEffect(() => {
    if (myGlobalData && myGlobalData?.data) {
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  }, [myGlobalData]);

  useEffect(() => {
    /** if web is running in app */
    if (params && params.get("running_in_app") === "true") {
      setSessionKey("running_in_app", "true");
    }
    dispatch(setUserType("user"));
  }, []);

  useEffect(() => {
    const url = window.location.href;
    let urlNew = url;
    let urlObj = new URL(urlNew);
    let params2 = new URLSearchParams(urlObj.search);
    var countableValue = params2.get("countable");
    if (countableValue === "true") {
      const counts = getSessionKey("url_counts");
        let urls = [];
        try{
          urls = JSON.parse(counts);
        }catch(err){
          console.log(err);
        }  
        if (urls.indexOf(url) === -1) {
          urls.push(url);
          setSessionKey("url_counts", JSON.stringify(urls));
          let formData = new FormData();
          formData.append("url", url);
          countClick(formData).then((response) => {

          }).catch((error) => {
            console.log("error", error);
          });
        }
    }
  }, []);


    useLayoutEffect(() => {
        window.scrollTo(0,0)
    },[pathname])

    function shouldShowCookies(){
      const flag = getLocalKey('cookies_acceptance');
      if(flag && flag === "true"){
        return false;
      }else{
        return true;
      }
    }
    function acceptCookies(){
      setLocalKey('cookies_acceptance','true');
      setActionPerformed(true);
    }
    
    useLayoutEffect(() => {
      setIsLoading(true);
      dispatch(setUserType("user"));
    }, []);
  return (
    <>
  <button  className="d-none" id="logout-functionality" type="button" onClick={() => {logOutUser(navigate);}}></button>
    <I18nextProvider i18n={i18n}>
      {isLoading 
      ? <GlobalLoader /> 
      : <>
        <Outlet />
        {shouldShowCookies() && !actionPerformed ? (
          <div class="wrapper-cookies">
            <div class="title-box-cookies">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-cookie" viewBox="0 0 16 16">
                <path d="M6 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m4.5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m-.5 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
                <path d="M8 0a7.96 7.96 0 0 0-4.075 1.114q-.245.102-.437.28A8 8 0 1 0 8 0m3.25 14.201a1.5 1.5 0 0 0-2.13.71A7 7 0 0 1 8 15a6.97 6.97 0 0 1-3.845-1.15 1.5 1.5 0 1 0-2.005-2.005A6.97 6.97 0 0 1 1 8c0-1.953.8-3.719 2.09-4.989a1.5 1.5 0 1 0 2.469-1.574A7 7 0 0 1 8 1c1.42 0 2.742.423 3.845 1.15a1.5 1.5 0 1 0 2.005 2.005A6.97 6.97 0 0 1 15 8c0 .596-.074 1.174-.214 1.727a1.5 1.5 0 1 0-1.025 2.25 7 7 0 0 1-2.51 2.224Z" />
              </svg>
              <h3>{t('web_lbl_cookies_consent')}</h3>
            </div>
            <div class="info-cookies">
              <p dangerouslySetInnerHTML={{ __html: getGlobalData?.settings?.cookies_content && getGlobalData?.settings?.cookies_content ? getGlobalData?.settings?.cookies_content : "N/A" }}></p>
            </div>
            <div class="cookies-buttons">
              <button class="cookies-button btn-hover accept" id="acceptBtn" onClick={acceptCookies}>
                {t('web_lbl_cookies_consent_accept')}
              </button>
              <button class="cookies-button btn-hover bg-dark" onClick={() => setActionPerformed(true)}>{t('web_lbl_cookies_consent_decline')}</button>
            </div>
          </div>
        ) : null}
      </>}
    </I18nextProvider>
    </>
  );
}

export default FrontRoot;
